import { Input, Modal, Spin, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PhoneInput from "react-phone-number-input";
import { PhotoView } from 'react-photo-view';
import BaseService from '../../../helpers/baseService';
import { global_variables } from '../../../helpers/globalVariables';
import { DriverPayload } from './driverdata.model';
import moment from 'moment';
import { XMarkIcon } from '@heroicons/react/24/outline';


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}

const EditDriverDetails = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const user_name = query.get("uid");

    const [isFetching, setIsFetching] = useState(false)
    const [driverDetails, setDriverDetails] = useState<DriverPayload>()
    const [driverFName, setDriverFName] = useState("")
    const [driverLName, setDriverLName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [ghCard, setGhCard] = useState("")

    const [licenceNum, setLicenceNum] = useState("")
    const [issued_date, setIssued_date] = useState<any>(null)
    const [exp_date, setExp_date] = useState<any>(null)
    const [actRenew, setActRenew] = useState<any>(null)
    const [nextRenew, setNextRenew] = useState<any>(null)
    const [lastRenew, setLastRenew] = useState<any>(null)


    //onload
    useEffect(() => {
        const getDriverInfo = async () => {
            setIsFetching(true)
            try {
                const response = await BaseService.get_api_v2(`${global_variables.get_all_drivers_v2}?filter={"driver_data.full_name": {"$regex":${JSON.stringify(user_name)},"$options":"i"}}`)
                const theData: DriverPayload = response?.data?.payload?.[0]
                setDriverDetails(theData)
                setDriverFName(theData?.driver_data?.first_name)
                setDriverLName(theData?.driver_data?.last_name)
                setPhoneNumber(theData?.driver_data?.phone)
                setGhCard(theData?.national_id)
                setLicenceNum(theData?.licence)
                setIssued_date(moment(theData?.dateIssued).format("YYYY-MM-DD"))
                setExp_date(moment(theData?.dateExpired).format("YYYY-MM-DD"))
                setActRenew(moment(theData?.license_active_renewal).format("YYYY-MM-DD"))
                setNextRenew(moment(theData?.license_renewals?.[0]).format("YYYY-MM-DD"))
                setLastRenew(moment(theData?.license_renewals?.[1]).format("YYYY-MM-DD"))
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getDriverInfo()
    }, [user_name])

    //approve driver
    const approveDriver = async () => {
        var driver_data = {
            driverId: driverDetails?._id,
            approved: true,
            remarks: driverDetails?.driver_data
                ? `Congratulations ${driverDetails?.driver_data?.full_name}!`
                : "Congratulations!",
        };
        // console.log(driver_data);

        try {
            const response = await BaseService.put_api_v2(
                `${global_variables.approve_or_decline_driver_v2}`,
                driver_data
            );
            notification.success({ message: response?.data?.message })
            handleClose()
            handleReFetch()
        } catch (error: any) {
            console.log(error);
            notification.error({ message: error?.response?.data?.error })
        }
    };

    //handleSubmit
    const handleSubmit = async () => {
        if (!ghCard || !driverFName || !driverLName || !phoneNumber || !licenceNum || !issued_date || !exp_date || !actRenew || !nextRenew || !lastRenew) {
            notification.warning({ message: "Please fill out all fields" })
        }
        else {
            setIsFetching(true)

            let data =
            {
                "_id": driverDetails?._id,
                "userid": driverDetails?.userid,
                "licence": driverDetails?.licence,
                "dateIssued": issued_date,
                "dateExpired": exp_date,
                "licence_image": driverDetails?.licence_image,
                "licence_back_image": driverDetails?.licence_back_image,
                "national_id": ghCard,
                "national_id_front_image": driverDetails?.national_id_front_image,
                "national_id_back_image": driverDetails?.national_id_back_image,
                "license_renewals": [
                    nextRenew,
                    lastRenew
                ],
                "license_active_renewal": actRenew
            }

            try {
                const response = await BaseService.put_api_v2(global_variables.edit_driver_details_v2, data)
                notification.success({ message: response?.data?.message })
                approveDriver()
            } catch (error: any) {
                console.log(error)
                setIsFetching(false)
                notification.error({ message: error?.response?.data?.error })
            }
        }
    }


    return (
        <>
            <Modal open={isOpened} width={1000} footer={null} keyboard={false} closeIcon={false}>
                <Spin spinning={isFetching}>
                    <div className='text-yarpGray'>
                        {/* personal details */}
                        <div>
                            <div className="flex justify-between items-center">
                                <p className='text-[20px] font-[600] mb-0'>Personal Details</p>
                                <button className='w-10 h-10' onClick={handleClose}><XMarkIcon className='w-5 h-5 cursor-pointer' /></button>
                            </div>
                            <hr className='mt-0' />

                            <div className='lg:flex items-center lg:gap-[50px]'>
                                <PhotoView
                                    src={driverDetails?.driver_data?.image || "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"}
                                >
                                    <img className='h-[153px] w-[153px] object-cover rounded-full shadow-sm' src={driverDetails?.driver_data?.image || "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"} alt='user' />
                                </PhotoView>
                                <div>
                                    <div className='flex gap-[50px] items-center w-[400px]'>
                                        <p className='text-[14px] font-[600] w-full'>First Name</p>
                                        <Input value={driverFName} onChange={(e) => setDriverFName(e.target.value)} />
                                    </div>
                                    <div className='flex gap-[50px] items-center w-[400px] mt-[20px]'>
                                        <p className='text-[14px] font-[600] w-full'>Last Name</p>
                                        <Input value={driverLName} onChange={(e) => setDriverLName(e.target.value)} />
                                    </div>
                                    <div className='flex gap-[50px] items-center w-[400px] mt-[20px]'>
                                        <p className='text-[14px] font-[600] w-full'>Phone No.</p>
                                        <PhoneInput
                                            id="phone"
                                            className="rounded-[5px] w-full border-[1px] border-gray-500 px-2 h-[40px]"
                                            defaultCountry="GH"
                                            inputStyle={{ width: "100%" }}
                                            numberInputProps={{
                                                className: "focus:outline-none"
                                            }}
                                            value={phoneNumber}
                                            onChange={(phone: any) => setPhoneNumber(phone)}
                                        //   disabled={isBusy}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* national id */}
                        <div className='mt-[30px]'>
                            <p className='text-[20px] font-[600] mb-0'>National ID</p>
                            <hr className='mt-0' />

                            <div className='flex gap-[50px] items-center w-[400px]'>
                                <p className='text-[14px] font-[600] w-full'>Ghana Card NO.</p>
                                <div className="w-[700px]">
                                    <Input className='w-full' value={ghCard} onChange={(e) => setGhCard(e.target.value)} placeholder='GHA-XXXXXXXXX-X' />
                                </div>
                            </div>

                            <div className="mt-[22px] lg:flex gap-[30px] w-[700px]">
                                <div className="w-full">
                                    <PhotoView
                                        src={driverDetails?.national_id_front_image || "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"}
                                    >
                                        <img
                                            className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                                            src={driverDetails?.national_id_front_image || "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"}
                                            alt="front"
                                        />
                                    </PhotoView>
                                    <p>Front view</p>
                                </div>
                                <div className="w-full">
                                    <PhotoView
                                        src={driverDetails?.national_id_front_image || "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"}
                                    >
                                        <img
                                            className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                                            src={driverDetails?.national_id_back_image || "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"}
                                            alt="front"
                                        />
                                    </PhotoView>
                                    <p>Back view</p>
                                </div>
                            </div>
                        </div>

                        {/* Lincence Details  */}
                        <div className='mt-[30px]'>
                            <p className='text-[20px] font-[600] mb-0'>Lincence Details</p>
                            <hr className='mt-0' />

                            <div className="lg:grid lg:grid-cols-3 gap-[20px] flex-wrap w-[700px]">
                                <div className='w-full'>
                                    <p className='text-[14px] font-[600] w-full mb-1'>License No</p>
                                    <Input value={licenceNum} onChange={(e) => setLicenceNum(e.target.value)} placeholder='Type here' />
                                </div>
                                <div className='w-full'>
                                    <p className='text-[14px] font-[600] w-full mb-1'>Issued Date</p>
                                    <input value={issued_date} onChange={(e) => setIssued_date(e.target.value)} type="date" className='w-full border-[1px] border-gray-400 rounded-[5px] h-[35px] px-2' />
                                </div>
                                <div className='w-full'>
                                    <p className='text-[14px] font-[600] w-full mb-1'>Expiry Date</p>
                                    <input value={exp_date} onChange={(e) => setExp_date(e.target.value)} type="date" className='w-full border-[1px] border-gray-400 rounded-[5px] h-[35px] px-2' />
                                </div>
                                <div className='w-full'>
                                    <p className='text-[14px] font-[600] w-full mb-1'>Active Renewal</p>
                                    <input value={actRenew} onChange={(e) => setActRenew(e.target.value)} type="date" className='w-full border-[1px] border-gray-400 rounded-[5px] h-[35px] px-2' />
                                </div>
                                <div className='w-full'>
                                    <p className='text-[14px] font-[600] w-full mb-1'>Next Renewal</p>
                                    <input value={nextRenew} onChange={(e) => setNextRenew(e.target.value)} type="date" className='w-full border-[1px] border-gray-400 rounded-[5px] h-[35px] px-2' />
                                </div>
                                <div className='w-full'>
                                    <p className='text-[14px] font-[600] w-full mb-1'>Last Renewal</p>
                                    <input value={lastRenew} onChange={(e) => setLastRenew(e.target.value)} type="date" className='w-full border-[1px] border-gray-400 rounded-[5px] h-[35px] px-2' />
                                </div>
                            </div>

                            {/* license images */}
                            <div className="mt-[30px] lg:flex gap-[30px] w-[700px]">
                                <div className="w-full">
                                    <PhotoView
                                        src={driverDetails?.licence_image || "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"}
                                    >
                                        <img
                                            className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                                            src={driverDetails?.licence_image || "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"}
                                            alt="front"
                                        />
                                    </PhotoView>
                                    <p>Front view</p>
                                </div>
                                <div className="w-full">
                                    <PhotoView
                                        src={driverDetails?.licence_back_image || "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"}
                                    >
                                        <img
                                            className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                                            src={driverDetails?.licence_back_image || "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"}
                                            alt="front"
                                        />
                                    </PhotoView>
                                    <p>Back view</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />
                    <div className='flex justify-end gap-4'>
                        <button className='' onClick={() => handleClose()}>
                            Cancel
                        </button>
                        <button className='px-3 py-2 rounded-[30px] bg-yarpOrange text-white' onClick={() => handleSubmit()}>
                            Save & Approve
                        </button>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default EditDriverDetails
