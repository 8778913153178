export const caseInsensitiveSort = (rowA: any, rowB: any) => {
  const a = rowA.full_name.toLowerCase();
  const b = rowB.full_name.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const modelSort = (rowA: any, rowB: any) => {
  const a = rowA.model.toLowerCase();
  const b = rowB.model.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const roadworthy_textSort = (rowA: any, rowB: any) => {
  const a = rowA.roadworthy_text.toLowerCase();
  const b = rowB.roadworthy_text.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const VEHICLETYPESort = (rowA: any, rowB: any) => {
  const a = rowA.car_type.toLowerCase();
  const b = rowB.car_type.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const OWNERSHIPSort = (rowA: any, rowB: any) => {
  const a = rowA.type.toLowerCase();
  const b = rowB.type.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const INSURANCESort = (rowA: any, rowB: any) => {
  const a = rowA.insurance_text.toLowerCase();
  const b = rowB.insurance_text.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const GoodsOwnerSort = (rowA: any, rowB: any) => {
  const a = rowA.user_data?.full_name.toLowerCase();
  const b = rowB.user_data?.full_name.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const DriverNameSort = (rowA: any, rowB: any) => {
  const a = rowA.driver_data?.full_name.toLowerCase();
  const b = rowB.driver_data?.full_name.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const method = (rowA: any, rowB: any) => {
  const a = rowA.method.toLowerCase();
  const b = rowB.method.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const BUSINESSSort = (rowA: any, rowB: any) => {
  const a = rowA.business_name.toLowerCase();
  const b = rowB.business_name.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const EMAILSSort = (rowA: any, rowB: any) => {
  const a = rowA.business_email.toLowerCase();
  const b = rowB.business_email.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const business_locationsort = (rowA: any, rowB: any) => {
  const a = rowA.business_email.toLowerCase();
  const b = rowB.business_email.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};
export const tripAmountSort = (rowA: any, rowB: any) => {
  const a = rowA.tripAmount;
  const b = rowB.tripAmount;

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const BALANCESort = (rowA: any, rowB: any) => {
  const a = rowA.amount;
  const b = rowB.amount;

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const phoneSort = (rowA: any, rowB: any) => {
  const a = rowA.phone;
  const b = rowB.phone;

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const driversensitiveSort = (rowA: any, rowB: any) => {
  const a = rowA.fullName.toLowerCase();
  const b = rowB.fullName.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const DeliveryLocation = (rowA: any, rowB: any) => {
  const a = rowA.delivery_place_name.toLowerCase();
  const b = rowB.delivery_place_name.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const truckRegisterationSort = (rowA: any, rowB: any) => {
  const a = rowA.truckRegisteration.toLowerCase();
  const b = rowB.truckRegisteration.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const PickupLocation = (rowA: any, rowB: any) => {
  const a = rowA.pickup_place_name.toLowerCase();
  const b = rowB.pickup_place_name.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};
//...........................
export const DateSort = (rowA: any, rowB: any) => {
  const a = new Date(rowA.login_at);
  const b = new Date(rowB.login_at);
  const timestampA = a.getTime();
  const timestampB = b.getTime();

  if (timestampA > timestampB) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const roadworthy_expireSort = (rowA: any, rowB: any) => {
  const a = new Date(rowA.roadworthy_expire);
  const b = new Date(rowB.roadworthy_expire);
  const timestampA = a.getTime();
  const timestampB = b.getTime();

  if (timestampA > timestampB) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const DATEEXPIREDsort = (rowA: any, rowB: any) => {
  const a = new Date(rowA.dateExpired);
  const b = new Date(rowB.dateExpired);
  const timestampA = a.getTime();
  const timestampB = b.getTime();

  if (timestampA > timestampB) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const insurance_expireSort = (rowA: any, rowB: any) => {
  const a = new Date(rowA.insurance_expire);
  const b = new Date(rowB.insurance_expire);
  const timestampA = a.getTime();
  const timestampB = b.getTime();

  if (timestampA > timestampB) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

//..............
export const CreatedOnteSort = (rowA: any, rowB: any) => {
  const a = new Date(rowA.created_at);
  const b = new Date(rowB.created_at);
  const timestampA = a.getTime();
  const timestampB = b.getTime();

  if (timestampA > timestampB) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const UdatedAtSort = (rowA: any, rowB: any) => {
  const a = new Date(rowA.updated_at);
  const b = new Date(rowB.updated_at);
  const timestampA = a.getTime();
  const timestampB = b.getTime();

  if (timestampA > timestampB) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const ATteSort = (rowA: any, rowB: any) => {
  const a = new Date(rowA.createdAt);
  const b = new Date(rowB.createdAt);
  const timestampA = a.getTime();
  const timestampB = b.getTime();

  if (timestampA > timestampB) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const TimeStampSort = (rowA: any, rowB: any) => {
  const a = new Date(rowA.timestamp);
  const b = new Date(rowB.timestamp);
  const timestampA = a.getTime();
  const timestampB = b.getTime();

  if (timestampA > timestampB) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};
