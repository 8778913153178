import { Input, Spinner, Typography } from "@material-tailwind/react";
import { EnvelopeIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import ShowToast from "../../components/alerts/all_toasts";
import BaseService from "../../helpers/baseService";
import { global_variables } from "../../helpers/globalVariables";
import { useNavigate } from "react-router";
import { StorageBox } from "../../helpers/storage";

const SignIn = () => {
  const navigate = useNavigate();
  const [theEmail, setTheEmail] = useState("");
  const [thePwd, setThePwd] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [pwdError, setPwdError] = useState(false);

  //handle signin
  const handleSignIn = async (e: any) => {
    e.preventDefault();
    if (!theEmail) {
      setEmailError(true);
    } else if (!thePwd) {
      setPwdError(true);
    } else {
      try {
        setIsBusy(true);
        const response = await BaseService.post_api(
          global_variables.signin_route,
          {
            email: theEmail,
            password: thePwd,
          }
        );
        console.log(response);
        if (response?.status === 200) {
          ShowToast.success_toast(response?.data?.message);
          StorageBox.saveAccessToken(response?.data?.token);
          StorageBox.saveUserData(response?.data?.payload);
          setIsBusy(false);
          navigate("/dashboard");
        }
      } catch (error: any) {
        console.log(error);
        setIsBusy(false);
        ShowToast.error_toast(error?.response?.data?.error);
      }
    }
  };

  return (
    <div className="h-screen overflow-hidden grid grid-rows-2 relative">
      <div className="row-span-1 bg-yarpBlue">
        <div className="flex flex-col justify-center items-center h-full text-white">
          <Typography variant="h3">Welcome Back </Typography>
          <Typography className="font-medium">
            Enter your credentials to proceed
          </Typography>
        </div>
      </div>
      <div className="row-span-2 bg-[#52b3ef1c]">
        <div className="flex justify-center items-center h-full absolute top-20 left-0 right-0">
          <div className="p-10 bg-white w-[500px] shadow-lg rounded-[20px]">
            <div className="border-b-[3px] w-fit border-yarpBlue flex mr-auto ml-auto">
              <Typography variant="h4" className="text-gray-600">
                Sign In
              </Typography>
            </div>

            {/* input */}
            <form onSubmit={handleSignIn}>
              <div className="mt-3">
                <Input
                  variant="static"
                  placeholder="Enter Email"
                  type="email"
                  value={theEmail}
                  onChange={(e) => setTheEmail(e.target.value)}
                  icon={<EnvelopeIcon />}
                  autoFocus
                  error={emailError}
                  disabled={isBusy}
                />
              </div>
              <div className="mt-3">
                <Input
                  variant="static"
                  placeholder="Enter Password"
                  type="password"
                  value={thePwd}
                  onChange={(e) => setThePwd(e.target.value)}
                  icon={<LockClosedIcon />}
                  error={pwdError}
                  disabled={isBusy}
                />
              </div>

              <div className="mt-8 flex gap-3 justify-center items-center">
                <button
                  className="w-[250px] h-[40px] rounded-[30px] bg-yarpBlue shadow-lg text-white hover:scale-105 transition transform duration-300 flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400"
                  onClick={(e) => handleSignIn(e)}
                  disabled={isBusy}
                >
                  {isBusy ? <Spinner /> : <span>Sign In</span>}
                </button>
              </div>
            </form>

            {/* <div className="mt-4 flex gap-3 justify-center items-center">
              <Typography className="text-gray-600 font-medium">
                Forgot password?
              </Typography>
              <button className="text-yarpBlue mb-3" disabled={isBusy}>
                Reset
              </button>
            </div> */}
          </div>
        </div>

        {/* footer */}
        {/* <div className="flex justify-center items-center absolute bottom-[20px] left-0 right-0 text-gray-500">
          <Typography>2023 @ Yarp Technologies</Typography>
        </div> */}
      </div>
    </div>
  );
};

export default SignIn;
