import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Button, Typography } from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GeneralInfo from "./general";
import ChangePicture from "./change-picture";
import ResetPasswrod from "./password/reset-pwd";

const UserProfile = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);

  const allMenus = ["General", "Change Picture", "Reset Password"];

  const allMenusComponents = [
    <GeneralInfo />,
    <ChangePicture />,
    <ResetPasswrod />,
  ];

  useEffect(() => {
    document.title = "User Profile - Yarp Main";
  }, []);

  return (
    <>
      <div className="h-screen overflow-y-auto bg-[#FFFCF9] p-10">
        <div className="flex gap-4 items-center">
          <Button
            variant="gradient"
            color="blue"
            size="sm"
            className="flex gap-2 items-center"
            onClick={() => navigate("/dashboard")}
          >
            <ArrowLeftIcon className="h-5 w-5" />
            Back
          </Button>
          <Typography variant="h4" className="mt-2">
            {" "}
            User Profile{" "}
          </Typography>
        </div>

        <div className="lg:grid lg:grid-cols-5 gap-4 mt-4">
          <div className="col-span-2 bg-white shadow-lg py-10 pr-10 rounded-[20px] h-fit">
            {allMenus.map((menu, i) => (
              <div
                key={i}
                className={`${
                  activeTab === i
                    ? "rounded-r-[30px] bg-[#FFFCF9] text-yarpOrange "
                    : "hover:bg-[#FFFCF9] hover:rounded-r-[30px]"
                } mr-[40px] h-fit py-1 cursor-pointer`}
                onClick={() => setActiveTab(i)}
              >
                <Typography className="ml-[30px] font-medium mt-[10px]">
                  {menu}
                </Typography>
              </div>
            ))}
          </div>

          <div className="col-span-3 bg-white shadow-lg p-10 rounded-[20px] h-full">
            {allMenusComponents[activeTab]}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
