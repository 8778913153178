import { useEffect, useReducer, useState } from "react";
import CustomNavbar from "../../components/layouts/navbar";
import CustomSideBar from "../../components/layouts/sidebar";
import { Typography } from "@material-tailwind/react";
import ThisWeekEarnings from "./charts/thisWeek";
import NewEarningTable from "./NewEarningTable";
import BaseService from "../../helpers/baseService";
import TimeSelect from "./EarningInputs/EarningSelect";
import useCurrentWeek from "./useCurrentWeek";
import { ReducerGraphFunction } from "./ReducerGraphFunction";
import { initialState } from "./ReducerGraphFunction";
const YarpEarning = () => {
  const [TimePeriod, setTimePeriod] = useState<string>("Week");

  const [query] = useCurrentWeek(TimePeriod);
  const [totalEarningReceived, setTotalEarningReceived] = useState<
    number | string
  >("---");
  const [TotalEarningOwed, setTotalEarningOwed] = useState<number | string>(
    "---"
  );
  const [TransactionVolume, setTransactionVolume] = useState<number | string>(
    "---"
  );

  const [megaState, dispatchFunction] = useReducer(
    ReducerGraphFunction,
    initialState
  );

  useEffect(() => {
    const TotalEarnings = async () => {
      try {
        const [resAllRemits, resRemitsBalance, resGetAllCredits, tripHistory] =
          await Promise.all([
            BaseService.get_api_v2(`/yarp/payment/transfer-total?value=amount`),
            BaseService.get_api_v2(
              `/yarp/payment/transfer-total?value=remaining`
            ),
            BaseService.get_api_v2(`/yarp/payment/transfer-total?value=amount`),
            BaseService.get_api_v2(`/yarp/trip/history-totalamount`),
          ]);
        const resferAll = resAllRemits.data;
        const resBalance = resRemitsBalance.data;
        const resAllCredit = resGetAllCredits.data;
        const restripHistory = tripHistory.data;

        const {
          payload: { total_amount: AllRemit },
        } = resferAll;
        const {
          payload: { total_amount: RemitBalance },
        } = resBalance;
        const {
          payload: { total_amount: AllCredit },
        } = resAllCredit;
        const {
          payload: { total_amount: TripHistory },
        } = restripHistory;

        const TotalEarning = Math.round(AllRemit - RemitBalance);
        const transactionVolume = Math.round(TripHistory);
        setTotalEarningReceived(TotalEarning);
        setTotalEarningOwed(AllCredit);
        setTransactionVolume(transactionVolume);
      } catch (error) {
        console.log("This is an error", error);
      }
    };

    TotalEarnings();
  }, []);

  useEffect(() => {
    if (!query.length) return;

    const fetchDataForDays = async () => {
      try {
        const AllRemitsfetchRequests = query.map(async (day) => {
          const { data } = await BaseService.get_api_v2(
            `/yarp/payment/transfer-total?value=amount${day}`
          );
          return data.payload.total_amount;
        });
        const RemitsBalancefetchRequests = query.map(async (day) => {
          const { data } = await BaseService.get_api_v2(
            `/yarp/payment/transfer-total?value=remaining${day}`
          );
          return data.payload.total_amount;
        });

        const TotalEarningOwedRequests = query.map(async (day) => {
          const { data } = await BaseService.get_api_v2(
            `/yarp/payment/transfer-total?value=amount${day}`
          );
          return data.payload.total_amount;
        });

        const transaction_volumeRequests = query.map(async (day) => {
          try {
            const { data } = await BaseService.get_api_v2(
              `/yarp/trip/history-totalamount${day}`.replace("&", "")
            );

            const totalAmount = data.payload.total_amount;
            return totalAmount !== null ? totalAmount : 0;
          } catch (error: any) {
            console.error("Error fetching data:", error.message);
            return 0;
          }
        });

        const AllRemitsresults = await Promise.all(AllRemitsfetchRequests);
        const RemitBalanceresults = await Promise.all(
          RemitsBalancefetchRequests
        );
        const TotalEarningOwedRequestresults = await Promise.all(
          TotalEarningOwedRequests
        );
        const transaction_volumeRequestresults = await Promise.all(
          transaction_volumeRequests
        );

        dispatchFunction({
          type: TimePeriod,
          payload: {
            AllRemitsresults,
            RemitBalanceresults,
            TotalEarningOwedRequestresults,
            transaction_volumeRequestresults,
          },
        });
      } catch (error: any) {
        console.log("Error fetching data:", error.message);
      }
    };

    fetchDataForDays();
  }, [TimePeriod]);

  const onTimeIntervalHandler = (timeInterval: string) => {
    setTimePeriod(timeInterval);
  };

  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar earningsActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <Typography className="text-[26px] font-[600] text-yarpGray">
                Yarp Earnings
              </Typography>

              <div className="p-[30px] bg-white shadow-md rounded-[30px]">
                {/* stats */}
                <div className="lg:grid lg:grid-cols-3 gap-[16px] w-full">
                  {/* total earnings received */}
                  <div className="w-full bg-yarpOrange text-white py-[18px] px-[30px] rounded-[20px] shadow-md h-fit mb-2 lg:mb-0">
                    <p className="font-[600] text-[26px]">
                      GH¢ {totalEarningReceived}
                    </p>
                    <p className="font-[600] text-[14px] mt-[16px]">
                      Total Earning Recieved
                    </p>
                  </div>
                  {/* total earnings owed */}
                  <div className="w-full border-[1px] border-yarpBlue text-yarpGray py-[18px] px-[30px] rounded-[20px] shadow-md h-fit mb-2 lg:mb-0">
                    <p className="font-[600] text-[26px]">
                      GH¢ {TotalEarningOwed}
                    </p>
                    <p className="font-[600] text-[14px] mt-[16px]">
                      Total Earning Owed
                    </p>
                  </div>
                  {/* transaction vloume */}
                  <div className="w-full border-[1px] border-yarpGreen text-yarpGray py-[18px] px-[30px] rounded-[20px] shadow-md h-fit">
                    <p className="font-[600] text-[26px]">
                      GH¢ {TransactionVolume}
                    </p>
                    <p className="font-[600] text-[14px] mt-[16px]">
                      Transaction volume
                    </p>
                  </div>
                </div>

                {/* chart */}
                <div className="mt-[30px]">
                  <div className="flex justify-between items-center">
                    <p className="font-[600] text-[18px] text-yarpGray">
                      Trip Earnings
                    </p>

                    <TimeSelect onTimeInterval={onTimeIntervalHandler} />
                  </div>
                  <ThisWeekEarnings
                    TimePeriod={TimePeriod}
                    Timedata={
                      TimePeriod === "Week"
                        ? megaState.weekState
                        : TimePeriod === "Month"
                        ? megaState.monthState
                        : TimePeriod === "Year"
                        ? megaState.yearState
                        : megaState.weekState
                    }
                  />
                </div>
              </div>

              <div className="mt-[30px]">
                <p className="text-[26px] font-[600] text-yarpGray">Remits</p>
                <NewEarningTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YarpEarning;
