import { useEffect, useState } from 'react';
import Select from 'react-select';
import BaseService from '../../helpers/baseService';
import { global_variables } from '../../helpers/globalVariables';

interface brandProps {
    onChange?: (data: any) => void
    value?: any
}

const TruckGoodsSelector = ({ onChange, value }: brandProps) => {

    const [isFetching, setIsFetching] = useState(false)
    const [allGoodsType, setAllGoodsType] = useState([])

    //getAllGoods
    useEffect(() => {
        const getAllGoods = () => {
            setIsFetching(true);

            BaseService.get_api(`${global_variables.get_products_cats}?limit=1000000`)
                .then((response) => {
                    // console.log(response?.data?.payload);
                    const rawData = response?.data?.payload
                    const preparedData = rawData.map((item: any) => (
                        { label: item?.tittle, value: item?.tittle }
                    ))
                    setAllGoodsType(preparedData)
                    setIsFetching(false);
                }).catch((error: any) => {
                    console.log(error);
                    setIsFetching(false);
                })
        }

        getAllGoods()
    }, [])


    return (
        <>
            <Select
                className="basic-single"
                placeholder="Search goods type"
                isLoading={isFetching}
                isClearable={true}
                isSearchable={true}
                options={allGoodsType}
                noOptionsMessage={() => "Example: Building Materials"}
                onChange={onChange}
                isMulti={true}
                value={value}
            />
        </>
    )
}

export default TruckGoodsSelector
