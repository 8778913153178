export const ReducerGraphFunction = (state: any, action: any) => {
  if (action.type === "Week") {
    const {
      AllRemitsresults,
      RemitBalanceresults,
      TotalEarningOwedRequestresults,
      transaction_volumeRequestresults,
    } = action.payload;
    const result = AllRemitsresults.map(
      (num: number, index: string | number) => num - RemitBalanceresults[index]
    );

    return {
      ...state,
      weekState: {
        ...state.weekState,
        receivedEarnings: result,
        owedEarnings: TotalEarningOwedRequestresults,
        transactionVolume: transaction_volumeRequestresults,
        loading: false,
      },
    };
  }
  if (action.type === "Month") {
    const {
      AllRemitsresults,
      RemitBalanceresults,
      TotalEarningOwedRequestresults,
      transaction_volumeRequestresults,
    } = action.payload;
    const result = AllRemitsresults.map(
      (num: number, index: string | number) => num - RemitBalanceresults[index]
    );

    return {
      ...state,
      monthState: {
        ...state.monthState,
        receivedEarnings: result,
        owedEarnings: TotalEarningOwedRequestresults,
        transactionVolume: transaction_volumeRequestresults,
        loading: false,
      },
    };
  }
  if (action.type === "Year") {
    const {
      AllRemitsresults,
      RemitBalanceresults,
      TotalEarningOwedRequestresults,
      transaction_volumeRequestresults,
    } = action.payload;
    const result = AllRemitsresults.map(
      (num: number, index: string | number) => num - RemitBalanceresults[index]
    );

    return {
      ...state,
      yearState: {
        ...state.yearState,
        receivedEarnings: result,
        owedEarnings: TotalEarningOwedRequestresults,
        transactionVolume: transaction_volumeRequestresults,
        loading: false,
      },
    };
  }

  return state;
};

export const initialState = {
  weekState: {
    receivedEarnings: [0, 0, 0, 0, 0, 0, 0],
    owedEarnings: [0, 0, 0, 0, 0, 0, 0],
    transactionVolume: [0, 0, 0, 0, 0, 0, 0],
    loading: true,
  },
  monthState: {
    receivedEarnings: [0, 0, 0, 0],
    owedEarnings: [0, 0, 0, 0],
    transactionVolume: [0, 0, 0, 0],
    loading: true,
  },
  yearState: {
    receivedEarnings: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    owedEarnings: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    transactionVolume: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    loading: true,
  },
};
