import { useState } from "react";
import { Container, FloatingLabel, Form, Modal } from "react-bootstrap";
import { Button, Option, Select, Spinner } from "@material-tailwind/react";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import ShowAlert from "../../../components/alerts/all_alerts";

interface modalprops {
  showModal: boolean;
  handleModalShow: () => void;
  handleReFetch: () => void;
}

const EditPriceForAll = ({
  showModal,
  handleModalShow,
  handleReFetch,
}: modalprops) => {
  const [isBusy, setIsBusy] = useState(false);
  const [thePercentage, setThePercentage] = useState("");
  const [selectedAction, setSelectedAction] = useState("true");

  //doUpdates
  const doUpdates = async () => {
    setIsBusy(true);
    try {
      const dataClone = JSON.stringify({
        percentage: parseInt(thePercentage),
        add: JSON.parse(selectedAction),
      });

      const response = await BaseService.put_api_v2(
        global_variables.update_price_accross,
        dataClone
      );
      console.log(response?.data);
      ShowToast.success_toast(response?.data?.message);
      handleModalShow();
      handleReFetch();
    } catch (error: any) {
      console.log(error);
      setIsBusy(false);
      handleModalShow();
      ShowAlert.error_alert("Error", error?.response?.data?.error, "Retry");
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleModalShow}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Update Pricing Accross`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container className="mb-3" fluid>
            <div>
              <div>
                <FloatingLabel
                  controlId="fpk"
                  label="Enter percentage value eg: 10"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    value={thePercentage}
                    onChange={(e) => setThePercentage(e.target.value)}
                  />
                </FloatingLabel>
              </div>

              <div>
                <Select
                  label="Add or Subtract"
                  value={selectedAction}
                  onChange={(val: any) => setSelectedAction(val)}
                >
                  <Option value="true">Add</Option>
                  <Option value="false">Subtract</Option>
                </Select>
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="gradient"
            color="blue"
            onClick={() => doUpdates()}
            disabled={isBusy || !thePercentage || !selectedAction}
          >
            {isBusy ? <Spinner /> : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditPriceForAll;
