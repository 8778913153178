import { useEffect, useState } from "react";
import { Button, Input, Modal, Spin } from "antd";
import { TiMessages } from "react-icons/ti";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { sendSmsFunction } from "../../../functions/sendSms";

const { TextArea } = Input;

interface CreateGradeLevelProps {
  showSMSModal: () => void;
  open: boolean;
  setOpenSMS: (open: boolean) => void;
  smsNumber: any;
  MessageForCustomers: string;
}

const SendSMSModal = ({
  showSMSModal,
  open,
  setOpenSMS,
  MessageForCustomers,
  smsNumber,
}: CreateGradeLevelProps) => {
  const [isHovered, setHovered] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [contact, setContact] = useState<string | undefined>();
  const [textAreaValue, setTextAreaValue] = useState<string | undefined>("");
  const maxTextAreaLength = 300;

  const handleOk = async () => {
    getSingleNotifications();
  };

  const handleCancel = () => {
    setOpenSMS(false);
  };

  const handleContact = (value: string | undefined) => {
    setContact(value);
  };

  const handleTextArea = (value: string | undefined) => {
    if (value && value.length <= maxTextAreaLength) {
      setTextAreaValue(value);
    }
  };

  const getSingleNotifications = async () => {
    setLoading(true);
    setOpenSMS(true);
    const msg_data = {
      message: `${textAreaValue}`,
      numbers: [`${contact}`],
    };
    try {
      sendSmsFunction(msg_data);
    } catch (error: any) {
      console.log("Message sending error:", error.message);
    } finally {
      setOpenSMS(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (smsNumber) {
      setContact(smsNumber);
    }
    if (MessageForCustomers) {
      setTextAreaValue(MessageForCustomers);
    }
  }, [smsNumber, MessageForCustomers]);

  const buttonStyle = {
    backgroundColor: isHovered ? "#FF6700" : "#FF6700",
    color: "white",
    border: "1px solid #ffffff",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <Button
        type="default"
        icon={<TiMessages size={"1.5em"} />}
        className="flex items-center"
        onClick={showSMSModal}
        onMouseEnter={() => setHovered((prev) => !prev)}
        onMouseLeave={() => setHovered((prev) => !prev)}
        style={buttonStyle}
      >
        <div className="w-full h-full">
          <p>Send Message</p>
        </div>
      </Button>

      <Modal
        width={"30%"}
        open={open}
        onOk={handleOk}
        okText={"Send"}
        confirmLoading={loading}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#FF6700",
            borderColor: "#FF6700",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "30vh" } }}
      >
        <Spin
          spinning={loading}
          indicator={
            <div className="flex justify-center items-center">
              <LoadingDataComponent />
            </div>
          }
        >
          <div>
            <div className="text-[20px]">Send Message</div>
            <section className="my-6 flex flex-col gap-6">
              <div>
                <Input
                  placeholder="Contact"
                  className="h-[60px]"
                  value={contact}
                  onChange={(e) => handleContact(e.target.value)}
                />
              </div>
              <div className="mb-[16px]">
                <TextArea
                  placeholder="Message"
                  rows={4}
                  value={textAreaValue}
                  onChange={(e) => handleTextArea(e.target.value)}
                  maxLength={maxTextAreaLength}
                  showCount
                />
              </div>
            </section>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default SendSMSModal;
