import { useEffect, useState, useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PrinterIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import BaseService from "../../helpers/baseService";
import { PhotoView } from "react-photo-view";
import { Table, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Tooltip } from "@material-tailwind/react";
import { Empty } from "antd";
import { formatCedis } from "../../utils/formatCedis";
import { hasDatePassed, metersToKilometers } from "../../utils/functions";
import NewDatePickerRange from "../../components/shared/newDatePicker/NewDatePicker";
import ExtraItems from "../../components/shared/ExtraItems/Extraitems";
import AntSelect from "../../components/shared/AntSelect/AntSelect";
import AntInput from "../../components/shared/AntInput/AntInput";
import {
  CreatedOnteSort,
  DateSort,
  DeliveryLocation,
  PickupLocation,
} from "../../components/general/TableSortFunctions";

const options = [
  { value: "pickup_location", label: "Pick up Location" },
  { value: "delivery_location", label: "Delivery Location" },
];

const SchedulesTable = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [reFetch, setReFetch] = useState(false);
  const [schedulesData, setSchedulesData] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<any>([]);
  const [selectData, setSelectData] = useState<any>("");
  const [deleteChange, setDeleteChange] = useState<any>(false);

  //getAllSchedules
  const getAllSchedules = useCallback(
    async (
      lim: number,
      sk: number,
      dateStart = null,
      dateEnd = null,
      textInput = null
    ) => {
      setIsFetching(true);
      try {
        let creditFilter = `/yarp/schedule?sort={"updated_at":-1}&limit=${lim}&skip=${sk}`;
        if (selectData && textInput) {
          const filterField =
            selectData === "pickup_location"
              ? "pickup_place_name"
              : "delivery_place_name";
          const filterQuery = {
            $or: [{ [filterField]: { $regex: textInput, $options: "i" } }],
          };
          creditFilter = `/yarp/schedule?filter=${encodeURIComponent(
            JSON.stringify(filterQuery)
          )}&sort={"updated_at":-1}&limit=${lim}&skip=${sk}`;
        } else if (dateStart && dateEnd) {
          creditFilter = `/yarp/schedule?filter={"$and":[{"updated_at":{"$gte":"${dateStart}"}},{"updated_at":{"$lt":"${dateEnd}"}}]}&sort={"updated_at":-1}&limit=${lim}&skip=${sk}`;
        }
        const response = await BaseService.get_api_v2(creditFilter);
        setSchedulesData(response?.data?.payload || []);
        setTotal(response?.data?.total || 0);
        console.log("This is the schedules", response?.data?.payload);
      } catch (error: any) {
        console.error(error.message);
      } finally {
        setIsFetching(false);
      }
    },
    [selectData]
  );

  useEffect(() => {
    getAllSchedules(limit, skip);
  }, [reFetch, limit, skip, deleteChange, getAllSchedules]);

  const handleRowChange = (newLimit: number) => setLimit(newLimit);
  const pageChange = (page: number) => setSkip((page - 1) * limit);

  const onDeletingHandler = (isDelete: any) => setDeleteChange(isDelete);

  const displayModal = (row: any) => {
    setModalData(row);
    setShow(true);
  };
  //table columns

  const columns: any = useMemo(
    () => [
      {
        name: "Pickup Location",
        cell: (row: any) => row?.pickup_place_name,
        width: "25%",
        sortable: true,
        sortFunction: PickupLocation,
      },
      {
        name: "Delivery Location",
        cell: (row: any) => row?.delivery_place_name,
        width: "25%",
        sortable: true,
        sortFunction: DeliveryLocation,
      },
      {
        name: "Amount",
        selector: (row: any) => formatCedis(row?.amount),
        width: "15%",
        sortable: true,
      },
      {
        name: "Created On",
        cell: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
        sortable: true,
        sortFunction: CreatedOnteSort,
      },
      {
        name: "Status",
        selector: (row: any) =>
          hasDatePassed(row?.pickuptime) ? (
            <div className="px-3 py-1 w-fit rounded-[5px] bg-red-500 bg-opacity-[15%] text-red-500">
              Expired
            </div>
          ) : (
            <div className="px-3 py-1 w-fit rounded-[5px] bg-green-500 bg-opacity-[15%] text-green-500">
              Active
            </div>
          ),
      },
      {
        name: "",
        selector: (row: any) => (
          <ExtraItems rowId={row} onDeleting={onDeletingHandler} />
        ),
        // width: "15%",
      },
    ],
    [onDeletingHandler]
  );

  const formatDataForExport = (data: any) => {
    return data.map((row: any) => ({
      "Pickup Location": row?.pickup_place_name,
      "Delivery Location": row?.delivery_place_name,
      Amount: row?.amount,
      "Date / Time": moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
    }));
  };

  const onDateDataHandler = (dateObj: any) => {
    const { start, end } = dateObj;
    getAllSchedules(limit, skip, start, end);
  };
  const Texthandler = (arg: any) => {
    getAllSchedules(limit, skip, null, null, arg);
  };

  const SelectDataHandler = (selectData: string) => {
    setSelectData(selectData);
  };

  return (
    <>
      <div className="  flex ">
        <div className="mr-3">
          <Tooltip content="Reload" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
              onClick={() => setReFetch((prev) => !prev)}
            >
              <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
        </div>

        <div className="flex gap-3 ml-auto">
          <NewDatePickerRange onDateData={onDateDataHandler} />
          <div className="flex justify-center gap-6 mr-3">
            <AntSelect
              onSelectData={SelectDataHandler}
              placeholder="location"
              options={options}
            />
            <AntInput onText={Texthandler} selectInfo={selectData} />
          </div>
          <Tooltip content="Print" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <PrinterIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <CSVLink
            data={formatDataForExport(schedulesData)}
            filename="Approved Trucks"
          >
            <Tooltip content="Export" placement="top">
              <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={schedulesData}
        progressPending={isFetching}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<Empty description="No schedules available" />}
        pagination
        paginationServer
        paginationTotalRows={total}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        pointerOnHover
        highlightOnHover
        onRowClicked={(selected) => displayModal(selected)}
        // defaultSortFieldId={3}
      />

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setModalData([]);
        }}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="text-yarpGray p-3">
            <div className="flex justify-between items-center">
              <p className="text-[20px] font-[600]">Notes</p>

              <button
                className="h-10 w-10 rounded-[5px] border-[1px] border-gray-400 flex justify-center items-center"
                onClick={() => setShow(false)}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
            <hr className="my-3" />
            <div className="p-3 rounded-md bg-[#EEF7FD]">
              <p className="text-[14px] text-yarpGray">{modalData?.notes}</p>
            </div>

            {/* info */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Trip Info</p>
              <hr className="my-3" />

              <div className="lg:grid lg:grid-cols-3 w-full gap-x-[20px]">
                <div>
                  <p className="text-[14px] font-[600] mb-0">Trip Cost</p>
                  <p className="text-[14px] font-[300]">
                    GHS {parseFloat(modalData?.amount).toFixed(2)}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] font-[600] mb-0">Pick Up</p>
                  <p className="text-[14px] font-[300]">
                    {modalData?.pickup_place_name}, {modalData?.cityfrom}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] font-[600] mb-0">Destination</p>
                  <p className="text-[14px] font-[300]">
                    {modalData?.delivery_place_name}, {modalData?.cityto}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] font-[600] mb-0">
                    Estimated Distance
                  </p>
                  <p className="text-[14px] font-[300]">
                    {metersToKilometers(modalData?.distance)} KM
                  </p>
                </div>
                <div>
                  <p className="text-[14px] font-[600] mb-0">Truck Type</p>
                  <p className="text-[14px] font-[300]">
                    {modalData?.car_type}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] font-[600] mb-0">Goods Weight</p>
                  <p className="text-[14px] font-[300]">
                    {modalData?.weight_load}
                  </p>
                </div>
              </div>
            </div>

            {/* images */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Item Images</p>
              <hr className="my-3" />

              <div className="lg:grid lg:grid-cols-3 w-full gap-x-[20px]">
                {modalData?.images ? (
                  modalData?.images.map((dd: any, i: number) => (
                    <PhotoView key={i} src={dd}>
                      <img
                        src={dd}
                        alt={`ghh${i}`}
                        className="rounded-[20px] object-cover h-[300px] w-[300px]"
                      />
                    </PhotoView>
                  ))
                ) : (
                  <p className="text-[14px] font-[600]">No images available</p>
                )}
              </div>
            </div>

            {/* table */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Items</p>
              <hr className="my-3" />

              <div>
                {modalData?.items ? (
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Weight</th>
                        <th scope="col">Unit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modalData?.items.map((d: any, index: number) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{d?.name}</td>
                            <td>{d?.quantity}</td>
                            <td>{d?.weight}</td>
                            <td>{d?.unit}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div>
                    <Empty description="No items available" />
                  </div>
                )}
              </div>
            </div>

            {/* waybill */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Way Bill</p>
              <hr className="my-3" />

              <div className="flex gap-[30px]">
                <PhotoView
                  src={
                    modalData?.waybill ||
                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                  }
                >
                  <img
                    src={
                      modalData?.waybill ||
                      "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                    }
                    alt="waybill"
                    className="rounded-[20px] object-cover h-[300px] w-[300px]"
                  />
                </PhotoView>
                <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                  <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
                </button>
              </div>
            </div>

            {/* sender */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Goods Owner</p>
              <hr className="my-3" />

              <div className="flex gap-[30px] items-center">
                <PhotoView src={modalData?.user_data?.image}>
                  <img
                    src={modalData?.user_data?.image}
                    alt="user"
                    className="rounded-full object-cover h-[70px] w-[70px]"
                  />
                </PhotoView>
                <div>
                  <p className="font-[600] text-[14px] mb-1">
                    {modalData?.user_data?.full_name}
                  </p>
                  <p className="font-[300] text-[14px] mb-0">
                    {modalData?.user_data?.phone}
                  </p>
                </div>
              </div>
            </div>

            {/* driver */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Driver</p>
              <hr className="my-3" />

              {modalData?.driver_data ? (
                <div className="flex gap-[30px] items-center">
                  <PhotoView src={modalData?.driver_data?.image}>
                    <img
                      src={modalData?.driver_data?.image}
                      alt="user"
                      className="rounded-full object-cover h-[70px] w-[70px]"
                    />
                  </PhotoView>
                  <div>
                    <p className="font-[600] text-[14px] mb-1">
                      {modalData?.driver_data?.full_name}
                    </p>
                    <p className="font-[300] text-[14px] mb-0">
                      {modalData?.driver_data?.phone}
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <Empty description="No driver assigned" />
                </div>
              )}
            </div>

            {/* pick person */}
            <div className="mt-[30px]">
              <p className="text-[20px] font-[600]">Pickup Person</p>
              <hr className="my-3" />

              <div className="lg:grid lg:grid-cols-3 w-full">
                <div>
                  <p className="font-[600] text-[14px] mb-1">Name</p>
                  <p className="font-[300] text-[14px]">
                    {modalData?.pickup_person_name}
                  </p>
                </div>
                <div>
                  <p className="font-[600] text-[14px] mb-1">Contact</p>
                  <p className="font-[300] text-[14px] mb-0">
                    {modalData?.pickup_person_contact}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-4">
          <button className="text-yarpGray" onClick={() => setShow(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SchedulesTable;
