import { useEffect, useState } from "react";
import { Button, Input, Modal, Spin } from "antd";
import BaseService from "../../../helpers/baseService";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import moment from "moment";

interface ModalProps {
  showModal?: () => void;
  open: boolean;
  newID: any;
  tripId: any;
  setOpen: (open: boolean) => void;
}

const NearDriversModal = ({
  newID,
  tripId,
  showModal,
  open,
  setOpen,
}: ModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [singleObject, setSingleObject] = useState<any>();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const getSingleNotifications = async () => {
    setLoading(true);
    try {
      const response = await BaseService.get_api_v2(
        `/yarp/driver/trip/${tripId}?filter={"$and":[{"truckData" : {"$ne" : null}}]}`
      );

      const foundObject = response?.data?.payload?.find(
        (item: { _id: any }) => item._id === newID
      );
      setSingleObject(foundObject);
      // console.log("---found object:", foundObject);
    } catch (error: any) {
      console.log("notification error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleNotifications();
  }, []);

  const isStatus = singleObject?.connectedData != null ? "Online" : "Offline";
  const backgroundColor =
    isStatus === "Online"
      ? "rgba(119, 209, 164, 0.1)"
      : "rgba(255, 99, 141, 0.1)";
  const textColor = isStatus === "Online" ? "#77D1A4" : "#FF638D";
  return (
    <>
      <Modal
        width={"50%"}
        open={open}
        onOk={handleOk}
        // confirmLoading={state.confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#52b3ef",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "40vh" } }}
      >
        <Spin
          spinning={loading}
          indicator={
            <div className="flex justify-center items-center  ">
              <LoadingDataComponent />
            </div>
          }
        >
          <div>
            <div className="text-[20px]">Driver's Details</div>
            <div className="w-full border-1 border-gray-300 "></div>

            <section>
              <div className="flex items-center gap-4">
                <p className="text-[14px] font-semibold">First name : </p>
                <p>{singleObject?.userData?.first_name}</p>
              </div>
              <div className="flex items-center gap-4">
                <p className="text-[14px] font-semibold">Last name : </p>
                <p>{singleObject?.userData?.last_name}</p>
              </div>
              <div className="flex items-center gap-4">
                <p className="text-[14px] font-semibold">Phone Number : </p>
                <p>{singleObject?.userData?.phone}</p>
              </div>
              <div className="flex items-center gap-4">
                <p className="text-[14px] font-semibold">Online Status : </p>
                <p
                  style={{ backgroundColor }}
                  className="rounded-[30px] h-[30px] px-[16px] flex justify-center items-center  w-auto"
                >
                  <span style={{ color: textColor }}>{isStatus}</span>
                </p>
              </div>
              <div className="w-full border-1 border-gray-300 "></div>

              <div className="flex items-center gap-4">
                <p className="text-[14px] font-semibold">Completed Trips : </p>
                <p>{singleObject?.completedTrips}</p>
              </div>

              <div className="flex items-center gap-4">
                <p className="text-[14px] font-semibold">
                  Distance In Kilometres :{" "}
                </p>
                <p>{singleObject?.distanceInKilometres.toFixed(3)}km</p>
              </div>

              <div className="w-full border-1 border-gray-300 "></div>

              <div className="flex items-center gap-4">
                <p className="text-[14px] font-semibold">Rating Data : </p>
                <div>
                  <p>
                    <span className="text-[14px] font-semibold">One : </span>{" "}
                    <span>{singleObject?.ratingData?.one}</span>
                  </p>
                  <p>
                    <span className="text-[14px] font-semibold">Two : </span>{" "}
                    <span>{singleObject?.ratingData?.two}</span>
                  </p>
                  <p>
                    <span className="text-[14px] font-semibold">Three : </span>{" "}
                    <span>{singleObject?.ratingData?.three}</span>
                  </p>
                  <p>
                    <span className="text-[14px] font-semibold">Four : </span>{" "}
                    <span>{singleObject?.ratingData?.four}</span>
                  </p>
                  <p>
                    <span className="text-[14px] font-semibold">Five : </span>{" "}
                    <span>{singleObject?.ratingData?.five}</span>
                  </p>
                </div>
              </div>
              <div className="w-full border-1 border-gray-300 "></div>

              <div className="flex items-center gap-4">
                <p className="text-[14px] font-semibold">Timestamp : </p>
                <p>
                  {`${moment(singleObject?.timestamp).format(
                    "Do MMM YYYY - hh:mm A"
                  )}`}{" "}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <p className="text-[14px]  font-semibold">Car-Type: </p>
                <p> {singleObject?.truckData?.car_type}</p>
              </div>
              <div className="flex items-center gap-4">
                <p className="text-[14px] font-semibold">Model: </p>
                <p> {singleObject?.truckData?.model}</p>
              </div>
            </section>
            <div className="flex gap-4">
              <section className="w-[100px] h-[100px] rounded-full">
                <img
                  src={
                    singleObject?.truckData?.truckback ||
                    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                  }
                  className="rounded-full"
                  alt=""
                />
              </section>
              <section className="w-[100px] h-[100px] rounded-full">
                <img
                  src={
                    singleObject?.truckData?.truckfront ||
                    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                  }
                  className="rounded-full"
                  alt=""
                />
              </section>
              <section className="w-[100px] h-[100px] rounded-full">
                <img
                  src={
                    singleObject?.truckData?.truckside ||
                    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                  }
                  className="rounded-full"
                  alt=""
                />
              </section>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default NearDriversModal;
