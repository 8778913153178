import { useEffect, useState } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import BaseService from "../../helpers/baseService";
import { Empty, Switch, Tag } from "antd";
import DataTable from "react-data-table-component";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import ShowAlert from "../../components/alerts/all_alerts";
import ShowToast from "../../components/alerts/all_toasts";

const SmsProviders = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [providerList, setProviderList] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)

  //getAllProviders
  const getAllProviders = async (lim: number, sk: number) => {
    setIsFetching(true)

    try {
      const response = await BaseService.get_api_v2(`/yarp/gateway/sms-provider`)
      console.log("providersRes:", response?.data)
      setProviderList(response?.data?.payload)
      setTotal(response?.data?.total)
      setIsFetching(false)
    } catch (error) {
      console.log("providersErr:", error)
    }
  }

  //onload
  useEffect(() => {
    getAllProviders(limit, skip)
  }, [limit, skip])

  //handleSwitchChange
  const handleSwitchChange = async (checked: boolean, selected: any) => {
    console.log(`switch to ${checked}`);
    console.log(selected)
    if (selected?.status === true) {
      ShowToast.info_toast(`${selected?.name} is already active`)
    } else {
      ShowAlert.question_alert("Confirm", `Do you want to switch to ${selected?.name}?`, "Switch")
        .then((results) => {
          if (results.isConfirmed) {
            ShowToast.loading_toast(`Switching to ${selected?.name}`)

            BaseService.put_api_v2(`/yarp/gateway/switch-sms-provider`, { "provider": selected?.code })
              .then((response) => {
                console.log("switchRes:", response?.data)
                ShowToast.success_toast(response?.data?.message)
                getAllProviders(limit, skip)
              }).catch((error: any) => {
                console.log("switchErr:", error)
                ShowAlert.error_alert("Error", error?.response?.data?.error || "An unexpected error occured. Please try again", "Try Again")
                  .then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload()
                    }
                  })
              })
          }
        })
    }
  };


  //table clomuns
  const columns: any = [
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">NAME</p>,
      cell: (row: any) => row?.name,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">CODE</p>
      ),
      cell: (row: any) => row?.code,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">STATUS</p>,
      selector: (row: any) =>
        row?.status ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">Inactive</Tag>
        ),
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">ACTION</p>,
      selector: (row: any) => <Switch checked={row?.status} onChange={(e) => handleSwitchChange(e, row)} className="bg-yarpGray" />
    },
  ];

  // pagination start
  const handleRowChange = (a: any) => {
    setLimit(a);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      setSkip(newSkip);
    }
  };
  // pagination end



  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar providerActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">SMS Providers</p>

              <div className="w-full bg-white shadow-md rounded-[20px] p-[20px]">
                <DataTable
                  columns={columns}
                  data={providerList}
                  progressPending={isFetching}
                  progressComponent={<LoadingDataComponent />}
                  noDataComponent={<Empty description="No data available" />}
                  pagination
                  paginationServer
                  paginationTotalRows={total}
                  onChangeRowsPerPage={handleRowChange}
                  onChangePage={pageChange}
                  highlightOnHover
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmsProviders;
