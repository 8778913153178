import { ArrowDownTrayIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { Button, Modal, Table } from "react-bootstrap";
import { PhotoView } from "react-photo-view";
import { metersToKilometers } from "../../../utils/functions";

const DetailsModal = ({
  detailShow,
  detail,
  closeDetailModal,
  handleViewMap,
}: {
  detail: any;
  detailShow: any;
  closeDetailModal: any;
  handleViewMap: any;
}) => {
  return (
    <Modal show={detailShow} onHide={closeDetailModal} size="xl">
      <Modal.Body>
        <div className="text-yarpGray p-3">
          <div className="flex justify-between items-center">
            <p className="text-[20px] font-[600]">Notes</p>
            <button
              className="h-[40px] px-4 wift rounded-[5px] border-[1px] border-yarpBlue text-yarpBlue flex gap-2 items-center"
              onClick={() => handleViewMap()}
            >
              <MapPinIcon className="h-5 w-5" />
              View Map
            </button>
          </div>
          <hr className="my-3" />
          <div className="p-3 rounded-md bg-[#EEF7FD]">
            <p className="text-[14px] text-yarpGray">{detail?.notes}</p>
          </div>

          {/* info */}
          <div className="mt-[30px]">
            <p className="text-[20px] font-[600]">Trip Info</p>
            <hr className="my-3" />

            <div className="lg:grid lg:grid-cols-3 w-full gap-x-[20px]">
              <div>
                <p className="text-[14px] font-[600] mb-0">Trip Cost</p>
                <p className="text-[14px] font-[300]">
                  GHS {parseFloat(detail?.amount).toFixed(2)}
                </p>
              </div>
              <div>
                <p className="text-[14px] font-[600] mb-0">Pick Up</p>
                <p className="text-[14px] font-[300]">
                  {detail?.pickup_place_name}, {detail?.cityfrom}
                </p>
              </div>
              <div>
                <p className="text-[14px] font-[600] mb-0">Destination</p>
                <p className="text-[14px] font-[300]">
                  {detail?.delivery_place_name}, {detail?.cityto}
                </p>
              </div>
              <div>
                <p className="text-[14px] font-[600] mb-0">
                  Estimated Distance
                </p>
                <p className="text-[14px] font-[300]">
                  {metersToKilometers(detail?.distance)} KM
                </p>
              </div>
              <div>
                <p className="text-[14px] font-[600] mb-0">Truck Type</p>
                <p className="text-[14px] font-[300]">{detail?.car_type}</p>
              </div>
              <div>
                <p className="text-[14px] font-[600] mb-0">Goods Weight</p>
                <p className="text-[14px] font-[300]">{detail?.weight_load}</p>
              </div>
            </div>
          </div>

          {/* images */}
          <div className="mt-[30px]">
            <p className="text-[20px] font-[600]">Item Images</p>
            <hr className="my-3" />

            <div className="lg:grid lg:grid-cols-3 w-full gap-x-[20px]">
              {detail?.images ? (
                detail?.images.map((dd: any, i: any) => (
                  <PhotoView key={i} src={dd}>
                    <img
                      src={dd}
                      alt={i}
                      className="rounded-[20px] object-cover h-[300px] w-[300px]"
                    />
                  </PhotoView>
                ))
              ) : (
                <p className="text-[14px] font-[600]">No images available</p>
              )}
            </div>
          </div>

          {/* table */}
          <div className="mt-[30px]">
            <p className="text-[20px] font-[600]">Items</p>
            <hr className="my-3" />

            <div>
              {detail?.items ? (
                <Table striped hover>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Weight</th>
                      <th scope="col">Unit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detail?.items.map((d: any, index: any) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{d?.name}</td>
                          <td>{d?.quantity}</td>
                          <td>{d?.weight}</td>
                          <td>{d?.unit}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <p className="text-[14px] font-[600]">No items available</p>
              )}
            </div>
          </div>

          {/* waybill */}
          <div className="mt-[30px]">
            <p className="text-[20px] font-[600]">Way Bill</p>
            <hr className="my-3" />

            <div className="flex gap-[30px]">
              <PhotoView
                src={
                  detail?.waybill ||
                  "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                }
              >
                <img
                  src={
                    detail?.waybill ||
                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                  }
                  alt="waybill"
                  className="rounded-[20px] object-cover h-[300px] w-[300px]"
                />
              </PhotoView>
              <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </div>
          </div>

          {/* sender */}
          <div className="mt-[30px]">
            <p className="text-[20px] font-[600]">Goods Owner</p>
            <hr className="my-3" />

            <div className="flex gap-[30px] items-center">
              <PhotoView src={detail?.user_data?.image}>
                <img
                  src={detail?.user_data?.image}
                  alt="user"
                  className="rounded-full object-cover h-[70px] w-[70px]"
                />
              </PhotoView>
              <div>
                <p className="font-[600] text-[14px] mb-1">
                  {detail?.user_data?.full_name}
                </p>
                <p className="font-[300] text-[14px] mb-0">
                  {detail?.user_data?.phone}
                </p>
              </div>
            </div>
          </div>

          {/* driver */}
          <div className="mt-[30px]">
            <p className="text-[20px] font-[600]">Driver</p>
            <hr className="my-3" />

            <div className="flex gap-[30px] items-center">
              <PhotoView src={detail?.driver_data?.image}>
                <img
                  src={detail?.driver_data?.image}
                  alt="user"
                  className="rounded-full object-cover h-[70px] w-[70px]"
                />
              </PhotoView>
              <div>
                <p className="font-[600] text-[14px] mb-1">
                  {detail?.driver_data?.full_name}
                </p>
                <p className="font-[300] text-[14px] mb-0">
                  {detail?.driver_data?.phone}
                </p>
              </div>
            </div>
          </div>

          {/* pick person */}
          <div className="mt-[30px]">
            <p className="text-[20px] font-[600]">Pickup Person</p>
            <hr className="my-3" />

            <div className="lg:grid lg:grid-cols-3 w-full">
              <div>
                <p className="font-[600] text-[14px] mb-1">Name</p>
                <p className="font-[300] text-[14px]">
                  {detail?.pickup_person_name}
                </p>
              </div>
              <div>
                <p className="font-[600] text-[14px] mb-1">Contact</p>
                <p className="font-[300] text-[14px] mb-0">
                  {detail?.pickup_person_contact}
                </p>
              </div>
            </div>
          </div>

          <hr className="my-4" />
          <div className="flex justify-end">
            <Button variant="outlined" onClick={closeDetailModal}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DetailsModal;
