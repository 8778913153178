import { useEffect, useState, useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Select, Tooltip } from "antd";
import BaseService from "../../helpers/baseService";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import NoDataComponent from "../../components/general/noDataComponent";
import NearDriversModal from "./Modals/NearDriversModal";
import useColumns from "./useColumns";
import SendMessageModal from "./Modals/SendMessageModal";
import { global_variables } from "../../helpers/globalVariables";
import AssignDriverModal from "./Modals/AssignDriverModal";

const customStyles = {
  rows: { style: { minHeight: "72px" } },
  headCells: { style: { paddingLeft: "8px", paddingRight: "8px" } },
  cells: { style: { paddingLeft: "8px", paddingRight: "8px" } },
};

const NearbyDriversTable = () => {
  const { param1, param2, param3, param4 } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [reFetch, setReFetch] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [truckTypes, setTruckTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedType, setSelectedType] = useState<any>(param3);
  const [open, setOpen] = useState<boolean>(false);
  const [openSMS, setOpenSMS] = useState<boolean>(false);
  const [tripDetails, setTripDetails] = useState<string>();
  const [newID, setID] = useState<any>();
  const [onlineStatus, setOnlineStatus] = useState<any>({});
  const [SmsNumber, setNumber] = useState<any>("");
  const [openAssignDriver, setOpenAssignDriver] = useState<boolean>(false);

  const [userRequesting, setUserRequesting] = useState<string | undefined>("");
  const [PickLocation, setPickLocation] = useState<string | undefined>("");

  const fetchNotifications = useCallback(
    async (lim = limit, sk = skip) => {
      setLoading(true);
      try {
        const filter = JSON.stringify({
          $and: [
            { truckData: { $ne: null } },
            { "truckData.car_type": selectedType },
            onlineStatus,
          ],
        });

        const response = await BaseService.get_api_v2(
          `/yarp/driver/trip/${param2}?limit=${lim}&skip=${sk}&filter=${encodeURIComponent(
            filter
          )}`
        );
        console.log("response?.data?.payload", response?.data?.payload);
        setTotal(response?.data?.total);
        setUsers(response?.data?.payload || []);
      } catch (error: any) {
        console.log("notification error:", error.message);
      } finally {
        setLoading(false);
      }
    },
    [limit, skip, selectedType, onlineStatus, param2]
  );

  const handleMenuClick = (
    e?: any,
    rowId?: any,
    _ID?: any,
    number?: any,
    userData?: any
  ) => {
    console.log("driver is :", _ID);
    if (e.key === "1") {
      setOpen(true);
      setTripDetails(rowId);
      setID(_ID);
    }
    if (e.key === "2") {
      setOpenSMS(true);
      setNumber(number);
      setUserRequesting(userData);
      setID(_ID);
    }
    if (e.key === "3") {
      setOpenAssignDriver(true);
      setID(_ID);
    }
  };

  const columns = useColumns(handleMenuClick);

  const showModal = () => setOpen(true);

  const handleRowChange = (newLimit: number) => setLimit(newLimit);

  const pageChange = (page: number) => setSkip((page - 1) * limit);

  useEffect(() => {
    const getAllTypes = async () => {
      setIsFetching(true);
      try {
        const response = await BaseService.get_api(
          `${global_variables.get_car_type}?limit=1000000`
        );
        const arranged = response?.data?.payload.map((data: any) => ({
          label: data.cartype,
          value: data.cartype,
        }));
        setTruckTypes(arranged);

        const matchingType = arranged.find(
          (type: { value: string | undefined }) => type.value === param3
        );
        setSelectedType(matchingType?.value || null);
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    };

    getAllTypes();
  }, [param3]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications, selectedType, onlineStatus, reFetch]);

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <div className="flex gap-3 items-center w-[70%]">
          <Tooltip placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
              onClick={() => setReFetch((prev) => !prev)}
            >
              <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <Select
            className="h-[40px] w-[200px]"
            options={[
              { value: "online", label: "Online Drivers" },
              { value: "offline", label: "Offline Drivers" },
            ]}
            placeholder="Online / Offline"
            onChange={(value) =>
              setOnlineStatus(
                value === "online"
                  ? { connectedData: { $ne: null } }
                  : { connectedData: { $eq: null } }
              )
            }
            allowClear
          />
          <Select
            className="h-[40px] w-[200px]"
            placeholder="Search truck"
            value={selectedType}
            disabled={isFetching}
            loading={isFetching}
            onChange={setSelectedType}
            options={truckTypes}
            allowClear
          />
        </div>
      </div>

      <DataTable
        data={users}
        columns={columns}
        progressPending={loading}
        paginationTotalRows={total}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<NoDataComponent text="No users found" />}
        pagination
        paginationServer
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        customStyles={customStyles}
      />

      {open && (
        <NearDriversModal
          showModal={showModal}
          newID={newID}
          tripId={param2}
          open={open}
          setOpen={setOpen}
        />
      )}
      {openSMS && (
        <SendMessageModal
          SmsNumber={SmsNumber}
          open={openSMS}
          MessageForNearbyDrivers={`Hi ${userRequesting},\nThere has been a request for a ${selectedType} at ${param4} Come online on your Yarp Driver app now to accept this trip!`}
          setOpenSMS={setOpenSMS}
        />
      )}
      {openAssignDriver && (
        <AssignDriverModal
          open={openAssignDriver}
          setOpenAssignDriver={setOpenAssignDriver}
          driverID={newID}
          tripId={param2}
          userid={param1}
        />
      )}
    </>
  );
};
//
export default NearbyDriversTable;
