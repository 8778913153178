import {
  ArrowUpTrayIcon,
  BackspaceIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import { Button, Typography } from "@material-tailwind/react";
import { useState, useEffect } from "react";

const ChangePicture = () => {
  const [imgFile, setImgFile] = useState<any>(null);
  const [selectedImgUrl, setSetselectedImgUrl] = useState("");

  //onImgSelect
  const onImgSelect = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setImgFile(file);
      const getUrl = URL.createObjectURL(file);
      setSetselectedImgUrl(getUrl);
    }
  };

  return (
    <>
      <div className="mb-20 h-full">
        <Typography variant="h5">Change Profile Picture</Typography>
        <hr />

        <div className="flex justify-center">
          <label htmlFor="chooseImg">
            <div className="h-[300px] w-[300px] bg-yarpBlue bg-opacity-[10%] border-[2px] border-dashed border-yarpBlue rounded-md cursor-pointer flex flex-col justify-center items-center">
              {selectedImgUrl ? (
                <img src={selectedImgUrl} alt="sel" />
              ) : (
                <>
                  <PhotoIcon className="h-10 w-10 text-yarpBlue" />
                  <small className="text-yarpBlue">Click to upload image</small>
                </>
              )}
            </div>
          </label>
          <input
            id="chooseImg"
            type="file"
            className="hidden"
            onChange={onImgSelect}
          />
        </div>

        {/* buttons */}
        <div className="mt-4 flex justify-center gap-4">
          <Button
            variant="gradient"
            color="red"
            className="flex justify-center items-center gap-2 disabled:cursor-not-allowed"
            onClick={() => setSetselectedImgUrl("")}
            disabled={!selectedImgUrl}
          >
            <BackspaceIcon className="h-5 w-5" />
            Clear
          </Button>
          <Button
            variant="gradient"
            color="green"
            className="flex justify-center items-center gap-2 disabled:cursor-not-allowed"
            disabled={!selectedImgUrl}
          >
            <ArrowUpTrayIcon className="h-5 w-5" />
            Upload
          </Button>
        </div>
      </div>
    </>
  );
};

export default ChangePicture;
