import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import SchedulesTable from "./schedulesTable";

const AllSchedules = () => {

  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar scehduleActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">Schedules</p>

              <div className="mt-3 bg-white shadow-md rounded-[20px] p-4">
                <SchedulesTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllSchedules;
