import { useState, useEffect } from "react";
import CustomNavbar from "../../components/layouts/navbar";
import CustomSideBar from "../../components/layouts/sidebar";
import BaseService from "../../helpers/baseService";
import { global_variables } from "../../helpers/globalVariables";
import { isOneWeekOld } from "../../utils/functions";
import { Link } from "react-router-dom";
import { ArrowPathIcon, EyeIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import DataTable from "react-data-table-component";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import { Empty } from "antd";
import {
  CreatedOnteSort,
  DeliveryLocation,
  PickupLocation,
} from "../../components/general/TableSortFunctions";

const UnfinishedTrips = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [trackingData, setTrackingData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [reFetch, setReFetch] = useState(false);

  //getOnRoad
  const getOnRoad = async (lim: number, sk: number) => {
    setIsFetching(true);
    try {
      const response = await BaseService.get_api(
        `${global_variables.getTracking}?sort={"created_at": -1}&limit=${lim}&skip=${sk}`
      );
      const filtered = isOneWeekOld(response?.data?.payload);
      setTrackingData(filtered);
      setTotal(filtered.length);
      console.log(filtered);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  //onload
  useEffect(() => {
    getOnRoad(limit, skip);
  }, [limit, skip, reFetch]);

  //table columns
  const columns: any = [
    {
      width: "30%",
      name: "PICK UP",
      cell: (row: any) => row?.pickup_place_name,
      sortable: true,
      sortFunction: PickupLocation,
    },
    {
      width: "30%",
      name: "DESTINATION",
      cell: (row: any) => row?.delivery_place_name,
      sortable: true,
      sortFunction: DeliveryLocation,
    },
    {
      width: "20%",
      name: "DATE & TIME",
      cell: (row: any) =>
        moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: CreatedOnteSort,
    },
    {
      width: "20%",
      name: "VIEW",
      selector: (row: any) => (
        <div className="flex gap-3">
          <Link
            to="#"
            // onClick={showDits.bind(this, row)}
            style={{ textDecoration: "none", color: "#000", fontSize: "22px" }}
          >
            {" "}
            <EyeIcon className="w-5 h-5 cursor-pointer" />
          </Link>
        </div>
      ),
    },
  ];

  //handleReftch
  const handleReftch = () => {
    setReFetch(!reFetch);
  };

  // pagination start
  const handleRowChange = (a: any) => {
    setLimit(a);
    getOnRoad(a, skip);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getOnRoad(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getOnRoad(limit, newSkip);
      setSkip(newSkip);
    }
  };
  // pagination end

  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar unfinished="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">
                Unfinished Trips
              </p>

              <div className="mt-3 bg-white shadow-md rounded-[20px] p-4">
                <button
                  className="h-10 w-10 border-[1px] border-yarpBlue rounded-[4px] flex justify-center items-center"
                  onClick={() => handleReftch()}
                >
                  <ArrowPathIcon className="h-5 w-5 text-yarpBlue" />
                </button>

                <div className="mt-5">
                  <DataTable
                    columns={columns}
                    data={trackingData}
                    progressPending={isFetching}
                    progressComponent={<LoadingDataComponent />}
                    noDataComponent={<Empty description="No data" />}
                    pagination
                    paginationServer
                    paginationTotalRows={total}
                    onChangeRowsPerPage={handleRowChange}
                    onChangePage={pageChange}
                    // onRowClicked={(e) => showDits(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnfinishedTrips;
