import { Input, Modal, Spin, notification } from 'antd'
import { useEffect, useState } from 'react'
import { TruckModel } from './payload.model'
import { Badge, Col, Row } from 'react-bootstrap'
import { PhotoView } from 'react-photo-view'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import BaseService from '../../helpers/baseService'
import { global_variables } from '../../helpers/globalVariables'
import TruckBrandSelector from '../../components/shared/truckBrandSelector'
import TruckModelSelector from '../../components/shared/truckModelSelector'
import TruckTypeSelector from '../../components/shared/truckTypeSelector'
import TruckGoodsSelector from '../../components/shared/truckGoodsSelector'
import { showFirstWord } from '../../utils/functions'


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    truckData: TruckModel
}

const EditTruckDetails = ({ isOpened, handleClose, handleReFetch, truckData }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    //truck
    const [truckNum, setTruckNum] = useState("")
    //insurance
    const [truckIns, setTruckIns] = useState("")
    const [truckInsExp, setTruckInsExp] = useState("")
    //roadworthy
    const [truckRoad, setTruckRoad] = useState("")
    const [truckRoadExp, setTruckRoadExp] = useState("")
    const [selectedBrand, setSelectedBrand] = useState("")
    const [selectedModel, setSelectedModel] = useState("")
    const [selectedTruckType, setSelectedTruckType] = useState("")
    const [truckGoods, setTruckGoods] = useState<any>([])

    //onload
    useEffect(() => {
        setTruckNum(truckData?.truckRegisteration)
        setTruckIns(truckData?.insurance_text)
        setTruckInsExp(moment(truckData?.insurance_expire).format("YYYY-MM-DD"))
        setTruckRoad(truckData?.roadworthy_text)
        setTruckRoadExp(moment(truckData?.roadworthy_expire).format("YYYY-MM-DD"))
        setSelectedBrand(showFirstWord(truckData?.model))
        setSelectedModel(truckData?.model)
        setSelectedTruckType(truckData?.car_type)
        setTruckGoods(truckData?.category_type)
    }, [])

    //approve a truck
    const approveTruck = async () => {
        var truck_data = {
            truck_number: truckData.truckRegisteration,
            approved: true,
            "remarks": "Congratulations!"
        };

        setIsBusy(true);

        try {
            const response = await BaseService.put_api_v2(
                `${global_variables.approve_or_decline_truck_v2}`,
                truck_data
            );
            // console.log(response);
            notification.success({ message: response?.data?.message })
            handleClose()
            setIsBusy(false)
            handleReFetch()
        } catch (error: any) {
            setIsBusy(false);
            console.log(error);
            notification.error({ message: error?.response?.data?.error })
        }
    };

    //handleUpdate
    const handleUpdate = async () => {
        if (!truckNum || !truckIns || !truckInsExp || !truckRoad || !truckRoadExp || !selectedBrand || !selectedModel || !selectedTruckType || truckGoods.length === 0) {
            notification.warning({ message: "Please fill out all fields" })
        }
        else {
            setIsBusy(true)

            const data = {
                "truckfront": truckData?.truckfront,
                "truckside": truckData?.truckside,
                "insurance": truckData?.insurance,
                "insurance_text": truckData?.insurance_text,
                "insurance_expire": truckInsExp,
                "roadworthy_inssued": truckData?.roadworthy_inssued,
                "insurance_issued": truckData?.insurance_issued,
                "roadworthy": truckData?.roadworthy,
                "roadworthy_text": truckData?.roadworthy_text,
                "roadworthy_expire": truckRoadExp,
                "model": `${selectedBrand} ${selectedModel}`,
                "weight_load": truckData?.weight_load,
                "type": truckData?.type,
                "category_type": truckGoods,
                "truckRegisteration": truckNum,
                "car_type": selectedTruckType,
                "userid": truckData?.driver_data?.userid,
                "truckback": truckData?.truckback
            }

            try {
                const response = await BaseService.put_api_v2(
                    `${global_variables.update_truck_details_v2}${truckData?._id}`,
                    data
                );
                notification.success({ message: response?.data?.message })
                approveTruck()
            } catch (error: any) {
                console.log(error);
                setIsBusy(false)
                notification.error({ message: error?.response?.data?.error })
            }
        }
    }


    return (
        <>
            <Modal open={isOpened} width={1000} footer={null} keyboard={false} closeIcon={false} zIndex={10}>
                <Spin spinning={isBusy}>

                    <div className="px-[30px] py-[15px] text-yarpGray">
                        <div className="flex justify-between items-center mb-0">
                            <p className="text-[20px] font-[600] text-yarpGray">Genral Info</p>
                            <button className="h-10 w-10" onClick={handleClose}>
                                <XMarkIcon className="h-5 w-5" />
                            </button>
                        </div>
                        <hr className='mt-0 mb-2' />


                        {isOpened ? (
                            <>
                                <div className="lg:grid lg:grid-cols-3">
                                    <p className="text-[14px] font-[600]">Truck Images</p>
                                    <div className="flex justify-between gap-[20px] lg:col-span-2">
                                        <div className="w-full">
                                            <PhotoView
                                                src={
                                                    truckData?.truckfront ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                                                    src={
                                                        truckData?.truckfront ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="front"
                                                />
                                            </PhotoView>
                                            <p className="text-[14px] font-[300] mt-[10px]">
                                                Front view
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <PhotoView
                                                src={
                                                    truckData?.truckside ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                                                    src={
                                                        truckData?.truckside ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="side"
                                                />
                                            </PhotoView>
                                            <p className="text-[14px] font-[300] mt-[10px]">
                                                Side view
                                            </p>
                                        </div>
                                        <div className="w-full">
                                            <PhotoView
                                                src={
                                                    truckData?.truckback ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                                                    src={
                                                        truckData?.truckback ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="back"
                                                />
                                            </PhotoView>
                                            <p className="text-[14px] font-[300] mt-[10px]">
                                                Back view
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-[30px] lg:grid lg:grid-cols-3">
                                    <p className="text-[14px] font-[600]">Registration</p>
                                    <div className="lg:col-span-2">
                                        <Input className='h-[40px] w-[300px]' value={truckNum} onChange={(e) => setTruckNum(e.target.value)} placeholder='Truck registration no.' />
                                    </div>
                                </div>

                                <div className="mt-[20px] lg:grid lg:grid-cols-3">
                                    <p className="text-[14px] font-[600]">Make / Brand</p>
                                    <div className="lg:col-span-2 w-[300px]">
                                        <TruckBrandSelector value={{ label: selectedBrand, value: selectedBrand }} onChange={(data) => setSelectedBrand(data?.value)} />
                                    </div>
                                </div>

                                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                                    <p className="text-[14px] font-[600]">Model</p>
                                    <div className="lg:col-span-2 w-[300px]">
                                        <TruckModelSelector value={{ label: selectedModel, value: selectedModel }} selectedBrand={selectedBrand} onChange={(data) => setSelectedModel(data?.value)} />
                                    </div>
                                </div>

                                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                                    <p className="text-[14px] font-[600]">Truck Type</p>
                                    <div className="lg:col-span-2 w-[300px]">
                                        <TruckTypeSelector value={{ label: selectedTruckType, value: selectedTruckType }} selectedModel={selectedModel} selectedBrand={selectedBrand} onChange={(data) => setSelectedTruckType(data?.value)} />
                                    </div>
                                </div>

                                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                                    <p className="text-[14px] font-[600]">Preferred Goods</p>
                                    <div className="lg:col-span-2 w-[300px]">
                                        <TruckGoodsSelector value={truckGoods.map((item: any) => (
                                            { label: item, value: item }
                                        ))} onChange={(data) => setTruckGoods(data.map((item: any) => item?.value))} />
                                    </div>
                                </div>

                                <div className="mt-[30px]">
                                    <p className="text-[20px] font-[600] text-yarpGray">
                                        Documents
                                    </p>

                                    <hr />

                                    <div className="lg:grid lg:grid-cols-3">
                                        <p className="text-[14px] font-[600]">Insurance</p>
                                        <div className="lg:col-span-2">
                                            <Row>
                                                <Col>
                                                    <PhotoView
                                                        src={
                                                            truckData?.insurance ||
                                                            "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                                                        }
                                                    >
                                                        <img
                                                            className="w-[400px] h-[250px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                                                            src={truckData?.insurance}
                                                            alt="Insurance"
                                                        />
                                                    </PhotoView>
                                                </Col>
                                                <Col>
                                                    <div className="fw-bolder mt-2">Insurance number</div>
                                                    <div>
                                                        <Input className='h-[40px] w-[200px] mt-2' value={truckIns} onChange={(e) => setTruckIns(e.target.value)} placeholder='Insurance no.' />
                                                    </div>
                                                    <div className="fw-bolder mt-3">Expiry Date</div>
                                                    <div>
                                                        <Input className='h-[40px] w-[200px] mt-2' value={truckInsExp} onChange={(e) => setTruckInsExp(e.target.value)} type="date" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <div className="lg:grid lg:grid-cols-3 mt-[20px]">
                                        <p className="text-[14px] font-[600]">Roadworthy</p>
                                        <div className="lg:col-span-2">
                                            <Row>
                                                <Col>
                                                    <PhotoView
                                                        src={
                                                            truckData?.roadworthy ||
                                                            "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                                                        }
                                                    >
                                                        <img
                                                            className="w-[400px] h-[250px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                                                            src={truckData?.roadworthy}
                                                            alt="Roadworthy"
                                                        />
                                                    </PhotoView>
                                                </Col>
                                                <Col>
                                                    <div className="fw-bolder mt-2">
                                                        Roadworthy number
                                                    </div>
                                                    <div>
                                                        <Input className='h-[40px] w-[200px] mt-2' value={truckRoad} onChange={(e) => setTruckRoad(e.target.value)} placeholder='Roadworthy no.' />
                                                    </div>
                                                    <div className="fw-bolder mt-3">Expiry Date</div>
                                                    <div>
                                                        <Input className='h-[40px] w-[200px] mt-2' value={truckRoadExp} onChange={(e) => setTruckRoadExp(e.target.value)} type="date" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>

                    <hr />
                    <div className='flex justify-end gap-4'>
                        <button className='' onClick={() => handleClose()}>
                            Dismiss
                        </button>
                        <button className='px-3 py-2 rounded-[30px] bg-yarpOrange text-white' onClick={() => handleUpdate()}>
                            Save & Approve
                        </button>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default EditTruckDetails
