import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import {
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import axios from "axios";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { FILE_UPLOAD_URL } from "../../../helpers/constants";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Button } from "@material-tailwind/react";
import { StorageBox } from "../../../helpers/storage";
import { PhotoView } from "react-photo-view";

const TruckType = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [show, setShow] = useState<any>(0);

  //on load
  useEffect(() => {
    getData(limit, skip);
  }, []);

  //get truck types
  const getData = (lim: number, ski: number) => {
    setLoading(true);

    BaseService.get_api(
      `${global_variables.get_car_type}?sort={"created_at": -1}&limit=${lim}&skip=${ski}`
    )
      .then((res) => {
        setLoading(false);
        setData(res?.data?.payload);
        setTotal(res?.data?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //table coumns
  const columns: any = [
    {
      name: "Model",
      selector: (row: any) => (
        <div className="flex gap-2 items-center">
          <PhotoView
            src={
              row?.image ||
              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
            }
          >
            <Image
              src={row?.image}
              fluid
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "100%",
                marginRight: "10px",
              }}
            />
          </PhotoView>
          {row?.cartype}
        </div>
      ),
      width: "50%",
    },
    {
      name: "Fuel Capacity",
      selector: (row: any) => row?.fuel_capacity,
    },
    {
      name: "Created on",
      cell: (row: any) => moment(row?.updated_at).format("Do MMMM YYYY"),
    },
  ];

  const handleRowChange = (a: any) => {
    getData(a, 0);
    setLimit(a);
    setSkip(0);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getData(limit, newSkip);
      setSkip(newSkip);
    }
  };

  // form handles
  const [cartype, setCarType] = useState("");
  const [fuel_capacity, set_fuel_capacity] = useState<any>("");
  const [selectedImage2, setSelectedImage2] = useState<any>();

  const imageChange2 = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage2(e.target.files[0]);
    }
  };

  //addNewCarType
  const addNewCarType = () => {
    ShowToast.loading_toast();

    const truckImage = document.getElementById(
      "truckImage"
    ) as HTMLInputElement;

    var imageData = new FormData();
    if (truckImage.files && truckImage.files.length > 0) {
      imageData.append("file", truckImage.files[0], "[PROXY]");
    }
    // var carTitleReplaced = cartype.replace(/ +/g, "");

    var config1 = {
      method: "post",
      url: `${FILE_UPLOAD_URL}/yarp/upload?storage=carmodel&imagename=${cartype}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
      data: imageData,
    };

    axios(config1)
      .then((response) => {
        const image = response.data.url;

        const postData = {
          cartype: cartype,
          fuel_capacity: parseFloat(fuel_capacity),
          image: image,
        };

        //post type
        BaseService.post_api(global_variables.create_car_type, postData)
          .then((resp) => {
            ShowToast.success_toast(
              response?.data?.message + " and " + resp?.data?.message
            );
            setShow(false);
            setCarType("");
            set_fuel_capacity(0);
            setSelectedImage2("");
            getData(limit, skip);
          })
          .catch((err) => {
            console.log(err);
            ShowToast.error_toast("Error creating car type");
          });
      })
      .catch((err) => {
        ShowToast.error_toast(err?.response?.data?.error);
        console.log(err);
      });
  };

  const [details, setDetails] = useState<any>(null);

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h5>Truck Types</h5>
        <Button
          variant="gradient"
          color="blue"
          onClick={() => {
            setShow(true);
          }}
        >
          Add Type
        </Button>
      </div>

      <div>
        <DataTable
          data={data}
          columns={columns}
          paginationTotalRows={total}
          progressPending={loading}
          progressComponent={<LoadingDataComponent />}
          noDataComponent={<p>You do not have any car brands</p>}
          pagination={total >= 11 ? true : false}
          paginationServer
          onChangeRowsPerPage={handleRowChange}
          onChangePage={pageChange}
          pointerOnHover
          onRowClicked={(_d) => {
            setDetails(_d);
            console.log(_d);
          }}
        />
      </div>

      {/* Add  */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Truck Type</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FloatingLabel controlId="bname" label="Truck type" className="mb-3">
            <Form.Control
              type="text"
              placeholder="Toyota"
              value={cartype}
              onChange={(e) => setCarType(e.target.value?.toUpperCase())}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="fuel_capacity  "
            label="Fuel Capacity"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Toyota"
              value={fuel_capacity}
              onChange={(e) => set_fuel_capacity(e.target.value)}
            />
          </FloatingLabel>

          <div>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                Select Car Image
              </label>
              <input
                className="mb-3 form-control"
                accept="image/*"
                type="file"
                id="truckImage"
                onChange={imageChange2}
              />
            </div>

            {selectedImage2 && (
              <>
                <Container className="mb-3">
                  <Image src={URL.createObjectURL(selectedImage2)} fluid />
                </Container>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="gradient"
            color="blue"
            onClick={() => addNewCarType()}
          >
            Post
          </Button>
        </Modal.Footer>
      </Modal>

      {/* View details */}
      <Modal
        show={details !== null}
        onHide={() => setDetails(null)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{details?.cartype}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Image src={details?.image} fluid />
            </Col>
            <Col md={6}>
              <Table>
                <tbody>
                  <tr>
                    <th style={{ width: "30%" }}>Fuel Capacity</th>
                    <td>{details?.fuel_capacity}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Created at</th>
                    <td>{moment(details?.created_at).format("lll")}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Updated at</th>
                    <td>{moment(details?.updated_at).format("lll")}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="text" onClick={() => setDetails(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TruckType;
