import { useEffect, useState } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import DeclinedDrivers from "./tabs/declinedDrivers";
import DeclinedTrucks from "./tabs/declinedTrucks";

const DeclinedDocs = () => {
  const [activeTab, setActiveTab] = useState(0);

  //tabNames
  const tabNames = ["Drivers", "Trucks"];

  //tabComponents
  const tabComponents = [<DeclinedDrivers />, <DeclinedTrucks />];

  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar decActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">Declined Documents</p>

              <div className="flex gap-3 mt-3">
                {tabNames.map((item, i) => (
                  <div
                    key={i}
                    className={`${activeTab === i ? "bg-yarpBlue text-white" : "bg-white"
                      } px-4 py-2 rounded-[5px] shadow-md cursor-pointer`}
                    onClick={() => setActiveTab(i)}
                  >
                    {item}
                  </div>
                ))}
              </div>

              <div className="mt-3">{tabComponents[activeTab]}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeclinedDocs;
