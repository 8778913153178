import { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import shimmer from "../../../images/yarp-spinner.gif";
import {
  Badge,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ChatBubbleLeftRightIcon,
  EyeIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import ShowToast from "../../../components/alerts/all_toasts";
import { sendSmsFunction } from "../../../functions/sendSms";
import { global_variables } from "../../../helpers/globalVariables";
import BaseService from "../../../helpers/baseService";
import { Button, Tooltip } from "@material-tailwind/react";
import { PhotoView } from "react-photo-view";
import { CSVLink } from "react-csv";
import { Empty } from "antd";
import {
  modelSort,
  roadworthy_expireSort,
  roadworthy_textSort,
  truckRegisterationSort,
} from "../../../components/general/TableSortFunctions";

/**
 * @author
 * @function ExpiredWorthy
 **/

const ExpiredWorthy = (props: any) => {
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [reFetch, setReFetch] = useState<boolean>(false);

  const [dataIn, setDataIn] = useState<boolean>(false);
  const [trucksRoad, setTrucksRoad] = useState<any>([]);

  const [expiredWorthyeData, setExpiredWorthyeData] = useState<any>();

  const [notifMsg, setNotifMsg] = useState<any>(
    "Dear Partner, The Road Worthy information uploaded for your vehicle has expired, please update it by logging into your yarp account now. Thank you"
  );

  const [showDits, setShowDits] = useState<boolean>(false);
  const [showSendMsg, setShowSendMsg] = useState<boolean>(false);

  //get_road_worthy
  const get_road_worthy = async (lim: number, sk: number) => {
    setDataIn(true);
    const date = new Date();
    const year = date.getFullYear();
    // const year = 2021;
    const month = date.getMonth() + 1;
    // const month = 13;
    function padLeadingZeros(num: string | number, size: number) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    }
    const yearMonth = `${year}-${padLeadingZeros(month, 2)}`;

    BaseService.get_api(
      `${global_variables.getunverifiedTrucks}?limit=${lim}&skip=${sk}&filter={"condition":"approved_expire_road","road":${yearMonth},"parameter":true}`
    )
      .then((res: any) => {
        var trucks_data = res?.data?.payload;
        setTrucksRoad(trucks_data);
        setTotalRows(res.total);
        setDataIn(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //onload
  useEffect(() => {
    get_road_worthy(limit, skip);
  }, [limit, skip, reFetch]);

  //table columns
  const columns: any = [
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">
          TRUCK REGISTRATION NO.
        </p>
      ),
      selector: (row: { truckRegisteration: any }) => row?.truckRegisteration,
      sortable: true,
      sortFunction: truckRegisterationSort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">MODEL</p>,
      selector: (row: { model: any }) => row?.model,
      sortable: true,
      sortFunction: modelSort,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">ROADWORTHY NO.</p>
      ),
      selector: (row: { roadworthy_text: any }) => row?.roadworthy_text,
      sortable: true,
      sortFunction: roadworthy_textSort,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">DATE EXPIRED</p>
      ),
      selector: (row: { roadworthy_expire: moment.MomentInput }) =>
        moment(row?.roadworthy_expire).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: roadworthy_expireSort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">ACTION</p>,
      selector: (row: any, index: any) => (
        <div className="flex gap-3">
          <Link
            to="#"
            onClick={showExpiredWorthy.bind(this, row)}
            style={{ textDecoration: "none", fontSize: "22px", padding: "3px" }}
          >
            <EyeIcon className="h-5 w-5 cursor-pointer" />
          </Link>
          <Link
            to="#"
            onClick={showSendExpiredWorthy.bind(this, row)}
            style={{ textDecoration: "none", fontSize: "22px", padding: "3px" }}
          >
            <ChatBubbleLeftRightIcon className="h-5 w-5 cursor-pointer" />
          </Link>
        </div>
      ),
    },
  ];

  //export data
  const formatDataForExport = (data: any[]) => {
    return data.map((row) => ({
      "TRUCK NO.": row?.truckRegisteration,
      "TRUCK MODEL.": row?.model,
      "ROADWORTHY NO.": row?.roadworthy_text,
      "DATE EXPIRED": moment(row?.roadworthy_expire).format(
        "Do MMM YYYY - hh:mm A"
      ),
    }));
  };

  const showExpiredWorthy = (row: any) => {
    setShowDits(true);
    setExpiredWorthyeData(row);
    // console.log(row);
  };

  const showSendExpiredWorthy = (row: any) => {
    setShowSendMsg(true);
    setExpiredWorthyeData(row);
    // console.log(row);
  };

  const SendNoticationWothy = (e: any) => {
    e.preventDefault();

    const phoneNumber = expiredWorthyeData["owner_data"]
      ? expiredWorthyeData["owner_data"]["phone"]
      : null;

    ShowToast.loading_toast();

    var msg_data = {
      message: `${notifMsg}`,
      numbers: [`${phoneNumber}`],
    };

    sendSmsFunction(msg_data);

    // console.log(msg_data)
  };

  const handleRowChange = (a: any) => {
    setLimit(a);
    get_road_worthy(a, skip);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      get_road_worthy(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      get_road_worthy(limit, newSkip);
      setSkip(newSkip);
    }
  };

  return (
    <>
      <div className="mt-[20px] flex justify-between items-center">
        <Tooltip content="Reload" placement="top">
          <button
            className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
            onClick={() => setReFetch(!reFetch)}
          >
            <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
          </button>
        </Tooltip>
        <div className="flex gap-[10px]">
          <Tooltip content="Print" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <PrinterIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <CSVLink
            data={formatDataForExport(trucksRoad)}
            filename="Expired Roadworthy"
          >
            <Tooltip content="Export" placement="top">
              <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>

      <DataTable
        className="mt-[10px]"
        columns={columns}
        data={trucksRoad}
        defaultSortAsc={false}
        highlightOnHover
        progressPending={dataIn}
        progressComponent={
          <img
            src={shimmer}
            style={{ marginTop: "100px", width: "200px" }}
            alt="Loading..."
          />
        }
        pagination
        noDataComponent={
          <Empty description="No expired roadwothy at the moment" />
        }
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        pointerOnHover
        onRowClicked={(e) => {
          showExpiredWorthy(e);
        }}
      />

      {/* EXPIRED DETAILS */}
      <Modal
        show={showDits}
        size="xl"
        onHide={() => {
          setShowDits(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Truck Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showDits ? (
            <Container fluid>
              <Table>
                <tbody>
                  {expiredWorthyeData.owner_data ? (
                    <tr>
                      <th style={{ width: "30%" }}>Owner Information: </th>
                      <td>
                        <Row>
                          <Col md={3}>
                            <PhotoView
                              src={
                                expiredWorthyeData.owner_data.image ||
                                "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                              }
                            >
                              <img
                                className="object-cover border border-gray-500 rounded-full w-[100px] h-[100px] cursor-pointer"
                                src={expiredWorthyeData.owner_data.image}
                                alt="ghCard"
                              />
                            </PhotoView>
                          </Col>
                          <Col md={9}>
                            <p className="fw-bold">
                              Name:{" "}
                              <span className="fw-light">
                                {expiredWorthyeData.owner_data.full_name}
                              </span>{" "}
                            </p>
                            <p className="fw-bold">
                              Contact:{" "}
                              <span className="fw-light">
                                {expiredWorthyeData.owner_data.phone}
                              </span>{" "}
                            </p>
                            <p className="fw-bold">
                              Email:{" "}
                              <span className="fw-light">
                                {expiredWorthyeData.owner_data.email}
                              </span>{" "}
                            </p>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <th style={{ width: "30%" }}>Truck Registration: </th>
                    <td>{expiredWorthyeData.truckRegisteration}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Model: </th>
                    <td>{expiredWorthyeData.model}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Gross Weight: </th>
                    <td>{expiredWorthyeData.gross}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Type: </th>
                    <td>{expiredWorthyeData.type}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Category Of Product: </th>
                    <td className="flex flex-wrap gap-2">
                      {expiredWorthyeData?.category_type.map(
                        (d: any, index: any) => {
                          return (
                            <Badge className="ms-1" bg="secondary" key={index}>
                              {d}
                            </Badge>
                          );
                        }
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Roadworthy Information: </th>
                    <td>
                      <Row>
                        <Col md={5}>
                          <PhotoView
                            src={
                              expiredWorthyeData.roadworthy ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="object-cover border border-gray-500 rounded-[10px] w-full h-[250px] cursor-pointer"
                              src={expiredWorthyeData.roadworthy}
                              alt="road"
                            />
                          </PhotoView>
                        </Col>
                        <Col md={7}>
                          <p className="fw-bold">
                            Roadworthy:{" "}
                            <span className="fw-light">
                              {expiredWorthyeData.roadworthy_text}
                            </span>
                          </p>
                          <p className="fw-bold">
                            Expiry Date:{" "}
                            <span className="fw-light">
                              {moment(
                                expiredWorthyeData.roadworthy_expire
                              ).format("MMMM Do YYYY")}
                            </span>{" "}
                          </p>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Truck Images: </th>
                    <td>
                      <Row>
                        <Col md={4}>
                          <h5>Front: </h5>
                          <PhotoView
                            src={
                              expiredWorthyeData.truckfront ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="object-cover border border-gray-500 rounded-[10px] w-full h-[200px] cursor-pointer"
                              src={expiredWorthyeData.truckfront}
                              alt="front"
                            />
                          </PhotoView>
                        </Col>
                        <Col md={4}>
                          <h5>Side: </h5>
                          <PhotoView
                            src={
                              expiredWorthyeData.truckside ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="object-cover border border-gray-500 rounded-[10px] w-full h-[200px] cursor-pointer"
                              src={expiredWorthyeData.truckside}
                              alt="side"
                            />
                          </PhotoView>
                        </Col>
                        <Col md={4}>
                          <h5>Back: </h5>
                          <PhotoView
                            src={
                              expiredWorthyeData.truckside ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="object-cover border border-gray-500 rounded-[10px] w-full h-[200px] cursor-pointer"
                              src={expiredWorthyeData.truckside}
                              alt="back"
                            />
                          </PhotoView>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          ) : (
            <>false</>
          )}
        </Modal.Body>
      </Modal>

      {/* Send Message Modal */}

      <Modal
        show={showSendMsg}
        onHide={() => {
          setShowSendMsg(false);
        }}
      >
        <Form onSubmit={SendNoticationWothy}>
          <Modal.Header closeButton>
            <Modal.Title>Notify Partner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showSendMsg ? (
              <Container>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="contactNumber"
                    value={
                      expiredWorthyeData["owner_data"]
                        ? expiredWorthyeData["owner_data"]["phone"]
                        : null
                    }
                    readOnly
                  />
                  <label htmlFor="contactNumber">Contact</label>
                </div>
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Leave a comment here"
                    id="expired_ins_message"
                    style={{ height: "100px" }}
                    value={notifMsg}
                    onChange={(e) => setNotifMsg(e.target.value)}
                  ></textarea>
                  <label htmlFor="expired_ins_message">Message</label>
                </div>
              </Container>
            ) : (
              <></>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="red"
              onClick={() => {
                setShowSendMsg(false);
              }}
            >
              Close
            </Button>
            <Button
              color="green"
              type="submit"
              onClick={() => {
                setShowSendMsg(false);
              }}
            >
              Send Message
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ExpiredWorthy;
