import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import {
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { Button } from "@material-tailwind/react";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";

const Cancellation = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState<any>(0);
  const [skip, setSkip] = useState<any>(0);
  const [limit, setLimit] = useState<number>(10);
  const [show, setShow] = useState<any>(0);

  const getData = (lim: number, ski: number) => {
    setLoading(true);

    BaseService.get_api(
      `${global_variables.get_cancel_reasons}?limit=${lim}&skip=${ski}`
    )
      .then((res) => {
        setLoading(false);
        setData(res?.data?.payload);
        setTotal(res?.data?.total);
      })
      .catch((err) => {
        ShowToast.error_toast(err?.error);
        console.log(err);
      });
  };

  const columns: any = [
    {
      name: "Name",
      cell: (row: { name: any }) => row.name,
      width: "70%",
    },
    {
      name: "Created on",
      selector: (row: { updated_at: moment.MomentInput }) =>
        moment(row.updated_at).format("Do MMMM YYYY"),
    },
  ];

  useEffect(() => {
    getData(limit, skip);
  }, []);

  const handleRowChange = (a: any) => {
    getData(a, 0);
    setLimit(a);
    setSkip(0);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getData(limit, newSkip);
      setSkip(newSkip);
    }
  };

  const [brand, setBrand] = useState("");

  const addBrand = () => {
    ShowToast.loading_toast();

    var upload_data = {
      name: brand,
    };

    BaseService.post_api(global_variables.get_cancel_reasons, upload_data)
      .then((res) => {
        setShow(false);
        ShowToast.success_toast(res?.data?.message);
        setBrand("");
        getData(limit, skip);
      })
      .catch((err) => {
        ShowToast.error_toast(err?.error);
        console.log(err);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h5>Cancellation</h5>
        <Button
          variant="gradient"
          color="blue"
          onClick={() => {
            setShow(true);
          }}
        >
          + Add New
        </Button>
      </div>

      <DataTable
        data={data}
        columns={columns}
        paginationTotalRows={total}
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        pagination={total >= 11 ? true : false}
        paginationServer
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
      />

      {/* Add  */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Post cancellation reason</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FloatingLabel
            controlId="cr"
            label="Cancellation reason"
            className="mb-3"
          >
            <Form.Control
              as="textarea"
              placeholder="Please type a cancellation reason"
              style={{ height: "100px" }}
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="gradient" color="blue" onClick={() => addBrand()}>
            Post
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Cancellation;
