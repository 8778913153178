import { SetStateAction, useEffect, useState } from "react";
import { Container, Form, Image, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { suspend_user } from "./app_users/suspend_user";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import { GenerateId } from "../../functions/functions";
import ShowToast from "../../components/alerts/all_toasts";
import BaseService from "../../helpers/baseService";
import { global_variables } from "../../helpers/globalVariables";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ExclamationTriangleIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { PhotoView } from "react-photo-view";
import { CSVLink } from "react-csv";
import { Spinner, Tooltip } from "@material-tailwind/react";
import NoDataComponent from "../../components/general/noDataComponent";
import { StorageBox } from "../../helpers/storage";
import { notification } from "antd";
import NewDatePickerRange from "../../components/shared/newDatePicker/NewDatePicker";
import { caseInsensitiveSort } from "../../components/general/TableSortFunctions";
import { DateSort } from "../../components/general/TableSortFunctions";

const FleetTable = ({ activeTab }: { activeTab: any }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [fleet, setFleet] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [condition, setCondition] = useState<any>(
    `"condition":"type","type":"fleet"`
  );
  const [selectedDrivers, setSelectedDrivers] = useState<any>([]);
  const [isBusy, setIsBusy] = useState<any>(false);
  const [reFetch, setReFetch] = useState<boolean>(false);

  const getFleet = (con: string, lim: number, sk: number) => {
    setLoading(true);
    BaseService.get_api(
      `${global_variables.yarpusers}?filter={${con}}&sort={"updated_at":-1}&limit=${lim}&skip=${sk}`
    )
      .then((res) => {
        setLoading(false);
        setTotal(res?.data?.total);
        setFleet(res?.data?.payload);
        console.log("fleet data", res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFleet(condition, limit, skip);
  }, [reFetch]);

  const handleRowChange = (a: any) => {
    setLimit(a);
    getFleet(condition, a, skip);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getFleet(condition, limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getFleet(condition, limit, newSkip);
      setSkip(newSkip);
    }
  };

  //table columns
  const columns: any = [
    {
      name: "Image",
      selector: (row: { image: any }) => (
        <>
          <PhotoView
            src={
              row?.image ||
              "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
            }
          >
            <Image
              src={
                row?.image ||
                "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              }
              fluid
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "100%",
                marginRight: "10px",
              }}
            />
          </PhotoView>
        </>
      ),
      width: "10%",
    },
    {
      name: "Full Name",
      cell: (row: { full_name: any }) => row?.full_name || "N/A",
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "Phone",
      selector: (row: { phone: any }) => row?.phone,
      sortable: true,
    },
    {
      name: "Last Seen",
      cell: (row: { login_at: moment.MomentInput }) =>
        moment(row?.login_at).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: DateSort,
    },

    {
      name: "Created On",
      cell: (row: { created_at: moment.MomentInput }) =>
        moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: DateSort,
    },
    {
      name: "Action",
      selector: (row: any) => (
        <ExclamationTriangleIcon
          className="w-5 h-5 text-red-500 cursor-pointer"
          onClick={showSuspend.bind(this, row)}
        />
      ),
      width: "10%",
    },
  ];

  //export data
  const formatDataForExport = (data: any[]) => {
    return data.map((row) => ({
      "FULL NAME": row?.full_name,
      "PHONE NUMBER": `.${row?.phone}`,
      "LAST SEEN": moment(row?.login_at).format("Do MMM YYYY - hh:mm A"),
      "DATE CREATED": moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
    }));
  };

  const [show, setShow] = useState(false);
  const [susData, setSuspendData] = useState<any>([]);
  const [randomNum, setRandomNum] = useState("");
  const showSuspend = (row: SetStateAction<never[]>) => {
    setShow(true);
    setSuspendData(row);
    setRandomNum(GenerateId(5));
  };

  const [confrimationCode, setConfirmationCode] = useState("");
  const [msg, setMsg] = useState("");
  const [susEnd, setEndDate] = useState("");
  const suspendUser = () => {
    suspend_user(
      randomNum,
      confrimationCode,
      susEnd,
      susData?.userid,
      susData?.phone,
      msg
    );
    getFleet(condition, limit, skip);
    setShow(false);
    setConfirmationCode("");
  };

  const reloadTable = () => {
    getFleet(`"condition":"type","type":"fleet"`, 10, 0);
    setCondition(`"condition":"type","type":"fleet"`);
    setLimit(10);
    setSkip(0);
  };

  const [qry, setQry] = useState("");
  const searchUser = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    // console.log(qry)

    if (qry === "") {
      ShowToast.warning_toast("please type a user name to search");
    } else {
      getFleet(
        `"condition":"type_name","parameter":"${qry}","type":"fleet"`,
        10,
        0
      );
      setCondition(
        `"condition":"type_name","parameter":"${qry}","type":"fleet"`
      );
      setLimit(10);
      setSkip(0);
    }
  };

  //queueForSms
  const queueForSms = () => {
    setIsBusy(true);
    setTimeout(() => {
      StorageBox.saveFleetSms(selectedDrivers);
      setIsBusy(false);
      notification.success({
        message: "Selected fleet owners have been queued for sms",
      });
      setSelectedDrivers([]);
      setReFetch(!reFetch);
    }, 2000);
  };
  const onDateDataHandler = (dateObj: any) => {
    // const { start, end } = dateObj;
    // getAllSchedules(limit, skip, start, end);
    // if (!start && !end) {
    //   getAllSchedules(limit, skip);
    // }
  };
  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div className="flex gap-3">
          <Tooltip content="Reload" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
              onClick={() => reloadTable()}
            >
              <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          {selectedDrivers.length !== 0 && (
            <button
              className="px-3 py-2 w-fit text-white bg-yarpBlue rounded-[5px] flex justify-center items-center disabled:bg-gray-400 disabled:cursor-not-allowed"
              onClick={() => queueForSms()}
              disabled={isBusy}
            >
              {isBusy ? <Spinner /> : "Queue SMS"}
            </button>
          )}
          <NewDatePickerRange onDateData={onDateDataHandler} />
        </div>
        <div className="flex gap-3 ml-3">
          <div>
            <Form className="d-flex flex-row-reverse" onSubmit={searchUser}>
              <input
                type="text"
                placeholder="Search.."
                className="form-control search_table h-10"
                value={qry}
                onChange={(e) => setQry(e.target.value)}
                style={{ width: "100%" }}
              />
            </Form>
          </div>
          <div className="flex gap-3">
            <Tooltip content="Print" placement="top">
              <button
                className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
                disabled
              >
                <PrinterIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
            <CSVLink
              data={formatDataForExport(fleet)}
              filename="Approved Fleet Owners"
            >
              <Tooltip content="Export" placement="top">
                <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                  <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
                </button>
              </Tooltip>
            </CSVLink>
          </div>
        </div>
      </div>
      <DataTable
        data={fleet}
        columns={columns}
        progressPending={loading}
        paginationTotalRows={total}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<NoDataComponent text="No fleet owners found" />}
        pagination
        paginationServer
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        pointerOnHover
        onRowClicked={(row: any) => {
          navigate(`/fleetdetail?uid=${row.full_name}&tab=${activeTab}`);
        }}
        selectableRows
        onSelectedRowsChange={(data) => setSelectedDrivers(data?.selectedRows)}
      />

      {/* Suspend Fleet Table  */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Suspend {susData.full_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show ? (
            <Container className="text-center">
              <h5 className="fw-light m-0 p-0"> Comfirmation Code </h5>
              <p className="fw-bolder">{randomNum}</p>
              <p style={{ fontSize: "13px" }}>
                Are you sure you want to suspend this user? Please enter the
                code above
              </p>
              <input
                value={confrimationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                type="text"
                className="form-control my-3"
                placeholder="Comfirmation code"
              />
              <textarea
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                className="form-control my-3"
                style={{ height: "100px" }}
                placeholder="Tell the user the reason for suspension"
              ></textarea>

              <small>Suspension ends on:</small>

              <input
                value={susEnd}
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                className="form-control"
              />
            </Container>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-danger" onClick={suspendUser}>
            Suspend
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FleetTable;
