import { Line } from "react-chartjs-2";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";

const ThisWeekEarnings = ({
  Timedata,
  TimePeriod,
}: {
  Timedata: any;
  TimePeriod: any;
}) => {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const week = ["1st week", "2nd week", "3rd week", "4th week"];
  const years = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const { receivedEarnings, owedEarnings, transactionVolume, loading } =
    Timedata;
  // const receivedEarnings = [100, 120, 90, 110, 130, 80, 195];
  // const owedEarnings = [80, 110, 200, 120, 105, 70, 90];
  // const transactionVolume = [150, 160, 140, 155, 170, 130, 145];

  // Create the chart data object
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.2,
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
  };

  const data = {
    labels:
      TimePeriod === "Week"
        ? days
        : TimePeriod === "Month"
        ? week
        : TimePeriod === "Year"
        ? years
        : days,
    datasets: [
      {
        label: "Total Earning Recieved",
        data: receivedEarnings,
        fill: true,
        borderColor: "#15C2A5",
        backgroundColor: "rgba(21,194,165,0.165703781512605)",
      },
      {
        label: "Total Earning Owed",
        data: owedEarnings,
        fill: true,
        borderColor: "#52B3EF",
        backgroundColor: "rgba(82,179,239,0.08727240896358546)",
      },
      {
        label: "Transaction Volume",
        data: transactionVolume,
        fill: true,
        borderColor: "#FF6700",
        backgroundColor: "#FEE9E0",
      },
    ],
  };

  return (
    <>
      <div className="h-[380px] relative">
        {!loading ? (
          <Line
            data={data}
            fallbackContent={<LoadingDataComponent />}
            options={options}
          />
        ) : (
          <div className=" w-full h-full flex justify-center items-center">
            <LoadingDataComponent />
          </div>
        )}

        {/* legend */}
        <div className="absolute right-2 bottom-3">
          <div className="flex gap-2 items-center">
            <div className="h-[10px] w-[10px] bg-yarpGreen rounded-full mb-3"></div>
            <p className="text-[14px] font-[400] text-yarpGray">
              Total Earning Recieved
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <div className="h-[10px] w-[10px] bg-yarpBlue rounded-full mb-3"></div>
            <p className="text-[14px] font-[400] text-yarpGray">
              Total Earning Owed
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <div className="h-[10px] w-[10px] bg-yarpOrange rounded-full mb-3"></div>
            <p className="text-[14px] font-[400] text-yarpGray">
              Transaction volume
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThisWeekEarnings;
