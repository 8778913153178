import { useState, useEffect } from "react";
import { Collapse } from "@material-tailwind/react";
// import UserProfile from "../general/profile";
// import UserNotifications from "../general/notifications";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Userprofile from "../general/userprofile";
import { Select } from 'antd';
import { useNavigate } from "react-router-dom";


const CustomNavbar = () => {

  const navigate = useNavigate()
  const [openNav, setOpenNav] = useState(false);

  //onlaod
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);


  //options
  const options = [
    {
      value: 'dashboard',
      label: 'Dashboard',
    },
    {
      value: 'trucks',
      label: 'Trucks',
    },
    {
      value: 'users',
      label: 'User Accounts',
    },
    {
      value: 'transactions',
      label: 'Transactions',
    },
    {
      value: 'triphistory',
      label: 'Trip History',
    },
    {
      value: 'tracking',
      label: 'Tracking',
    },
    {
      value: 'schedules',
      label: 'Schedules',
    },
    {
      value: 'sendsms',
      label: 'SMS & Messaging',
    },
    {
      value: 'administration',
      label: 'Administration',
    }
  ]


  const onChange = (value: string) => {
    console.log(`selected ${value}`);
    navigate(`/${value}`)
  };

  const onSearch = (value: string) => {
    console.log('search:', value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption: any = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


  return (
    <div className="w-full px-4 py-2 shadow-md bg-white">
      <div className="flex items-center justify-between text-blue-gray-900">
        {/* left */}
        <div className="w-[400px] relative">
          <Select
            className="h-[50px] w-full bg-[#ff660009]"
            placeholder="What are you looking for?"
            options={options}
            suffixIcon={null}
            showSearch
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
          />
          {/* <input
            className="h-[50px] px-3 w-full focus:outline-none bg-[#ff660009]"
            placeholder="What are you looking for?"
          /> */}
          <MagnifyingGlassIcon className="h-5 w-5 absolute right-3 bottom-[15px] text-midGreen text-gray-500" />
        </div>

        {/* middle */}
        <div className="hidden lg:block"></div>

        {/* right */}
        <div className="flex gap-4 items-center">
          {/* <UserNotifications /> */}
          <Userprofile />
        </div>
      </div>

      {/* on mobile */}
      <Collapse open={openNav}>...</Collapse>
    </div>
  );
};

export default CustomNavbar;
