import React, { useEffect, useState, useCallback, useRef } from "react";
import { APIProvider, Map, Marker, useMap } from "@vis.gl/react-google-maps";
import { BASE_URL_V1, MAPS_KEY } from "../../../helpers/constants";
import { StorageBox } from "../../../helpers/storage";
import { io } from "socket.io-client";
import HistoryAdvanceMarker from "./HistoryAdvanceMarker";
import { useParams } from "react-router-dom";
import HistoryDraggableBox from "./HistoryDraggableBox";
import socketConnection from "../../../sockets/connection";
import { Image } from "react-bootstrap";
import loadingGif from "../../../images/yarp-spinner.gif";

const MapPage = ({
  pickupLatitude,
  deliveryLatitude,
  getInitialLatlng,
  getInitialSuccess,
  getTripFootPrint,
}: {
  pickupLatitude: any;
  deliveryLatitude: any;
  getInitialLatlng: any;
  getInitialSuccess: any;
  getTripFootPrint: any;
}) => {
  const { tripID, driverId } = useParams();
  const AdvanceMarkerRef = useRef<any>(null);

  const handleMarkerAction = (action: "start" | "pause" | "continue") => {
    if (AdvanceMarkerRef.current) {
      if (action === "start") {
        AdvanceMarkerRef.current.handleStartAnimation();
      } else if (action === "pause") {
        AdvanceMarkerRef.current.pauseAnimation();
      } else if (action === "continue") {
        AdvanceMarkerRef.current.continueAnimation();
      }
    }
  };

  useEffect(() => {
    console.log("getTripFootPrint : ", getTripFootPrint);
  }, [getTripFootPrint]);

  return getInitialSuccess && getInitialLatlng ? (
    <div style={{ height: "100%", width: "100%" }}>
      <APIProvider apiKey={MAPS_KEY}>
        <Map
          mapId={"539b1f8f16816929"}
          defaultCenter={{
            lat: getInitialLatlng?.latitude,
            lng: getInitialLatlng?.longitude,
          }}
          defaultZoom={20}
        >
          <HistoryAdvanceMarker
            ref={AdvanceMarkerRef}
            getTripFootPrint={getTripFootPrint}
          />

          <HistoryDraggableBox
            PauseButtonAction={() => handleMarkerAction("pause")}
            handleStartAnimation={() => handleMarkerAction("start")}
            continueAnimation={() => handleMarkerAction("continue")}
          />
        </Map>
      </APIProvider>
    </div>
  ) : (
    <div className=" flex items-center justify-center w-full">
      <section className=" w-1/5 h-1/5  relative">
        <Image fluid src={loadingGif} />
      </section>
    </div>
  );
};

export default MapPage;
