import { useEffect } from "react";
import TrackingTable from "./tracking_table";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";

const Tracking = () => {
  useEffect(() => {
    document.title = "Tracking - Yarp Main";
  }, []);
  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar trackingActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">Tracking</p>

              <div className="mt-3 bg-white shadow-md rounded-[20px] p-5">
                <TrackingTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tracking;
