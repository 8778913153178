import React, { useState } from "react";
import ThisYearChart from "./ThisYearChart";
import ThisMonthChart from "./ThisMonthChart";
import ThisWeekChart from "./ThisWeekChart";
import { DatePicker, Space } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const EarningsChart = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const tabNames = ["This Year", "This Month", "This Week"];
  const chartComponents = [
    <ThisYearChart startDate={startDate} endDate={endDate} />,
    <ThisMonthChart />,
    <ThisWeekChart />,
  ];

  //onDateChange
  const onDateChange = (dates: any, dateStrings: any) => {
    if (dateStrings[0] && dateStrings[1]) {
      const startDateISO = moment(dateStrings[0]).startOf("day").toISOString();
      const endDateISO = moment(dateStrings[1]).endOf("day").toISOString();
      setStartDate(startDateISO);
      setEndDate(endDateISO);
      // console.log('Start Date:', startDateISO);
      // console.log('End Date:', endDateISO);
    }
  };

  return (
    <>
      <div className="p-3 rounded-[20px] bg-white shadow-md w-full h-full">
        <div className="flex justify-between items-center">
          <p className="font-[600] text-[20px] text-yarpGray">Earnings</p>
          {/* <div className="flex gap-x-[20px] items-center">
            {tabNames.map((item, i) => (
              <div
                key={i}
                className={`${
                  activeTab === i ? "border-b-[2px] border-b-yarpOrange" : ""
                } cursor-pointer px-1 pb-[3px] text-[14px] font-[300] text-yarpGray`}
                onClick={() => setActiveTab(i)}
              >
                {item}
              </div>
            ))}
          </div> */}
          <div>
            <Space direction="vertical" size={12}>
              <RangePicker onChange={onDateChange} />
            </Space>
          </div>
        </div>

        <div>{chartComponents[activeTab]}</div>
      </div>
    </>
  );
};

export default EarningsChart;
