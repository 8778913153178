import { Button, Input, Spinner } from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { StorageBox } from "../../../helpers/storage";
import ShowToast from "../../../components/alerts/all_toasts";
import axios from "axios";
import { BASE_URL_V1 } from "../../../helpers/constants";
import { global_variables } from "../../../helpers/globalVariables";
import PinInput from "react-pin-input";
import ShowAlert from "../../../components/alerts/all_alerts";

const ResetPasswrod = () => {
  const [userEmail, setUserEmail] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [isError, setIsError] = useState(false);
  const [step, setStep] = useState(1);
  const [pwdTkn, setPwdTkn] = useState("");
  const [thePwd, setThePwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");

  useEffect(() => {
    const userdata = StorageBox.retrieveUserData();
    setUserEmail(userdata?.email);
  }, []);

  //handleProceed
  const handleProceed = () => {
    if (!userEmail) {
      setIsError(true);
    } else {
      setIsError(false);
      setIsBusy(true);

      let data = JSON.stringify({
        email: userEmail,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: BASE_URL_V1 + global_variables.frgt_pass_url,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          //   console.log(response?.data);
          if (response?.status === 200) {
            setPwdTkn(response?.data?.token);
            setStep(2);
            setIsBusy(false);
          }
        })
        .catch((error: any) => {
          console.log(error);
          setIsBusy(false);
          ShowToast.error_toast(error?.response?.data?.error);
        });
    }
  };

  //handleConfirm
  const handleConfirm = (theOtp: any) => {
    // console.log(theOtp);
    setIsBusy(true);

    let data = JSON.stringify({
      verifytoken: theOtp,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: BASE_URL_V1 + global_variables.validate_code,
      headers: {
        "x-access-token": pwdTkn,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response?.data);
        if (response?.status === 200) {
          setStep(3);
          setIsBusy(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsBusy(false);
        ShowToast.error_toast(error?.response?.data?.error);
      });
  };

  //handlePassSave
  const handlePassSave = () => {
    // console.log(theOtp);

    if (!thePwd || !confirmPwd) {
      setIsError(true);
    } else if (thePwd !== confirmPwd) {
      ShowToast.warning_toast("Passwords do not match!");
    } else {
      setIsBusy(true);

      let data = JSON.stringify({
        password: thePwd,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: BASE_URL_V1 + global_variables.reset_pass,
        headers: {
          "x-access-token": pwdTkn,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          //   console.log(response?.data);
          ShowAlert.success_alert(
            "Success",
            "Password has been reset. You will be logged out of your account.",
            "Okay"
          ).then((result) => {
            if (result.isConfirmed) {
              StorageBox.clearStorage();
              window.location.reload();
            }
          });
        })
        .catch((error) => {
          console.log(error);
          setIsBusy(false);
          ShowToast.error_toast(error?.response?.data?.error);
        });
    }
  };

  return (
    <>
      <div className="flex justify-center items-center h-full">
        {step === 1 ? (
          <div className="text-center w-[300px]">
            <p>Confirm your email below and click proceed</p>
            <Input
              placeholder="Your email address"
              variant="static"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              disabled={isBusy}
              error={isError}
            />

            <Button
              className="mt-4"
              color="blue"
              onClick={() => handleProceed()}
              disabled={isBusy}
            >
              {isBusy ? <Spinner /> : <span>Proceed</span>}
            </Button>
          </div>
        ) : step === 2 ? (
          <>
            <div className="text-center">
              <p>Enter the OTP we sent to your email address</p>
              <PinInput
                length={6}
                initialValue=""
                secret
                secretDelay={100}
                onChange={(value, index) => { }}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px" }}
                inputStyle={{ borderColor: "red" }}
                inputFocusStyle={{ borderColor: "blue" }}
                onComplete={(value, index) => handleConfirm(value)}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                disabled={isBusy}
              />

              <div className="mt-3 flex justify-center">
                {isBusy ? <Spinner /> : <></>}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-center w-[300px]">
              <p>Enter your new password</p>
              <Input
                className="w-full"
                label="Your password"
                type="password"
                value={thePwd}
                onChange={(e) => setThePwd(e.target.value)}
                disabled={isBusy}
                error={isError}
              />

              <div className="mt-3">
                <Input
                  className="w-full"
                  label="Confirm your password"
                  type="password"
                  value={confirmPwd}
                  onChange={(e) => setConfirmPwd(e.target.value)}
                  disabled={isBusy}
                  error={isError}
                />
              </div>

              <Button
                className="mt-4"
                color="blue"
                onClick={() => handlePassSave()}
                disabled={isBusy}
              >
                {isBusy ? <Spinner /> : <span>Save New Password</span>}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ResetPasswrod;
