import { CheckCircleRounded, RadioButtonUnchecked } from "@mui/icons-material";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import {
  ATteSort,
  BALANCESort,
  CreatedOnteSort,
  caseInsensitiveSort,
  driversensitiveSort,
  phoneSort,
  tripAmountSort,
} from "../../components/general/TableSortFunctions";
const useColumnsHook = () => {
  const myColumns: any = [
    {
      name: (
        <div className="w-full">
          <div className="text-center">DRIVER</div>
        </div>
      ),
      width: "30%",
      selector: (row: any) => (
        <section className="flex  items-center justify-start gap-2 ">
          <div>
            <Checkbox
              inputProps={{ "aria-label": "controlled" }}
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircleRounded />}
              sx={{
                borderRadius: "50%",
                color: "#BCBCBC",
                "&.Mui-checked": {
                  color: "#FF6700",
                },
              }}
            />
          </div>
          <div className="w-[45px] h-[45px] flex justify-center items-center rounded-full ">
            <img src={row.image} alt="" className="rounded-full " />
          </div>
          <div>{row.fullName}</div>
        </section>
      ),
      style: {
        paddingTop: "5px",
        paddingBottom: "5px",
        "&:hover": {
          cursor: "pointer",
        },
      },
      sortable: true,
      sortFunction: driversensitiveSort,
    },
    {
      name: "DATE-TIME",
      cell: (row: any) =>
        moment(row?.createdAt).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: ATteSort,
    },
    {
      name: "CREDIT",
      cell: (row: { tripAmount: any }) => row.tripAmount,
      sortable: true,
      sortFunction: tripAmountSort,
    },
    {
      name: "BALANCE",
      cell: (row: { amount: any }) => row.amount,
      sortable: true,
      sortFunction: BALANCESort,
    },
    {
      name: "CONTACT",
      cell: (row: any) => (
        <div>
          <div>{row.phone}</div>
        </div>
      ),
      sortable: true,
      sortFunction: phoneSort,
    },
  ];

  return [myColumns];
};

export default useColumnsHook;
