export const GenerateId = (length: number) => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const formatCedis = (number: any) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GHS",
  }).format(number);
};

export const getDistancePercent = (distance: number, discovered: number) => {
  var percentageDistance = ((distance - discovered) / distance) * 100;
  percentageDistance = parseFloat(percentageDistance.toFixed(2));
  return 100 - percentageDistance;
};
