import { useEffect, useState, useMemo } from "react";
import io from "socket.io-client";
import { StorageBox } from "../../helpers/storage";
import { BASE_URL_V2 } from "../../helpers/constants";

const initChannel = "1";
const handShakeParams = {
  token: StorageBox.getAccessToken(),
  initial_channel: initChannel,
};

const useSocket = () => {
  const [isNotified, setIsNotified] = useState<boolean>(false);

  const socket = useMemo(() => io(`${BASE_URL_V2}/yarp-realtime`), []);

  useEffect(() => {
    const startSocket = () => {
      socket.on("connect", () => {
        console.log("connected to the socket");
        socket.emit("/yarp/handshake", handShakeParams);

        socket.on(initChannel, (res: any) => {
          console.log("connected to the handshake", res);

          socket.on("admin-notifications", (response: any) => {
            setIsNotified(true);
            console.log("response from new notifications ", response);
          });
        });
      });

      socket.on("disconnect", () => {
        console.log("Disconnected");
      });
    };

    startSocket();

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off(initChannel);
      socket.off("admin-notifications");
    };
  }, [socket]);

  return isNotified;
};

export default useSocket;
