export function hasDatePassed(isoDateString: any) {
  const currentDate = new Date();
  const targetDate = new Date(isoDateString);

  // Set the time to midnight for both dates to compare dates only
  currentDate.setHours(0, 0, 0, 0);
  targetDate.setHours(0, 0, 0, 0);

  return targetDate < currentDate;
}

export function metersToKilometers(meters: number) {
  // 1 kilometer is equal to 1000 meters
  const result = meters / 1000;
  return result ?? "";
}

export const showFirstWord = (sentence: string) => {
  const firstWord = sentence.split(" ");
  return firstWord[0];
};

export function isOneWeekLess(tripsArray: any) {
  // Convert the array of trip objects to an array of filtered trip objects
  const currentDate: any = new Date();

  return tripsArray.filter((trip: any) => {
    const tripDate: any = new Date(trip?.created_at);

    // Calculate the difference in milliseconds
    const timeDifference = currentDate - tripDate;

    // Calculate the number of milliseconds in a week
    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;

    // Filter trips that are one week less old
    return timeDifference <= oneWeekInMillis;
  });
}

export function isOneWeekOld(tripsArray: any) {
  // Convert the array of trip objects to an array of filtered trip objects
  const currentDate: any = new Date();

  return tripsArray.filter((trip: any) => {
    const tripDate: any = new Date(trip?.created_at);

    // Calculate the difference in milliseconds
    const timeDifference = currentDate - tripDate;

    // Calculate the number of milliseconds in a week
    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;

    // Filter trips that are one week old or older
    return timeDifference >= oneWeekInMillis;
  });
}

export function calculateDateInterval(inputDate: any) {
  const currentDate = new Date(inputDate);

  // Calculate the date after two years
  const twoYearsLater = new Date(currentDate);
  twoYearsLater.setFullYear(currentDate.getFullYear() + 2);

  // Calculate the date after four years
  const fourYearsLater = new Date(currentDate);
  fourYearsLater.setFullYear(currentDate.getFullYear() + 4);

  return [twoYearsLater.toISOString(), fourYearsLater.toISOString()];
}
