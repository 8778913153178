import ShowToast from "../components/alerts/all_toasts";
import BaseService from "../helpers/baseService";
import { global_variables } from "../helpers/globalVariables";

export const sendSmsFunction = async (data: any) => {
  try {
    const response = await BaseService.post_api(
      `${global_variables.sendSms}`,
      data
    );
    ShowToast.success_toast("Notification sent!");
    console.log(response);
  } catch (error) {
    console.log(error);
    ShowToast.warning_toast("something went wrong!");
  }
};
