import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import {
  Container,
  FloatingLabel,
  Form,
  Image,
  InputGroup,
  ListGroup,
  Modal,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import axios from "axios";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import {
  CheckIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { FILE_UPLOAD_URL } from "../../../helpers/constants";
import { StorageBox } from "../../../helpers/storage";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Button } from "@material-tailwind/react";
import AsyncSelect from "react-select/async";
import { RunChecks } from "./runChecks";
import { PhotoView } from "react-photo-view";

const TruckModel = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [show, setShow] = useState<any>(0);

  //get all models
  const getData = (lim: number, ski: number) => {
    setLoading(true);

    BaseService.get_api(
      `${global_variables.get_car_models}?sort={"created_at": -1}&limit=${lim}&skip=${ski}`
    )
      .then((res) => {
        setLoading(false);
        console.log(res?.data?.payload);
        setData(res?.data?.payload);
        setTotal(res?.data?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // table columns
  const columns: any = [
    {
      name: "Model",
      selector: (row: any) => (
        <div className="flex gap-2 items-center">
          <PhotoView
            src={
              row?.carimage ||
              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
            }
          >
            <Image
              src={row?.carimage}
              fluid
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "100%",
                marginRight: "10px",
              }}
            />
          </PhotoView>
          {row?.model}
        </div>
      ),
      width: "30%",
    },
    {
      name: "Brand",
      selector: (row: any) => row?.brand,
    },
    {
      name: "Type",
      cell: (row: any) => (
        <ul style={{ listStyle: "none", padding: "0px", margin: "0px" }}>
          {row?.type?.map((d: any, index: any) => {
            return (
              <li key={index} className="flex gap-1 items-center">
                <CheckIcon className="h-3 w-3" /> {d}
              </li>
            );
          })}
        </ul>
      ),
    },
    {
      name: "Created on",
      cell: (row: any) => moment(row.updated_at).format("Do MMMM YYYY"),
    },
  ];

  useEffect(() => {
    getData(limit, skip);
  }, []);

  const handleRowChange = (a: any) => {
    getData(a, 0);
    setLimit(a);
    setSkip(0);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getData(limit, newSkip);
      setSkip(newSkip);
    }
  };

  // Form Handling
  const [typeLoaded, setTypeLoaded] = useState(true);
  const [types, setTypes] = useState<any[]>([]);
  const [brandList, setBrandList] = useState();
  const [brandLoaded, setBrandLoaded] = useState(true);

  const [typeArray, setTypeArray] = useState<any[]>([]);
  const [brand, setBrand] = useState<any>("");
  const [model, setModel] = useState<any>("");

  const [selectedImage2, setSelectedImage2] = useState();
  const imageChange2 = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage2(e.target.files[0]);
    }
  };

  const showAddModel = () => {
    setShow(true);
    getTypes();
  };

  //get car brands
  const getCarBrands = async (inputValue: any) => {
    try {
      const response = await BaseService.get_api_v2(
        `${
          global_variables.get_truck_brands_v2
        }?filter={"brand":{"$regex":${JSON.stringify(
          inputValue
        )},"$options":"i"}}`
      );

      const theBrands = response?.data?.payload;
      const options = theBrands.map((dd: any) => ({
        value: dd?.brand,
        label: dd?.brand,
      }));

      return options;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  //get car types
  const getTypes = () => {
    BaseService.get_api(`${global_variables.get_car_type}`)
      .then((res) => {
        setTypeLoaded(false);
        setTypes(res?.data?.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //add car type
  const addCarType = () => {
    const typeInputElement = document.getElementById(
      "selectType"
    ) as HTMLInputElement;
    const typeInput = typeInputElement.value as string;
    if (typeInput !== "") {
      if (typeArray.includes(typeInput)) {
        ShowToast.warning_toast("Truck type added");
      } else {
        setTypeArray([typeInput, ...typeArray]);
      }
    } else {
      console.log(typeInput);
    }
  };

  const removeCarType = (i: any) => {
    // console.log(i)
    typeArray.splice(i, 1);
    setTypeArray([...typeArray]);
  };

  //addCarModel
  const addCarModel = () => {
    ShowToast.loading_toast();

    const truckImage = document.getElementById(
      "truckImage"
    ) as HTMLInputElement;

    var imageData = new FormData();
    if (truckImage.files && truckImage.files.length > 0) {
      imageData.append("file", truckImage.files[0], "[PROXY]");
    }
    var config1 = {
      method: "post",
      url: `${FILE_UPLOAD_URL}/yarp/upload?storage=carmodel&imagename=${model}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
      data: imageData,
    };

    axios(config1)
      .then((response) => {
        const image = response.data.url;

        const postData = {
          type: typeArray,
          model: RunChecks(brand, model),
          carimage: image,
          brand: brand,
        };

        console.log(postData);

        //post type
        BaseService.post_api(global_variables.create_car_model, postData)
          .then((resp) => {
            ShowToast.success_toast(
              response?.data?.message + " and " + resp?.data?.message
            );
            setShow(false);
            setModel("");
            setTypeArray([]);
            getData(limit, skip);
          })
          .catch((err) => {
            console.log(err);
            ShowToast.error_toast("Error creating car model");
          });
      })
      .catch((err) => {
        console.log(err);
        ShowToast.error_toast(err?.response?.data?.error);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h5>Truck Models</h5>
        <Button
          className="flex gap-2 items-center"
          size="sm"
          variant="gradient"
          color="blue"
          onClick={showAddModel}
        >
          <PlusCircleIcon className="h-5 w-5" />
          Add Model
        </Button>
      </div>

      <div>
        <DataTable
          data={data}
          columns={columns}
          paginationTotalRows={total}
          progressPending={loading}
          progressComponent={<LoadingDataComponent />}
          noDataComponent={<p>You do not have any car brands</p>}
          pagination={total >= 11 ? true : false}
          paginationServer
          onChangeRowsPerPage={handleRowChange}
          onChangePage={pageChange}
        />
      </div>

      {/* Add  */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Model</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AsyncSelect
            loadOptions={getCarBrands}
            className="mb-3 absolute z-10"
            placeholder="Search Brand..."
            defaultOptions
            cacheOptions
            isClearable
            onChange={(e: any) => setBrand(e?.value)}
          />
          {/* <select
              className="form-select"
              id="selectBrand"
              aria-label="Select brand"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            >
              <option value="" disabled></option>
              {(brandLoaded && <option value="">Loading...</option>) ||
                brandList.map((brandList) => {
                  return (
                    <option value={brandList.brand} key={brandList._id}>
                      {brandList.brand}
                    </option>
                  );
                })}
            </select> */}

          <FloatingLabel
            controlId="bname"
            label="Model name"
            className="mb-3"
            onChange={(e: any) => setModel(e.target.value)}
          >
            <Form.Control type="text" value={model} placeholder="Toyota" />
          </FloatingLabel>

          <Container className="mb-3" fluid>
            {typeArray.length >= 1 ? (
              <ListGroup>
                <ListGroup.Item>Truck Types</ListGroup.Item>
                {typeArray.map((d, index) => {
                  return (
                    <ListGroup.Item key={index}>
                      <div className="flex gap-1 items-center">
                        <XMarkIcon
                          className="h-5 w-5 cursor-pointer text-red-500"
                          onClick={removeCarType.bind(this, index)}
                        />
                        {d}
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            ) : null}
          </Container>

          <InputGroup className="mb-3">
            <Form.Select id="selectType">
              <option value="" disabled></option>
              {(typeLoaded && <option value="">Loading...</option>) ||
                types.map((types) => {
                  return (
                    <option value={types.cartype} key={types._id}>
                      {types.cartype}
                    </option>
                  );
                })}
            </Form.Select>
            <Button
              onClick={addCarType}
              style={{ background: "#52b3ef" }}
              type="button"
            >
              <PlusCircleIcon className="h-5 w-5" />
            </Button>
          </InputGroup>
          <div>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                Select Image
              </label>
              <input
                className="mb-3 form-control"
                accept="image/*"
                type="file"
                id="truckImage"
                onChange={imageChange2}
              />
            </div>

            {selectedImage2 && (
              <>
                <Container className="mb-3">
                  <Image src={URL.createObjectURL(selectedImage2)} fluid />
                </Container>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="gradient" color="blue" onClick={() => addCarModel()}>
            Post
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TruckModel;
