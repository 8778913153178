export const BASE_URL_V1 = process.env.REACT_APP_BASE_URL_V1 as string;
export const BASE_URL_V1_PROD = process.env
  .REACT_APP_BASE_URL_V1_PROD as string;

export const BASE_URL_V2 = process.env.REACT_APP_BASE_URL_V2 as string;
export const BASE_URL_V2_PROD = process.env
  .REACT_APP_BASE_URL_V2_PROD as string;

export const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY as string;

export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL as string;
export const PAYMENT_URL_PROD = process.env
  .REACT_APP_PAYMENT_URL_PROD as string;

export const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL as string;
export const FILE_UPLOAD_URL_PROD = process.env
  .REACT_APP_FILE_UPLOAD_URL_PROD as string;

export const MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
