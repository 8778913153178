import { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import {
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import icon from "../../../images/userIcon.png";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { sendSmsFunction } from "../../../functions/sendSms";
import { GenerateId } from "../../../functions/functions";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ChatBubbleLeftRightIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { Button, Tooltip } from "@material-tailwind/react";
import ShowAlert from "../../../components/alerts/all_alerts";
import { PhotoView } from "react-photo-view";
import { CSVLink } from "react-csv";
import { Empty } from "antd";
import {
  DATEEXPIREDsort,
  DriverNameSort,
} from "../../../components/general/TableSortFunctions";

const ExpiredLincence = (props: any) => {
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [reFetch, setReFetch] = useState<boolean>(false);

  const [dataIn, setDataIn] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<any>([]);
  const [specificData, setSpecificData] = useState<any>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [notifMsg, setNotifMsg] = useState<string>(
    "Dear Partner, The License information uploaded for your vehicle has expired, please update it by logging into your yarp account now. Thank you"
  );

  const [showModal, setShowModal] = useState<boolean>(false);

  // suspendsion Modal
  const [suspendModal, setSuspendModal] = useState<boolean>(false);
  const [suspendCode, setSuspendCode] = useState<any>("");
  const [suspendDate, setSuspendDate] = useState<any>("");
  const [msg, setMsg] = useState<any>("");
  const [randomNum, setRandomNum] = useState<any>("");

  //get expire licence
  const get_drivers = async (lim: number, sk: number) => {
    setDataIn(true);
    const date = new Date();
    const year = date.getFullYear();
    // const year = 2021;
    const month = date.getMonth() + 1;
    // const month = 13;
    function padLeadingZeros(num: string | number, size: number) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    }
    const yearMonth = `${year}-${padLeadingZeros(month, 2)}`;
    // console.log(yearMonth)

    try {
      const response = await BaseService.get_api(
        `${global_variables.yarpunverified_drivers}?limit=${lim}&skip=${sk}&filter={"condition":"approved_expired","FGT_Parameter":"${yearMonth}","parameter":true}`
      );
      var drivers_data = response?.data?.payload;
      // console.log(drivers_data);
      setDrivers(drivers_data);
      setTotalRows(response?.data.total);
      setDataIn(false);
    } catch (error) {
      console.log(error);
    }
  };

  //onload
  useEffect(() => {
    get_drivers(limit, skip);
  }, [reFetch, limit, skip]);

  //table columns
  const columns: any = [
    {
      name: "",
      selector: (row: { driver_data: { image: string | undefined } }) => (
        <>
          {row?.driver_data ? (
            <img
              src={row?.driver_data?.image}
              className="img-fluid"
              alt="..."
              style={{
                width: "50px",
                borderRadius: "100%",
                padding: "2px",
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              src={icon}
              alt="..."
              style={{
                width: "50px",
                borderRadius: "100%",
                padding: "2px",
                objectFit: "cover",
              }}
            />
          )}
        </>
      ),
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">FULL NAME</p>,
      selector: (row: { driver_data: { full_name: any } }) =>
        row?.driver_data.full_name || "N/A",
      sortable: true,
      sortFunction: DriverNameSort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">PHONE</p>,
      selector: (row: { driver_data: { phone: any } }) =>
        row?.driver_data.phone || "N/A",
      sortable: true,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">DATE EXPIRED</p>
      ),
      selector: (row: { dateExpired: moment.MomentInput }) =>
        moment(row?.dateExpired).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: DATEEXPIREDsort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">ACTION</p>,
      selector: (row: any, index: any) => (
        <div className="flex gap-2">
          <Link
            to="#"
            onClick={showDetails.bind(this, row)}
            style={{ textDecoration: "none", fontSize: "22px", padding: "3px" }}
          >
            <EyeIcon className="h-5 w-5 cursor-pointer" />
          </Link>
          <Link
            to="#"
            onClick={showSuspend.bind(this, row)}
            style={{
              textDecoration: "none",
              color: "red",
              fontSize: "22px",
              padding: "3px",
            }}
          >
            <ExclamationTriangleIcon className="h-5 w-5 cursor-pointer text-red-500" />
          </Link>
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#notifyLinces"
            onClick={sendMsg.bind(this, row)}
            style={{ textDecoration: "none", fontSize: "22px", padding: "3px" }}
          >
            <ChatBubbleLeftRightIcon className="h-5 w-5 cursor-pointer" />
          </Link>
        </div>
      ),
    },
  ];

  //export data
  const formatDataForExport = (data: any[]) => {
    return data.map((row) => ({
      "DRIVER NAME": row?.driver_data?.full_name,
      PHONE: row?.business_phone,
      "DATE EXPIRED": moment(row?.dateExpired).format("Do MMM YYYY - hh:mm A"),
    }));
  };

  const showSuspend = (row: any) => {
    setRandomNum(GenerateId(5));
    setSuspendModal(true);
    setSpecificData(row);
    // console.log(row)
  };

  const showDetails = (row: { driver_data: { image: string | undefined } }) => {
    setLoaded(true);
    setSpecificData(row);
    setShowModal(true);
  };

  const sendMsg = (row: any) => {
    setLoaded(true);
    setSpecificData(row);
  };

  const SendNoticationLicense = async (e: any) => {
    e.preventDefault();
    const phoneNumber = !loaded ? null : specificData["driver_data"]["phone"];

    ShowToast.loading_toast();

    var msg_data = {
      message: `${notifMsg}`,
      numbers: [`${phoneNumber}`],
    };

    sendSmsFunction(msg_data);
    // props.setReload(!props.reload);
    // console.log("msg_data", msg_data);
  };

  const suspendUser = (e: any) => {
    e.preventDefault();
    setSuspendModal(false);
    setSuspendCode("");
    // console.log(randomNum);

    if (randomNum !== suspendCode) {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "Please enter the comfirmaton code to suspend user from yarp",
      });
    } else if (msg === "") {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "Please tell the user the reason for suspension",
      });
    } else if (suspendDate === "") {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "Please set a date for the suspension to end",
      });
    } else if (randomNum === suspendCode) {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const driver_contact = specificData.driver_data
        ? specificData.driver_data.phone
        : null;
      const userId = specificData.userid;

      var suspend_data = {
        suspend: true,
        suspension_start: moment().format("YYYY-MM-D"),
        suspension_end: moment(suspendDate).format("YYYY-MM-D"),
        userid: userId,
      };

      BaseService.put_api(`${global_variables.suspend_users}`, suspend_data)
        .then((response) => {
          setMsg("");
          setSuspendDate("");
          get_drivers(limit, skip);
          ShowToast.success_toast(response.data.message);
          // props.setReload(!props.reload);
        })
        .catch((err) => {
          console.log(err);
          ShowAlert.error_alert("Error", err?.response?.data?.error, "Retry");
        });
    } else {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "There was an error!!!!!",
      });
    }
  };

  const handleRowChange = (a: any) => {
    setLimit(a);
    get_drivers(a, skip);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      get_drivers(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      get_drivers(limit, newSkip);
      setSkip(newSkip);
    }
  };

  return (
    <>
      <div className="mt-[20px] flex justify-between items-center">
        <Tooltip content="Reload" placement="top">
          <button
            className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
            onClick={() => setReFetch(!reFetch)}
          >
            <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
          </button>
        </Tooltip>
        <div className="flex gap-[10px]">
          <Tooltip content="Print" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <PrinterIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <CSVLink
            data={formatDataForExport(drivers)}
            filename="Expired License"
          >
            <Tooltip content="Export" placement="top">
              <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>

      <DataTable
        className="mt-[10px]"
        columns={columns}
        data={drivers}
        defaultSortAsc={false}
        highlightOnHover
        progressPending={dataIn}
        noDataComponent={
          <Empty description="No expired licence at the moment" />
        }
        progressComponent={<LoadingDataComponent />}
        pagination={totalRows >= 11 ? true : false}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        pointerOnHover
        onRowClicked={(e: any) => {
          showDetails(e);
        }}
      />

      {/* Driver Details Modal */}
      <Modal
        show={showModal}
        size="lg"
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Driver Details</Modal.Title>
        </Modal.Header>
        {loaded ? (
          <>
            <Modal.Body>
              <Container>
                <Table>
                  <tbody>
                    <tr>
                      <th style={{ width: "30%" }}>Full Name</th>
                      <td>
                        <Row>
                          <Col md={2}>
                            <Image
                              src={
                                specificData["driver_data"]
                                  ? specificData["driver_data"]["image"]
                                  : icon
                              }
                              fluid
                              style={{
                                borderRadius: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </Col>
                          <Col md={9} className="text-start">
                            <h4>
                              {specificData.driver_data
                                ? specificData.driver_data.full_name
                                : null}
                            </h4>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "30%" }}>Contact</th>
                      <td>
                        {specificData["driver_data"]
                          ? specificData["driver_data"]["phone"]
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "30%" }}>Status</th>
                      <td>
                        {specificData["approved"] === true ? (
                          <span
                            className="text-success"
                            style={{ fontWeight: "bold" }}
                          >
                            {" "}
                            Approved
                          </span>
                        ) : (
                          <span
                            className="text-danger"
                            style={{ fontWeight: "bold" }}
                          >
                            Pending
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "30%" }}>Licence Information:</th>
                      <td>
                        <Row>
                          <Col md={5}>
                            <PhotoView
                              src={
                                specificData["licence_image"] ||
                                "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                              }
                            >
                              <img
                                className="h-[150px] w-full rounded-[10px] object-cover border-[1px] border-gray-500 cursor-pointer"
                                src={
                                  specificData["licence_image"] ||
                                  "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                                }
                                alt="driver"
                              />
                            </PhotoView>
                          </Col>
                          <Col md={7}>
                            <p>
                              {" "}
                              <span className="fw-bold">Licence Number: </span>
                              {specificData["licence"]}{" "}
                            </p>
                            <p>
                              {" "}
                              <span className="fw-bold">Date Issued:</span>{" "}
                              {moment(specificData["dateIssued"]).format(
                                "MMM Do YYYY"
                              )}
                            </p>
                            <p>
                              {" "}
                              <span className="fw-bold">Expiry Date:</span>{" "}
                              {moment(specificData["dateExpired"]).format(
                                "MMM Do YYYY"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Container>
            </Modal.Body>
          </>
        ) : (
          <>....</>
        )}
      </Modal>

      {/* Send Message Modal */}
      <div
        className="modal fade"
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="truckInsuranceExpireNotify"
        aria-hidden="true"
        id="notifyLinces"
      >
        <div className="modal-dialog">
          <form className="modal-content" onSubmit={SendNoticationLicense}>
            <div className="modal-header">
              <h5 className="modal-title">Notify Partner </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="contactNumber"
                  /*value={!loaded ? "---" : specificData['driver_data']['phone']}*/ value={
                    loaded
                      ? specificData["driver_data"]
                        ? specificData["driver_data"]["phone"]
                        : "..."
                      : "..."
                  }
                  readOnly
                />
                <label htmlFor="contactNumber">Contact</label>
              </div>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="expired_ins_message"
                  style={{ height: "100px" }}
                  value={notifMsg}
                  onChange={(e) => setNotifMsg(e.target.value)}
                ></textarea>
                <label htmlFor="expired_ins_message">Message</label>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                color="red"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </Button>
              <Button type="submit" color="green" data-bs-dismiss="modal">
                Send Notification
              </Button>
            </div>
          </form>
        </div>
      </div>

      {/* Suspend User start  */}

      <Modal
        show={suspendModal}
        onHide={() => {
          setSuspendModal(false);
        }}
      >
        <form className="modal-content" onSubmit={suspendUser}>
          <Modal.Header closeButton>
            <Modal.Title>Suspend</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="text-center mb-2">
                <h5 className="fw-light m-0 p-0"> Comfirmation Code </h5>
                <p className="fw-bold m-0 p-0">
                  {suspendModal ? randomNum : "loadingGif...."}
                </p>
              </div>
              <p>
                Are you sure you want to suspend this user? Please enter the
                code above
              </p>
              <div className="form-floating mb-2">
                <input
                  type="text"
                  className="form-control"
                  id="comfirm"
                  placeholder="code"
                  style={{ textTransform: "uppercase" }}
                  value={suspendCode}
                  onChange={(e) => {
                    setSuspendCode(e.target.value.toUpperCase());
                  }}
                />
                <label htmlFor="comfirm">Enter code</label>
              </div>

              <FloatingLabel
                controlId="message"
                label="Message"
                className="mb-3"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a message for the user"
                  style={{ height: "100px" }}
                  value={msg}
                  onChange={(e) => {
                    setMsg(e.target.value);
                  }}
                />
              </FloatingLabel>

              <div className="form-floating">
                <input
                  type="date"
                  className="form-control"
                  id="datePicke"
                  placeholder="code"
                  style={{ textTransform: "uppercase" }}
                  value={suspendDate}
                  onChange={(e) => {
                    setSuspendDate(e.target.value);
                  }}
                />
                <label htmlFor="datePicke">Select Suspension End Date</label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" color="red">
              Suspend
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ExpiredLincence;
