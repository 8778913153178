import { Modal } from "react-bootstrap";
import MapsLayoutNew from "../MapsLayoutNew";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";

const MapModal = ({
  closeModal,
  DeliveryLocationLatLng,
  PickupLocationLatLong,
  mapShow,
  tripForModal,
  detail,
}: {
  mapShow: any;
  PickupLocationLatLong: any;
  DeliveryLocationLatLng: any;
  closeModal: any;
  tripForModal: any;
  detail: any;
}) => {
  return (
    <Modal show={mapShow} onHide={closeModal} size="lg" fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title>Tracking</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mapShow && tripForModal.length !== 0 ? (
          <>
            <MapsLayoutNew
              driverId={tripForModal?.driverId}
              trackingId={tripForModal?._id}
              DeliveryLocationLatLng={DeliveryLocationLatLng}
              PickupLocationLatLong={PickupLocationLatLong}
              detail={detail}
            />
          </>
        ) : (
          <div className="flex justify-center items-center h-full">
            <LoadingDataComponent />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MapModal;
