import moment from "moment";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import CustomSideBar from "../../../components/layouts/sidebar";
import CustomNavbar from "../../../components/layouts/navbar";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Typography } from "@material-tailwind/react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { PhotoView } from "react-photo-view";
import { TripHistoryTable } from "../drivers/trans_history";
import UnderConstruction from "../../../components/general/underConstruction";
import { TransactionHistoryTable } from "./TransactionHistoryTable";
import DailySessions from "../drivers/DailySessions";
import OfflineSession from "../drivers/OfflineSession";

const UserDetail = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<any>([]);

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const userid = query.get("uid");
  const activeSec = query.get("tab");

  const [activeTab, setActiveTab] = useState<number>(0);

  const getUserDetails = async () => {
    console.log("userId is", userid);
    if (userid === "" || !userid || userid === "undefined") {
      ShowToast.error_toast("User not found");
      navigate("/users");
    } else {
      try {
        const results = await BaseService.get_api_v2(
          `/yarp/user/all?filter={"userid":"${userid}"}`
        );
        const data = await results.data;
        if (data.payload.length === 0) {
          ShowToast.error_toast("User not found");
          navigate("/users");
        } else {
          setUser(data.payload);
          setLoading(false);
          console.log(data.payload);
        }
      } catch (error) {
        // console.log("This is the error", error);
        navigate("/users");
      }
    }
  };

  const tabComponents = [
    // <UnderConstruction />,
    <TripHistoryTable user_id={userid} mytype="user_id" />,
    <TransactionHistoryTable user_id={userid} mytype="user_id" />,
    <DailySessions user_id={userid} mytype="user_id" />,
    <OfflineSession user_id={userid} mytype="user_id" />,
    // <UnderConstruction />,
  ];

  useEffect(() => {
    getUserDetails();
  }, [userid]);

  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar usersActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <div className="flex gap-3 items-center">
                <button
                  className="flex gap-2 items-center h-[30px] px-3 bg-yarpBlue text-white rounded-[10px]"
                  onClick={() => navigate(`/users?tab=${activeSec}`)}
                >
                  <ArrowLeftIcon className="w-5 h-5" />
                  Back
                </button>
                <Typography variant="h4" className="mt-2">
                  User Details
                </Typography>
              </div>

              <Container fluid>
                <div>
                  {loading ? (
                    <div className="p-[30px] bg-white shadow-md rounded-[20px] mt-[18px] flex justify-center">
                      <LoadingDataComponent />
                    </div>
                  ) : (
                    <>
                      <div className="p-[30px] bg-white shadow-md rounded-[20px] mt-[18px] grid grid-cols-3 gap-5 w-full">
                        <div className="col-span-1 border-r-[1px] border-r-gray-500">
                          <div>
                            <div className="flex gap-[15px] items-center">
                              <PhotoView
                                src={
                                  user[0]?.image ||
                                  "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                }
                              >
                                <img
                                  className="h-[100px] w-[100px] rounded-full object-cover border-[1px] border-gray-500 cursor-pointer"
                                  src={
                                    user[0]?.image ||
                                    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                  }
                                  alt="driver"
                                />
                              </PhotoView>
                              <div>
                                <p className="font-[700] text-[20px] leading-[30px] text-[#656565] mb-0">
                                  {user[0]?.full_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col justify-center w-full col-span-2">
                          <div className="lg:grid lg:grid-cols-3 gap-y-[20px]">
                            <div className="w-full">
                              <p className="font-[700] text-[14px] leading-[21px] text-yarpGray mb-0">
                                Email
                              </p>
                              <p className="font-[300] text-[14px] leading-[21px] text-yarpGray">
                                {user[0]?.email || "N/A"}
                              </p>
                            </div>
                            <div className="w-full">
                              <p className="font-[700] text-[14px] leading-[21px] text-yarpGray mb-0">
                                Contact
                              </p>
                              <p className="font-[300] text-[14px] leading-[21px] text-yarpGray">
                                {user[0]?.phone}
                              </p>
                            </div>
                            <div className="w-full">
                              <p className="font-[700] text-[14px] leading-[21px] text-yarpGray mb-0">
                                Date Created
                              </p>
                              <p className="font-[300] text-[14px] leading-[21px] text-yarpGray">
                                {moment(user[0]?.created_at).format(
                                  "Do MMM YYYY - hh:mm A"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="p-10 bg-white shadow-lg rounded-[20px] mt-4">
                  <div className="flex gap-3">
                    {[
                      // "Dashboard",
                      "Trip History",
                      "Transactions",
                      "Daily Sessions",
                      "Online/Offline Session",
                    ].map((dd, i) => (
                      <div
                        key={i}
                        className={`${
                          activeTab === i
                            ? "border-b-[2px] border-b-yarpOrange px-3"
                            : ""
                        } cursor-pointer`}
                        onClick={() => setActiveTab(i)}
                      >
                        <Typography className="mb-0">{dd}</Typography>
                      </div>
                    ))}
                  </div>

                  <div>{tabComponents[activeTab]}</div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetail;
