export const ReductionFunction = (state: any, action: any) => {
  if (action.type === "TEXT") {
    const withTextFilter = state.newState.filter((objs: any) =>
      action.payload
        ? new RegExp(action.payload.trim(), "i").test(objs.fullName.trim())
        : objs
    );

    return {
      ...state,
      nameSearch: withTextFilter,
    };
  }
  if (action.type === 0) {
    const { creditPayload } = action.payload;
    const filteredValue = creditPayload.map((objData: any) => {
      return {
        id: objData._id,
        amount: objData.amount,
        createdAt: objData.createdAt,
        phone: objData.driver_data.phone,
        fullName: objData.driver_data.full_name,
        tripAmount: objData.tripAmount,
        image: objData.user_data?.image,
        transactionStatus: objData.transactionStatus,
      };
    });

    return {
      ...state,
      creditState: filteredValue,
      newState: filteredValue,
      loadingCredit: false,
    };
  }

  if (action.type === 1) {
    const { remitPayload } = action.payload;
    const filteredValue = remitPayload.map((objData: any) => {
      return {
        id: objData._id,
        amount: objData.amount,
        createdAt: objData.createdAt,
        phone: objData.driver_data.phone,
        fullName: objData.driver_data.full_name,
        tripAmount: objData.tripAmount,
        image: objData.user_data?.image,
        transactionStatus: objData.transactionStatus,
      };
    });

    return {
      ...state,
      RemitState: filteredValue,
      newState: filteredValue,
      loadingRemit: false,
    };
  }

  return state;
};
