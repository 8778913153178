import { useCallback } from "react";

const useProperName = () => {
  const displayProperNames = useCallback((status: string) => {
    switch (status) {
      case "Pending":
        return "Pending";
      case "Accept":
        return "Accepted";
      case "Arrived At PickUp":
        return "Arrived";
      case "complete Trip":
        return "complete Trip";
      case "Start Queuing":
        return "Started Queuing";
      case "End Queuing":
        return "Ended Queuing";
      case "Start Loading":
        return "Started Loading";
      case "Cancel":
        return "Canceled";
      case "End Loading":
        return "Ended Loading";
      case "Trip Started":
        return "Trip Started";
      case "Trip Ended":
        return "Trip Ended";
      case "End Queuing At Destination":
        return "End Queuing";
      case "Payment Complete":
        return "Payment Complete";
      case "Start Offloading":
        return "Offloading";
      case "Queuing At Destination":
        return "Queuing At Destination";
      case "End Offloading":
        return "Offload Ended";
      case "Not Available":
        return "Not Available";
      default:
        return "";
    }
  }, []);

  return displayProperNames;
};

export default useProperName;
