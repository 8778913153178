export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.startsWith("+233")) {
    // Phone number starts with +233, return as it is
    return phoneNumber;
  } else if (phoneNumber.startsWith("233")) {
    // Phone number starts with 233, add a + before it
    return `+${phoneNumber}`;
  } else if (phoneNumber.startsWith("0")) {
    // Phone number starts with 0, replace it with +233
    return `+233${phoneNumber.substr(1)}`;
  } else {
    // For any other case, return the original number (no formatting)
    return "Invalid phone number";
  }
}
