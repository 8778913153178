import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import axios from "axios";
import { global_variables } from "../../../helpers/globalVariables";
import { StorageBox } from "../../../helpers/storage";
import { BASE_URL_V1 } from "../../../helpers/constants";
import getWeeksInMonth from "../../../HOC/getWeeksInMonth";

// import loadingGif from '../../images/yarp-spinner.gif';
ChartJS.register(...registerables);

const api = BASE_URL_V1;

var date = new Date();

const year = date.getFullYear();
// const year = 2021;
const month = date.getMonth() + 1;
// const month = 13;

const weeksDated = getWeeksInMonth(year, month, "monday");

function padLeadingZeros(num: string | number, size: number) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

const ThisMonthChart = () => {
  const [dataIn, setDataIn] = useState(false);
  const [val_1, setWeek1] = useState(0);
  const [val_2, setWeek2] = useState(0);
  const [val_3, setWeek3] = useState(0);
  const [val_4, setWeek4] = useState(0);
  const [val_5, setWeek5] = useState(0);

  const monthOfYear = year + "-" + padLeadingZeros(month, 2);

  const week1_begin =
    monthOfYear + "-" + padLeadingZeros(weeksDated[0].start, 2);
  const week1_end = monthOfYear + "-" + padLeadingZeros(weeksDated[0].end, 2);

  const week2_begin =
    monthOfYear + "-" + padLeadingZeros(weeksDated[1].start, 2);
  const week2_end = monthOfYear + "-" + padLeadingZeros(weeksDated[1].end, 2);

  const week3_begin =
    monthOfYear + "-" + padLeadingZeros(weeksDated[2].start, 2);
  const week3_end = monthOfYear + "-" + padLeadingZeros(weeksDated[2].end, 2);

  const week4_begin =
    monthOfYear + "-" + padLeadingZeros(weeksDated[3].start, 2);
  const week4_end = monthOfYear + "-" + padLeadingZeros(weeksDated[3].end, 2);

  const week5_begin =
    monthOfYear + "-" + padLeadingZeros(weeksDated[4].start, 2);
  const week5_end = monthOfYear + "-" + padLeadingZeros(weeksDated[4].end, 2);

  const fetchWeek1 = async (week_begin: string, week_end: string) => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${week_begin}","FGT_Parameter":"${week_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);
        if (gotData === null) {
          setWeek1(0);
        } else {
          setWeek1(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchWeek2 = async (week_begin: string, week_end: string) => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${week_begin}","FGT_Parameter":"${week_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);
        if (gotData === null) {
          setWeek2(0);
        } else {
          setWeek2(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchWeek3 = async (week_begin: string, week_end: string) => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${week_begin}","FGT_Parameter":"${week_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);
        if (gotData === null) {
          setWeek3(0);
        } else {
          setWeek3(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchWeek4 = async (week_begin: string, week_end: string) => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${week_begin}","FGT_Parameter":"${week_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);
        if (gotData === null) {
          setWeek4(0);
        } else {
          setWeek4(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchWeek5 = async (week_begin: string, week_end: string) => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${week_begin}","FGT_Parameter":"${week_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);
        if (gotData === null) {
          setWeek5(0);
        } else {
          setWeek5(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const week_one_value = fetchWeek1(week1_begin, week1_end);
  const week_two_value = fetchWeek2(week2_begin, week2_end);
  const week_three_value = fetchWeek3(week3_begin, week3_end);
  const week_four_value = fetchWeek4(week4_begin, week4_end);
  const week_five_value = fetchWeek5(week5_begin, week5_end);

  var theChart;
  var theOption;

  var week1 = [val_1];
  var week2 = [val_2];
  var week3 = [val_3];
  var week4 = [val_4];
  var week5 = [val_5];

  var xValues = ["", "WEEK 1", "WEEK 2", "WEEK 3", "WEEK 4", "WEEK 5"];
  var yValues = [0, week1, week2, week3, week4, week5];

  //console.log(yValues);

  //SETUP BLOCK
  var data = {
    labels: xValues,
    datasets: [
      {
        backgroundColor: "rgba(255, 103, 0)",
        borderColor: "rgba(255, 103, 0)",
        radius: 5,
        data: yValues,
        label: "Earnings - GHC",
      },
    ],
  };

  theChart = data;

  //CONFIG BLOCK

  var options = {
    tension: 0.5,
    borderWidth: 1,
    plugins: {
      legend: {
        display: false,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  theOption = options;

  return (
    <>
      <Line
        data={theChart}
        options={theOption}
        style={{ maxHeight: "330px", minHeight: "330px" }}
      />
    </>
  );
};

export default ThisMonthChart;
