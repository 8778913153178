import { useState } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import {
  Button,
  Spinner,
  Textarea,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import ShowToast from "../../components/alerts/all_toasts";
import BaseService from "../../helpers/baseService";
import { global_variables } from "../../helpers/globalVariables";
import { ClipboardDocumentListIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import PhoneInput from "react-phone-number-input";
import { Dropdown, Empty, notification } from 'antd';
import type { MenuProps } from 'antd';
import { StorageBox } from "../../helpers/storage";

const SendSMS = () => {
  const [numbersList, setNumbersList] = useState<any>([]);
  const [thePhoneNumber, setThePhoneNumber] = useState("");
  const [theMessage, setTheMessage] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [fetchingList, setFetchingList] = useState(false)

  //handleSendSmss
  const handleSendSms = async () => {
    if (numbersList.length === 0) {
      ShowToast.warning_toast("Please add at leaast one recipient");
    } else if (!theMessage) {
      ShowToast.warning_toast("Please enter a message");
    } else {
      setIsBusy(true);
      const data = JSON.stringify({
        message: theMessage,
        numbers: numbersList,
      });
      try {
        const response = await BaseService.post_api(
          global_variables.sendSms,
          data
        );
        console.log(response?.data);
        ShowToast.success_toast(response?.data?.message);
        setTheMessage("");
        setNumbersList([]);
        setThePhoneNumber("");
        setIsBusy(false);
      } catch (error: any) {
        console.log(error);
        ShowToast.error_toast(error?.response?.data?.error);
      }
    }
  };

  //dropdown menus
  const items: MenuProps['items'] = [
    {
      label: (
        <button className="w-full text-left" onClick={() => lineUpDrivers()}>Drivers</button>
      ),
      key: '0',
    },
    {
      label: <button className="w-full text-left" onClick={() => lineUpUsers()}>App Users</button>,
      key: '1',
    },
    {
      label: <button className="w-full text-left" onClick={() => lineUpFleet()}>Fleet Owners</button>,
      key: '3',
    }
  ];

  //handleReset
  const handleReset = () => {
    setTheMessage("");
    setNumbersList([]);
    setThePhoneNumber("");
  };

  //handleAddToList
  const handleAddToList = () => {
    if (!thePhoneNumber) {
      ShowToast.warning_toast("Please eneter a phone number");
    } else {
      const tempArr = numbersList;
      numbersList.push(thePhoneNumber);
      setNumbersList(tempArr);
      setThePhoneNumber("");
    }
  };

  //handleDelete
  const handleDelete = (data: any) => {
    const filtered = numbersList.filter((item: any) => item !== data);
    setNumbersList(filtered);
  };

  //handle all queuing start
  const lineUpDrivers = async () => {
    setFetchingList(true)
    setTimeout(() => {
      const allDrivers = StorageBox.retrieveDriverSms()
      if (allDrivers) {
        notification.success({ message: "All driver contacts retrieved" })
        const pileList = allDrivers.map((item: any) => (
          item?.driver_data?.phone
        ))
        setNumbersList(pileList)
        setFetchingList(false)
      }
      else {
        notification.error({ message: "No users have been queued" })
        setFetchingList(false)
      }
    }, 2000)
  }

  const lineUpUsers = async () => {
    setFetchingList(true)
    setTimeout(() => {
      const allUsers = StorageBox.retrieveUserSms()
      if (allUsers) {
        notification.success({ message: "All user contacts retrieved" })
        const pileList = allUsers.map((item: any) => (
          item?.phone
        ))
        setNumbersList(pileList)
        setFetchingList(false)
      }
      else {
        notification.error({ message: "No users have been queued" })
        setFetchingList(false)
      }
    }, 2000)
  }

  const lineUpFleet = async () => {
    setFetchingList(true)
    setTimeout(() => {
      const allUsers = StorageBox.retrieveFleetSms()
      if (allUsers) {
        notification.success({ message: "All fleet owners contacts retrieved" })
        const pileList = allUsers.map((item: any) => (
          item?.phone
        ))
        setNumbersList(pileList)
        setFetchingList(false)
      }
      else {
        notification.error({ message: "No users have been queued" })
        setFetchingList(false)
      }
    }, 2000)
  }
  //handle all queuing end


  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar smsActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">Send SMS</p>

              <div className="lg:grid lg:grid-cols-2 gap-x-[20px]">
                <div className="p-4 rounded-[20px] shadow-lg bg-white lg:mb-3 mb-0 h-fit">
                  <small>Enter a phone number and click the plus icon</small>
                  <div className="flex gap-[20px]">
                    <PhoneInput
                      id="phone"
                      className="rounded-[5px] w-full border-[1px] border-gray-500 px-2 h-[40px]"
                      defaultCountry="GH"
                      inputStyle={{ width: "100%" }}
                      value={thePhoneNumber}
                      onChange={(phone: any) => setThePhoneNumber(phone)}
                      disabled={isBusy}
                      numberInputProps={{
                        className: "focus:outline-none",
                      }}
                    />
                    <Button
                      variant="gradient"
                      color="blue"
                      size="sm"
                      onClick={() => handleAddToList()}
                      disabled={isBusy}
                    >
                      <PlusCircleIcon className="w-5 h-5" />
                    </Button>
                  </div>
                  <div className="mt-3">
                    <Textarea
                      label="Enter message"
                      className="h-[400px]"
                      value={theMessage}
                      onChange={(e) => setTheMessage(e.target.value)}
                      disabled={isBusy}
                    />
                  </div>
                  <div className="mt-3 flex gap-5 justify-between">
                    <Button
                      variant="gradient"
                      color="gray"
                      onClick={() => handleReset()}
                      disabled={isBusy}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="gradient"
                      color="blue"
                      onClick={() => handleSendSms()}
                      disabled={isBusy}
                    >
                      {isBusy ? <Spinner /> : "Send SMS"}
                    </Button>
                  </div>
                </div>

                <div className="p-4 rounded-[20px] shadow-lg bg-white h-fit">
                  <div className="flex justify-between items-center">
                    <Typography className="font-semibold">
                      List of phone number to receive sms
                    </Typography>
                    <Tooltip content="Fetch List" placement="top">
                      <Dropdown menu={{ items }} trigger={['click']} disabled={fetchingList}>
                        <button
                          className="w-10 h-10 flex justify-center items-center bg-yarpBlue rounded-[5px] disabled:cursor-not-allowed disabled:bg-gray-400"
                          onClick={(e) => e.preventDefault()}
                          disabled={fetchingList}
                        >
                          {fetchingList ? <Spinner /> : <ClipboardDocumentListIcon className="w-5 h-5 text-white" />}
                        </button>
                      </Dropdown>
                    </Tooltip>
                  </div>
                  <hr />

                  {numbersList.length === 0 ? (
                    <div className="flex justify-center items-center">
                      <Empty description="No mobile numbers added" />
                    </div>
                  ) : (
                    <>
                      {numbersList.map((item: any, i: number) => (
                        <div
                          key={i}
                          className="p-1 border-b border-b-gray-400 flex justify-between items-center"
                        >
                          <p>{i + 1}.</p>
                          <p>{item}</p>
                          <TrashIcon
                            className="h-5 w-5 text-red-500 cursor-pointer mb-3"
                            onClick={() => handleDelete(item)}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendSMS;
