import React from "react";
import { Input } from "antd";

const AntInput = ({ onText, selectInfo }: { onText: any; selectInfo: any }) => {
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    onText(e.target.value);
  };
  return (
    <>
      <Input
        placeholder={
          selectInfo === "pickup_location"
            ? "Search Pickup location"
            : selectInfo === "delivery_location"
            ? "Search Delivery location"
            : "Search Pickup / Delivery Location"
        }
        allowClear
        disabled={selectInfo ? false : true}
        onChange={onChange}
        style={{
          width: "250px",
          border: `${selectInfo ? "1px solid #2196F3" : ""}`,
        }}
      />
    </>
  );
};

export default AntInput;
