import moment from "moment";
import { useEffect, useState } from "react";
import { Image, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Spinner, Tooltip } from "@material-tailwind/react";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { sendSmsFunction } from "../../../functions/sendSms";
import { PhotoView } from "react-photo-view";
import { CSVLink } from "react-csv";
import { Empty } from "antd";
import NewDatePickerRange from "../../../components/shared/newDatePicker/NewDatePicker";
import {
  BUSINESSSort,
  EMAILSSort,
  business_locationsort,
} from "../../../components/general/TableSortFunctions";

const UnapprovedFleetTable = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [fleets, setFleets] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(100);
  const [skip, setSkip] = useState(0);
  const [reFetch, setReFetch] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  //fetch fellet owners
  const getFleets = async (lim: any, sk: any) => {
    try {
      setLoading(true);
      const response = await BaseService.get_api(
        `${global_variables.getunverifiedFleet}?limit=${lim}&skip=${sk}&sort={"created_at":-1}`
      );
      // console.log(response?.data);
      setLoading(false);
      setTotalRows(response?.data?.total);
      setFleets(response?.data?.payload);
    } catch (error) {
      console.log(error);
    }
  };

  //on load
  useEffect(() => {
    getFleets(limit, skip);
  }, [limit, skip, reFetch]);

  //table columns
  const columns: any = [
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">BUSINESS NAME</p>
      ),
      selector: (row: any) => (
        <div className="flex ga-3 items-center">
          <Image
            src={
              row.user_data?.image ||
              "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
            }
            fluid
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              borderRadius: "100%",
              marginRight: "10px",
            }}
          />
          {row?.business_name}
        </div>
      ),
      sortable: true,
      sortFunction: BUSINESSSort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">PHONE</p>,
      selector: (row: any) => row.business_phone,
      sortable: true,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">EMAIL</p>,
      selector: (row: any) => row.business_email,
      sortable: true,
      sortFunction: EMAILSSort,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">
          BUSINESS LOCATION
        </p>
      ),
      selector: (row: any) => row?.business_location,
      sortable: true,
      sortFunction: business_locationsort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">STATUS</p>,
      selector: (row: any) =>
        row?.approved ? (
          <div className="px-4 py-1 bg-yarpGreen text-yarpGreen bg-opacity-[20%] rounded-[40px]">
            Approved
          </div>
        ) : (
          <div className="px-4 py-1 bg-red-500 text-red-500 bg-opacity-[20%] rounded-[40px]">
            Pending
          </div>
        ),
    },
  ];

  //export data
  const formatDataForExport = (data: any) => {
    return data.map((row: any) => ({
      "BUSINESS NAME": row?.business_name,
      PHONE: `.${row?.business_phone}`,
      EMAIL: row?.business_email,
      "BUSINESS LOCATION": row?.business_location,
      STATUS: row?.approved ? "Approved" : "Pending",
    }));
  };

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<any>([]);
  const displayModal = (row: any) => {
    setModalData(row);
    setShow(true);
  };

  //approve fleet details
  const approveDetails = async () => {
    setIsBusy(true);

    var fleet_data = {
      company_id: modalData.userid,
      approved: true,
    };

    try {
      const response = await BaseService.put_api(
        `${global_variables.verifyFleet}`,
        fleet_data
      );
      // console.log(response);
      ShowToast.success_toast("Company details has been approved");
      setIsBusy(false);
      //send message
      var msg_data = {
        message: `Dear partner, your yarp company account has succesfully been approved. Thanks for choosing Yarp`,
        numbers: [`${modalData.business_phone}`],
      };
      sendSmsFunction(msg_data);
      setShow(false);
      getFleets(limit, skip);
    } catch (error: any) {
      console.log(error);
      ShowToast.error_toast(error?.response?.data?.message);
      setIsBusy(false);
    }
  };

  const handleRowChange = (a: any) => {
    setLimit(a);
    getFleets(a, skip);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getFleets(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getFleets(limit, newSkip);
      setSkip(newSkip);
    }
  };
  const onDateDataHandler = (dateObj: any) => {
    const { start, end } = dateObj;
    console.log(start, end);
    //getDrivers(limit, skip, start, end);
    // if (!start && !end) {
    //   getDrivers(limit, skip);
    // }
  };
  return (
    <>
      <div className="mt-[20px]  flex justify-between items-center">
        <Tooltip content="Reload" placement="top">
          <button
            className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
            onClick={() => setReFetch(!reFetch)}
          >
            <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
          </button>
        </Tooltip>
        <div className="flex gap-[10px]">
          <NewDatePickerRange onDateData={onDateDataHandler} />

          <Tooltip content="Print" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <PrinterIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <CSVLink
            data={formatDataForExport(fleets)}
            filename="Unapproved Fleet Owners"
          >
            <Tooltip content="Export" placement="top">
              <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>

      <DataTable
        className="mt-[10px]"
        columns={columns}
        data={fleets}
        pagination={totalRows >= 11 ? true : false}
        paginationServer
        pointerOnHover
        highlightOnHover
        paginationTotalRows={totalRows}
        noDataComponent={
          <Empty description="There are no fleet accounts pending approval" />
        }
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        onRowClicked={(row) => {
          displayModal(row);
        }}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
      />

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setModalData([]);
        }}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="p-[30px]">
            <p className="text-[20px] font-[600] text-yarpGray">
              Personal Details
            </p>

            <hr />

            {show ? (
              <>
                {/* owner info */}
                <div className="flex gap-[53px] items-center">
                  <PhotoView
                    src={
                      modalData?.user_data?.image ||
                      "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                    }
                  >
                    <img
                      className="h-[153px] w-[153px] rounded-full object-cover cursor-pointer"
                      src={
                        modalData?.user_data?.image ||
                        "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                      }
                      alt="driver"
                    />
                  </PhotoView>
                  <div className="w-[450px]">
                    <div className="lg:grid lg:grid-cols-2 mb-0">
                      <p className="text-[14px] font-[600] text-yarpGray">
                        Full Name
                      </p>
                      <p className="text-[14px] font-[300] text-yarpGray">
                        {modalData?.user_data?.first_name}
                      </p>
                    </div>
                    <div className="lg:grid lg:grid-cols-2">
                      <p className="text-[14px] font-[600] text-yarpGray">
                        Email
                      </p>
                      <p className="text-[14px] font-[300] text-yarpGray">
                        {modalData?.user_data?.middle_name || "N/A"}
                      </p>
                    </div>
                    <div className="lg:grid lg:grid-cols-2">
                      <p className="text-[14px] font-[600] text-yarpGray">
                        Phone No.
                      </p>
                      <p className="text-[14px] font-[300] text-yarpGray">
                        {modalData?.user_data?.phone}
                      </p>
                    </div>
                    <div className="lg:grid lg:grid-cols-2">
                      <p className="text-[14px] font-[600] text-yarpGray">
                        Joined
                      </p>
                      <p className="text-[14px] font-[300] text-yarpGray">
                        {moment(modalData?.user_data?.created_at).format(
                          "Do MMMM YYYY hh:mm: a"
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                {/* national id */}
                <div className="mt-5">
                  <p className="text-[20px] font-[600] text-yarpGray">
                    National ID
                  </p>

                  <hr />

                  <div className="w-[450px]">
                    <div className="lg:grid lg:grid-cols-2">
                      <p className="font-[600] text-[14px] text-yarpGray">
                        Ghana Card NO.
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {modalData?.ghana_card_number || "N/A"}
                      </p>
                    </div>
                  </div>

                  <div className="lg:grid lg:grid-cols-2 lg:gap-x-5 w-full">
                    <div className="w-full">
                      <div className="w-full rounded-[20px] mt-2">
                        <PhotoView
                          src={
                            modalData?.ghana_card ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                        >
                          <img
                            src={
                              modalData?.ghana_card ||
                              "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                            }
                            alt="nFront"
                            className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                          />
                        </PhotoView>
                      </div>
                      <small className="text-gray-500 mt-[13px]">
                        Front View
                      </small>
                    </div>

                    <div className="w-full">
                      <div className="w-full rounded-[20px] mt-2">
                        <PhotoView
                          src={
                            modalData?.ghana_card ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                        >
                          <img
                            src={
                              modalData?.ghana_card ||
                              "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                            }
                            alt="nBack"
                            className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                          />
                        </PhotoView>
                      </div>
                      <small className="text-gray-500 mt-[13px]">
                        Back View
                      </small>
                    </div>
                  </div>
                </div>

                {/* business */}
                <div className="mt-5">
                  <p className="text-[20px] font-[600] text-yarpGray">
                    Company Details
                  </p>

                  <hr />

                  <div className="w-full lg:grid lg:grid-cols-3 gap-[40px]">
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Company Name
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {modalData?.business_name}
                      </p>
                    </div>
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Company Email
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {modalData?.business_email}
                      </p>
                    </div>
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Company Contact
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {modalData?.business_phone}
                      </p>
                    </div>
                  </div>

                  <div className="w-full lg:grid lg:grid-cols-3 gap-[40px]">
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Location
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {modalData?.business_location}
                      </p>
                    </div>
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Region
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {modalData?.region}
                      </p>
                    </div>
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Registration ID
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {modalData?.business_id}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-4">
          <button className="text-yarpGray" onClick={() => setShow(false)}>
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded-[40px] border-[1px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white flex justify-center items-center disabled:cursor-not-allowed"
            // onClick={() => handleOpenedDeclineModal()}
            disabled={true}
          >
            {isBusy ? <Spinner color="red" /> : <span>Decline</span>}
          </button>
          <button
            className="px-4 py-2 rounded-[40px] bg-yarpOrange text-white flex justify-center items-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={approveDetails}
            disabled={isBusy}
          >
            {isBusy ? <Spinner /> : <span> Approve</span>}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UnapprovedFleetTable;
