import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ShowToast from "../../alerts/all_toasts";
import IconButton from "@material-tailwind/react/components/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Modal } from "antd";
import BaseService from "../../../helpers/baseService";
import { SyncOutlined } from "@ant-design/icons";
import { hasDatePassed } from "../../../utils/functions";
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ExtraItems({
  rowId,
  onDeleting,
}: {
  rowId: any;
  onDeleting: any;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDeleting, setDeleting] = React.useState(false);
  const open = Boolean(anchorEl);
  const [myopen, setOpen] = React.useState(false);

  const DeleteSchedule = async () => {
    const del_data = {
      scheduleId: "",
    };
    try {
      const response = await BaseService.delete_api_v2(
        `/yarp/schedule/${rowId._id}`,
        del_data
      );
      const data = await response.data;
      setDeleting(true);
      onDeleting(true);
      ShowToast.success_toast("Schedule Deleted successful");
    } catch (error: any) {
      setDeleting(false);
      ShowToast.error_toast("Schedule failed to delete");
    }
  };

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    if (hasDatePassed(rowId?.pickuptime)) {
      setDeleting(true);
      DeleteSchedule();
    } else {
      ShowToast.info_toast("Schedule has not expired yet");
    }

    setTimeout(() => {
      setDeleting(false);
      setOpen(false);
    }, 1000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{ background: "white" }}
      >
        <MoreVertIcon style={{ color: "black" }} />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            showModal();
            handleClose();
          }}
          disableRipple
        >
          <DeleteForeverIcon style={{ color: "red", fontSize: "20px" }} />
          delete schedule
        </MenuItem>
      </StyledMenu>
      <Modal
        open={myopen}
        title="Delete the Schedule"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={(_, { CancelBtn }) => (
          <div className="flex justify-end gap-2 ">
            <Button
              style={{ border: "1px solid #d9d9d9", color: "black" }}
              onClick={handleOk}
            >
              {isDeleting ? <SyncOutlined spin /> : "ok"}
            </Button>
            <Button
              style={{ border: "1px solid #d9d9d9", color: "black" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {/* <CancelBtn /> */}
          </div>
        )}
      >
        <p className="text-sm">
          Are you sure you want to perform this action ?
        </p>
      </Modal>
    </div>
  );
}
