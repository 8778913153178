import noDataImg from "../../images/no_data.png";

const NoDataComponent = ({ text }: any) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <img src={noDataImg} alt="nodata" className="w-[100px]" />
      <p>{text}</p>
    </div>
  );
};

export default NoDataComponent;
