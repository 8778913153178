import React from "react";
import { Input } from "antd";

const InputSingle = ({
  onText,
  placeholder,
}: {
  onText: any;
  placeholder?: any;
}) => {
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    onText(e.target.value);
  };
  return (
    <>
      <Input
        placeholder={placeholder || "Search Name"}
        allowClear
        // disabled={selectInfo ? false : true}
        onChange={onChange}
        style={{
          width: "100%",
          height: "40px",
          //   border: `${selectInfo ? "1px solid #2196F3" : ""}`,
        }}
      />
    </>
  );
};

export default InputSingle;
