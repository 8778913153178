import { useEffect, useState } from "react";
import { Button, Input, Modal, Spin } from "antd";
import BaseService from "../../../helpers/baseService";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { sendSmsFunction } from "../../../functions/sendSms";

interface ModalProps {
  open: boolean;
  SmsNumber: any;
  driverName: string | undefined;
  pickupLocation: string | undefined;
  deleiveryLocation: string | undefined;
  setOpenSMS: (open: boolean) => void;
  MessageForNearbyDrivers?: string | undefined;
}

const SmsModal = ({
  SmsNumber,
  open,
  setOpenSMS,
  driverName,
  pickupLocation,
  deleiveryLocation,
  MessageForNearbyDrivers,
}: ModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [contact, setContact] = useState<string | undefined>(SmsNumber);
  const [textAreaValue, setTextAreaValue] = useState<string | undefined>(
    MessageForNearbyDrivers
  );

  const handleCancel = () => {
    setOpenSMS(false);
  };

  const handleOk = () => {
    getSingleNotifications();
  };

  const getSingleNotifications = async () => {
    setLoading(true);
    setOpenSMS(true);

    const msg_data = {
      message: `${textAreaValue}`,
      numbers: [`${contact}`],
    };
    try {
      sendSmsFunction(msg_data);
    } catch (error: any) {
      console.log("Message sending  error:", error.message);
    } finally {
      setOpenSMS(false);
      setLoading(false);
    }
  };

  const handleContact = (value: string | undefined) => {
    // console.log("contact value is", value);
    setContact(value);
  };
  const handleTextArea = (value: string | undefined) => {
    // console.log("Text value is", value);
    setTextAreaValue(value);
  };

  useEffect(() => {
    setTextAreaValue(`Hi ${driverName},\nyour trip from ${pickupLocation} to ${deleiveryLocation} has been ongoing for a while now. Is it still in progress? If you have completed it, kindly visit your Yarp Driver app and complete it so that you can receive new requests.
Thank you for using Yarp.`);
  }, [driverName, pickupLocation, deleiveryLocation]);

  return (
    <>
      <Modal
        width={"35%"}
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        okText="Send"
        okButtonProps={{
          style: {
            backgroundColor: "#52b3ef",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "30vh" } }}
      >
        <Spin
          spinning={loading}
          indicator={
            <div className="flex justify-center items-center  ">
              <LoadingDataComponent />
            </div>
          }
        >
          <div>
            <div className="text-[20px]">Send Message</div>
            {/* <div className="w-full border-[1px] border-gray-300 "></div> */}
            <section className="my-6 flex flex-col gap-6">
              <div>
                <Input
                  placeholder="Contact"
                  className="h-[60px]"
                  value={contact}
                  onChange={(e) => handleContact(e.target.value)}
                />
              </div>
              <div>
                <TextArea
                  placeholder="Message"
                  rows={8}
                  value={textAreaValue}
                  onChange={(e) => handleTextArea(e.target.value)}
                />
              </div>
            </section>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default SmsModal;
