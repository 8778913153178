import { useState } from 'react'
import { Input, Modal, Spin, notification } from 'antd'
import BaseService from '../../../helpers/baseService'
import { global_variables } from '../../../helpers/globalVariables'


interface modalProps {
    isOpened: boolean
    handleCloseModal: () => void
    handleReFetch: () => void
}

const CreateCompanyRole = ({ isOpened, handleCloseModal, handleReFetch }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [theRole, setTheRole] = useState("")
    const [theDesc, setTheDesc] = useState("")

    //handleCreate
    const handleCreate = async () => {
        if (!theRole || !theDesc) {
            notification.warning({ message: "Please fill out all fields" })
        }
        else {
            setIsBusy(true)
            const data = {
                name: theRole,
                description: theDesc
            }

            try {
                const response = await BaseService.post_api_v2(global_variables.company_roles_route_v2, JSON.stringify(data))
                notification.success({ message: response?.data?.message || "Role created successfully!" })
                setIsBusy(false)
                handleCloseModal()
                handleReFetch()
            } catch (error: any) {
                console.log(error)
                setIsBusy(false)
                notification.error({
                    message: error?.response?.data?.error || "An unknown error occured!",
                });
            }
        }
    }

    return (
        <>
            <Modal open={isOpened} onCancel={handleCloseModal} footer={null} closeIcon={false} maskClosable={false}>
                <Spin spinning={isBusy}>
                    <div>
                        <p className='font-semibold text-lg mb-0'>Create Company Role</p>
                        <hr />
                    </div>

                    <div className="w-full">
                        <label htmlFor='role'>Enter role title</label>
                        <Input id='role' className='h-[40px]' value={theRole} onChange={(e) => setTheRole(e.target.value)} placeholder='E.g: Sales' />
                    </div>

                    <div className="w-full mt-3">
                        <label htmlFor='desc'>Enter role description</label>
                        <Input.TextArea id='desc' value={theDesc} onChange={(e) => setTheDesc(e.target.value)} placeholder='E.g: Selling products or services to customers' />
                    </div>

                    <hr />

                    <div className="flex justify-end gap-3 items-center">
                        <button className='px-3 py-2 border-[1px] border-gray-400 rounded-[5px] text-gray-500' onClick={handleCloseModal}>
                            Cancel
                        </button>
                        <button className='px-3 py-2 bg-yarpBlue rounded-[5px] text-white' onClick={handleCreate}>
                            Submit
                        </button>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default CreateCompanyRole
