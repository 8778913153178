import UsersTable from "./users_table";
import CustomSideBar from "../../../components/layouts/sidebar";
import CustomNavbar from "../../../components/layouts/navbar";
import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SuspendedUsersTable from "../suspended/suspended_users";
import DriverTable from "../drivers/driversTable";
import FleetTable from "../fleet_table";

const Users = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const activeSec: any = query.get("tab");

  const [activeTab, setActiveTab] = useState(0);

  const tabNames = ["Customers", "Yarp Drivers", "Fleet Owners"];

  const tabComponents = [
    <UsersTable activeTab={activeTab} />,
    <DriverTable activeTab={activeTab} />,
    <FleetTable activeTab={activeTab} />,
    <SuspendedUsersTable activeTab={activeTab} />,
  ];

  //onload
  useEffect(() => {
    setActiveTab(parseInt(activeSec) || 0);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar usersActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">All Users</p>

              <div className="flex gap-3 mt-3">
                {tabNames.map((item, i) => (
                  <div
                    key={i}
                    className={`${activeTab === i ? "bg-yarpBlue text-white" : "bg-white"
                      } px-4 py-2 rounded-[5px] shadow-md cursor-pointer`}
                    onClick={() => setActiveTab(i)}
                  >
                    {item}
                  </div>
                ))}
              </div>

              <div className="mt-[20px] bg-white shadow-lg p-[20px] rounded-[20px]">
                {tabComponents[activeTab]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
