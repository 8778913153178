import moment from "moment";
import { useEffect, useState } from "react";
import { Badge, Col, Image, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Tooltip } from "@material-tailwind/react";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { PhotoView } from "react-photo-view";
import NoDataComponent from "../../../components/general/noDataComponent";
import { CSVLink } from "react-csv";
import DeclineTruckDialog from "../dialogs/declineTruckDialog";
import { Empty } from "antd";
import NewDatePickerRange from "../../../components/shared/newDatePicker/NewDatePicker";
import {
  OWNERSHIPSort,
  UdatedAtSort,
  VEHICLETYPESort,
  modelSort,
  truckRegisterationSort,
} from "../../../components/general/TableSortFunctions";

const UnapprovedTrucksTable = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [trucks, setTrucks] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [reFetch, setReFetch] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  //decline modal stuff
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const handleOpenedDeclineModal = () => {
    setOpenDeclineModal(true);
    setShow(false);
  };
  const handleCloseDeclineModal = () => {
    setOpenDeclineModal(false);
    setShow(true);
  };

  //get unapproved trucks
  const getTrucks = async (
    lim: any,
    sk: any,
    start: any = null,
    end: any = null
  ) => {
    try {
      setLoading(true);

      let url = `filter={"$and": [{"approved": false}, {"declined": {"$eq": null}}]}`;
      if (start && end) {
        url = `filter={"$and":[{"timestamp":{"$gte":"${start}"}},{"timestamp":{"$lt":"${end}"}}]}`;
      }
      url += `&limit=${lim}&skip=${sk}&sort={"created_at":-1}`;
      const response = await BaseService.get_api_v2(
        `${global_variables.get_all_trucks_v2}?${url}`
      );
      // console.log(response?.data);
      setLoading(false);
      setTotalRows(response?.data?.total);
      setTrucks(response?.data?.payload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTrucks(limit, skip);
  }, [limit, skip, reFetch]);

  //table clomuns
  const columns: any = [
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">VEHICLE REG.</p>
      ),
      selector: (row: any) => (
        <div className="flex gap-3 items-center">
          <Image
            src={row.truckfront}
            fluid
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              borderRadius: "100%",
              marginRight: "10px",
            }}
          />
          {row?.truckRegisteration}
        </div>
      ),
      sortable: true,
      sortFunction: truckRegisterationSort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">MODEL</p>,
      selector: (row: any) => row?.model,
      sortable: true,
      sortFunction: modelSort,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">VEHICLE TYPE</p>
      ),
      selector: (row: any) => row?.car_type,
      sortable: true,
      sortFunction: VEHICLETYPESort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">OWNERSHIP</p>,
      selector: (row: any) => row?.type,
      sortable: true,
      sortFunction: OWNERSHIPSort,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">DATE CREATED</p>
      ),
      cell: (row: any) =>
        moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: UdatedAtSort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">STATUS</p>,
      selector: (row: any) =>
        row?.approved ? (
          <div className="px-4 py-1 bg-yarpGreen text-yarpGreen bg-opacity-[20%] rounded-[40px]">
            Approved
          </div>
        ) : (
          <div className="px-4 py-1 bg-red-500 text-red-500 bg-opacity-[20%] rounded-[40px]">
            Pending
          </div>
        ),
    },
  ];

  //export data
  const formatDataForExport = (data: any) => {
    return data.map((row: any) => ({
      "TRUCK NO.": row?.truckRegisteration,
      MODEL: row?.model,
      "VEHICLE TYPE": row?.car_type,
      "OWNER TYPE": row?.type,
      "DATE CREATED": moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
      STATUS: row?.approved ? "Approved" : "Pending",
    }));
  };

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<any>([]);
  const displayModal = (row: any) => {
    setModalData(row);
    setShow(true);
  };

  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  //approve a truck
  const approveTruck = async () => {
    ShowToast.loading_toast("Please wait...");

    var truck_data = {
      truck_number: modalData.truckRegisteration,
      approved: true,
      remarks: "Congratulations!",
    };

    setIsBusy(true);

    try {
      const response = await BaseService.put_api_v2(
        `${global_variables.approve_or_decline_truck_v2}`,
        truck_data
      );
      // console.log(response);
      ShowToast.success_toast("Truck has been approved");
      setShow(false);
      getTrucks(limit, skip);
      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
      console.log(error);
      ShowToast.error_toast(error?.response?.data?.error);
    }
  };

  const handleRowChange = (a: any) => {
    setLimit(a);
    getTrucks(a, skip);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getTrucks(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getTrucks(limit, newSkip);
      setSkip(newSkip);
    }
  };
  const onDateDataHandler = (dateObj: any) => {
    const { start, end } = dateObj;
    getTrucks(limit, skip, start, end);
    if (!start && !end) {
      getTrucks(limit, skip);
    }
  };
  return (
    <>
      <div className="mt-[20px]  flex justify-between items-center">
        <Tooltip content="Reload" placement="top">
          <button
            className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
            onClick={() => setReFetch(!reFetch)}
          >
            <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
          </button>
        </Tooltip>
        <div className="flex gap-[10px]">
          <NewDatePickerRange onDateData={onDateDataHandler} />

          <Tooltip content="Print" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <PrinterIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>

          <CSVLink
            data={formatDataForExport(trucks)}
            filename="Unapproved Trucks"
          >
            <Tooltip content="Export" placement="top">
              <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>

      <DataTable
        className="mt-[10px]"
        columns={columns}
        data={trucks}
        pagination
        paginationServer
        pointerOnHover
        highlightOnHover
        paginationTotalRows={totalRows}
        noDataComponent={
          <Empty description="There are no trucks pending approval" />
        }
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        onRowClicked={(row) => {
          displayModal(row);
        }}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
      />

      {/* truck details */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setModalData([]);
        }}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="p-[30px] text-yarpGray">
            <p className="text-[20px] font-[600] text-yarpGray">Genral Info</p>

            <hr />

            {show ? (
              <>
                <div className="lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Truck Images</p>
                  <div className="flex justify-between gap-[20px] lg:col-span-2">
                    <div className="w-full">
                      <PhotoView
                        src={
                          modalData?.truckfront ||
                          "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                        }
                      >
                        <img
                          className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                          src={
                            modalData?.truckfront ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                          alt="front"
                        />
                      </PhotoView>
                      <p className="text-[14px] font-[300] mt-[10px]">
                        Front view
                      </p>
                    </div>
                    <div className="w-full">
                      <PhotoView
                        src={
                          modalData?.truckside ||
                          "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                        }
                      >
                        <img
                          className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                          src={
                            modalData?.truckside ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                          alt="side"
                        />
                      </PhotoView>
                      <p className="text-[14px] font-[300] mt-[10px]">
                        Side view
                      </p>
                    </div>
                    <div className="w-full">
                      <PhotoView
                        src={
                          modalData?.truckback ||
                          "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                        }
                      >
                        <img
                          className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                          src={
                            modalData?.truckback ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                          alt="back"
                        />
                      </PhotoView>
                      <p className="text-[14px] font-[300] mt-[10px]">
                        Back view
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-[30px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Registration</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {modalData?.truckRegisteration}
                    </p>
                  </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Ownership</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {modalData?.type.toUpperCase()}
                    </p>
                  </div>
                </div>

                {modalData?.brand ? (
                  <div className="mt-[10px] lg:grid lg:grid-cols-3">
                    <p className="text-[14px] font-[600]">Brand</p>
                    <div className="lg:col-span-2">
                      <p className="text-[14px] font-[300]">
                        {modalData?.brand}
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Model</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">{modalData?.model}</p>
                  </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Truck Type</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {modalData.car_type}
                    </p>
                  </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Preferred Goods</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {modalData.category_type.map((d: any, index: number) => {
                        return (
                          <Badge className="ms-1" bg="secondary" key={index}>
                            {d}
                          </Badge>
                        );
                      })}
                    </p>
                  </div>
                </div>

                <div className="mt-[30px]">
                  <p className="text-[20px] font-[600] text-yarpGray">
                    Documents
                  </p>

                  <hr />

                  <div className="lg:grid lg:grid-cols-3">
                    <p className="text-[14px] font-[600]">Insurance</p>
                    <div className="lg:col-span-2">
                      <Row>
                        <Col>
                          <PhotoView
                            src={
                              modalData?.insurance ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="w-[400px] h-[250px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                              src={modalData?.insurance}
                              alt="Insurance"
                            />
                          </PhotoView>
                        </Col>
                        <Col>
                          <div className="fw-bolder mt-2">Insurance number</div>
                          <div>{modalData?.insurance_text}</div>
                          <div className="fw-bolder mt-3">Expiry Date</div>
                          <div>
                            {moment(modalData?.insurance_expire).format(
                              "Do MMMM YYYY"
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="lg:grid lg:grid-cols-3 mt-[20px]">
                    <p className="text-[14px] font-[600]">Roadworthy</p>
                    <div className="lg:col-span-2">
                      <Row>
                        <Col>
                          <PhotoView
                            src={
                              modalData?.roadworthy ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="w-[400px] h-[250px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                              src={modalData?.roadworthy}
                              alt="Roadworthy"
                            />
                          </PhotoView>
                        </Col>
                        <Col>
                          <div className="fw-bolder mt-2">
                            Roadworthy number
                          </div>
                          <div>{modalData?.roadworthy_text}</div>
                          <div className="fw-bolder mt-3">Expiry Date</div>
                          <div>
                            {moment(modalData?.roadworthy_expire).format(
                              "Do MMMM YYYY"
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-4">
          <button className="text-yarpGray" onClick={() => setShow(false)}>
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded-[40px] border-[1px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white flex justify-center items-center disabled:cursor-not-allowed"
            onClick={() => handleOpenedDeclineModal()}
            disabled={isBusy}
          >
            {isBusy ? <Spinner color="red" /> : <span>Decline</span>}
          </button>
          <button
            className="px-4 py-2 rounded-[40px] bg-yarpOrange text-white flex justify-center items-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={approveTruck}
            disabled={isBusy}
          >
            {isBusy ? <Spinner /> : <span> Approve Truck</span>}
          </button>
        </Modal.Footer>
      </Modal>

      {/* declined modal */}
      {openDeclineModal ? (
        <DeclineTruckDialog
          isOpen={openDeclineModal}
          handleClose={handleCloseDeclineModal}
          truckData={modalData}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default UnapprovedTrucksTable;
