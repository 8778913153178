// SubMenuContext.js
import { createContext, useContext, useState } from "react";

const SubMenuContext = createContext<any>(null);

export const SubMenuProvider = ({ children }: any) => {
  const [accountsSubMenuOpen, setAccountsSubMenuOpen] = useState(false);
  const [tripsSubMenuOpen, setTripsSubMenuOpen] = useState(false);
  const [financeSubMenuOpen, setFinanceSubMenuOpen] = useState(false);
  const [communicationSubMenuOpen, setCommunicationSubMenuOpen] = useState(false);
  const [companiesSubMenu, setCompaniesSubMenu] = useState(false);

  return (
    <SubMenuContext.Provider
      value={{
        accountsSubMenuOpen,
        setAccountsSubMenuOpen,
        tripsSubMenuOpen,
        setTripsSubMenuOpen,
        financeSubMenuOpen,
        setFinanceSubMenuOpen,
        communicationSubMenuOpen,
        setCommunicationSubMenuOpen,
        companiesSubMenu,
        setCompaniesSubMenu
      }}
    >
      {children}
    </SubMenuContext.Provider>
  );
};

export const useSubMenu = () => {
  return useContext(SubMenuContext);
};
