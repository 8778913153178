import { XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { Badge, Col, Modal, Row } from "react-bootstrap";
import { PhotoView } from "react-photo-view";

interface modalProps {
  isOpened: boolean
  handleClose: () => void
  truckData: any
}

const TruckDetailsModal = ({ isOpened, handleClose, truckData }: modalProps) => {

  return (
    <>
      <Modal
        show={isOpened}
        onHide={handleClose}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="px-[30px] py-[15px] text-yarpGray">
            <div className="flex justify-between items-center">
              <p className="text-[20px] font-[600] text-yarpGray">Genral Info</p>
              <button className="h-10 w-10" onClick={handleClose}>
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>

            <hr />

            {isOpened ? (
              <>
                <div className="lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Truck Images</p>
                  <div className="flex justify-between gap-[20px] lg:col-span-2">
                    <div className="w-full">
                      <PhotoView
                        src={
                          truckData?.truckfront ||
                          "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                        }
                      >
                        <img
                          className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                          src={
                            truckData?.truckfront ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                          alt="front"
                        />
                      </PhotoView>
                      <p className="text-[14px] font-[300] mt-[10px]">
                        Front view
                      </p>
                    </div>
                    <div className="w-full">
                      <PhotoView
                        src={
                          truckData?.truckside ||
                          "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                        }
                      >
                        <img
                          className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                          src={
                            truckData?.truckside ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                          alt="side"
                        />
                      </PhotoView>
                      <p className="text-[14px] font-[300] mt-[10px]">
                        Side view
                      </p>
                    </div>
                    <div className="w-full">
                      <PhotoView
                        src={
                          truckData?.truckback ||
                          "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                        }
                      >
                        <img
                          className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                          src={
                            truckData?.truckback ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                          alt="back"
                        />
                      </PhotoView>
                      <p className="text-[14px] font-[300] mt-[10px]">
                        Back view
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-[30px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Registration</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {truckData?.truckRegisteration}
                    </p>
                  </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Ownership</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {truckData?.type.toUpperCase()}
                    </p>
                  </div>
                </div>

                {
                  truckData?.brand ?
                    <div className="mt-[10px] lg:grid lg:grid-cols-3">
                      <p className="text-[14px] font-[600]">Brand</p>
                      <div className="lg:col-span-2">
                        <p className="text-[14px] font-[300]">{truckData?.brand}</p>
                      </div>
                    </div>
                    :
                    <></>
                }

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Model</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">{truckData?.model}</p>
                  </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Truck Type</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {truckData.car_type}
                    </p>
                  </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Preferred Goods</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {truckData.category_type.map((d: any, index: number) => {
                        return (
                          <Badge className="ms-1" bg="secondary" key={index}>
                            {d}
                          </Badge>
                        );
                      })}
                    </p>
                  </div>
                </div>

                <div className="mt-[30px]">
                  <p className="text-[20px] font-[600] text-yarpGray">
                    Documents
                  </p>

                  <hr />

                  <div className="lg:grid lg:grid-cols-3">
                    <p className="text-[14px] font-[600]">Insurance</p>
                    <div className="lg:col-span-2">
                      <Row>
                        <Col>
                          <PhotoView
                            src={
                              truckData?.insurance ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="w-[400px] h-[250px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                              src={truckData?.insurance}
                              alt="Insurance"
                            />
                          </PhotoView>
                        </Col>
                        <Col>
                          <div className="fw-bolder mt-2">Insurance number</div>
                          <div>{truckData?.insurance_text}</div>
                          <div className="fw-bolder mt-3">Expiry Date</div>
                          <div>
                            {moment(truckData?.insurance_expire).format(
                              "Do MMMM YYYY"
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="lg:grid lg:grid-cols-3 mt-[20px]">
                    <p className="text-[14px] font-[600]">Roadworthy</p>
                    <div className="lg:col-span-2">
                      <Row>
                        <Col>
                          <PhotoView
                            src={
                              truckData?.roadworthy ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="w-[400px] h-[250px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                              src={truckData?.roadworthy}
                              alt="Roadworthy"
                            />
                          </PhotoView>
                        </Col>
                        <Col>
                          <div className="fw-bolder mt-2">
                            Roadworthy number
                          </div>
                          <div>{truckData?.roadworthy_text}</div>
                          <div className="fw-bolder mt-3">Expiry Date</div>
                          <div>
                            {moment(truckData?.roadworthy_expire).format(
                              "Do MMMM YYYY"
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-4 py-4 px-4">
          <button className="text-yarpGray" onClick={handleClose}>
            Dismiss
          </button>
        </Modal.Footer>
      </Modal>



    </>
  );
};

export default TruckDetailsModal;
