import { useEffect, useState } from "react";
import {
    Button,
    Input,
    Spinner,
    Textarea,
} from "@material-tailwind/react";
import { Modal } from "react-bootstrap";
import ShowToast from "../../../components/alerts/all_toasts";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";

interface modalProps {
    openEdit: boolean
    handleOpenEdit: () => void;
    handleReFetch: () => void
    selectedData: any
}

const EditProfessionModal = ({ openEdit, handleOpenEdit, handleReFetch, selectedData }: modalProps) => {

    const [title, setTitle] = useState("")
    const [desc, setDesc] = useState("")
    const [isBusy, setIsBusy] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        setTitle(selectedData?.profession?.profession)
        setDesc(selectedData?.profession?.description)
    }, [selectedData])

    //edit profession
    const doEditProfession = async () => {
        if (!title || !desc) {
            setIsError(true)
        }
        else {
            setIsBusy(true)
            let profession_data = JSON.stringify({
                profession: title,
                description: desc
            })
            try {
                const response = await BaseService.post_api(global_variables.create_profession, profession_data)
                if (response.status === 200) {
                    ShowToast.success_toast(response?.data?.message);
                    handleOpenEdit()
                    handleReFetch()
                }
            } catch (error: any) {
                console.log(error)
                setIsBusy(false)
                ShowToast.error_toast(error?.response?.data?.error)
            }
        }
    }

    return (
        <>
            <Modal show={openEdit} onHide={handleOpenEdit} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Update Profession</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-10">
                        <div>
                            <Input label="Profession Title" value={title} onChange={(e) => setTitle(e.target.value)} disabled={true} error={isError} />
                        </div>
                        <div className="mt-3">
                            <Textarea label="Profession Description" value={desc} onChange={(e) => setDesc(e.target.value)} disabled={isBusy} error={isError} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="red" variant="text" onClick={handleOpenEdit} disabled={isBusy}>
                        Close
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => doEditProfession()} disabled={isBusy}>
                        {
                            isBusy ? <Spinner /> : <span>Update Profession</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditProfessionModal