import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import {
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Button } from "@material-tailwind/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

const Regions = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState<any>(0);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [show, setShow] = useState<any>(0);

  //on load
  useEffect(() => {
    getData(limit, skip);
  }, []);

  const getData = (lim: number, ski: number) => {
    setLoading(true);

    BaseService.get_api(
      `${global_variables.get_regions}?limit=${lim}&skip=${ski}`
    )
      .then((res) => {
        setLoading(false);
        setData(res?.data?.payload);
        setTotal(res?.data?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns: any = [
    {
      name: "NAME",
      selector: (row: { region: any }) => row.region,
    },
    {
      name: "INCOMING PERCENTAGE",
      selector: (row: { percentcoming: any }) => row.percentcoming,
    },
    {
      name: "OUTGOING PERCENTAGE",
      selector: (row: { percentgoing: any }) => row.percentgoing,
    },
    {
      name: "LAST UPDATED",
      selector: (row: { updated_at: moment.MomentInput }) =>
        moment(row.updated_at).format("Do MMMM YYYY"),
    },
    // {
    //   name: "",
    //   selector: (row) => (
    //     <PencilSquareIcon
    //       className="h-5 w-5 cursor-pointer"
    //       onClick={showEditModal.bind(this, row)}
    //     />
    //   ),
    //   width: "5%",
    // },
  ];

  const handleRowChange = (a: any) => {
    getData(a, 0);
    setLimit(a);
    setSkip(0);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getData(limit, newSkip);
      setSkip(newSkip);
    }
  };

  const [editData, setEditData] = useState<any>([]);
  const [income, setIncome] = useState<any>(0);
  const [outgo, setOutGo] = useState<any>(0);

  const showEditModal = (row: any) => {
    setEditData(row);
    setShow(true);
  };

  const updateRegion = () => {
    ShowToast.loading_toast();

    var region_data = {
      region: editData.region,
      percentcoming: parseFloat(income),
      percentgoing: parseFloat(outgo),
    };

    BaseService.post_api(global_variables.create_region, region_data)
      .then((res) => {
        getData(limit, skip);
        setShow(false);
        ShowToast.success_toast(res?.data?.message);
      })
      .catch((err) => {
        ShowToast.error_toast(err?.error);
        console.log(err);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h5>Regions</h5>
      </div>

      <DataTable
        data={data}
        columns={columns}
        paginationTotalRows={total}
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<p>You do not have any car brands</p>}
        pagination={total >= 11 ? true : false}
        paginationServer
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        pointerOnHover
        onRowClicked={showEditModal}
      />

      {/* Add  */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit {editData.region}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FloatingLabel
            controlId="income"
            label="Incoming Percentage"
            className="mb-3"
          >
            <Form.Control
              type="number"
              placeholder="0"
              defaultValue={editData.percentcoming}
              onChange={(e) => setIncome(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="outgo"
            label="Outgoing Percentage"
            className="mb-3"
          >
            <Form.Control
              type="number"
              placeholder="0"
              defaultValue={editData.percentgoing}
              onChange={(e) => setOutGo(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="gradient"
            color="blue"
            onClick={() => updateRegion()}
          >
            Post
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Regions;
