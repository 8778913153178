import { useEffect, useState } from 'react'
import CustomSideBar from '../../../components/layouts/sidebar'
import CustomNavbar from '../../../components/layouts/navbar'
import { ArrowDownTrayIcon, ArrowPathIcon, PencilSquareIcon, PlusCircleIcon, PrinterIcon } from '@heroicons/react/24/outline'
import CreateCompanyRole from './createCompanyRole'
import { RolesModel } from './payload.model'
import EditCompanyRole from './editCompanyRole'
import moment from 'moment'
import DataTable from 'react-data-table-component'
import { LoadingDataComponent } from '../../../components/general/loadingDataComponent'
import { Empty } from 'antd'
import BaseService from '../../../helpers/baseService'
import { global_variables } from '../../../helpers/globalVariables'
import { Tooltip } from '@material-tailwind/react'

const AllCompanyRoles = () => {

    const [isFetching, setIsFetching] = useState(false)
    const [rolesData, setRolesData] = useState([])
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [skip, setSkip] = useState(0)
    const [reFetch, setReFetch] = useState(false)
    // open create modal
    const [isModalOpened, setIsModalOpened] = useState(false)
    const openCreateModal = () => {
        setIsModalOpened(true)
    }
    const closeCreateModal = () => {
        setIsModalOpened(false)
    }
    // open edit modal
    const [isEditOpened, setIsEditOpened] = useState(false)
    const [dataForEdit, setDataForEdit] = useState<RolesModel | any>(null)
    const openEditModal = () => {
        setIsEditOpened(true)
    }
    const closeEditModal = () => {
        setIsEditOpened(false)
    }
    const triggerEdit = (data: RolesModel) => {
        setDataForEdit(data)
        openEditModal()
    }

    //getAllRoles
    const getAllRoles = async (lim: number, sk: number) => {
        setIsFetching(true)
        try {
            const response = await BaseService.get_api_v2(`${global_variables.company_roles_route_v2}?sort={"updated_at":-1}&limit=${lim}&skip=${sk}`)
            console.log(response?.data)
            setRolesData(response?.data?.payload)
            setTotal(response?.data?.total)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
        }
    }

    //onload
    useEffect(() => {
        getAllRoles(limit, skip)
    }, [limit, skip, reFetch])

    //table clomuns
    const columns: any = [
        {
            name: (
                <p className="font-[600] text-[14px] text-yarpGray">TITLE</p>
            ),
            cell: (row: RolesModel) => row?.name,
        },
        {
            name: <p className="font-[600] text-[14px] text-yarpGray">DESCRIPTION</p>,
            cell: (row: RolesModel) => row?.description,
            width: "35%"
        },
        {
            name: (
                <p className="font-[600] text-[14px] text-yarpGray">DATE CREATED</p>
            ),
            cell: (row: RolesModel) =>
                moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
        },
        {
            name: (
                <p className="font-[600] text-[14px] text-yarpGray">DATE UPDATED</p>
            ),
            cell: (row: RolesModel) =>
                moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
        },
        {
            name: (
                <p className="font-[600] text-[14px] text-yarpGray">ACTION</p>
            ),
            selector: (row: any) => <PencilSquareIcon className='h-5 w-5 cursor-pointer' onClick={() => triggerEdit(row)} />
        },
    ];

    // pagination start
    const handleRowChange = (a: any) => {
        setLimit(a);
        getAllRoles(a, skip);
    };

    const pageChange = (a: any) => {
        if (a === 1) {
            getAllRoles(limit, 0);
            setSkip(0);
        } else {
            const newSkip = a * limit - limit;
            getAllRoles(limit, newSkip);
            setSkip(newSkip);
        }
    };
    // pagination end

    return (
        <>
            <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
                {/* sidebar*/}
                <CustomSideBar rolesActive="active" />
                {/* content */}
                <div className="w-full">
                    {/* navbar */}
                    <CustomNavbar />

                    {/* main */}
                    <div className="h-full overflow-y-auto">
                        <div className="p-8 mb-20">
                            <div className="flex justify-between items-center">
                                <p className="text-[26px] font-[600] text-yarpGray">Company Roles</p>

                                <button className='px-3 py-2 rounded-[40px] bg-yarpBlue text-white shadow-sm flex gap-2 items-center hover:scale-105 duration-100' onClick={() => openCreateModal()} >
                                    <PlusCircleIcon className='w-5 h-5' />
                                    Create Role
                                </button>
                            </div>

                            {/* table */}
                            <div className="mt-3 bg-white shadow-md rounded-[20px] p-4">
                                {/* buttons */}
                                <div className="d-flex justify-content-between mb-3">
                                    <div>
                                        <Tooltip content="Reload" placement="top">
                                            <button
                                                className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
                                                onClick={() => setReFetch(!reFetch)}
                                            >
                                                <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
                                            </button>
                                        </Tooltip>
                                    </div>
                                    <div className="flex gap-3">
                                        <Tooltip content="Print" placement="top">
                                            <button
                                                className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
                                                disabled
                                            >
                                                <PrinterIcon className="w-5 h-5 text-yarpBlue" />
                                            </button>
                                        </Tooltip>

                                        <Tooltip content="Export" placement="top">
                                            <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                                                <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>

                                <DataTable
                                    columns={columns}
                                    data={rolesData}
                                    progressPending={isFetching}
                                    progressComponent={<LoadingDataComponent />}
                                    noDataComponent={<Empty description="No data" />}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={total}
                                    onChangeRowsPerPage={handleRowChange}
                                    onChangePage={pageChange}
                                    highlightOnHover
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* create role modal */}
            <CreateCompanyRole isOpened={isModalOpened} handleCloseModal={closeCreateModal} handleReFetch={() => setReFetch(!reFetch)} />

            {/* edit role modal */}
            <EditCompanyRole isEditOpened={isEditOpened} handleCloseEdit={closeEditModal} roleData={dataForEdit} handleReFetch={() => setReFetch(!reFetch)} />
        </>
    )
}

export default AllCompanyRoles
