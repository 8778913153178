import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import moment from "moment";
import axios from "axios";
import { global_variables } from "../../../helpers/globalVariables";
import { StorageBox } from "../../../helpers/storage";
import { BASE_URL_V1 } from "../../../helpers/constants";

ChartJS.register(...registerables);

const api = BASE_URL_V1;

const ThisYearChart = ({
  startDate,
  endDate,
}: {
  startDate: any;
  endDate: any;
}) => {
  const [jan, setJan] = useState<any>(0);
  const [feb, setFeb] = useState<any>(0);
  const [mar, setMar] = useState<any>(0);
  const [apr, setApr] = useState<any>(0);
  const [may, setMay] = useState<any>(0);
  const [jun, setJun] = useState<any>(0);
  const [jul, setJul] = useState<any>(0);
  const [aug, setAug] = useState<any>(0);
  const [sep, setSep] = useState<any>(0);
  const [oct, setOct] = useState<any>(0);
  const [nov, setNov] = useState<any>(0);
  const [dec, setDec] = useState<any>(0);

  const jan_start = moment().month(0).startOf("month").format("YYYY-MM-DD");
  const feb_start = moment().month(1).startOf("month").format("YYYY-MM-DD");
  const mar_start = moment().month(2).startOf("month").format("YYYY-MM-DD");
  const apr_start = moment().month(3).startOf("month").format("YYYY-MM-DD");
  const may_start = moment().month(4).startOf("month").format("YYYY-MM-DD");
  const jun_start = moment().month(5).startOf("month").format("YYYY-MM-DD");
  const jul_start = moment().month(6).startOf("month").format("YYYY-MM-DD");
  const aug_start = moment().month(7).startOf("month").format("YYYY-MM-DD");
  const sep_start = moment().month(8).startOf("month").format("YYYY-MM-DD");
  const oct_start = moment().month(9).startOf("month").format("YYYY-MM-DD");
  const nov_start = moment().month(10).startOf("month").format("YYYY-MM-DD");
  const dec_start = moment().month(11).startOf("month").format("YYYY-MM-DD");

  const jan_end = moment().month(0).endOf("month").format("YYYY-MM-DD");
  const feb_end = moment().month(1).endOf("month").format("YYYY-MM-DD");
  const mar_end = moment().month(2).endOf("month").format("YYYY-MM-DD");
  const apr_end = moment().month(3).endOf("month").format("YYYY-MM-DD");
  const may_end = moment().month(4).endOf("month").format("YYYY-MM-DD");
  const jun_end = moment().month(5).endOf("month").format("YYYY-MM-DD");
  const jul_end = moment().month(6).endOf("month").format("YYYY-MM-DD");
  const aug_end = moment().month(7).endOf("month").format("YYYY-MM-DD");
  const sep_end = moment().month(8).endOf("month").format("YYYY-MM-DD");
  const oct_end = moment().month(9).endOf("month").format("YYYY-MM-DD");
  const nov_end = moment().month(10).endOf("month").format("YYYY-MM-DD");
  const dec_end = moment().month(11).endOf("month").format("YYYY-MM-DD");

  useEffect(() => {
    getJan();
    // getFeb();
    // getMar();
    // getApr();
    // getMay();
    // getJun();
    // getJul();
    // getAug();
    // getSep();
    // getOct();
    // getNov();
    // getDec();
  }, [startDate, endDate]);

  const getJan = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${startDate}","FGT_Parameter":"${endDate}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        // console.log(response?.data);
        const gotData = response?.data?.payload;
        if (gotData) {
          const result = gotData.map((item: any) => ({
            amount: item?.amount,
            timestamp: item?.timestamp,
          }));
          // console.log(result);

          setJan(result);
        } else {
          setJan(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getFeb = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${feb_start}","FGT_Parameter":"${feb_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;
        // console.log(response.data);
        gotData === null ? setFeb(0) : setFeb(gotData["total_amount"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMar = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${mar_start}","FGT_Parameter":"${mar_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;
        // console.log(response.data);
        gotData === null ? setMar(0) : setMar(gotData["total_amount"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getApr = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${apr_start}","FGT_Parameter":"${apr_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;
        // console.log(response.data);
        gotData === null ? setApr(0) : setApr(gotData["total_amount"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMay = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${may_start}","FGT_Parameter":"${may_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;
        // console.log(response.data);
        gotData === null ? setMay(0) : setMay(gotData["total_amount"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getJun = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${jun_start}","FGT_Parameter":"${jun_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;
        // console.log(response.data);
        gotData === null ? setJun(0) : setJun(gotData["total_amount"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getJul = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${jul_start}","FGT_Parameter":"${jul_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;
        // console.log(response.data);
        gotData === null ? setJul(0) : setJul(gotData["total_amount"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getAug = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${aug_start}","FGT_Parameter":"${aug_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;
        // console.log(response.data);
        gotData === null ? setAug(0) : setAug(gotData["total_amount"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSep = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${sep_start}","FGT_Parameter":"${sep_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;
        // console.log(response.data);
        gotData === null ? setSep(0) : setSep(gotData["total_amount"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getOct = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${oct_start}","FGT_Parameter":"${oct_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;
        // console.log(response.data);
        gotData === null ? setOct(0) : setOct(gotData["total_amount"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getNov = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${nov_start}","FGT_Parameter":"${nov_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;
        // console.log(response.data);
        gotData === null ? setNov(0) : setNov(gotData["total_amount"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getDec = async () => {
    var config = {
      method: "get",
      url:
        api +
        global_variables.getTransaction +
        `?filter={"condition":"range","FLT_Parameter":"${dec_start}","FGT_Parameter":"${dec_end}"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };
    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;
        // console.log(response.data);
        gotData === null ? setDec(0) : setDec(gotData["total_amount"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var xValues: any = jan
    ? jan.map((item: any) => moment(item?.timestamp).format("Do MMM"))
    : null;
  var yValues = jan ? jan.map((item: any) => item?.amount) : null;

  var data = {
    labels: xValues,
    datasets: [
      {
        backgroundColor: "rgba(255, 103, 0)",
        borderColor: "rgba(255, 103, 0)",
        radius: 5,
        data: yValues,
        label: "Earnings - GHC",
      },
    ],
  };

  var options = {
    tension: 0.5,
    borderWidth: 1,
    plugins: {
      legend: {
        display: false,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <Line
      data={data}
      options={options}
      style={{ maxHeight: "330px", minHeight: "330px" }}
    />
  );
};

export default ThisYearChart;
