// src/hooks/useColumns.ts
import { useCallback } from "react";
import { Dropdown, MenuProps } from "antd";
import { CiEdit } from "react-icons/ci";
import { IoMdMore } from "react-icons/io";
import { Image } from "react-bootstrap";
import { PhotoView } from "react-photo-view";
import { BiMessageRoundedDots } from "react-icons/bi";
import { GiOnTarget } from "react-icons/gi";
const useColumns = (
  handleMenuClick: (
    e?: any,
    tripId?: any,
    id?: any,
    row?: any,
    userData?: any,
    locationPlace?: any
  ) => void
) => {
  const columns: any = [
    {
      name: "Drivers Name",
      sortable: true,
      width: "15%",
      cell: (row: any) => {
        return (
          <section className="flex justify-center items-center">
            <PhotoView
              src={
                row?.userData?.image ||
                "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              }
            >
              <Image
                src={
                  row?.userData?.image
                    ? row?.userData?.image
                    : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                }
                fluid
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  borderRadius: "100%",
                  marginRight: "10px",
                }}
              />
            </PhotoView>
            <div className="w-full ">
              {`${row?.userData?.first_name} ${row?.userData?.last_name}` ||
                "N/A"}
            </div>
          </section>
        );
      },
    },
    {
      name: "Phone",
      selector: (row: { userData: any }) => row?.userData?.phone,
      sortable: true,
    },
    {
      name: "Online/Offline",
      selector: (row: { connectedData: any }) => {
        const isStatus = row?.connectedData != null ? "Online" : "Offline";
        const backgroundColor =
          isStatus === "Online"
            ? "rgba(119, 209, 164, 0.1)"
            : "rgba(255, 99, 141, 0.1)";
        const textColor = isStatus === "Online" ? "#77D1A4" : "#FF638D";

        return (
          <div
            className=" flex justify-center items-center  w-auto rounded-[30px] h-[30px] px-[16px] pb-[3px]"
            style={{ backgroundColor, color: textColor }}
          >
            {isStatus}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Truck model",
      selector: (row: { truckData: any }) => row?.truckData?.model,
      sortable: true,
    },

    {
      name: "Distance From Customer",
      selector: (row: { distanceInKilometres: any }) => (
        <span>{row?.distanceInKilometres.toFixed(2)} km</span>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Current Status",
      selector: (row: { current_status: any }) => {
        const isStatus = row?.current_status != "Busy" ? "Free" : "Busy";
        const backgroundColor =
          isStatus === "Free"
            ? "rgba(119, 209, 164, 0.1)"
            : "rgba(255, 99, 141, 0.1)";
        const textColor = isStatus === "Free" ? "#77D1A4" : "#FF638D";

        return (
          <div
            className=" flex justify-center items-center  w-auto rounded-[30px] h-[30px] px-[16px] pb-[3px]"
            style={{ backgroundColor, color: textColor }}
          >
            {isStatus}
          </div>
        );
      },
      sortable: true,
    },

    {
      name: "Completed-Trips",
      cell: (row: { completedTrips: any }) => row?.completedTrips,
      sortable: true,
    },

    {
      name: "",
      cell: (row: any) => {
        const menuItems: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <span className="text-default_ash text-sm">View Details</span>
            ),
            icon: <CiEdit size={"1.3em"} />,
            onClick: (e) => handleMenuClick(e, row.trip_id, row._id),
          },
          {
            key: "2",
            label: (
              <span className="text-default_ash text-sm">Send Message</span>
            ),
            icon: <BiMessageRoundedDots size={"1.3em"} />,
            onClick: (e) =>
              handleMenuClick(
                e,
                row.trip_id,
                row._id,
                row?.userData?.phone,
                row?.userData?.full_name
              ),
          },
          {
            key: "3",
            label: (
              <span className="text-default_ash text-sm">Assign Driver</span>
            ),
            icon: <GiOnTarget size={"1.3em"} />,
            onClick: (e) => handleMenuClick(e, row.trip_id, row.userid),
          },
        ];
        return (
          <Dropdown menu={{ items: menuItems }} className="text-[14px]">
            <IoMdMore size={"2em"} />
          </Dropdown>
        );
      },
      width: "3%",
    },
  ];
  return columns;
};

export default useColumns;
