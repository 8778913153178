import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ClipboardDocumentListIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import BaseService from "../../helpers/baseService";
import { global_variables } from "../../helpers/globalVariables";
import { PhotoView } from "react-photo-view";
import { Image, Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Tooltip } from "@material-tailwind/react";
import TruckTypeSelector from "../../components/general/truckTypeSelector";
import TruckDetailsModal from "./truckDetailsModal";
import type { MenuProps } from "antd";
import { Dropdown, Empty } from "antd";
import EditTruckDetails from "./editTruckDetails";
import NewDatePickerRange from "../../components/shared/newDatePicker/NewDatePicker";

const TrucksTable = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [truckData, setTruckData] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [truckNum, setTruckNum] = useState("");
  const [selectedTruckType, setSelectedTruckType] = useState<any>("");

  useEffect(() => {
    getAllTrucks(limit, skip);
  }, [reFetch, limit, skip, selectedTruckType]);

  //getAllTrucks
  const getAllTrucks = async (
    lim: number,
    sk: number,
    dateStart: any = null,
    dateEnd: any = null
  ) => {
    try {
      setIsFetching(true);

      let filter = '{"$and":[{"approved":true}]}';

      if (selectedTruckType) {
        filter = `{"$and":[{"approved":true},{"car_type":${JSON.stringify(
          selectedTruckType
        )}}]}`;
      }

      if (dateStart && dateEnd) {
        filter = `{"$and":[{"approved":true},{"timestamp":{"$gte":"${dateStart}"}},{"timestamp":{"$lt":"${dateEnd}"}}]}`;
      }

      const originalEndpoint = `${
        global_variables.get_all_trucks_v2
      }?filter=${encodeURIComponent(
        filter
      )}&limit=${lim}&skip=${sk}&sort={"created_at":-1}`;

      const response = await BaseService.get_api_v2(originalEndpoint);
      setTruckData(response?.data?.payload);
      setTotal(response?.data?.total);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<any>([]);
  const displayModal = (row: any) => {
    setShow(true);
  };

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  //handleReftch
  const handleReftch = () => {
    setReFetch(!reFetch);
    setTruckNum("");
  };

  // pagination start
  const handleRowChange = (a: any) => {
    setLimit(a);
    getAllTrucks(a, skip);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getAllTrucks(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getAllTrucks(limit, newSkip);
      setSkip(newSkip);
    }
  };
  // pagination end

  //dropdown menus
  const items: MenuProps["items"] = [
    {
      label: (
        <button
          className="flex gap-2 items-center"
          onClick={() => displayModal(true)}
        >
          <ClipboardDocumentListIcon className="h-4 w-4" />
          View Details
        </button>
      ),
      key: "0",
    },
    {
      label: (
        <button
          className="flex gap-2 items-center"
          onClick={() => handleOpenEdit()}
        >
          <PencilIcon className="h-4 w-4" />
          Edit Details
        </button>
      ),
      key: "1",
    },
  ];

  //table clomuns
  const columns: any = [
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">TRUCK REG.</p>,
      selector: (row: any) => (
        <div className="flex gap-3 items-center">
          <PhotoView
            src={
              row?.truckfront ||
              "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
            }
          >
            <Image
              src={
                row?.truckfront ||
                "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              }
              fluid
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "100%",
                marginRight: "10px",
              }}
            />
          </PhotoView>
          {row?.truckRegisteration}
        </div>
      ),
      width: "20%",
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">MODEL</p>,
      cell: (row: any) => row?.model,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">VEHICLE TYPE</p>
      ),
      cell: (row: any) => row?.car_type,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">OWNED BY</p>,
      cell: (row: any) => row?.owner_data?.full_name || "N/A",
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">DATE CREATED</p>
      ),
      cell: (row: any) =>
        moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">STATUS</p>,
      selector: (row: any) =>
        row?.approved ? (
          <div className="px-4 py-1 bg-yarpGreen text-yarpGreen bg-opacity-[20%] rounded-[40px]">
            Approved
          </div>
        ) : (
          <div className="px-4 py-1 bg-red-500 text-red-500 bg-opacity-[20%] rounded-[40px]">
            Pending
          </div>
        ),
      width: "15%",
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">ACTION</p>,
      cell: (row: any) => (
        <Dropdown menu={{ items }} trigger={["click"]} placement="topLeft">
          <button onClick={() => setModalData(row)}>
            <EllipsisVerticalIcon className="h-5 w-5" />
          </button>
        </Dropdown>
      ),
    },
  ];

  //export data
  const formatDataForExport = (data: any) => {
    return data.map((row: any) => ({
      "TRUCK NO.": row?.truckRegisteration,
      MODEL: row?.model,
      "VEHICLE TYPE": row?.car_type,
      "OWNED BY": row?.owner_data?.full_name,
      "DATE CREATED": moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
      STATUS: row?.approved ? "Approved" : "Pending",
    }));
  };

  //handleTruckSearch
  const handleTruckSearch = async (e: any) => {
    e.preventDefault();
    setIsFetching(true);
    try {
      const response = await BaseService.get_api_v2(
        `${
          global_variables.get_all_trucks_v2
        }?filter={"truckRegisteration":  {"$regex":${JSON.stringify(
          truckNum
        )}}}`
      );
      console.log(response?.data?.payload);
      setTruckData(response?.data?.payload);
      setTotal(response?.data?.total);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onDateDataHandler = (dateObj: any) => {
    const { start, end } = dateObj;
    getAllTrucks(limit, skip, start, end);
    if (!start && !end) {
      getAllTrucks(limit, skip);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between mb-3 ">
        <div className="flex items-center gap-6">
          <Tooltip content="Reload" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
              onClick={() => handleReftch()}
            >
              <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <NewDatePickerRange onDateData={onDateDataHandler} />
        </div>
        <div className="flex gap-3">
          <div className="flex gap-2">
            <div className="w-full">
              <TruckTypeSelector
                onChange={(selected: any) =>
                  setSelectedTruckType(selected?.value)
                }
              />
            </div>
            <Form className="w-full" onSubmit={handleTruckSearch}>
              <input
                type="text"
                placeholder="Search truck reg."
                className="form-control h-10"
                style={{ width: "100%" }}
                value={truckNum}
                onChange={(e) => setTruckNum(e.target.value)}
                required
              />
            </Form>
          </div>
          <div className="flex gap-3">
            <Tooltip content="Print" placement="top">
              <button
                className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
                disabled
              >
                <PrinterIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
            <CSVLink
              data={formatDataForExport(truckData)}
              filename="Approved Trucks"
            >
              <Tooltip content="Export" placement="top">
                <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                  <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
                </button>
              </Tooltip>
            </CSVLink>
          </div>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={truckData}
        progressPending={isFetching}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<Empty description="No trucks available" />}
        pagination
        paginationServer
        paginationTotalRows={total}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        highlightOnHover
      />

      {/* truck details modal */}
      {show ? (
        <TruckDetailsModal
          isOpened={show}
          handleClose={() => setShow(false)}
          truckData={modalData}
        />
      ) : (
        <></>
      )}

      {/* open edit modal */}
      {openEdit ? (
        <EditTruckDetails
          isOpened={openEdit}
          handleClose={handleCloseEdit}
          truckData={modalData}
          handleReFetch={handleReftch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TrucksTable;
