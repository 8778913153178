import React, { useState } from "react";
import { Button, Input, Spinner, Textarea } from "@material-tailwind/react";
import { Modal } from "react-bootstrap";
import ShowToast from "../../../components/alerts/all_toasts";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { useNavigate } from "react-router-dom";

interface modalProps {
  openCreate: boolean;
  handleOpenCreate: () => void;
}

const CreateAdminModal = ({ openCreate, handleOpenCreate }: modalProps) => {
  const navigate = useNavigate();
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [mName, setMName] = useState("");
  const [theEmail, setTheEmail] = useState("");
  const [thePwd, setThePwd] = useState("");
  const [phonenum, setPhonenum] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [isError, setIsError] = useState(false);

  //create admin
  const doCreateAdmin = async () => {
    if (!fName || !lName || !theEmail || !thePwd || !phonenum) {
      setIsError(true);
    } else {
      setIsBusy(true);
      let admin_data = JSON.stringify({
        first_name: fName,
        last_name: lName,
        middle_name: mName,
        email: theEmail,
        password: thePwd,
        phone: formatPhoneNumber(phonenum),
        country_code: "+233",
        user_type: "63cd5da614320cf4683f2bea",
      });
      try {
        const response = await BaseService.post_api_v2(
          global_variables.create_user,
          admin_data
        );
        if (response.status === 200) {
          ShowToast.success_toast(response?.data?.message);
          handleOpenCreate();
          window.location.reload();
        }
      } catch (error: any) {
        console.log(error);
        setIsBusy(false);
        ShowToast.error_toast(error?.response?.data?.error);
      }
    }
  };

  return (
    <>
      <Modal show={openCreate} onHide={handleOpenCreate}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-10">
            <div>
              <Input
                label="First Name"
                value={fName}
                onChange={(e) => setFName(e.target.value)}
                disabled={isBusy}
                error={isError}
              />
            </div>
            <div className="mt-3">
              <Input
                label="Last Name"
                value={lName}
                onChange={(e) => setLName(e.target.value)}
                disabled={isBusy}
                error={isError}
              />
            </div>
            <div className="mt-3">
              <Input
                label="Middle Name (Optional)"
                value={mName}
                onChange={(e) => setMName(e.target.value)}
                disabled={isBusy}
                error={isError}
              />
            </div>
            <div className="mt-3">
              <Input
                label="Email"
                value={theEmail}
                onChange={(e) => setTheEmail(e.target.value)}
                disabled={isBusy}
                error={isError}
              />
            </div>
            <div className="mt-3">
              <Input
                label="Password"
                value={thePwd}
                onChange={(e) => setThePwd(e.target.value)}
                disabled={isBusy}
                error={isError}
              />
            </div>
            <div className="mt-3">
              <Input
                label="Phone Number"
                value={phonenum}
                onChange={(e) => setPhonenum(e.target.value)}
                disabled={isBusy}
                error={isError}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="red"
            variant="text"
            onClick={handleOpenCreate}
            disabled={isBusy}
          >
            Close
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => doCreateAdmin()}
            disabled={isBusy}
          >
            {isBusy ? <Spinner /> : <span>Create Admin</span>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateAdminModal;
