import { Button, Modal } from "antd";

interface ModalProps {
  showModal: () => void;
  open: boolean;
  userData: any;
  title: string;
  setOpen: (open: boolean) => void;
}

const ModalButton = ({
  userData,
  title,
  showModal,
  open,
  setOpen,
}: ModalProps) => {
  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const buttonStyle = {
    border: "1px solid #FF6700",
    color: "#FF6700",
  };
  const getTitleDisplay = (title: string) => {
    switch (title) {
      case "User":
        return "User Information";
      case "Reader":
        return "Reader's Information";
      case "Resolver":
        return "Resolver's Information";
      default:
        return "Information";
    }
  };

  return (
    <>
      <Button
        type="default"
        // shape="round"
        onClick={showModal}
        style={buttonStyle}
      >
        <div className="flex items-center justify-center">
          <p> See Details</p>
        </div>
      </Button>

      <Modal
        width={"50%"}
        open={open}
        onOk={handleOk}
        // confirmLoading={state.confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#52b3ef",
            color: "#FFFFFF",
            boxShadow: "none",
          },
          //   disabled: !state.isFormValid,
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "40vh" } }}
      >
        <div>
          <div className="text-[20px]">{getTitleDisplay(title)}</div>
          <div className="w-full border-1 border-gray-300 "></div>
          <div className=" grid mt-6 grid-cols-2">
            <section className="w-[200px] h-[200px] rounded-full">
              <img
                src={
                  userData?.image ||
                  "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                }
                className="rounded-full"
                alt=""
              />
            </section>
            <section>
              <div className="flex items-center gap-4">
                <p>First name : </p>
                <p>{userData?.first_name}</p>
              </div>
              {userData?.middle_name && (
                <div className="flex items-center gap-4">
                  <p>Middle name:</p>
                  <p>{userData.middle_name}</p>
                </div>
              )}
              <div className="flex items-center gap-4">
                <p>Last name : </p>
                <p>{userData?.last_name}</p>
              </div>
              <div className="flex items-center gap-4">
                <p>Phone Number : </p>
                <p>{userData?.phone}</p>
              </div>

              <div className="flex items-center gap-4">
                {userData?.user_type && (
                  <div className="flex items-center gap-4">
                    <p>Admin Level name:</p>
                    <p>{userData.user_type}</p>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalButton;
