import { Modal } from 'antd'
import { PhotoView } from 'react-photo-view'
import { XMarkIcon } from '@heroicons/react/24/outline'
import CompleteDriverProfile from './completeProfile'

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    driverInfo: any
    handleReFetch: () => void
}

const AccountDetails = ({ isOpened, handleClose, driverInfo, handleReFetch }: modalProps) => {
    return (
        <>
            <Modal open={isOpened} width={1500} footer={null} closeIcon={false}>
                <div className="flex justify-between items-center">
                    <p className="text-[20px] font-[600]">{`Driver's Contact: ${driverInfo?.user_data?.phone}`}</p>

                    <button className="h-10 w-10 rounded-[5px] border-[1px] border-gray-400 flex justify-center items-center" onClick={handleClose}>
                        <XMarkIcon className="h-5 w-5" />
                    </button>
                </div>
                <hr />

                <div className="lg:grid lg:grid-cols-2 w-full gap-x-[30px]">
                    {/* left */}
                    <div className='w-full'>
                        <>
                            {/* driver info */}
                            <div className="items-center">
                                <PhotoView
                                    src={
                                        driverInfo?.image || driverInfo?.profile_image ||
                                        "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                    }
                                >
                                    <img
                                        className="h-[153px] w-[153px] rounded-full object-cover cursor-pointer"
                                        src={
                                            driverInfo?.image || driverInfo?.profile_image ||
                                            "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                        }
                                        alt="driver"
                                    />
                                </PhotoView>
                            </div>

                            {/* national id */}
                            <div className="mt-5">
                                <p className="text-[20px] font-[600] text-yarpGray">
                                    National ID
                                </p>

                                <hr />

                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-5 w-full">
                                    <div className="w-full">
                                        <div className="w-full rounded-[20px] mt-2">
                                            <PhotoView
                                                src={
                                                    driverInfo?.national_id_front_image ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    src={
                                                        driverInfo?.national_id_front_image ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="nFront"
                                                    className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                                                />
                                            </PhotoView>
                                        </div>
                                        <small className="text-gray-500 mt-[13px]">
                                            Front View
                                        </small>
                                    </div>

                                    <div className="w-full">
                                        <div className="w-full rounded-[20px] mt-2">
                                            <PhotoView
                                                src={
                                                    driverInfo?.national_id_back_image ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    src={
                                                        driverInfo?.national_id_back_image ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="nBack"
                                                    className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                                                />
                                            </PhotoView>
                                        </div>
                                        <small className="text-gray-500 mt-[13px]">
                                            Back View
                                        </small>
                                    </div>
                                </div>
                            </div>

                            {/* licese */}
                            <div className="mt-5">
                                <p className="text-[20px] font-[600] text-yarpGray">
                                    Licence Info
                                </p>

                                <hr />

                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-5">
                                    <div>
                                        <div className="w-full border border-gray-500 rounded-[20px] mt-2">
                                            <PhotoView
                                                src={
                                                    driverInfo?.licence_image ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    src={
                                                        driverInfo?.licence_image ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="lfront"
                                                    className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                                                />
                                            </PhotoView>
                                        </div>
                                        <small className="text-gray-500 mt-[13px]">
                                            Front View
                                        </small>
                                    </div>
                                    <div>
                                        <div className="w-full border border-gray-500 rounded-[20px] mt-2">
                                            <PhotoView
                                                src={
                                                    driverInfo?.licence_back_image ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    src={
                                                        driverInfo?.licence_back_image ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="lBack"
                                                    className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                                                />
                                            </PhotoView>
                                        </div>
                                        <small className="text-gray-500 mt-[13px]">
                                            Back View
                                        </small>
                                    </div>
                                </div>
                            </div>

                            {/* ins & road */}
                            <div className="mt-5">
                                <p className="text-[20px] font-[600] text-yarpGray">
                                    Insurance & Roadworthy
                                </p>

                                <hr />

                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-5">
                                    <div>
                                        <div className="w-full border border-gray-500 rounded-[20px] mt-2">
                                            <PhotoView
                                                src={
                                                    driverInfo?.truck_data_partial?.insurance || driverInfo?.truck_data?.insurance ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    src={
                                                        driverInfo?.truck_data_partial?.insurance || driverInfo?.truck_data?.insurance ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="lfront"
                                                    className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                                                />
                                            </PhotoView>
                                        </div>
                                        <small className="text-gray-500 mt-[13px]">
                                            Insurance
                                        </small>
                                    </div>
                                    <div>
                                        <div className="w-full border border-gray-500 rounded-[20px] mt-2">
                                            <PhotoView
                                                src={
                                                    driverInfo?.truck_data_partial?.roadworthy || driverInfo?.truck_data?.roadworthy ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    src={
                                                        driverInfo?.truck_data_partial?.roadworthy || driverInfo?.truck_data?.roadworthy ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="lBack"
                                                    className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                                                />
                                            </PhotoView>
                                        </div>
                                        <small className="text-gray-500 mt-[13px]">
                                            Roadworthy
                                        </small>
                                    </div>
                                </div>
                            </div>

                            {/* truck */}
                            <div className="mt-5">
                                <p className="text-[20px] font-[600] text-yarpGray">
                                    Truck Images
                                </p>

                                <hr />

                                <div className="lg:grid lg:grid-cols-3 lg:gap-x-5">
                                    <div>
                                        <div className="w-full border border-gray-500 rounded-[20px] mt-2">
                                            <PhotoView
                                                src={
                                                    driverInfo?.truck_data_partial?.truckfront || driverInfo?.truck_data?.truckfront ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    src={
                                                        driverInfo?.truck_data_partial?.truckfront || driverInfo?.truck_data?.truckfront ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="lfront"
                                                    className="w-full h-[200px] object-cover rounded-[20px] cursor-pointer"
                                                />
                                            </PhotoView>
                                        </div>
                                        <small className="text-gray-500 mt-[13px]">
                                            Front
                                        </small>
                                    </div>
                                    <div>
                                        <div className="w-full border border-gray-500 rounded-[20px] mt-2">
                                            <PhotoView
                                                src={
                                                    driverInfo?.truck_data_partial?.truckside || driverInfo?.truck_data?.truckside ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    src={
                                                        driverInfo?.truck_data_partial?.truckside || driverInfo?.truck_data?.truckside ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="lBack"
                                                    className="w-full h-[200px] object-cover rounded-[20px] cursor-pointer"
                                                />
                                            </PhotoView>
                                        </div>
                                        <small className="text-gray-500 mt-[13px]">
                                            Side
                                        </small>
                                    </div>
                                    <div>
                                        <div className="w-full border border-gray-500 rounded-[20px] mt-2">
                                            <PhotoView
                                                src={
                                                    driverInfo?.truck_data_partial?.truckback || driverInfo?.truck_data?.truckback ||
                                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                }
                                            >
                                                <img
                                                    src={
                                                        driverInfo?.truck_data_partial?.truckback || driverInfo?.truck_data?.truckback ||
                                                        "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                                    }
                                                    alt="lBack"
                                                    className="w-full h-[200px] object-cover rounded-[20px] cursor-pointer"
                                                />
                                            </PhotoView>
                                        </div>
                                        <small className="text-gray-500 mt-[13px]">
                                            Back
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>

                    {/* right */}
                    <div className='w-full h-full border-l-[1px] px-5'>
                        <CompleteDriverProfile driverInfo={driverInfo} handleReFetch={handleReFetch} handleClose={handleClose} />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AccountDetails
