import { useEffect, useState } from 'react';
import Select from 'react-select';
import BaseService from '../../helpers/baseService';
import { global_variables } from '../../helpers/globalVariables';

interface brandProps {
    onChange?: (data: any) => void
    selectedBrand: string
    selectedModel: string
    value?: any
}

const TruckTypeSelector = ({ onChange, selectedModel, selectedBrand, value }: brandProps) => {

    const [isFetching, setIsFetching] = useState(false)
    const [allTypes, setAllTypes] = useState([])

    //handleTypeSearch
    useEffect(() => {
        const handleTypeSearch = () => {

            if (selectedModel !== "") {
                setIsFetching(true);

                BaseService.get_api_v2(`${global_variables.get_truck_types_v2}?filter={"brand":${JSON.stringify(selectedBrand)}, "model":{"$regex": ${JSON.stringify(selectedModel)},"$options":"i"}}&limit=1000000`)
                    .then((response) => {
                        // console.log(response?.data?.payload);
                        const rawData = response?.data?.payload
                        const transformedData = rawData.map((item: any) => {
                            return item.commonCategories.map((category: any) => ({
                                label: category?.cartype,
                                value: category?.cartype
                            }));
                        }).flat();
                        setAllTypes(transformedData)
                        setIsFetching(false);
                    }).catch((error: any) => {
                        console.log(error);
                        setIsFetching(false);
                    })
            }
            else {
                setAllTypes([])
            }

        };

        handleTypeSearch()
    }, [selectedModel])


    return (
        <>
            <Select
                className="basic-single"
                placeholder="Search truck type"
                isLoading={isFetching}
                isClearable={true}
                isSearchable={true}
                options={allTypes}
                noOptionsMessage={() => "Example: Mini Truck"}
                onChange={onChange}
                isDisabled={isFetching || selectedModel === ""}
                value={value}
            />
        </>
    )
}

export default TruckTypeSelector
