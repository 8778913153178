import moment from "moment";
import React from "react";
import { metersToKilometers } from "../../../utils/functions";
import { PhotoView } from "react-photo-view";
const MapInformation = ({ tripHistoryData }: { tripHistoryData: any }) => {
  return (
    <section>
      <div>
        <div className="p-4 text-text_deep  rounded-[25px] my-4 bg-white drop-shadow-[0_6px_6px_rgba(255,102,23,0.1)]">
          <p className="text-[20px] text-text_deep mb-2 font-medium ">
            Trip Information
          </p>
          <hr />
          <div className="grid grid-cols-4 mt-4">
            <section>
              <p className="text-[12px]">Trip Cost</p>
              <p className="mt-1 text-[16px]">
                {" "}
                GHS {parseFloat(tripHistoryData?.amount).toFixed(2)}
              </p>
            </section>
            <section>
              <p className="text-[12px]">Pick Up</p>
              <p className="mt-1 text-[16px]">
                {tripHistoryData?.pickup_place_name},{" "}
                {tripHistoryData?.cityfrom}
              </p>
            </section>
            <section>
              <p className="text-[12px]">Destination</p>
              <p className="mt-1 text-[16px]">
                {" "}
                {tripHistoryData?.delivery_place_name},{" "}
                {tripHistoryData?.cityto}
              </p>
            </section>
            <section>
              <p className="text-[12px]">Truck Type</p>
              <p className="mt-1 text-[16px]">{tripHistoryData?.car_type}</p>
            </section>

            <section>
              <p className="text-[12px]">Goods Weight</p>
              <p className="mt-1 text-[16px]">
                {" "}
                {tripHistoryData?.weight_load}
              </p>
            </section>
            <section>
              <p className="text-[12px]">Estimated Distance</p>
              <p className="mt-1 text-[16px]">
                {" "}
                {metersToKilometers(tripHistoryData?.distance)} KM
              </p>
            </section>
            <section>
              <p className="text-[12px]">Time</p>
              {moment(tripHistoryData?.timestamp).format(
                "Do MMM YYYY - hh:mm A"
              )}
            </section>
          </div>
        </div>
        <div className="p-4 text-text_deep  drop-shadow-[0_6px_6px_rgba(255,102,23,0.1)] rounded-[25px] my-4 bg-white">
          <p className="text-[20px] text-text_deep mb-2 font-medium ">
            Images Information
          </p>
          <hr />
          <div className="lg:grid lg:grid-cols-3 w-full ">
            {tripHistoryData?.images ? (
              tripHistoryData?.images.map((dd: any, i: any) => (
                <PhotoView key={i} src={dd}>
                  <img
                    src={dd}
                    alt={i}
                    className="rounded-[20px] object-cover h-[100px] w-[100px]"
                  />
                </PhotoView>
              ))
            ) : (
              <p className="text-[14px] font-[600]">No images available</p>
            )}
          </div>
        </div>
        <div className="p-4 text-text_deep drop-shadow-[0_6px_6px_rgba(255,102,23,0.1)] rounded-[25px] my-4 bg-white">
          <p className="text-[20px] text-text_deep mb-2 font-medium ">
            Driver Information
          </p>
          <hr />
          <div className="grid grid-cols-4 mt-6 gap-5">
            <section>
              <p className="text-[12px]">Driver Image</p>
              <PhotoView src={tripHistoryData?.driver_data?.image}>
                <img
                  src={tripHistoryData?.driver_data?.image}
                  alt="user"
                  className="rounded-full object-cover h-[70px] w-[70px]"
                />
              </PhotoView>
            </section>

            <section>
              <p className="text-[12px]">Full Name</p>
              <p className="mt-1 text-[16px]">
                <span>
                  {tripHistoryData?.driver_data?.full_name}

                  {/* {" "}
                {moment(schooData?.currentAcademicTermData?.endsOn).format(
                  "Do MMM YYYY - hh:mm A"
                )} */}
                </span>
              </p>
            </section>
            <section>
              <p className="text-[12px]">Driver Contact</p>
              <p className="mt-1 text-[16px]">
                {tripHistoryData?.driver_data?.phone}
              </p>
            </section>
          </div>
        </div>
        {/* <div className="p-4 text-text_deep drop-shadow-[0_6px_6px_rgba(255,102,23,0.1)] rounded-[25px] my-4 bg-white">
          <p className="text-[20px] text-text_deep mb-2 font-medium ">
            Curriculum Information
          </p>
          <hr />
        </div> */}
      </div>
    </section>
  );
};

export default MapInformation;
