import { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { MAPS_KEY } from "../../../helpers/constants";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { formatCedis } from "../../../utils/formatCedis";
import { Empty } from "antd";

const PriceChecker = () => {
  //Load Car types
  const [types, setTypes] = useState<any>("");
  const [typeLoaded, setTypeLoaded] = useState<boolean>(false);

  const fetchType = async () => {
    setTypeLoaded(false);

    BaseService.get_api(global_variables.get_car_type)
      .then((res) => {
        setTypes(res?.data?.payload);
        setTypeLoaded(true);
        //   console.log(res.payload)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchType();
  }, []);

  const [gross, setGross] = useState<any>("heavy");
  const [regionTo, setRegionTo] = useState<any>();
  const [regionFrom, setRegionFrom] = useState<any>();
  const [type, setType] = useState<string>("");

  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [pricing, setPrice] = useState<any>([]);



  const getPricing = () => {
    setLoading(true);
    setShow(true);

    var geocoder = new window.google.maps.Geocoder();

    // Region to
    geocoder.geocode(
      { address: regionTo.label },
      (results: any, status: any) => {
        if (status === "OK") {
          var address = results[0].address_components;
          for (var i = 0; i < address.length; i++) {
            if (address[i].types[0] === "administrative_area_level_1") {
              var regionToName = address[i].long_name;
              // console.log(results[0]?.geometry?.location.lat());
              // console.log(results[0]?.geometry?.location.lng());

              //Region From
              // eslint-disable-next-line
              geocoder.geocode(
                { address: regionFrom.label },
                async (resultsTwo: any, statusTwo: any) => {
                  if (statusTwo === "OK") {
                    var address = resultsTwo[0].address_components;

                    for (var i = 0; i < address.length; i++) {
                      if (
                        address[i].types[0] === "administrative_area_level_1"
                      ) {
                        // Do something with the state or province here

                        // console.log(resultsTwo[0]?.geometry?.location);

                        var regionFromName = address[i].long_name;

                        // const l1 = results[0]?.geometry?.location.lat();
                        // const l2 = results[0]?.geometry?.location.lng();

                        // var origin1 = new window.google.maps.LatLng(l1, l2);
                        // var origin2 = new window.google.maps.LatLng(
                        //   resultsTwo[0]?.geometry?.location.lat(),
                        //   resultsTwo[0]?.geometry?.location.lng()
                        // );

                        // console.log(results[0]?.geometry?.location.lng());
                        // console.log(origin2);

                        var service: any =
                          new window.google.maps.DistanceMatrixService();
                        service.getDistanceMatrix(
                          {
                            origins: [regionFrom.label],
                            destinations: [regionTo.label],
                            travelMode: "DRIVING",
                            // transitOptions: TransitOptions,
                            // drivingOptions: DrivingOptions,
                            // unitSystem: UnitSystem,
                            // avoidHighways: Boolean,
                            // avoidTolls: Boolean,
                          },
                          callback
                        );

                        // eslint-disable-next-line

                        break;
                      }
                    }
                  } else {
                    ShowToast.error_toast("An error occurred please try again");
                  }
                }
              );

              break;
            }
          }
        } else {
          ShowToast.error_toast("An error occurred please try again");
        }
      }
    );
  };

  function callback(response: any, status: any) {
    if (status === "OK") {

      const price_raw_data = {
        weight: gross,
        region_to: regionTo?.label,
        type: type,
        region_from: regionFrom?.label,
        distance: response.rows[0]?.elements[0]?.distance.value,
      };

      console.log(price_raw_data);

      BaseService.post_api(global_variables.check_pricing, price_raw_data)
        .then((res) => {
          console.log("this is teh response", res);
          setPrice(res?.data?.payload);
          setLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
          ShowToast.error_toast(error?.response?.data?.error || "Error occured");
        });
    } else {
      ShowToast.error_toast("An error occurred please try again");
    }
  }

  return (
    <>
      <Container>
        <div>
          <Form.Group className="mb-3" controlId="pickup">
            <Form.Label>Pick up</Form.Label>
            <GooglePlacesAutocomplete
              apiKey={`${MAPS_KEY}`}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["gh"],
                },
              }}
              selectProps={{
                onChange: setRegionFrom,
              }}
            />
          </Form.Group>

          {/* AIzaSyAjYtFwu50zto5p85R33Ie6ZMWdah8_j7o */}
          <Form.Group className="mb-3" controlId="destination">
            <Form.Label>Destination</Form.Label>
            <GooglePlacesAutocomplete
              apiKey={`${MAPS_KEY}`}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["gh"],
                },
              }}
              selectProps={{
                onChange: setRegionTo,
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="carType">
            <Form.Label>Car type</Form.Label>
            <Form.Select
              id="selectType"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="" disabled></option>
              {(!typeLoaded && <option value="">Loading...</option>) ||
                types.map((types: any) => {
                  return (
                    <option value={types.cartype} key={types._id}>
                      {types.cartype}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="GrossWeright">
            <Form.Label>Laod weight</Form.Label>
            <Form.Select
              value={gross}
              onChange={(e) => {
                setGross(e.target.value);
              }}
            >
              <option value="">Seelect Load weight</option>
              <option value="heavy">Heavy</option>
              <option value="moderate">Moderate</option>
              <option value="light">Light</option>
            </Form.Select>
          </Form.Group>
          <div className=" text-center">
            <button
              className="px-3 py-2 bg-yarpOrange text-white rounded-[30px] shadow-md"
              onClick={() => getPricing()}
            >
              Check Price
            </button>
          </div>
        </div>
      </Container>

      {/* Modal */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pricing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {loading ? (
              <div className="flex justify-center items-center">
                <LoadingDataComponent />
              </div>
            ) : pricing ? (
              <>
                <Row>
                  <Col md={3}>
                    <p>
                      <span className="fw-bold">Total Price: </span>
                      {formatCedis(pricing.total_price)}
                    </p>
                    <p>
                      <span className="fw-bold">Bucket Price: </span>{" "}
                      {formatCedis(pricing.bucket_price)}
                    </p>
                    <p>
                      <span className="fw-bold">Normal Amount: </span>{" "}
                      {formatCedis(pricing.normal_amount)}
                    </p>
                  </Col>
                  <Col md={6}>
                    {/* {console.log(regionTo)} */}
                    <p>
                      <span className="fw-bold">Pick up: </span>
                      {regionFrom.label}
                    </p>
                    <p>
                      <span className="fw-bold">Destination: </span>
                      {regionTo.label}
                    </p>
                  </Col>
                  <Col md={3}>
                    <p>
                      <span className="fw-bold">Region to: </span>
                      {pricing.region_to}%
                    </p>
                    <p>
                      <span className="fw-bold">Region from: </span>
                      {pricing.region_from}%
                    </p>
                    <p>
                      <span className="fw-bold">Car type: </span>
                      {type}
                    </p>
                  </Col>
                </Row>
                <hr />
                <p className="fw-bold">Break Down: </p>
                <DataTable
                  data={pricing?.break}
                  columns={[
                    {
                      name: "Maximum Kilo",
                      selector: (row: any) => row.maxkilo,
                    },
                    {
                      name: "Baseprice",
                      selector: (row) => formatCedis(row.baseprice),
                    },
                  ]}
                />
              </>
            ) : (
              <div className="mt-[50px]">
                <Empty description="No data" />
              </div>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PriceChecker;
