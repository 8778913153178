import { useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import loadingGif from "../../../images/yarp-spinner.gif";
import { useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { GenerateId } from "../../../functions/functions";
import { Container, Image, Modal, Table } from "react-bootstrap";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { ExclamationTriangleIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Empty } from "antd";



const ActiveAdmins = () => {
  const [adminLoaded, setAdminLoaded] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [mloaded, setmLoaded] = useState(false);
  const [madmin, setmAdmin] = useState<any>([]);
  const [randomNum, setRandomNum] = useState("");
  const [suspendCode, setSuspendCode] = useState("");
  const [suspendDate, setSuspendDate] = useState("");
  const [show, setShow] = useState(false);
  const [showSus, setShowSuspend] = useState(false);

  useEffect(() => {
    getAdmins();
  }, []);

  //get all admins
  const getAdmins = async () => {
    setAdminLoaded(true);

    BaseService.get_api(
      `${global_variables.yarpusers}?filter={"condition":"admin","type":"admin"}&sort={"updated_at":-1}`
    )
      .then(function (response) {
        // console.log(response.data.payload);
        setAdmins(response.data.payload);
        setAdminLoaded(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const showAdminDetails = (row: any) => {
    setShow(true);
    setmAdmin(row);
  };

  const showSuspend = (row: any) => {
    setmLoaded(true);
    setmAdmin(row);
    setRandomNum(GenerateId(5));
    setShowSuspend(true);
  };

  const columns = [
    {
      name: "Full name",
      selector: (row: any) => row.full_name,
      cellExport: (row: any) => row.full_name,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      cellExport: (row: any) => row.email,
    },
    {
      name: "Phone",
      selector: (row: any) => row.phone,
      cellExport: (row: any) => row.phone,
    },
    {
      name: "Action",
      selector: (row: any) => (
        <div className="flex gap-3">
          <Link
            to="#"
            style={{ textDecoration: "none", color: "#000", fontSize: "22px" }}
            onClick={showAdminDetails.bind(this, row)}
          >
            <EyeIcon className="h-5 w-5 cursor-pointer" />
          </Link>

          <Link
            to="#"
            style={{ textDecoration: "none", color: "red", fontSize: "22px" }}
            className="mx-1"
            onClick={showSuspend.bind(this, row)}
          >
            <ExclamationTriangleIcon className="h-5 w-5 cursor-pointer text-red-500" />
          </Link>
        </div>
      ),
      cellExport: (row: any) => row.title,
    },
  ];

  const suspendUser = async (e: any) => {
    e.preventDefault();
    setShowSuspend(false);

    Swal.fire({
      showConfirmButton: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      text: "Loading",
      position: "top-right",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (randomNum !== suspendCode) {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "Please enter the comfirmaton code to suspend user from yarp",
      });
    } else if (suspendDate === "") {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "Please set a date for the suspension to end",
      });
    } else if (randomNum === suspendCode) {
      // mloaded ? madmin['userId'];
      const userId = mloaded ? madmin["userid"] : "none";

      var suspend_data = {
        suspend: true,
        suspension_start: moment().format("YYYY-MM-D"),
        suspension_end: moment(suspendDate).format("YYYY-MM-D"),
        userid: userId,
      };

      BaseService.put_api(global_variables.suspend_users, suspend_data)
        .then(function (response) {
          getAdmins();
          Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            text: response.data.message,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "Chairman ne3333",
      });
    }
  };

  return (
    <>
      <div style={{ minHeight: "50vh" }}>
        <DataTable
          columns={columns}
          data={admins}
          noDataComponent={
            <div className="mt-[50px]">
              <Empty description="No data" />
            </div>
          }
          progressPending={adminLoaded}
          progressComponent={
            <Image
              fluid
              src={loadingGif}
              style={{ marginTop: "100px", width: "200px" }}
              alt="Loading..."
            />
          }
          pointerOnHover
          onRowClicked={(e) => showAdminDetails(e)}
        />
      </div>
      {/* {
                admins.length !== 0 && <div className="fa-pull-left pt-3"> <NavLink type="button" to="/all_admins" id={styles['addNew-btn']}>view all</NavLink> </div>
            } */}

      {/* Admin Detail Modal */}
      <Modal show={show} size="lg" onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Admin Details</Modal.Title>
        </Modal.Header>
        <div className="modal-body">
          {show ? (
            <Container>
              <Table>
                <tbody>
                  <tr>
                    <th style={{ width: "30%" }}>Name</th>
                    <td>{madmin["full_name"]}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Email</th>
                    <td>{madmin["email"]}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Country Code</th>
                    <td>{madmin["country_code"]}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Phone</th>
                    <td>{madmin["phone"]}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Registration Date</th>
                    <td>
                      {moment(madmin["created_at"]).format("Do MMM YYYY")}
                    </td>
                  </tr>
                  {/* <tr>
                    <th style={{ width: "30%" }}>User type</th>
                    <td>{madmin["user_type"]}</td>
                  </tr> */}
                </tbody>
              </Table>
            </Container>
          ) : (
            <div className="text-center">
              <Image
                fluid
                src={loadingGif}
                style={{ marginTop: "10px", width: "200px" }}
                alt="Loading..."
              />
            </div>
          )}
        </div>
      </Modal>

      {/* Suspend User */}
      <Modal
        show={showSus}
        onHide={() => {
          setShowSuspend(false);
        }}
      >
        <form className="modal-content" onSubmit={suspendUser}>
          <Modal.Header closeButton>
            <Modal.Title>Suspend</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            <div className="container">
              <div className="text-center mb-2">
                <h5 className="fw-light m-0 p-0"> Comfirmation Code </h5>
                <p className="fw-bold m-0 p-0">
                  {mloaded ? randomNum : "loadingGif...."}
                </p>
              </div>
              <p>
                Are you sure you want to suspend this user? Please enter the
                code above
              </p>
              <div className="form-floating mb-2">
                <input
                  type="text"
                  className="form-control"
                  id="comfirm"
                  placeholder="code"
                  style={{ textTransform: "uppercase" }}
                  value={suspendCode}
                  onChange={(e) => {
                    setSuspendCode(e.target.value.toUpperCase());
                  }}
                />
                <label htmlFor="comfirm">Enter code</label>
              </div>

              <div className="form-floating">
                <input
                  type="date"
                  className="form-control"
                  id="datePicke"
                  placeholder="code"
                  style={{ textTransform: "uppercase" }}
                  value={suspendDate}
                  onChange={(e) => {
                    setSuspendDate(e.target.value);
                  }}
                />
                <label htmlFor="datePicke">Select Suspension End Date</label>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setShowSuspend(false);
              }}
            >
              Close
            </button>
            <button type="submit" className="btn btn-danger">
              Suspend
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ActiveAdmins;
