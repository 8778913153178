import { useEffect, useState } from "react";
import { Container, FloatingLabel, Form, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PhoneInput from "react-phone-number-input";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Button, Spinner } from "@material-tailwind/react";
import {
  AiOutlineWhatsApp,
  AiOutlineFacebook,
  AiOutlineTwitter,
  AiOutlineInstagram,
} from "react-icons/ai";
import { Empty } from "antd";

const CustomerService = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [cservice, setHelps] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [reFetch, setReFetch] = useState<boolean>(false);

  useEffect(() => {
    getCService();
    document.title = "Customer Service - Yarp Main";
  }, []);

  //getCService
  const getCService = (lim?: any, sk?: any) => {
    setLoading(true);

    BaseService.get_api_v2(
      `${global_variables.get_customer_service}?limit=${lim}&skip=${sk}&sort={"timestamp":-1}`
    )
      .then((res) => {
        setLoading(false);
        setHelps(res?.data?.payload);
        setTotal(res?.data?.total);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //table columns
  const columns: any = [
    { name: "Name", cell: (row: { name: any }) => row.name },
    { name: "Country", cell: (row: { country: any }) => row.country },
    {
      name: "Socials",
      cell: (row: any) => (
        <div className="flex gap-3 items-center">
          <AiOutlineWhatsApp className="h-5 w-5 cursor-pointer" />
          <AiOutlineFacebook className="h-5 w-5 cursor-pointer" />
          <AiOutlineTwitter className="h-5 w-5 cursor-pointer" />
          <AiOutlineInstagram className="h-5 w-5 cursor-pointer" />
        </div>
      ),
    },
  ];

  //handleRefetch
  const handleRefetch = () => [setReFetch(!reFetch)];

  const handleRowChange = (a: any) => {
    getCService(a, 0);
    setLimit(a);
    setSkip(0);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getCService(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getCService(limit, newSkip);
      setSkip(newSkip);
    }
  };

  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState<any>("");
  const [app, setApp] = useState("");
  const [facebook, setFacebook] = useState("");
  const [country, setCountry] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [name, setName] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  //postCustomer
  const postCustomer = async () => {
    const data = {
      phone: phone,
      app: app,
      facebook: facebook,
      country: country,
      whatsapp: whatsapp,
      name: name,
      instagram: instagram,
      twitter: twitter,
    };

    setIsBusy(true);

    BaseService.post_api_v2(global_variables.create_customer_service, data)
      .then((res) => {
        ShowToast.success_toast(res?.data?.message);
        setIsBusy(false);
        setShow(false);
        handleRefetch();
      })
      .catch((err) => {
        console.log(err);
        ShowToast.error_toast("An error occured please try again");
      });
  };

  return (
    <>
      <div className="text-end mb-3">
        <Button variant="gradient" color="blue" onClick={() => setShow(true)}>
          Add
        </Button>
      </div>

      <div>
        <DataTable
          highlightOnHover
          data={cservice}
          columns={columns}
          progressPending={loading}
          progressComponent={<LoadingDataComponent />}
          noDataComponent={<Empty description="No data" />}
          pagination={total >= 11 ? true : false}
          paginationServer
          paginationTotalRows={total}
          onChangeRowsPerPage={handleRowChange}
          onChangePage={pageChange}
        />
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Customer Care Representative</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <FloatingLabel controlId="Name" label="Full Name" className="mb-3">
              <Form.Control
                type="text"
                placeholder="1.0"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </FloatingLabel>

            <div className="w-full mb-3">
              <PhoneInput
                className="rounded-[5px] w-full border-[1px] border-gray-300 px-2 h-[50px]"
                placeholder="Enter phone number"
                defaultCountry="GH"
                value={phone}
                onChange={(phone) => setPhone(phone)}
                numberInputProps={{
                  className: "focus:outline-none",
                }}
              />
            </div>

            <FloatingLabel controlId="App" label="App" className="mb-3">
              <Form.Control
                type="text"
                placeholder="1.0"
                value={app}
                onChange={(e) => {
                  setApp(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel controlId="country" label="country" className="mb-3">
              <Form.Control
                type="text"
                placeholder="1.0"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="facebook"
              label="facebook"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="1.0"
                value={facebook}
                onChange={(e) => {
                  setFacebook(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="whatsapp"
              label="whatsapp"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="1.0"
                value={whatsapp}
                onChange={(e) => {
                  setWhatsapp(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="instagram"
              label="instagram"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="1.0"
                value={instagram}
                onChange={(e) => {
                  setInstagram(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel controlId="twitter" label="twitter" className="mb-3">
              <Form.Control
                type="text"
                placeholder="1.0"
                value={twitter}
                onChange={(e) => {
                  setTwitter(e.target.value);
                }}
              />
            </FloatingLabel>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="gradient"
            color="blue"
            onClick={() => postCustomer()}
            disabled={isBusy}
          >
            {isBusy ? <Spinner /> : <span>Register</span>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerService;
