import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import axios from "axios";

import moment from "moment";
import { BASE_URL_V1 } from "../../../helpers/constants";
import { StorageBox } from "../../../helpers/storage";
import { global_variables } from "../../../helpers/globalVariables";
ChartJS.register(...registerables);

const api = BASE_URL_V1;

var currentDate = moment();
var weekStart = currentDate.clone().startOf("week");
var weekEnd = currentDate.clone().endOf("week");

var days = [];
let i;
for (i = 0; i <= 6; i++) {
  days.push(moment(weekStart).add(i, "days").format("YYYY-MM-DD"));
}

const sunday = days[0];
const monday = days[1];
const tuesday = days[2];
const wednesday = days[3];
const thursday = days[4];
const friday = days[5];
const saturday = days[6];

const ThisWeekChart = () => {
  const [val_1, setDay1] = useState(0);
  const [val_2, setDay2] = useState(0);
  const [val_3, setDay3] = useState(0);
  const [val_4, setDay4] = useState(0);
  const [val_5, setDay5] = useState(0);
  const [val_6, setDay6] = useState(0);
  const [val_7, setDay7] = useState(0);

  useEffect(() => {
    fetchDay1();
    fetchDay2();
    fetchDay3();
    fetchDay4();
    fetchDay5();
    fetchDay6();
    fetchDay7();
  }, []);

  const fetchDay1 = async () => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${sunday}T00:00:00","FGT_Parameter":"${sunday}T23:59:00"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);
        if (gotData === null) {
          setDay1(0);
        } else {
          setDay1(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchDay2 = async () => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${monday}T00:00:00","FGT_Parameter":"${monday}T23:59:00"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);

        if (gotData === null) {
          setDay2(0);
        } else {
          setDay2(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchDay3 = async () => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${tuesday}T00:00:00","FGT_Parameter":"${tuesday}T23:59:00"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);

        if (gotData === null) {
          setDay3(0);
        } else {
          setDay3(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchDay4 = async () => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${wednesday}T00:00:00","FGT_Parameter":"${wednesday}T23:59:00"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);

        if (gotData === null) {
          setDay4(0);
        } else {
          setDay4(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchDay5 = async () => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${thursday}T00:00:00","FGT_Parameter":"${thursday}T23:59:00"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);

        if (gotData === null) {
          setDay5(0);
        } else {
          setDay5(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchDay6 = async () => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${friday}T00:00:00","FGT_Parameter":"${friday}T23:59:00"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);

        if (gotData === null) {
          setDay6(0);
        } else {
          setDay6(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchDay7 = async () => {
    var config = {
      method: "get",
      url: `${
        api + global_variables.getTransaction
      }?filter={"condition":"range","FLT_Parameter":"${saturday}T00:00:00","FGT_Parameter":"${saturday}T23:59:00"}`,
      headers: {
        "x-access-token": StorageBox.getAccessToken(),
      },
    };

    await axios(config)
      .then(function (response) {
        const gotData = response?.data?.details;

        //console.log(response);

        if (gotData === null) {
          setDay7(0);
        } else {
          setDay7(gotData["total_amount"]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var theChart;
  var theOption;

  var day1 = [val_1];
  var day2 = [val_2];
  var day3 = [val_3];
  var day4 = [val_4];
  var day5 = [val_5];
  var day6 = [val_6];
  var day7 = [val_7];

  var xValues = ["", "SUN", "MON", "TUE", "WED", "THUR", "FRI", "SAT"];
  var yValues = [0, day1, day2, day3, day4, day5, day6, day7];

  //console.log(yValues);

  //SETUP BLOCK
  var data = {
    labels: xValues,
    datasets: [
      {
        backgroundColor: "rgba(255, 103, 0)",
        borderColor: "rgba(255, 103, 0)",
        radius: 5,
        data: yValues,
        label: "Earnings - GHC",
      },
    ],
  };

  theChart = data;

  //CONFIG BLOCK

  var options = {
    tension: 0.5,
    borderWidth: 1,
    plugins: {
      legend: {
        display: false,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  theOption = options;

  return (
    <>
      <Line
        data={theChart}
        options={theOption}
        style={{ maxHeight: "330px", minHeight: "330px" }}
      />
    </>
  );
};

export default ThisWeekChart;
