import { useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { useEffect } from "react";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";

const Roles = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [roles, setRoles] = useState<any[]>([]);

  const getRoles = () => {
    setLoading(true);

    BaseService.get_api(`${global_variables.get_roles}`)
      .then((res) => {
        setRoles(res?.data?.payload);
        setLoading(false);
      })
      .catch((err) => {
        ShowToast.error_toast(err?.error);
        console.log(err);
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <div>
        <div>
          {loading ? (
            <div className="flex items-center justify-center h-screen">
              <LoadingDataComponent />
            </div>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Role</th>
                  <th>Permissions</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(roles).map((d, index) => {
                  return (
                    <tr key={index}>
                      <th>{d.toUpperCase()}</th>
                      <td>
                        <ol>
                          {Object.values(roles)[index].map(
                            (l: any, i: number) => {
                              return <li key={i}>{l._id}</li>;
                            }
                          )}
                        </ol>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default Roles;
