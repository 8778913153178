import { Fragment, useState } from "react";
import CustomSidebar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import { Typography } from "@material-tailwind/react";
import TotalDrivers from "./totalDrivers";
import TotalFleetOwners from "./totalFleetOwners";
import UnapprovedDriversTable from "./unapprovedTabs/unapprovedDrivers";
import UnapprovedTrucksTable from "./unapprovedTabs/unapprovedTrucks";
import UnapprovedFleetTable from "./unapprovedTabs/unapprovedFleet";
import ExpiredLincence from "./expiredTabs/expired_license";
import ExpiredInsurance from "./expiredTabs/expired_insurance";
import ExpiredWorthy from "./expiredTabs/expired_roadworthy";
import Administrators from "./admins";
import EarningsChart from "./charts/earningsChart";
import MostRecent from "./mostRecent";
import TotalYarpUsers from "./totalyarpUsers";
import TotalCustomers from "./totalCustomers";
const Dashboard = () => {
  const [activeUnapproved, setActiveUnapproved] = useState(0);
  const unapprovedTabs = ["Driver", "Trucks", "Fleet Owners"];
  const unapprovedComponents = [
    <UnapprovedDriversTable />,
    <UnapprovedTrucksTable />,
    <UnapprovedFleetTable />,
  ];

  const [activeExpiredTab, setActiveExpiredTab] = useState(0);
  const expiredTabs = [
    "Expired License",
    "Expired Insurance",
    "Expired Roadworthy",
  ];
  const expiredComponents = [
    <ExpiredLincence />,
    <ExpiredInsurance />,
    <ExpiredWorthy />,
  ];

  return (
    <Fragment>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSidebar dashActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">Dashboard</p>

              {/* top cards */}
              <div className="lg:grid lg:grid-cols-5 gap-[20px] mt-[16px]">
                <div className="mb-3 lg:mb-0">
                  {/* overall users*/}
                  <TotalCustomers />
                </div>
                <div className="mb-3 lg:mb-0">
                  {/* total users */}
                  <TotalYarpUsers />
                </div>
                <div className="mb-3 lg:mb-0 cursor-pointer">
                  <TotalDrivers />
                </div>
                <div className="mb-3 lg:mb-0">
                  <TotalFleetOwners />
                </div>
                <div className="mb-3 lg:mb-0">
                  <Administrators />
                </div>
              </div>

              {/* EarningsChart */}
              <div className="lg:grid lg:grid-cols-3 mt-[20px] gap-x-[20px]">
                <div className="col-span-2 lg:mb-0 min-h-[400px]">
                  <EarningsChart />
                </div>

                <div className="p-3 rounded-[20px] bg-white shadow-md w-full min-h-[400px]">
                  <MostRecent />
                </div>
              </div>

              {/* unapproved users */}
              <div className="mt-4 w-full bg-white shadow-md p-5 rounded-[20px]">
                <p className="text-[20px] font-[600] text-yarpGray">
                  Approval Requests
                </p>

                <div className="mt-4 flex gap-x-3">
                  {unapprovedTabs.map((tab, i) => (
                    <div
                      key={i}
                      className={`${activeUnapproved === i
                        ? "border-b-[2px] border-b-yarpOrange"
                        : ""
                        } w-fit px-1 cursor-pointer`}
                      onClick={() => setActiveUnapproved(i)}
                    >
                      <Typography className="mb-0 font-[400] text-[14px] text-yarpGray">
                        {tab}
                      </Typography>
                    </div>
                  ))}
                </div>
                <div>{unapprovedComponents[activeUnapproved]}</div>
              </div>

              {/* expired docs */}
              <div className="mt-4 w-full bg-white shadow-md p-5 rounded-[20px]">
                <p className="text-[20px] font-[600] text-yarpGray">
                  Expired Documents
                </p>

                <div className="mt-4 flex gap-3">
                  {expiredTabs.map((tab, i) => (
                    <div
                      key={i}
                      className={`${activeExpiredTab === i
                        ? "border-b-[2px] border-b-yarpOrange"
                        : ""
                        } w-fit px-1 cursor-pointer`}
                      onClick={() => setActiveExpiredTab(i)}
                    >
                      <Typography className="mb-0 font-[400] text-[14px] text-yarpGray">
                        {tab}
                      </Typography>
                    </div>
                  ))}
                </div>
                <div>{expiredComponents[activeExpiredTab]}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
// 734718
