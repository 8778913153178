import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import SignIn from "./pages/authentication/signin";
import ProtectedRoute from "./HOC/ProtectedRoutes";
import Dashboard from "./pages/dashboard/dashboard";
import TotalSalesContext from "./context/totalSalesContext";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import BaseService from "./helpers/baseService";
import { global_variables } from "./helpers/globalVariables";
import TripHistory from "./pages/tripHistory/trip_history";
import Tracking from "./pages/tracking/tracking";
import Users from "./pages/users/app_users/users";
import UserDetail from "./pages/users/app_users/user_detail_page";
import DriverDetail from "./pages/users/drivers/driver_detail_pages";
import FleetDetail from "./pages/users/fleet_detail_pages";
import Administration from "./pages/administration/administration";
import Transactions from "./pages/transactions/transactions";
import YarpEarning from "./pages/YarpEarning/YarpEarning";
import Support from "./pages/support/support";
import ManageYarp from "./pages/manage-yarp/manage-yarp";
import UserProfile from "./pages/profile/user-profile";
import { PhotoProvider } from "react-photo-view";
import SendSMS from "./pages/send-sms/sendSms";
import DeclinedDocs from "./pages/declined/declined";
import AllTrucks from "./pages/trucks/trucks";
import AllSchedules from "./pages/schedules/schedules";
import DraftedDrivers from "./pages/drafted_drivers/drafted_drivers";
import UnfinishedTrips from "./pages/unfinished/unfinishedTrips";
import { SubMenuProvider } from "./context/subMenuContext";
import { connectionEnum } from "./sockets/connection.model";
import socketConnection from "./sockets/connection";
import { Alert } from "antd";
import AllCompanyRoles from "./pages/companies/roles/allCompanyRoles";
import MapPage from "./pages/mapPage/MapPage";
import SmsProviders from "./pages/send-sms/smsProviders";
import Notifications from "./pages/Notifications/Notifications";
import NotificationDetails from "./pages/Notifications/NotificationDetails";
import NearbyDrivers from "./pages/NearbyDrivers/NearbyDriver";
import TripHistoryMap from "./pages/tripHistory/TripHistoryMap";
export default function App() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [amountType, setAmountType] = useState<any>("Today");
  const [totalSale, setTotalSale] = useState<any>(0);
  const [details, setDetails] = useState<any>([]);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  //sockets and connection
  const [connection, setConnection] = useState(connectionEnum.connected);
  const [hideAlert, setHideAlert] = useState(false);
  const [alertDisplay, setAlertDisplay] = useState("Connecting ...");

  const switcherName = (value: any) => {
    switch (value) {
      case "Connected to the internet":
        return "success";
      case "Connection lost":
        return "error";
      default:
        return "warning";
    }
  };

  useEffect(() => {
    const connectionSubscription = socketConnection.listener$.subscribe(
      (response) => {
        setHideAlert(false);
        if (response["listener"] == "connection") {
          setConnection(response.data.stage);
        }
      }
    );

    return () => {
      //stop subscriptions
      connectionSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    switch (connection) {
      case connectionEnum.connected:
        setAlertDisplay("Connected to the internet");
        break;

      case connectionEnum.disconnected:
        setAlertDisplay("Connection lost");
        break;

      case connectionEnum.reconnecting:
        setAlertDisplay("Unstable internet, reconnecting...");
        break;
    }

    if (connection === connectionEnum.connected) {
      setTimeout(() => {
        setHideAlert(true);
      }, 5000);
    }
  }, [connection]);

  //get user data
  const GetUserData = () => {
    BaseService.get_api(global_variables.uid)
      .then((response) => {
        setDetails(response.data.payload);
        // console.log(response.data.payload);
      })
      .catch((error: any) => {
        console.log("error from app.tsx", error);
      });
  };

  //get total sales
  const getTotalSales = () => {
    setDataLoaded(false);
    let urlTxt = "";

    if (amountType === "This Week") {
      const weekBegin = moment().startOf("week").format("YYYY-MM-DD");
      const weekEnd = moment().endOf("week").format("YYYY-MM-DD");
      urlTxt = `${global_variables.getTransaction}?filter={"condition":"range","FLT_Parameter":"${weekBegin}","FGT_Parameter":"${weekEnd}"}&limit=1`;
    } else if (amountType === "This Month") {
      const monthBegin = moment().startOf("month").format("YYYY-MM-DD");
      const monthEnd = moment().endOf("month").format("YYYY-MM-DD");
      urlTxt = `${global_variables.getTransaction}?filter={"condition":"range","FLT_Parameter":"${monthBegin}","FGT_Parameter":"${monthEnd}"}&limit=1`;
    } else if (amountType === "This Year") {
      const yearBegin = moment().startOf("year").format("YYYY-MM-DD");
      const yearEnd = moment().endOf("year").format("YYYY-MM-DD");
      urlTxt = `${global_variables.getTransaction}?filter={"condition":"range","FLT_Parameter":"${yearBegin}","FGT_Parameter":"${yearEnd}"}&limit=1`;
    } else if (amountType === "Today") {
      const dayBegin = moment().startOf("day").format("YYYY-MM-DD HH:MM:SS");
      const dayEnd = moment().endOf("day").format("YYYY-MM-DD HH:MM:SS");
      urlTxt = `${global_variables.getTransaction}?filter={"condition":"range","FLT_Parameter":"${dayBegin}","FGT_Parameter":"${dayEnd}"}&limit=1`;
    }

    BaseService.get_api(urlTxt)
      .then((res) => {
        // console.log(res)
        if (res.data.details !== null) {
          setTotalSale(res.data.details.total_amount);
        } else {
          setTotalSale(0);
        }
        setDataLoaded(true);
      })
      .catch((err) => {
        // displayErrMsg(err?.error)
      });
  };

  useEffect(() => {
    getTotalSales();
    GetUserData();
  }, [amountType]);

  useEffect(() => {
    if (audioEnabled) {
      const audio = new Audio("/sms-alert.mp3");
      audioRef.current = audio;
      audio.play().catch((error) => {
        console.log("Audio play was prevented:", error);
      });
    }
  }, [audioEnabled]);

  const handleEnableAudio = () => {
    setAudioEnabled(true);
    const audio = new Audio("/sms-alert.mp3");
    audio.play().catch((error) => {
      console.log("Audio play was prevented:", error);
    });
  };
  return (
    <>
      <SubMenuProvider>
        <TotalSalesContext.Provider
          value={{
            dataLoaded,
            amountType,
            setAmountType,
            totalSale,
            details,
            setDetails,
          }}
        >
          <PhotoProvider>
            <Router>
              <Routes>
                <Route path="/*" element={<h4>Page not found...</h4>} />
                {/* <Route path="/" element={<SplashScreen />} /> */}
                <Route path="/" element={<SignIn />} />

                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/triphistory"
                  element={
                    <ProtectedRoute>
                      <TripHistory />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/trip-history-map/:tripID/:driverId"
                  element={
                    <ProtectedRoute>
                      <TripHistoryMap />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/schedules"
                  element={
                    <ProtectedRoute>
                      <AllSchedules />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/tracking/:id"
                  element={
                    <ProtectedRoute>
                      <Tracking />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/tracking"
                  element={
                    <ProtectedRoute>
                      <Tracking />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <ProtectedRoute>
                      <Users />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/userdetail"
                  element={
                    <ProtectedRoute>
                      <UserDetail />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/driverdetail/:id"
                  element={
                    <ProtectedRoute>
                      <DriverDetail />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/fleetdetail"
                  element={
                    <ProtectedRoute>
                      <FleetDetail />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/administration"
                  element={
                    <ProtectedRoute>
                      <Administration />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/transactions"
                  element={
                    <ProtectedRoute>
                      <Transactions />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/earnings"
                  element={
                    <ProtectedRoute>
                      <YarpEarning />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/support"
                  element={
                    <ProtectedRoute>
                      <Support />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/sendsms"
                  element={
                    <ProtectedRoute>
                      <SendSMS />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/notifications"
                  element={
                    <ProtectedRoute>
                      <Notifications audioEnabled={audioEnabled} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/notifications/:param1/:param2"
                  element={
                    <ProtectedRoute>
                      <NotificationDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/sms-providers"
                  element={
                    <ProtectedRoute>
                      <SmsProviders />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/declineddocs"
                  element={
                    <ProtectedRoute>
                      <DeclinedDocs />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/drafted-drivers"
                  element={
                    <ProtectedRoute>
                      <DraftedDrivers />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-yarp"
                  element={
                    <ProtectedRoute>
                      <ManageYarp />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/trucks"
                  element={
                    <ProtectedRoute>
                      <AllTrucks />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/notifications/:param1/:param2/:param3/:param4"
                  element={
                    <ProtectedRoute>
                      <NearbyDrivers />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <UserProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/earnings"
                  element={
                    <ProtectedRoute>
                      <YarpEarning />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/unfinished-trips"
                  element={
                    <ProtectedRoute>
                      <UnfinishedTrips />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/company-roles"
                  element={
                    <ProtectedRoute>
                      <AllCompanyRoles />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/mapPage"
                  element={
                    <ProtectedRoute>
                      <MapPage />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Router>
          </PhotoProvider>
        </TotalSalesContext.Provider>
      </SubMenuProvider>
      {/* {!audioEnabled && (
        <button
          onClick={handleEnableAudio}
          className="fixed bottom-4 right-4 bg-blue-500 text-white p-2 rounded"
        >
          Enable Audio Notifications
        </button>
      )} */}
      <div className="fixed bottom-5 z-50 right-5">
        {hideAlert ? (
          <></>
        ) : (
          <Alert
            message={alertDisplay}
            type={(switcherName(alertDisplay) as any) ?? ("warning" as any)}
            showIcon
            closable
          />
        )}
      </div>
    </>
  );
}
