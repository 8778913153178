import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
} from "@vis.gl/react-google-maps";

import { GoogleMap, LoadScript, Polyline } from "@react-google-maps/api";

const MapPage = () => {
  const pathCoordinates = [
    { lat: -34.397, lng: 150.644 },
    { lat: -34.4, lng: 150.644 },
    { lat: -34.4, lng: 150.648 },
    { lat: -34.397, lng: 150.648 },
  ];

  const polylineOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths: pathCoordinates,
    zIndex: 1,
  };
  const position = { lat: 5.6, lng: -0.1773 };

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <APIProvider apiKey="AIzaSyBsQHT8KD6YsEFthLMySGStyBLbGm0KLcs">
        <Map zoom={15} center={position} mapId="539b1f8f16816929">
          <AdvancedMarker position={position}>
            <Pin
              background={"#FBBC04"}
              glyphColor={"#000"}
              borderColor={"#000"}
            />
            <Polyline path={pathCoordinates} options={polylineOptions} />
          </AdvancedMarker>
        </Map>
      </APIProvider>
    </div>
  );
};

export default MapPage;
