import moment from "moment";
import { useEffect } from "react";
import { Badge, Col, Row, Table } from "react-bootstrap";
import { PhotoView } from "react-photo-view";
import NoDataComponent from "../../../components/general/noDataComponent";
import { showFirstWord } from "../../../utils/functions";

interface theProps {
  driverVehicle: any;
}

const DriverVehicleInfoTab = ({ driverVehicle }: theProps) => {
  useEffect(() => {
    console.log(driverVehicle);
  }, []);

  return (
    <>
      <div className="mt-4">
        {driverVehicle !== null ? (
          <>
            <hr />
            <Table>
              <tbody>
                <tr>
                  <th>Images:</th>
                  <td>
                    <div className="flex justify-between gap-[20px]">
                      <div className="w-full">
                        <>Front view</>
                        <PhotoView
                          src={
                            driverVehicle?.truckfront ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                        >
                          <img
                            className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                            src={
                              driverVehicle?.truckfront ||
                              "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                            }
                            alt="front"
                          />
                        </PhotoView>
                      </div>
                      <div className="w-full">
                        <>Side view</>
                        <PhotoView
                          src={
                            driverVehicle?.truckside ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                        >
                          <img
                            className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                            src={
                              driverVehicle?.truckside ||
                              "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                            }
                            alt="side"
                          />
                        </PhotoView>
                      </div>
                      <div className="w-full">
                        <>Back view</>
                        <PhotoView
                          src={
                            driverVehicle?.truckback ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                        >
                          <img
                            className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                            src={
                              driverVehicle?.truckback ||
                              "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                            }
                            alt="back"
                          />
                        </PhotoView>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Registration:</th>
                  <td>{driverVehicle?.truckRegisteration}</td>
                </tr>
                <tr>
                  <th>Ownership:</th>
                  <td>{driverVehicle?.type.toUpperCase()}</td>
                </tr>
                <tr>
                  <th>Brand:</th>
                  <td>{showFirstWord(driverVehicle?.model)}</td>
                </tr>
                <tr>
                  <th>Model:</th>
                  <td>{driverVehicle?.model}</td>
                </tr>
                <tr>
                  <th>Type:</th>
                  <td>{driverVehicle?.car_type}</td>
                </tr>
                <tr>
                  <th>Preferred gooods:</th>
                  <td className="flex flex-wrap gap-2">
                    {driverVehicle?.category_type.map(
                      (d: any, index: number) => {
                        return (
                          <Badge className="ms-1" bg="secondary" key={index}>
                            {d}
                          </Badge>
                        );
                      }
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Weight Load:</th>
                  <td className="flex flex-wrap gap-2">
                    {driverVehicle?.weight_load.map((d: any, index: number) => {
                      return (
                        <Badge className="ms-1" bg="secondary" key={index}>
                          {d}
                        </Badge>
                      );
                    })}
                  </td>
                </tr>

                <tr>
                  <th colSpan={2}>
                    <h4
                      style={{
                        color: "#656565",
                        marginTop: "30px",
                      }}
                    >
                      Documents
                    </h4>
                  </th>
                </tr>
                <tr>
                  <th>Insurance:</th>
                  <td>
                    <Row>
                      <Col>
                        <PhotoView
                          src={
                            driverVehicle?.insurance ||
                            "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                          }
                        >
                          <img
                            className="w-[400px] h-[250px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                            src={driverVehicle?.insurance}
                            alt="Insurance"
                          />
                        </PhotoView>
                      </Col>
                      <Col>
                        <div className="fw-bolder mt-2">Insurance number</div>
                        <div>{driverVehicle?.insurance_text}</div>
                        <div className="fw-bolder mt-3">Expiry Date</div>
                        <div>
                          {moment(driverVehicle?.insurance_expire).format(
                            "Do MMMM YYYY"
                          )}
                        </div>
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th>Roadworthy:</th>
                  <td>
                    <Row>
                      <Col>
                        <PhotoView
                          src={
                            driverVehicle?.roadworthy ||
                            "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                          }
                        >
                          <img
                            className="w-[400px] h-[250px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                            src={driverVehicle?.roadworthy}
                            alt="Roadworthy"
                          />
                        </PhotoView>
                      </Col>
                      <Col>
                        <div className="fw-bolder mt-2">Roadworthy</div>
                        <div>{driverVehicle?.roadworthy_text}</div>
                        <div className="fw-bolder mt-3">Expiry Date</div>
                        <div>
                          {moment(driverVehicle?.roadworthy_expire).format(
                            "Do MMMM YYYY"
                          )}
                        </div>
                      </Col>
                    </Row>
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <div>
            <NoDataComponent text="No vehicle data available..." />
          </div>
        )}
      </div>
    </>
  );
};

export default DriverVehicleInfoTab;
