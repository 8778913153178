import axios from "axios";
import { BASE_URL_V1, BASE_URL_V2, PAYMENT_URL } from "./constants";
import { StorageBox } from "./storage";

const config: any = {
  headers: {
    "Content-Type": "application/json",
  },
};

//Axios Post
const post_api = (route: string, data: any) => {
  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios.post(BASE_URL_V1 + route, data, config);
};
//Axios Post v2
const post_api_v2 = (route: string, data: any) => {
  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios.post(BASE_URL_V2 + route, data, config);
};
//Axios post_for_payment
const post_for_payment = (route: string, data: any) => {
  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios.post(PAYMENT_URL + route, data, config);
};

//Axios Get
const get_api = (route: string) => {
  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios.get(BASE_URL_V1 + route, config);
};

//Axios Get v2
const get_api_v2 = (route: string) => {
  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios.get(BASE_URL_V2 + route, config);
};

//Axios get_for_payment
const get_for_payment = (route: string) => {
  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios.get(PAYMENT_URL + route, config);
};

//Axios Put
const put_api = (route: string, data: any) => {
  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios.put(BASE_URL_V1 + route, data, config);
};

//Axios Put v2
const put_api_v2 = (route: string, data: any) => {
  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios.put(BASE_URL_V2 + route, data, config);
};

//Axios Delete
const delete_api = (route: string, data: any) => {
  var config = {
    method: "delete",
    url: BASE_URL_V1 + route,
    headers: {
      "x-access-token": StorageBox.getAccessToken(),
      "Content-Type": "application/json",
    },
    data: data,
  };

  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios(config);
};

//Axios Delete v2
const delete_api_v2 = (route: string, data: any) => {
  var config = {
    method: "delete",
    url: BASE_URL_V2 + route,
    headers: {
      "x-access-token": StorageBox.getAccessToken(),
      "Content-Type": "application/json",
    },
    data: data,
  };

  config.headers["x-access-token"] = StorageBox.getAccessToken();
  return axios(config);
};

const BaseService = {
  post_api,
  post_api_v2,
  post_for_payment,
  get_api,
  get_api_v2,
  get_for_payment,
  put_api,
  put_api_v2,
  delete_api,
  delete_api_v2,
};

export default BaseService;
