import { SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import shimmer from "../../../images/yarp-spinner.gif";
import {
  Badge,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ChatBubbleLeftRightIcon,
  EyeIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import ShowToast from "../../../components/alerts/all_toasts";
import { sendSmsFunction } from "../../../functions/sendSms";
import { Button, Tooltip } from "@material-tailwind/react";
import { PhotoView } from "react-photo-view";
import { CSVLink } from "react-csv";
import { Empty } from "antd";
import {
  INSURANCESort,
  insurance_expireSort,
  modelSort,
  truckRegisterationSort,
} from "../../../components/general/TableSortFunctions";

/**
 * @author
 * @function ExpiredInsurance
 **/

const ExpiredInsurance = (props: any) => {
  const [limit, setLimit] = useState<any>(10);
  const [skip, setSkip] = useState<any>(0);
  const [totalRows, setTotalRows] = useState<any>(0);
  const [reFetch, setReFetch] = useState<boolean>(false);

  const [dataIn, setDataIn] = useState<boolean>(false);
  const [trucksIns, setTrucksIns] = useState<any>([]);

  const [expiredInsuranceData, setExpiredInsuranceData] = useState<any>();

  const [notifMsg, setNotifMsg] = useState<any>(
    "Dear Partner, The Insurance information uploaded for your vehicle has expired, please update it by logging into your yarp account now. Thank you"
  );

  const [show, setShow] = useState<boolean>(false);
  const [showSendMsg, setShowSendMsg] = useState<boolean>(false);

  //get_insurance
  const get_insurance = async (lim: any, sk: number) => {
    setDataIn(true);
    const date = new Date();
    const year = date.getFullYear();
    // const year = 2021;
    const month = date.getMonth() + 1;
    // const month = 13;
    function padLeadingZeros(num: string | number, size: number) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    }
    const yearMonth = `${year}-${padLeadingZeros(month, 2)}`;

    BaseService.get_api(
      `${global_variables.getunverifiedTrucks}?limit=${lim}&skip=${sk}&filter={"condition":"approved_expire_insurance","insurance":${yearMonth},"parameter":true}`
    )
      .then((res: any) => {
        // console.log(res)//
        var trucks_data_e_ins = res?.data?.payload;
        setTrucksIns(trucks_data_e_ins);
        setTotalRows(res.total);
        setDataIn(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //onload
  useEffect(() => {
    get_insurance(limit, skip);
  }, [reFetch, limit, skip]);

  //table columns
  const columns: any = [
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">
          TRUCK REGISTRATION NO.
        </p>
      ),
      selector: (row: { truckRegisteration: any }) => row?.truckRegisteration,
      sortable: true,
      sortFunction: truckRegisterationSort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">MODEL</p>,
      selector: (row: { model: any }) => row?.model,
      sortable: true,
      sortFunction: modelSort,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">INSURANCE NO.</p>
      ),
      selector: (row: { insurance_text: any }) => row?.insurance_text,
      sortable: true,
      sortFunction: INSURANCESort,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">DATE EXPIRED</p>
      ),
      selector: (row: { insurance_expire: moment.MomentInput }) =>
        moment(row?.insurance_expire).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: insurance_expireSort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">ACTION</p>,
      selector: (row: any, index: any) => (
        <div className="flex gap-3">
          <Link
            to="#"
            onClick={showExpiredInsurance.bind(this, row)}
            style={{ textDecoration: "none", fontSize: "22px", padding: "3px" }}
          >
            <EyeIcon className="h-5 w-5 cursor-pointer" />
          </Link>
          <Link
            to="#"
            onClick={sendMessageExpiredInsurance.bind(this, row)}
            style={{ textDecoration: "none", fontSize: "22px", padding: "3px" }}
          >
            <ChatBubbleLeftRightIcon className="h-5 w-5 cursor-pointer" />
          </Link>
        </div>
      ),
    },
  ];

  //export data
  const formatDataForExport = (data: any[]) => {
    return data.map((row: any) => ({
      "TRUCK NO.": row?.truckRegisteration,
      "TRUCK MODEL.": row?.model,
      "INSURANCE NO.": row?.insurance_text,
      "DATE EXPIRED": moment(row?.insurance_expire).format(
        "Do MMM YYYY - hh:mm A"
      ),
    }));
  };

  const showExpiredInsurance = (row: any) => {
    setShow(true);
    setExpiredInsuranceData(row);
    // console.log(row);
  };

  const sendMessageExpiredInsurance = (row: any) => {
    setExpiredInsuranceData(row);
    setShowSendMsg(true);
    // console.log(row);
  };

  const SendNoticationInsurance = (e: any) => {
    e.preventDefault();

    const phoneNumber = expiredInsuranceData["owner_data"]
      ? expiredInsuranceData["owner_data"]["phone"]
      : null;

    ShowToast.loading_toast();

    var msg_data = {
      message: `${notifMsg}`,
      numbers: [`${phoneNumber}`],
    };

    sendSmsFunction(msg_data);
  };

  const handleRowChange = (a: any) => {
    setLimit(a);
    get_insurance(a, skip);
  };

  const pageChange = (a: number) => {
    if (a === 1) {
      get_insurance(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      get_insurance(limit, newSkip);
      setSkip(newSkip);
    }
  };

  return (
    <>
      <div className="mt-[20px] flex justify-between items-center">
        <Tooltip content="Reload" placement="top">
          <button
            className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
            onClick={() => setReFetch(!reFetch)}
          >
            <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
          </button>
        </Tooltip>
        <div className="flex gap-[10px]">
          <Tooltip content="Print" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <PrinterIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <CSVLink
            data={formatDataForExport(trucksIns)}
            filename="Expired Insurance"
          >
            <Tooltip content="Export" placement="top">
              <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>

      <DataTable
        className="mt-[10px]"
        columns={columns}
        data={trucksIns}
        defaultSortAsc={false}
        highlightOnHover
        progressPending={dataIn}
        progressComponent={
          <Image
            fluid
            src={shimmer}
            style={{ marginTop: "100px", width: "200px" }}
            alt="Loading..."
          />
        }
        noDataComponent={
          <Empty description="No expired insurance at the moment" />
        }
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        pointerOnHover
        onRowClicked={(e) => showExpiredInsurance(e)}
      />

      {/* EXPIRED DETAILS */}
      <Modal
        show={show}
        size="xl"
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Truck Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show ? (
            <Container>
              <Table>
                <tbody>
                  {expiredInsuranceData.owner_data ? (
                    <tr>
                      <th style={{ width: "30%" }}>Owner Information: </th>
                      <td>
                        <Row>
                          <Col md={3}>
                            <PhotoView
                              src={
                                expiredInsuranceData.owner_data.image ||
                                "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                              }
                            >
                              <img
                                className="object-cover border border-gray-500 rounded-full w-[100px] h-[100px] cursor-pointer"
                                src={expiredInsuranceData.owner_data.image}
                                alt="ghCard"
                              />
                            </PhotoView>
                          </Col>
                          <Col md={9}>
                            <p className="fw-bold">
                              Name:{" "}
                              <span className="fw-light">
                                {expiredInsuranceData.owner_data.full_name}
                              </span>{" "}
                            </p>
                            <p className="fw-bold">
                              Contact:{" "}
                              <span className="fw-light">
                                {expiredInsuranceData.owner_data.phone}
                              </span>{" "}
                            </p>
                            <p className="fw-bold">
                              Email:{" "}
                              <span className="fw-light">
                                {expiredInsuranceData.owner_data.email}
                              </span>{" "}
                            </p>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <th style={{ width: "30%" }}>Truck Registration:</th>
                    <td>{expiredInsuranceData["truckRegisteration"]}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Model</th>
                    <td>{expiredInsuranceData["model"]}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Gross Weight</th>
                    <td>{expiredInsuranceData["gross"]}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Type</th>
                    <td>{expiredInsuranceData["type"].toUpperCase()}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Category Of Product</th>
                    <td className="flex flex-wrap gap-2">
                      {expiredInsuranceData?.category_type.map(
                        (d: any, index: any) => {
                          return (
                            <Badge className="ms-1" bg="secondary" key={index}>
                              {d}
                            </Badge>
                          );
                        }
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Insurance Information</th>
                    <td>
                      <Row>
                        <Col md={5}>
                          <PhotoView
                            src={
                              expiredInsuranceData.insurance ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="object-cover border border-gray-500 rounded-[10px] w-full h-[250px] cursor-pointer"
                              src={expiredInsuranceData.insurance}
                              alt="ins"
                            />
                          </PhotoView>
                        </Col>
                        <Col md={7}>
                          <p>
                            {" "}
                            <span className="fw-bold">Insurance Number: </span>
                            {expiredInsuranceData["insurance_text"]}{" "}
                          </p>
                          <p>
                            {" "}
                            <span className="fw-bold">Date Issued:</span>{" "}
                            {moment(expiredInsuranceData["dateIssued"]).format(
                              "MMM Do YYYY"
                            )}
                          </p>
                          <p>
                            {" "}
                            <span className="fw-bold">Expiry Date:</span>{" "}
                            {moment(
                              expiredInsuranceData["insurance_expire"]
                            ).format("MMM Do YYYY")}
                          </p>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Truck Images: </th>
                    <td>
                      <Row>
                        <Col md={4}>
                          <h5>Front: </h5>
                          <PhotoView
                            src={
                              expiredInsuranceData.truckfront ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="object-cover border border-gray-500 rounded-[10px] w-full h-[200px] cursor-pointer"
                              src={expiredInsuranceData.truckfront}
                              alt="front"
                            />
                          </PhotoView>
                        </Col>
                        <Col md={4}>
                          <h5>Side: </h5>
                          <PhotoView
                            src={
                              expiredInsuranceData.truckside ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="object-cover border border-gray-500 rounded-[10px] w-full h-[200px] cursor-pointer"
                              src={expiredInsuranceData.truckside}
                              alt="side"
                            />
                          </PhotoView>
                        </Col>
                        <Col md={4}>
                          <h5>Back: </h5>
                          <PhotoView
                            src={
                              expiredInsuranceData.truckside ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="object-cover border border-gray-500 rounded-[10px] w-full h-[200px] cursor-pointer"
                              src={expiredInsuranceData.truckside}
                              alt="back"
                            />
                          </PhotoView>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>

      {/* Send Message Modal */}
      <Modal
        show={showSendMsg}
        onHide={() => {
          setShowSendMsg(false);
        }}
      >
        <Form onSubmit={SendNoticationInsurance}>
          <Modal.Header closeButton>
            <Modal.Title>Send Reminder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {showSendMsg ? (
                <>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="contactNumber"
                      value={
                        expiredInsuranceData["owner_data"]
                          ? expiredInsuranceData["owner_data"]["phone"]
                          : "---"
                      }
                      readOnly
                    />
                    <label htmlFor="contactNumber">Contact</label>
                  </div>
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="expired_ins_message"
                      style={{ height: "100px" }}
                      value={notifMsg}
                      onChange={(e) => setNotifMsg(e.target.value)}
                    ></textarea>
                    <label htmlFor="expired_ins_message">Message</label>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="red"
              onClick={() => {
                setShowSendMsg(false);
              }}
            >
              Close
            </Button>
            <Button
              color="green"
              type="submit"
              onClick={() => {
                setShowSendMsg(false);
              }}
            >
              Send
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ExpiredInsurance;
