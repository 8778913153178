import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  AdjustmentsHorizontalIcon,
  BanknotesIcon,
  Bars3CenterLeftIcon,
  BookOpenIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  ComputerDesktopIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  ListBulletIcon,
  MapPinIcon,
  RssIcon,
  ShieldExclamationIcon,
  Squares2X2Icon,
  TruckIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import yarpLogo from "../../images/logo-orange.png";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import TotalSales from "../general/total_sales";
import { AiOutlineLineChart } from "react-icons/ai";
import { LiaTeamspeak } from "react-icons/lia";
import { PiRoadHorizonBold } from "react-icons/pi";
import { useSubMenu } from "../../context/subMenuContext";
import {
  HiOutlineBuildingOffice2,
  HiOutlineClipboardDocumentList,
} from "react-icons/hi2";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { BsHddNetwork } from "react-icons/bs";
import { IoIosNotificationsOutline } from "react-icons/io";
import { AiOutlineNotification } from "react-icons/ai";
import { PiNotification } from "react-icons/pi";
import socketConnection from "../../sockets/connection";
import { BASE_URL_V2 } from "../../helpers/constants";
import { StorageBox } from "../../helpers/storage";
import useSocket from "./useSocket";
import { Badge } from "antd";
const { io } = require("socket.io-client");

interface sideBarProps {
  dashActive?: any;
  tripsActive?: any;
  trackingActive?: any;
  usersActive?: any;
  adminActive?: any;
  transActive?: any;
  earningsActive?: any;
  supportActive?: any;
  mgtActive?: any;
  smsActive?: any;
  providerActive?: any;
  decActive?: any;
  trucksActive?: any;
  scehduleActive?: any;
  draftedActive?: any;
  unfinished?: any;
  companiesActive?: any;
  rolesActive?: any;
  audioEnabled?: any;
  NotificationsActive?: any;
}

const CustomSideBar = ({
  dashActive,
  tripsActive,
  trackingActive,
  usersActive,
  adminActive,
  transActive,
  earningsActive,
  supportActive,
  trucksActive,
  mgtActive,
  smsActive,
  providerActive,
  decActive,
  scehduleActive,
  draftedActive,
  unfinished,
  companiesActive,
  rolesActive,
  NotificationsActive,
}: sideBarProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isNotified = useSocket();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  useEffect(() => {
    if (isNotified) {
      const audio = new Audio("/sms-alert.mp3");
      audioRef.current = audio;
      audio.play().catch((error) => {
        console.log("Audio play was prevented:", error);
      });

      const timeout = setTimeout(() => {
        audio.pause();
        audioRef.current = null;
      }, 1600);

      return () => {
        clearTimeout(timeout);
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
        }
      };
    }
  }, [isNotified]);

  const {
    accountsSubMenuOpen,
    setAccountsSubMenuOpen,
    tripsSubMenuOpen,
    setTripsSubMenuOpen,
    financeSubMenuOpen,
    setFinanceSubMenuOpen,
    communicationSubMenuOpen,
    setCommunicationSubMenuOpen,
    companiesSubMenu,
    setCompaniesSubMenu,
  } = useSubMenu();

  useEffect(() => {
    const handleDeviceType = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setIsCollapsed(true);
      } else if (width >= 768 && width < 1024) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    handleDeviceType();

    window.addEventListener("resize", handleDeviceType);

    return () => {
      window.removeEventListener("resize", handleDeviceType);
    };
  }, []);

  // useEffect(() => {
  //   console.log("isNotified", isNotified);
  //   console.log("audioEnabled", audioEnabled);
  // }, [isNotified]);
  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Sidebar collapsed={isCollapsed}>
      <Menu>
        <div className="bg-white h-screen">
          {/* logo */}
          <div className="py-4 flex items-center gap-[30px] w-full">
            <Bars3CenterLeftIcon
              className="h-8 w-8 cursor-pointer ml-6"
              onClick={() => handleCollapse()}
            />
            {!isCollapsed && <img className="mt-1" src={yarpLogo} alt="logo" />}
          </div>

          {isCollapsed ? (
            <></>
          ) : (
            <div className="mb-2">
              <TotalSales />
            </div>
          )}

          <div>
            <MenuItem
              icon={<Squares2X2Icon className="w-6 h-6" />}
              component={<Link to="/dashboard" />}
              className={dashActive}
            >
              <p className="text-sm mt-3">Dashboard</p>
            </MenuItem>
            <MenuItem
              icon={<IoIosNotificationsOutline className="w-6 h-6" />}
              component={<Link to="/notifications" />}
              className={NotificationsActive}
            >
              <div className={`flex items-center`}>
                <p className={`text-sm mt-3`}>Notifications</p>
                <div className=" -mt-4 ml-2 ">
                  {isNotified && (
                    <>
                      <p className="animate-ping  bg-[#FF6700] absolute h-1.5 w-1.5  rounded-full  opacity-75"></p>
                      <p className="  bg-red-300 ml-[0.6px] mt-[0.6px] rounded-full  h-1 w-1  "></p>
                    </>
                  )}
                </div>
              </div>
            </MenuItem>

            <MenuItem
              icon={<TruckIcon className="w-6 h-6" />}
              component={<Link to="/trucks" />}
              className={trucksActive}
            >
              <p className="text-sm mt-3">Trucks</p>
            </MenuItem>

            {/* accounts */}
            <SubMenu
              icon={<BookOpenIcon className="w-6 h-6" />}
              label={<p className="text-sm mt-3">User Accounts</p>}
              open={accountsSubMenuOpen}
              onOpenChange={() => setAccountsSubMenuOpen(!accountsSubMenuOpen)}
            >
              <div className="h-auto overflow-hidden">
                <MenuItem
                  icon={<UserGroupIcon className="w-6 h-6" />}
                  component={<Link to="/users" />}
                  className={usersActive}
                >
                  <p className="text-sm mt-3">All Users</p>
                </MenuItem>

                <MenuItem
                  icon={<ListBulletIcon className="w-6 h-6" />}
                  component={<Link to="/drafted-drivers" />}
                  className={draftedActive}
                >
                  <p className="text-sm mt-3">Drafted Drivers</p>
                </MenuItem>

                <MenuItem
                  icon={<ShieldExclamationIcon className="w-6 h-6" />}
                  component={<Link to="/declineddocs" />}
                  className={decActive}
                >
                  <p className="text-sm mt-3">Declined</p>
                </MenuItem>
              </div>
            </SubMenu>

            {/* trips */}
            <SubMenu
              icon={<PiRoadHorizonBold className="w-6 h-6" />}
              label={<p className="text-sm mt-3">Trips</p>}
              open={tripsSubMenuOpen}
              onOpenChange={() => setTripsSubMenuOpen(!tripsSubMenuOpen)}
            >
              <div className="h-auto overflow-hidden">
                <MenuItem
                  icon={<MapPinIcon className="w-6 h-6" />}
                  component={<Link to="/tracking" />}
                  className={trackingActive}
                >
                  <p className="text-sm mt-3">Realtime</p>
                </MenuItem>

                <MenuItem
                  icon={<ClockIcon className="w-6 h-6" />}
                  component={<Link to="/schedules" />}
                  className={scehduleActive}
                >
                  <p className="text-sm mt-3">Schedules</p>
                </MenuItem>

                <MenuItem
                  icon={<ClipboardDocumentCheckIcon className="w-6 h-6" />}
                  component={<Link to="/triphistory" />}
                  className={tripsActive}
                >
                  <p className="text-sm mt-3">Trip History</p>
                </MenuItem>

                <MenuItem
                  icon={<ExclamationTriangleIcon className="w-6 h-6" />}
                  component={<Link to="/unfinished-trips" />}
                  className={unfinished}
                >
                  <p className="text-sm mt-3">Unfinished</p>
                </MenuItem>
              </div>
            </SubMenu>

            {/* finance */}
            <SubMenu
              icon={<AiOutlineLineChart className="w-6 h-6" />}
              label={<p className="text-sm mt-3">Finance</p>}
              open={financeSubMenuOpen}
              onOpenChange={() => setFinanceSubMenuOpen(!financeSubMenuOpen)}
            >
              <div className="h-auto overflow-hidden">
                <MenuItem
                  icon={<CurrencyDollarIcon className="w-6 h-6" />}
                  component={<Link to="/earnings" />}
                  className={earningsActive}
                >
                  <p className="text-sm mt-3">Yarp Earnings</p>
                </MenuItem>

                <MenuItem
                  icon={<BanknotesIcon className="w-6 h-6" />}
                  component={<Link to="/transactions" />}
                  className={transActive}
                >
                  <p className="text-sm mt-3">Transactions</p>
                </MenuItem>
              </div>
            </SubMenu>

            {/* Communication */}
            <SubMenu
              icon={<LiaTeamspeak className="w-6 h-6" />}
              label={<p className="text-sm mt-3">Communication</p>}
              open={communicationSubMenuOpen}
              onOpenChange={() =>
                setCommunicationSubMenuOpen(!communicationSubMenuOpen)
              }
            >
              <div className="h-auto overflow-hidden">
                <MenuItem
                  icon={<RssIcon className="w-6 h-6" />}
                  component={<Link to="/support" />}
                  className={supportActive}
                >
                  <p className="text-sm mt-3">Support</p>
                </MenuItem>

                <MenuItem
                  icon={<BsHddNetwork className="w-6 h-6" />}
                  component={<Link to="/sms-providers" />}
                  className={providerActive}
                >
                  <p className="text-sm mt-3">SMS Providers</p>
                </MenuItem>

                <MenuItem
                  icon={<ChatBubbleLeftRightIcon className="w-6 h-6" />}
                  component={<Link to="/sendsms" />}
                  className={smsActive}
                >
                  <p className="text-sm mt-3">Send SMS</p>
                </MenuItem>
              </div>
            </SubMenu>

            <div className="bg-white">
              <MenuItem
                icon={<AdjustmentsHorizontalIcon className="w-6 h-6" />}
                component={<Link to="/manage-yarp" />}
                className={mgtActive}
              >
                <p className="text-sm mt-3">Manage Yarp</p>
              </MenuItem>

              <MenuItem
                icon={<ComputerDesktopIcon className="w-6 h-6" />}
                component={<Link to="/administration" />}
                className={adminActive}
              >
                <p className="text-sm mt-3">Administration</p>
              </MenuItem>
            </div>

            {/* finance */}
            <SubMenu
              className="bg-white pb-2"
              icon={<HiOutlineBuildingOffice2 className="w-6 h-6" />}
              label={<p className="text-sm mt-3">Companies</p>}
              open={companiesSubMenu}
              onOpenChange={() => setCompaniesSubMenu(!companiesSubMenu)}
            >
              <div className="h-auto overflow-hidden">
                <MenuItem
                  icon={<HiOutlineClipboardDocumentList className="w-6 h-6" />}
                  component={<Link to="#" />}
                  className={companiesActive}
                >
                  <p className="text-sm mt-3">All Companies</p>
                </MenuItem>

                <MenuItem
                  icon={<MdOutlineAdminPanelSettings className="w-6 h-6" />}
                  component={<Link to="/company-roles" />}
                  className={rolesActive}
                >
                  <p className="text-sm mt-3">Roles</p>
                </MenuItem>
              </div>
            </SubMenu>
          </div>
        </div>
      </Menu>
    </Sidebar>
  );
};

export default CustomSideBar;
