import BaseService from "../helpers/baseService";
import { global_variables } from "../helpers/globalVariables";

export const getAllTruckGoods = async () => {
  try {
    const response = await BaseService.get_api(
      `${global_variables.get_products_cats}?limit=1000000`
    );
    return response?.data?.payload;
  } catch (error) {
    return error;
  }
};
