import { useMemo } from "react";
import moment from "moment";
import { Dropdown, MenuProps } from "antd";
import { IoMdMore } from "react-icons/io";
import { BiMessageRoundedDots } from "react-icons/bi";
import { CiEdit } from "react-icons/ci";
import { CgDetailsMore } from "react-icons/cg";
import { PiWarningDiamondLight } from "react-icons/pi";
import useStatusStyle from "./useStatusStyle";
import useProperName from "./useProperName";
import { MapPinIcon } from "@heroicons/react/24/outline";

const useColumns = (
  handleMenuClick: (e: any, row: any, index: any) => void
) => {
  const getStatusStyle = useStatusStyle();
  const displayProperNames = useProperName();

  const columns = useMemo(
    () => [
      {
        width: "25%",
        name: "PICK UP",
        cellExport: (row: { pickup_place_name: any }) => row?.pickup_place_name,
        cell: (row: { pickup_place_name: any }) => row?.pickup_place_name,
      },
      {
        width: "25%",
        name: "DESTINATION",
        cellExport: (row: { delivery_place_name: any }) =>
          row?.delivery_place_name,
        cell: (row: { delivery_place_name: any }) => row?.delivery_place_name,
      },
      {
        name: "STATUS",
        cell: (row: any) => {
          const isEvent =
            row?.current_event_data === null ? (
              ""
            ) : (
              <div
                className="w-full"
                style={{
                  ...getStatusStyle(row?.current_event_data?.status),
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                  borderRadius: "30px",
                  height: "30px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
              >
                {displayProperNames(row?.current_event_data?.status)}
              </div>
            );

          return isEvent;
        },
      },
      {
        name: "DATE & TIME",
        cellExport: (row: { created_at: moment.MomentInput }) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
        cell: (row: { created_at: moment.MomentInput }) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
      },
      {
        name: "",
        width: "5%",
        cell: (row: any, index: any) => {
          const menuItems: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <span className="text-default_ash text-sm">View Tracking</span>
              ),
              icon: <MapPinIcon className="w-5 h-5 cursor-pointer" />,
              onClick: (e) => handleMenuClick(e, row, index),
            },
            {
              key: "2",
              label: (
                <span className="text-default_ash text-sm">Send Message</span>
              ),
              icon: <BiMessageRoundedDots className="w-5 h-5 cursor-pointer" />,
              onClick: (e) => handleMenuClick(e, row, index),
            },
            {
              key: "3",
              label: (
                <span className="text-default_ash text-sm">Change Status</span>
              ),
              icon: <CiEdit className="w-5 h-5 cursor-pointer" />,
              onClick: (e) => handleMenuClick(e, row, index),
            },
            {
              key: "4",
              label: (
                <span className="text-default_ash text-sm">Show Details</span>
              ),
              icon: <CgDetailsMore className="w-5 h-5 cursor-pointer" />,
              onClick: (e) => handleMenuClick(e, row, index),
            },
            {
              key: "5",
              label: (
                <span className="text-default_ash text-sm">
                  UnAssign Driver
                </span>
              ),
              icon: (
                <PiWarningDiamondLight className="w-5 h-5 cursor-pointer" />
              ),
              onClick: (e) => handleMenuClick(e, row, index),
            },
          ];
          return (
            <Dropdown menu={{ items: menuItems }} className="text-[14px]">
              <IoMdMore size={"2em"} />
            </Dropdown>
          );
        },
      },
    ],
    [getStatusStyle, displayProperNames, handleMenuClick]
  );

  return columns;
};

export default useColumns;
