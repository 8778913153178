import { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import BaseService from "../../../helpers/baseService";
import ShowToast from "../../../components/alerts/all_toasts";

const { confirm } = Modal;

interface UpdateStatusModalProps {
  openStatusUpdate: boolean;
  setOpenStatusUpdate: (open: boolean) => void;
  OnRoadData: any;
  StatusOfTrip: string;
  RowID: string;
  handleReftch: any;
}

const statusOptions = [
  // { label: "Pending", bgColor: "#fcf9f0", textColor: "#ECC15D" },
  // { label: "Accept", bgColor: "#f3faf6", textColor: "#77D1A4" },
  { label: "Arrived At PickUp", bgColor: "#eff7fc", textColor: "#52B3EF" },
  { label: "Start Queuing", bgColor: "#fdf0e6", textColor: "#FF6700" },
  { label: "End Queuing", bgColor: "#fdf0e6", textColor: "#FF6700" },
  { label: "Start Loading", bgColor: "#f2f0fe", textColor: "#7D68FE" },
  { label: "End Loading", bgColor: "#F3F9E9", textColor: "#8BCE1C" },
  { label: "Trip Started", bgColor: "#F3F9E9", textColor: "#8BCE1C" },
  { label: "Trip Ended", bgColor: "#FBE7E6", textColor: "#FF0000" },
  { label: "Payment Complete", bgColor: "#fcf9f0", textColor: "#ECC15D" },
  {
    label: "Queuing At Destination",
    bgColor: "#f3faf6",
    textColor: "#ECC15D",
  },
  {
    label: "End Queuing At Destination",
    bgColor: "#FCE4EC",
    textColor: "#C2185B",
  },
  { label: "Start Offloading", bgColor: "#f2f0fe", textColor: "#7D68FE" },
  { label: "End Offloading", bgColor: "#E6EEFB", textColor: "#0A67F3" },
  { label: "Completed", bgColor: "#eff7fc", textColor: "#52B3EF" },
  { label: "Cancel", bgColor: "#E6EEFB", textColor: "#0A67F3" },
];

const UpdateStatusModal = ({
  openStatusUpdate,
  StatusOfTrip,
  RowID,
  setOpenStatusUpdate,
  OnRoadData,
  handleReftch,
}: UpdateStatusModalProps) => {

  const [loading, setLoading] = useState(false);
  const [tripData, setTripData] = useState<any>();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const [label, setLabel] = useState<string | null>(null);

  useEffect(() => {
    setTripData(OnRoadData);
    setClickedIndex(
      statusOptions.findIndex((status) => status?.label === StatusOfTrip)
    );
  }, [OnRoadData, StatusOfTrip]);

  const updateTripStatus = async () => {
    if (!tripData || clickedIndex === null) return;

    setLoading(true);
    try {
      const {
        _id: trip_id,
        userid,
        driverId: driver,
      } = tripData.find((trip: any) => trip._id === RowID);
      const payload = {
        trip_id,
        status: label,
        userid,
        driver,
      };

      await BaseService.put_api_v2("/yarp/trip/event", payload);
      ShowToast.success_toast("Updated successfully");
      setOpenStatusUpdate(false);
      handleReftch();
    } catch (error) {
      console.error("Failed to update trip status:", error);
      ShowToast.info_toast("Failed to update status");
    } finally {
      setLoading(false);
    }
  };

  const handleOk = () => {
    if (label === "Completed" || "Cancel" || "Payment Complete") {
      confirm({
        title:
          label === "Completed" || "Payment Complete"
            ? `Are you sure you want to mark this trip as ${label}?`
            : "Are you sure you want cancel this Trip?",
        okButtonProps: {
          style: {
            backgroundColor: "#fdf0e6",
            borderColor: "#FF6700",
            color: "#FF6700",
            boxShadow: "none",
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: "#FFFFFF",
            borderColor: "#d9d9d9",
            color: "#000000",
            boxShadow: "none",
          },
        },
        icon: <ExclamationCircleOutlined />,
        content:
          label === "Completed" || "Payment Complete"
            ? `Once the trip is marked as ${label}, this action cannot be undone.`
            : "Once the trip is cancelled, it cannot be undone.",
        onOk: () => updateTripStatus(),
      });
    } else {
      updateTripStatus();
    }
  };

  useEffect(() => {
    console.log("statusOptions", statusOptions);
  }, []);

  const handleCancel = () => setOpenStatusUpdate(false);

  return (
    <Modal
      width="20%"
      open={openStatusUpdate}
      onOk={handleOk}
      confirmLoading={loading}
      okText="Update Status"
      onCancel={handleCancel}
      okButtonProps={{
        style: {
          backgroundColor: "#77D1A4",
          color: "#FFFFFF",
          boxShadow: "none",
        },
        // disabled: !StatusOfTrip,
      }}
      cancelButtonProps={{ style: { boxShadow: "none" } }}
    >
      <Spin
        spinning={loading}
        indicator={
          <div className="flex justify-center items-center">
            <LoadingDataComponent />
          </div>
        }
      >
        <div>
          <div className="text-[20px]">Change Status</div>
          <section className="my-6  flex flex-col">
            {statusOptions.map((status, index) => (
              <div
                key={index}
                className="text-[14px]   px-4 py-[4px]  mb-[8px] rounded-[30px] "
                style={{
                  backgroundColor: status.bgColor,
                  width: "fit-content",
                  color: status.textColor,
                  border:
                    clickedIndex === index
                      ? `2px solid ${status.textColor}`
                      : "none",
                  cursor: "pointer",
                  opacity: hoveredIndex === index ? 0.8 : 1,
                }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => {
                  setClickedIndex(index);
                  setLabel(status.label);
                }}
              >
                {status.label}
              </div>
            ))}
          </section>
        </div>
      </Spin>
    </Modal>
  );
};

export default UpdateStatusModal;
