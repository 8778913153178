import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  AdvancedMarker,
  InfoWindow,
  Marker,
  useMap,
} from "@vis.gl/react-google-maps";
import moment from "moment";

const HistoryAdvanceMarker = forwardRef(
  ({ getTripFootPrint }: { getTripFootPrint: any }, ref) => {
    const map = useMap();
    const [markerPosition, setMarkerPosition] = useState<any>();
    const [previousPosition, setPreviousPosition] = useState<any>(null);
    const [currentData, setCurrentData] = useState<any>(null);
    const [rotation, setRotation] = useState<number>(0);

    const markerRef = useRef<google.maps.Marker | null>(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const animationSpeed = 1000;

    const animationFrameId = useRef<any>(null);
    const currentIndex = useRef(0);
    const isPaused = useRef(false);
    const startTime = useRef<number | null>(null);

    const pausedLatLng = useRef({ lat: 0, lng: 0 });

    useEffect(() => {
      if (getTripFootPrint && getTripFootPrint.length > 0) {
        setMarkerPosition({
          lat: getTripFootPrint[0].latitude,
          lng: getTripFootPrint[0].longitude,
        });
        setCurrentData(getTripFootPrint[0]);
      }
    }, [getTripFootPrint]);

    const moveMarkerSmoothly = (
      from: { lat: number; lng: number },
      to: { lat: number; lng: number },
      duration: number
    ) => {
      const startLat = from.lat;
      const startLng = from.lng;
      const deltaLat = to.lat - from.lat;
      const deltaLng = to.lng - from.lng;

      // Calculate the heading (rotation) between the two points
      const heading = google.maps.geometry.spherical.computeHeading(
        new google.maps.LatLng(from.lat, from.lng),
        new google.maps.LatLng(to.lat, to.lng)
      );
      setRotation(heading);

      const animateStep = (timestamp: number) => {
        if (!startTime.current) {
          startTime.current = timestamp;
        }
        let elapsedTime = timestamp - startTime.current;

        if (isPaused.current) {
          cancelAnimationFrame(animationFrameId.current!);
          return;
        }

        const progress = Math.min(elapsedTime / duration, 1);
        const currentLat = startLat + deltaLat * progress;
        const currentLng = startLng + deltaLng * progress;

        setPreviousPosition({ lat: startLat, lng: startLng });
        setMarkerPosition({ lat: currentLat, lng: currentLng });

        if (progress < 1) {
          animationFrameId.current = requestAnimationFrame(animateStep);
        } else {
          currentIndex.current++;
          if (currentIndex.current < getTripFootPrint.length - 1) {
            startTime.current = null;
            moveMarkerSmoothly(
              {
                lat: getTripFootPrint[currentIndex.current].latitude,
                lng: getTripFootPrint[currentIndex.current].longitude,
              },
              {
                lat: getTripFootPrint[currentIndex.current + 1].latitude,
                lng: getTripFootPrint[currentIndex.current + 1].longitude,
              },
              animationSpeed
            );
            setCurrentData(getTripFootPrint[currentIndex.current + 1]);
          } else {
            setIsAnimating(false);
          }
        }
      };

      animationFrameId.current = requestAnimationFrame(animateStep);
    };

    const handleStartAnimation = () => {
      if (isAnimating || getTripFootPrint.length === 0) return;

      setIsAnimating(true);
      isPaused.current = false;
      currentIndex.current = 0;
      startTime.current = null;

      moveMarkerSmoothly(
        {
          lat: getTripFootPrint[0].latitude,
          lng: getTripFootPrint[0].longitude,
        },
        {
          lat: getTripFootPrint[1].latitude,
          lng: getTripFootPrint[1].longitude,
        },
        animationSpeed
      );
      setCurrentData(getTripFootPrint[1]);
    };

    const pauseAnimation = () => {
      if (isAnimating) {
        isPaused.current = true;
        cancelAnimationFrame(animationFrameId.current!);

        pausedLatLng.current = {
          lat: markerPosition.lat,
          lng: markerPosition.lng,
        };
      }
    };

    const continueAnimation = () => {
      if (isPaused.current) {
        isPaused.current = false;
        setIsAnimating(true);

        if (currentIndex.current < getTripFootPrint.length - 1) {
          moveMarkerSmoothly(
            pausedLatLng.current,
            {
              lat: getTripFootPrint[currentIndex.current + 1].latitude,
              lng: getTripFootPrint[currentIndex.current + 1].longitude,
            },
            animationSpeed
          );
          setCurrentData(getTripFootPrint[currentIndex.current + 1]);
        }
      }
    };

    useEffect(() => {
      return () => {
        cancelAnimationFrame(animationFrameId.current!);
      };
    }, []);

    useEffect(() => {
      if (!map || !getTripFootPrint) return;

      const latlngPath = getTripFootPrint.map((point: any) => ({
        lat: point.latitude,
        lng: point.longitude,
      }));

      const flightPath = new google.maps.Polyline({
        path: latlngPath,
        geodesic: true,
        strokeColor: "#52B3EF",
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });

      flightPath.setMap(map);

      return () => {
        flightPath.setMap(null);
      };
    }, [map, getTripFootPrint]);

    useImperativeHandle(ref, () => ({
      handleStartAnimation,
      pauseAnimation,
      continueAnimation,
    }));

    return (
      <>
        {markerPosition && currentData && (
          <>
            {/* Previous Marker */}
            {previousPosition && (
              <Marker
                position={previousPosition}
                icon={{
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 5,
                  fillColor: "blue",
                  fillOpacity: 0.6,
                  strokeWeight: 0,
                }}
              />
            )}
            <Marker
              position={markerPosition}
              icon={{
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 5,
                rotation: rotation,
                fillColor: "red",
                fillOpacity: 0.8,
                strokeWeight: 0,
              }}
            />
            <InfoWindow position={markerPosition}>
              <div
                style={{
                  width: "150px",
                  height: "90px",
                  padding: "10px",
                  backgroundColor: "rgba(255, 103, 0, 0.3)",
                  borderRadius: "8px",
                  color: "black",
                }}
              >
                <p
                  style={{
                    fontSize: "10px",
                    margin: "2px 0",
                    fontWeight: "700",
                  }}
                >
                  Date:
                  {moment(currentData.timestamp).format("DD-MM-YYYY")}
                </p>
                <p
                  style={{
                    fontSize: "10px",
                    margin: "2px 0",
                    fontWeight: "700",
                  }}
                >
                  Time:
                  {moment(currentData.timestamp).format("hh:mm A")}
                </p>
                <p
                  style={{
                    fontSize: "10px",
                    margin: "2px 0",
                    fontWeight: "700",
                  }}
                >
                  Speed: {Math.round(currentData.speed * 3.6)} km/h
                </p>
              </div>
            </InfoWindow>
          </>
        )}
      </>
    );
  }
);

export default HistoryAdvanceMarker;
