import { useContext } from "react";
import TotalSalesContext from "../../context/totalSalesContext";
import { formatCedis } from "../../utils/formatCedis";
import { Option, Select, Typography } from "@material-tailwind/react";

const TotalSales = () => {
  const { dataLoaded, amountType, setAmountType, totalSale } =
    useContext(TotalSalesContext);
  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <Typography className="font-medium">Total Sales</Typography>
        <div className="relative">
          <Select
            label="Select"
            value={amountType}
            onChange={(val) => setAmountType(val)}
          >
            <Option value="Today">Today</Option>
            <Option value="This Week">This Week</Option>
            <Option value="This Month">This Month</Option>
            <Option value="This Year">This Year</Option>
          </Select>

          {/* <select
            className="cursor-pointer border-[1px] border-yarpBlue focus:outline-none h-[40px] rounded-[30px] w-[140px] text-center pr-5"
            value={amountType}
            onChange={(e) => setAmountType(e.target.value)}
          >
            <option value="Today">Today</option>
            <option value="This Week">This Week</option>
            <option value="This Month">This Month</option>
            <option value="This Year">This Year</option>
          </select> */}
        </div>
        <Typography className="text-yarpBlue font-semibold mt-3">
          {dataLoaded ? <>{formatCedis(totalSale)}</> : <>---</>}
        </Typography>
      </div>
    </>
  );
};

export default TotalSales;
