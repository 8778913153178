import moment from "moment";
import { useEffect, useState } from "react";
import {
  FloatingLabel,
  Form,
  InputGroup,
  ListGroup,
  Modal,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Button } from "@material-tailwind/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Empty } from "antd";

const Updates = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [cservice, setHelps] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  //getUpdates
  const getUpdates = (lim: any, sk: any) => {
    setLoading(true);

    BaseService.get_api_v2(
      `${global_variables.get_updates}?sort={"updated_at":-1}&limit=${lim}&skip=${sk}`
    )
      .then((res) => {
        setLoading(false);
        setHelps(res?.data?.payload);
        setTotal(res?.data?.total);
        console.log(res?.data?.payload[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //onload
  useEffect(() => {
    getUpdates(limit, skip);
  }, [limit, skip]);

  //table columns
  const columns: any = [
    { name: "Build", cell: (row: { build: any }) => row?.build },
    { name: "Version", cell: (row: { version: any }) => row?.version },
    { name: "Package", cell: (row: { package: any }) => row?.package },
    { name: "Platform", cell: (row: { platform: any }) => row?.platform },
    {
      name: "Date",
      cell: (row: { created_at: moment.MomentInput }) =>
        moment(row?.created_at).format("Do MMMM YYYY"),
    },
    {
      name: "Updates",
      cell: (row: { update: any[] }) => (
        <ul style={{ listStyle: "none" }}>
          {row.update?.map((d, index) => {
            return (
              <li key={index} className="mb-2 flex gap-1 items-center">
                <CheckIcon className="h-3 w-3" /> {d}
              </li>
            );
          })}
        </ul>
      ),
      width: "30%",
    },
  ];

  const handleRowChange = (a: any) => {
    getUpdates(a, 0);
    setLimit(a);
    setSkip(0);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getUpdates(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getUpdates(limit, newSkip);
      setSkip(newSkip);
    }
  };

  const [show, setShow] = useState(false);
  const [version, setVersion] = useState("");
  const [build, setBuild] = useState("");
  const [platform, setPlatform] = useState("");
  const [pack, setPackage] = useState("");
  const [lastforce, setLastforce] = useState("");
  const [message, setMessage] = useState("");
  const [listOfUpdates, setListOfUpdates] = useState<any[]>([]);
  const [link, setLink] = useState("");

  const [update, setUpdate] = useState<any>("");

  //postUpdate
  const postUpdate = async (e: any) => {
    e.preventDefault();

    const postData = {
      version: version,
      build: parseInt(build),
      platform: platform,
      package: pack,
      message: message,
      lastforce: parseInt(lastforce),
      link: link,
      update: listOfUpdates,
    };

    console.log("---forUpdate:", postData);

    ShowToast.loading_toast();

    BaseService.post_api_v2(global_variables.create_updates, postData)
      .then((res) => {
        ShowToast.success_toast(res?.data?.message);
        getUpdates(limit, skip);
        setLink("");
        setListOfUpdates([]);
        setMessage("");
        setLastforce("");
        setPackage("");
        setBuild("");
        setBuild("");
        setVersion("");
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
        ShowToast.error_toast(err?.error);
      });
  };

  const addUpdate = () => {
    if (update !== "") {
      setListOfUpdates([update, ...listOfUpdates]);
      setUpdate("");
    }
  };

  // deleteUpdate
  const deleteUpdate = (i: any) => {
    console.log(i);
    listOfUpdates.splice(i, 1);

    if (update === "") {
      setUpdate(" ");
    } else {
      setUpdate("");
    }
  };

  return (
    <>
      <div className="text-end mb-3">
        <Button
          variant="gradient"
          color="blue"
          onClick={() => {
            setShow(true);
          }}
        >
          + Create New
        </Button>
      </div>

      <div>
        <DataTable
          data={cservice}
          columns={columns}
          progressPending={loading}
          progressComponent={<LoadingDataComponent />}
          noDataComponent={<Empty description="No data" />}
          pagination
          paginationServer
          paginationTotalRows={total}
          onChangeRowsPerPage={handleRowChange}
          onChangePage={pageChange}
        />
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Post New Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FloatingLabel controlId="Version" label="Version" className="mb-3">
              <Form.Control
                type="text"
                placeholder="1.0"
                value={version}
                onChange={(e) => {
                  setVersion(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel controlId="Build" label="Build" className="mb-3">
              <Form.Control
                type="number"
                placeholder="1.1.1"
                value={build}
                onChange={(e) => {
                  setBuild(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="LastForce"
              label="Last Force"
              className="mb-3"
            >
              <Form.Control
                type="number"
                placeholder="0"
                value={lastforce}
                onChange={(e) => {
                  setLastforce(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel controlId="Package" label="Package" className="mb-3">
              <Form.Control
                type="text"
                placeholder="0"
                value={pack}
                onChange={(e) => {
                  setPackage(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="Link"
              label="Update link"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="0"
                value={link}
                onChange={(e) => {
                  setLink(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="Platform"
              label="Platform"
              className="mb-3"
            >
              <Form.Select
                aria-label="Select the platform for which this update is directed"
                value={platform}
                onChange={(e) => {
                  setPlatform(e.target.value);
                }}
              >
                <option>Select Platform</option>
                <option value="android">Android</option>
                <option value="ios">IOS</option>
                <option value="web">Web</option>
              </Form.Select>
            </FloatingLabel>

            <FloatingLabel
              controlId="Messaage"
              label="Messaage"
              className="mb-3"
            >
              <Form.Control
                as="textarea"
                placeholder="Leave a message here"
                style={{ height: "100px" }}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </FloatingLabel>

            {listOfUpdates.length === 0 ? null : (
              <ListGroup className="mb-3">
                <ListGroup.Item disabled>Updates</ListGroup.Item>
                {listOfUpdates.map((d, index) => {
                  return (
                    <ListGroup.Item key={index}>
                      <i
                        className="fa fa-times text-danger"
                        onClick={deleteUpdate.bind(this, index)}
                      ></i>
                      {d}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            )}

            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Update"
                aria-label="Update"
                aria-describedby="basic-addon2"
                value={update}
                onChange={(e) => {
                  setUpdate(e.target.value);
                }}
              />
              <Button
                type="button"
                variant="outlined"
                id="add_update_list"
                onClick={addUpdate}
              >
                +
              </Button>
            </InputGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="gradient" color="blue" onClick={postUpdate}>
            Post
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Updates;
