import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { Form, Image } from "react-bootstrap";
import BaseService from "../../helpers/baseService";
import { global_variables } from "../../helpers/globalVariables";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import { formatCedis } from "../../utils/formatCedis";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { CSVLink } from "react-csv";
import { Tooltip } from "@material-tailwind/react";
import NewDatePickerRange from "../../components/shared/newDatePicker/NewDatePicker";
import {
  BALANCESort,
  DriverNameSort,
  GoodsOwnerSort,
  TimeStampSort,
  method,
} from "../../components/general/TableSortFunctions";
const TransactionTable = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [transactions, setTransactions] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);

  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [condition, setCondition] = useState<string>("all");
  const [fltstate, setFltstate] = useState<any>(
    moment().startOf("year").format("YYYY-MM-DD")
  );
  const [fgtstate, setFgtstate] = useState<any>(
    moment().endOf("year").format("YYYY-MM-DD")
  );

  const columns: any = [
    {
      name: "Driver Name",
      cell: (row: any) => (
        <div className="flex gap-2 items-center">
          <Image
            src={row.driver_data?.image}
            fluid
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              borderRadius: "100%",
              marginRight: "10px",
            }}
          />
          {row.driver_data?.full_name}
        </div>
      ),
      width: "20%",
      sortable: true,
      sortFunction: DriverNameSort,
    },
    {
      name: "Goods Owner",
      cell: (row: any) => (
        <div className="flex gap-2 items-center">
          <Image
            src={row.user_data?.image}
            fluid
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              borderRadius: "100%",
              marginRight: "10px",
            }}
          />
          {row.user_data?.full_name}
        </div>
      ),
      width: "20%",
      sortable: true,
      sortFunction: GoodsOwnerSort,
    },
    {
      name: "Date",
      cell: (row: { timestamp: moment.MomentInput }) =>
        moment(row.timestamp).format("Do MMM YYYY"),
      width: "15%",
      sortable: true,
      sortFunction: TimeStampSort,
    },
    {
      name: "Time",
      cell: (row: { timestamp: moment.MomentInput }) =>
        moment(row.timestamp).format("hh:mm a"),
      width: "15%",
      sortable: true,
      sortFunction: TimeStampSort,
    },
    {
      name: "Amount",
      cell: (row: { amount: any }) => formatCedis(row.amount),
      width: "15%",
      sortable: true,
      sortFunction: BALANCESort,
    },
    {
      name: "Payment Method",
      cell: (row: { method: any }) => row.method,
      width: "15%",
      sortable: true,
      sortFunction: method,
    },
  ];

  //export data
  const formatDataForExport = (data: any[]) => {
    return data.map((row: any) => ({
      "TRUCK NO.": row?.truckRegisteration,
      MODEL: row?.model,
      "VEHICLE TYPE": row?.car_type,
      "OWNED BY": row?.owner_data?.full_name,
      "DATE CREATED": moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
      STATUS: row?.approved ? "Approved" : "Pending",
    }));
  };

  //get transactions
  const getTransactions = async (
    condition: string,
    flt: string,
    fgt: string,
    lim: number,
    sk: number
  ) => {
    setLoading(true);

    let url = `${global_variables.getTransaction}?filter={"condition":"${condition}"}&sort={"timestamp":-1}&limit=${lim}&skip=${sk}`;
    if (flt && fgt) {
      url = `${global_variables.getTransaction}?filter={"condition":"${condition}","FLT_Parameter":"${flt}","FGT_Parameter":"${fgt}"}&sort={"timestamp":-1}&limit=${lim}&skip=${sk}`;
    }

    BaseService.get_api(url)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setTotal(res?.data?.total);
        setTransactions(res?.data?.payload);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowChange = (a: any) => {
    getTransactions(condition, fltstate, fgtstate, a, 0);
    setLimit(a);
    setSkip(0);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getTransactions(condition, fltstate, fgtstate, limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getTransactions(condition, fltstate, fgtstate, limit, newSkip);
      setSkip(newSkip);
    }
  };

  useEffect(() => {
    getTransactions(condition, fltstate, fgtstate, limit, skip);
  }, [condition, fltstate, fgtstate, limit, skip]);

  const reloadTable = () => {
    getTransactions(
      "all",
      moment().startOf("year").format("YYYY-MM-DD"),
      moment().endOf("year").format("YYYY-MM-DD"),
      10,
      0
    );
    setCondition("all");
    setFltstate(moment().startOf("year").format("YYYY-MM-DD"));
    setFgtstate(moment().endOf("year").format("YYYY-MM-DD"));
    setLimit(10);
    setSkip(0);
  };
  const onDateDataHandler = (dates: any) => {
    const { start, end } = dates;
    getTransactions("range", start, end, limit, 0);
    setCondition("range");
    setFltstate(start);
    setFgtstate(end);
    setSkip(0);

    if (!start && !end) {
      getTransactions(
        "all",
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
        10,
        0
      );
      setCondition("all");
      setFltstate(moment().startOf("year").format("YYYY-MM-DD"));
      setFgtstate(moment().endOf("year").format("YYYY-MM-DD"));
      setSkip(0);
    }
  };

  return (
    <>
      <div className="d-flex d-flex justify-content-between">
        <div>
          <Tooltip content="Reload" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
              onClick={() => reloadTable()}
            >
              <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
        </div>

        <div className="d-flex">
          <Form
            className="d-flex flex-row-reverse me-4"
            onSubmit={(e) => e.preventDefault()}
          >
            {/* <DateRangePicker onApply={handleDateFilter}>
              <input
                type="text"
                style={{ width: "100%" }}
                className="form-control"
              />
            </DateRangePicker> */}
            <NewDatePickerRange onDateData={onDateDataHandler} />
          </Form>
          <div className="flex gap-3">
            <Tooltip content="Print" placement="top">
              <button
                className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
                disabled
              >
                <PrinterIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
            <CSVLink
              data={formatDataForExport(transactions)}
              filename="Transactions"
            >
              <Tooltip content="Export" placement="top">
                <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                  <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
                </button>
              </Tooltip>
            </CSVLink>
          </div>
        </div>
      </div>

      <DataTable
        data={transactions}
        columns={columns}
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<p>There are no transactions recorded</p>}
        pagination={total >= 11 ? true : false}
        paginationServer
        paginationTotalRows={total}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
      />
    </>
  );
};

export default TransactionTable;
