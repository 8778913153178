import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EarningTab({ onTabData }: { onTabData: any }) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onTabData(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        // TabIndicatorProps={{ style: { backgroundColor: "#FF6700" } }}
      >
        <Tab
          label="Credited"
          {...a11yProps(0)}
          sx={{ textTransform: "none" }}
        />
        <Tab
          label="Remitted"
          {...a11yProps(1)}
          sx={{ textTransform: "none" }}
        />
      </Tabs>
    </Box>
  );
}
