import { useState } from 'react';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import BaseService from '../../helpers/baseService';
import { global_variables } from '../../helpers/globalVariables';

interface brandProps {
    onChange?: (data: any) => void
    value?: any
}

const TruckBrandSelector = ({ onChange, value }: brandProps) => {

    const [isFetching, setIsFetching] = useState(false)
    const [allBrands, setAllBrands] = useState([])

    //handleBrandSearch
    const handleBrandSearch = debounce((searchValue: string) => {

        if (searchValue) {
            setIsFetching(true);

            BaseService.get_api_v2(`${global_variables.get_truck_brands_v2}?filter={"brand":{"$regex":${JSON.stringify(searchValue)},"$options":"i"}}`)
                .then((response) => {
                    // console.log(response?.data?.payload);
                    const rawData = response?.data?.payload
                    const preparedData = rawData.map((item: any) => (
                        { label: item?.brand, value: item?.brand }
                    ))
                    setAllBrands(preparedData)
                    setIsFetching(false);
                }).catch((error: any) => {
                    console.log(error);
                    setIsFetching(false);
                })
        }
        else {
            setAllBrands([])
        }

    }, 500);


    return (
        <>
            <Select
                className="basic-single"
                placeholder="Search truck make or brand"
                isLoading={isFetching}
                isClearable={true}
                isSearchable={true}
                options={allBrands}
                noOptionsMessage={() => "Example: Ford"}
                onInputChange={(val) => handleBrandSearch(val)}
                onChange={onChange}
                value={value}
            />
        </>
    )
}

export default TruckBrandSelector
