import { SetStateAction, useEffect, useState } from "react";
import { Container, Image, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { GenerateId } from "../../../functions/functions";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  CheckBadgeIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { suspend_user } from "../app_users/suspend_user";

const SuspendedUsersTable = ({ activeTab }: { activeTab: any }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [condition, setCondition] = useState<string>("");

  //getAllUsers
  const getAllUsers = (
    con?: string | undefined,
    lim?: number | undefined,
    sk?: number | undefined
  ) => {
    setLoading(true);

    BaseService.get_api(`${global_variables.yarpusers}?sort={"created_at":-1}`)
      .then((res) => {
        setLoading(false);

        const rawData = res?.data?.payload;
        const filteredData = rawData.filter(
          (data: { suspend: any }) => data?.suspend
        );
        console.log(filteredData);
        setTotal(filteredData.length);

        setUsers(filteredData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleRowChange = (a: SetStateAction<number>) => {
    setLimit(a);
    // getAllUsers(condition, a, skip);
  };

  const pageChange = (a: number) => {
    if (a === 1) {
      // getAllUsers(condition, limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      // getAllUsers(condition, limit, newSkip);
      setSkip(newSkip);
    }
  };

  //table columns
  const columns: any = [
    {
      name: "Image",
      selector: (row: { image: string | undefined }) => (
        <>
          <Image
            src={row.image ? row.image : "null"}
            fluid
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              borderRadius: "100%",
              marginRight: "10px",
            }}
          />
        </>
      ),
      width: "10%",
    },
    {
      name: "Full Name",
      cell: (row: { full_name: any }) => row.full_name || "N/A",
    },
    {
      name: "Phone",
      selector: (row: { phone: any }) => row.phone,
    },
    {
      name: "Start Date",
      cell: (row: { suspend: { suspension_start: moment.MomentInput } }) =>
        moment(row?.suspend?.suspension_start).format("Do MMM YYYY - hh:mm A"),
    },
    {
      name: "End Date",
      cell: (row: { suspend: { suspension_end: moment.MomentInput } }) =>
        moment(row?.suspend?.suspension_end).format("Do MMM YYYY - hh:mm A"),
    },
    {
      name: "Action",
      selector: (row: any) => (
        <CheckBadgeIcon
          title="Unsuspend"
          className="w-5 h-5 text-green-500 cursor-pointer"
          onClick={showSuspend.bind(this, row)}
        />
      ),
      width: "10%",
    },
  ];

  const reloadTable = () => {
    getAllUsers();
    setLimit(10);
    setSkip(0);
  };

  // const [qry, setQry] = useState("");
  // const searchUser = (e) => {
  //   e.preventDefault();

  //   console.log(qry);

  //   if (qry === "") {
  //     ShowToast.warning_toast("please type a user name to search");
  //   } else {
  //     getAllUsers(
  //       `"condition":"type_name","parameter":"${qry}","type":"user"`,
  //       10,
  //       0
  //     );
  //     setCondition(
  //       `"condition":"type_name","parameter":"${qry}","type":"user"`
  //     );
  //     setLimit(10);
  //     setSkip(0);
  //   }
  // };

  const [show, setShow] = useState<boolean>(false);
  const [susData, setSuspendData] = useState<any>([]);
  const [randomNum, setRandomNum] = useState<string>("");
  const showSuspend = (row: SetStateAction<never[]>) => {
    setShow(true);
    setSuspendData(row);
    setRandomNum(GenerateId(5));
  };

  const [confrimationCode, setConfirmationCode] = useState("");
  const [msg, setMsg] = useState("");
  const [susEnd, setEndDate] = useState("");
  const suspendUser = () => {
    suspend_user(
      randomNum,
      confrimationCode,
      susEnd,
      susData?.userid,
      susData?.phone,
      msg
    );
    getAllUsers(condition, limit, skip);
    setShow(false);
    setConfirmationCode("");
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <button
            className="h-10 w-10 border-[1px] border-yarpBlue rounded-[4px] flex justify-center items-center"
            onClick={reloadTable}
          >
            <ArrowPathIcon className="h-5 w-5 text-yarpBlue" />
          </button>
        </div>
        <div className="flex gap-3">
          <div>
            {/* <Form className="d-flex flex-row-reverse" onSubmit={searchUser}>
              <input
                type="text"
                placeholder="Search.."
                className="form-control search_table h-10"
                value={qry}
                onChange={(e) => setQry(e.target.value)}
                style={{ width: "100%" }}
              />
            </Form> */}
          </div>
          <div className="flex gap-3">
            <button className="h-10 w-10 border-[1px] border-yarpBlue rounded-[4px] flex justify-center items-center">
              <PrinterIcon className="h-5 w-5 text-yarpBlue" />
            </button>
            <button
              type="submit"
              className="h-10 w-10 border-[1px] border-yarpBlue rounded-[4px] flex justify-center items-center"
            >
              <ArrowDownTrayIcon className="h-5 w-5 text-yarpBlue" />
            </button>
          </div>
        </div>
      </div>

      <DataTable
        data={users}
        columns={columns}
        progressPending={loading}
        paginationTotalRows={total}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<p>No data...</p>}
        // pagination
        // paginationServer
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        pointerOnHover
        onRowClicked={(row: any) => {
          navigate(`/userdetail?uid=${row.full_name}&tab=${activeTab}`);
        }}
      />

      {/* Suspend User Table  */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Suspend {susData.full_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show ? (
            <Container className="text-center">
              <h5 className="fw-light m-0 p-0"> Comfirmation Code </h5>
              <p className="fw-bolder">{randomNum}</p>
              <p style={{ fontSize: "13px" }}>
                Are you sure you want to suspend this user? Please enter the
                code above
              </p>
              <input
                value={confrimationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                type="text"
                className="form-control my-3"
                placeholder="Comfirmation code"
              />
              <textarea
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                className="form-control my-3"
                style={{ height: "100px" }}
                placeholder="Tell the user the reason for suspension"
              ></textarea>

              <small>Suspension ends on:</small>
              <input
                value={susEnd}
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                className="form-control"
              />
            </Container>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-danger" onClick={suspendUser}>
            Suspend
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SuspendedUsersTable;
