import { Typography } from "@material-tailwind/react";
import { useState } from "react";
import CustomNavbar from "../../components/layouts/navbar";
import CustomSideBar from "../../components/layouts/sidebar";
import TruckBrand from "./tabs/truck_brand";
import TruckModel from "./tabs/truck_model";
import TruckType from "./tabs/truck_type";
import ProductCategory from "./tabs/product_category";
import Pricing from "./tabs/pricing";
import Regions from "./tabs/region";
import Cancellation from "./tabs/cancellation";

const ManageYarp = () => {
  const [activeTab, setActiveTab] = useState(0);

  const allMenus = [
    "Truck Brand",
    "Truck Model",
    "Truck Type",
    "Product Categoty",
    "Pricing",
    "Region",
    "Cancellation",
  ];

  const allMenusComponents = [
    <TruckBrand />,
    <TruckModel />,
    <TruckType />,
    <ProductCategory />,
    <Pricing />,
    <Regions />,
    <Cancellation />,
  ];


  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar mgtActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">Manage Yarp</p>

              <div className="mt-3 lg:grid lg:grid-cols-4 gap-3">
                <div className="mt-3 bg-white shadow-md rounded-[20px] py-5 col-span-1 w-full h-fit">
                  {allMenus.map((menu, i) => (
                    <div
                      key={i}
                      className={`${activeTab === i
                        ? "rounded-r-[30px] bg-yarpBlue text-white "
                        : "hover:bg-yarpBlue hover:bg-opacity-[10%] hover:rounded-r-[30px]"
                        } mr-[40px] h-fit py-1 cursor-pointer`}
                      onClick={() => setActiveTab(i)}
                    >
                      <Typography className="ml-[30px] font-medium mt-[10px]">
                        {menu}
                      </Typography>
                    </div>
                  ))}
                </div>

                <div className="mt-3 bg-white shadow-md rounded-[20px] col-span-3 p-5">
                  {allMenusComponents[activeTab]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageYarp;
