import React, { useCallback, useState } from "react";
import { Button } from "antd";
import { CloseOutlined, ReloadOutlined } from "@ant-design/icons";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { metersToKilometers } from "../../../utils/functions";

const DraggableBox = ({
  handleMapDistance,
  TripDetails,
}: {
  handleMapDistance: any;
  TripDetails: any;
}) => {
  const initialPosition = { x: 0, y: 0 };
  const [position, setPosition] = useState(initialPosition);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [visible, setVisible] = useState(true);

  const handleMouseDown = useCallback(
    (e: any) => {
      setIsDragging(true);
      setOffset({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [position]
  );

  const handleMouseMove = useCallback(
    (e: any) => {
      if (isDragging) {
        setPosition({
          x: e.clientX - offset.x,
          y: e.clientY - offset.y,
        });
      }
    },
    [isDragging, offset]
  );

  const handleMouseUp = () => {
    setIsDragging(false);
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const closeBox = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div
      className="absolute w-[300px] cursor-grabbing top-0 left-0 p-4 text-white bg-[rgba(45,55,72,0.8)] rounded-[4px]"
      style={{
        transform: `translate(${position.x}px, ${position.y}px) `,
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div className="mb-2 w-full flex justify-between">
        <h3 className="">Trip Info</h3>

        <Button
          icon={<CloseOutlined />}
          size="small"
          onClick={closeBox}
          style={{
            background: "none",
            border: "none",
            color: "#FF6700",
            boxShadow: "none",
          }}
        />
      </div>

      <p>
        <span className="text-[#FF6700] font-body">Pickup Location : </span>
        <span>{TripDetails?.pickup_place_name}</span>
      </p>
      <p>
        <span className="text-[#FF6700]">Delivery Location : </span>
        <span>{TripDetails?.delivery_place_name}</span>
      </p>
      <p>Distance : {metersToKilometers(TripDetails?.distance)} KM</p>

      <div className="mb-2 w-full">
        <Button
          icon={<MapPinIcon className="w-4 h-4 cursor-pointer" />}
          onClick={handleMapDistance}
          size="small"
          className="w-full"
          style={{
            background: "#FF6700",
            border: "none",
            color: "white",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          Get Distance on Map!
        </Button>
      </div>
    </div>
  );
};

export default DraggableBox;
