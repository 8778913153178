import moment from "moment";
import { useEffect, useState } from "react";
import {
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Table,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { formatCedis } from "../../../utils/formatCedis";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Spinner,
} from "@material-tailwind/react";
import {
  ClipboardDocumentListIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import ClonePriceModal from "./clonePrice";
import EditPriceByPercentage from "./editPriceByPercentage";
import EditPriceForAll from "./editPriceForAll";

const compare = (a: { maxkilo: string }, b: { maxkilo: string }) => {
  if (parseInt(a.maxkilo) < parseInt(b.maxkilo)) {
    return -1;
  }
  if (parseInt(a.maxkilo) > parseInt(b.maxkilo)) {
    return 1;
  }
  return 0;
};

const Pricing = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [show, setShow] = useState<any>(0);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [reFetch, setReFetch] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [editId, setEditId] = useState<any>("");

  //clone price
  const [showCLoneModal, setShowCLoneModal] = useState<boolean>(false);
  const [dataToClone, setDataToClone] = useState<any>(null);
  const handleShowCloneModale = () => {
    setShowCLoneModal(!showCLoneModal);
  };
  const setCloneDataToModal = (data: any) => {
    setDataToClone(data);
    handleShowCloneModale();
  };

  //edit by percentage
  const [showEditPercentModal, setShowEditPercentModal] = useState(false);
  const handleEditPercentModal = () => {
    setShowEditPercentModal(!showEditPercentModal);
  };
  const triggerEditPercentModal = (data: any) => {
    setDataToClone(data);
    handleEditPercentModal();
  };

  //editAccros
  const [editAccross, setEditAccross] = useState(false);
  const handleEditAccross = () => {
    setEditAccross(!editAccross);
  };

  useEffect(() => {
    getData();
  }, [reFetch]);

  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  //get prices
  const getData = (lim?: any, ski?: any) => {
    setLoading(true);

    BaseService.get_api(
      `${global_variables.get_pricing}?sort={"created_at":-1}&limit=${lim}&skip=${ski}`
    )
      .then((res) => {
        setLoading(false);
        setData(res?.data?.payload);
        setTotal(res?.data?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //table columns
  const columns: any = [
    {
      name: "Car type",
      cell: (row: { car_type: any }) => row.car_type,
      width: "15%",
    },
    {
      name: "Bucket price",
      selector: (row: { bucketprice: any }) => row.bucketprice,
      width: "15%",
    },
    {
      name: "Date created",
      cell: (row: { created_at: moment.MomentInput }) =>
        moment(row.created_at).format("Do MMM YYYY"),
      width: "15%",
    },
    {
      name: `Price Metrics`,
      cell: (row: { kilorange: any[] }) => (
        <Table>
          <thead>
            <tr>
              <th>Maximum Distance(km)</th>
              <th>Base Price</th>
            </tr>
          </thead>
          <tbody style={{ width: "100%" }}>
            {row.kilorange?.map((d, index) => {
              return (
                <tr key={index}>
                  <td width="60%">{d.maxkilo}km</td>
                  <td>{formatCedis(d.baseprice)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ),
      width: "40%",
    },
    {
      name: "Action",
      selector: (row: any) => (
        <div className="flex gap-3">
          <Menu>
            <MenuHandler>
              <PencilSquareIcon
                className="w-5 h-5 cursor-pointer"
                title="Edit Price"
              />
            </MenuHandler>
            <MenuList>
              <MenuItem onClick={() => triggergeneralEdit(row)}>
                Edit Price
              </MenuItem>
              <MenuItem onClick={() => triggerEditPercentModal(row)}>
                Edit By Percentage
              </MenuItem>
            </MenuList>
          </Menu>
          <ClipboardDocumentListIcon
            className="w-5 h-5 cursor-pointer"
            title="Clone Price"
            onClick={() => setCloneDataToModal(row)}
          />
        </div>
      ),
      width: "15%",
    },
  ];

  const handleRowChange = (a: any) => {
    getData(a, 0);
    setLimit(a);
    setSkip(0);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getData(limit, newSkip);
      setSkip(newSkip);
    }
  };

  // Form Handling
  const [typeLoaded, setTypeLoaded] = useState<boolean>(true);
  const [types, setTypes] = useState<any[]>([]);

  const [type, setType] = useState<any>("");
  const [maxBase, setMaxBase] = useState<any>([]);
  const [bucket_price, setBucketPrice] = useState<any>(0);
  const [fuel_per_kilo, set_fuel_per_kilo] = useState<any>(0);

  const showAddModel = () => {
    setShow(true);
    getTypes();
  };

  //triggerGeneralEdit
  const triggergeneralEdit = (data: any) => {
    console.log(data);
    setEditing(true);
    showAddModel();
    setEditId(data?._id);
    setType(data?.car_type);
    setMaxBase(data?.kilorange);
    set_fuel_per_kilo(data?.fuel_per_kilo);
    setBucketPrice(data?.bucketprice);
  };

  //getTypes
  const getTypes = () => {
    BaseService.get_api(global_variables.get_car_type)
      .then((res) => {
        setTypeLoaded(false);
        setTypes(res?.data?.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //addMaxBase
  const addMaxBase = (e: any) => {
    const maxkiloElement = document.getElementById("max") as HTMLInputElement;
    const basepriceElement = document.getElementById(
      "base"
    ) as HTMLInputElement;

    const maxkilo = maxkiloElement.value as string;
    const baseprice = basepriceElement.value as string;

    if (maxkilo === "" || baseprice === "") {
      ShowToast.warning_toast(
        "Please set Maximum distance and its corresponding base price"
      );
    } else {
      setMaxBase(
        [
          ...maxBase,
          { maxkilo: parseFloat(maxkilo), baseprice: parseFloat(baseprice) },
        ].sort(compare)
      );
      const maxkiloElement = document.getElementById("max") as HTMLInputElement;
      const basepriceElement = document.getElementById(
        "base"
      ) as HTMLInputElement;
      maxkiloElement.value = "";
      basepriceElement.value = "";
    }
  };

  const removeBase = (i: any) => {
    maxBase.splice(i, 1);
    setMaxBase([...maxBase]);
  };

  //postPricing
  const postPricing = () => {
    if (
      type === "" ||
      bucket_price === "" ||
      fuel_per_kilo == "" ||
      maxBase.length === 0
    ) {
      ShowToast.warning_toast("Please complete the form");
    } else {
      var upload_data = {
        car_type: type,
        bucketprice: parseFloat(bucket_price),
        kilorange: maxBase.sort(compare),
        fuel_per_kilo: parseFloat(fuel_per_kilo),
      };

      setIsBusy(true);

      //post type
      BaseService.post_api_v2(global_variables.create_price_v2, upload_data)
        .then((res) => {
          ShowToast.success_toast(res?.data?.message);
          setIsBusy(false);
          setShow(false);
          setBucketPrice("");
          set_fuel_per_kilo("");
          setMaxBase([]);
          getData(limit, skip);
        })
        .catch((err) => {
          ShowToast.error_toast(err?.error);
          console.log(err);
        });
    }
  };

  //editPricing
  const editPricing = () => {
    if (
      type === "" ||
      bucket_price === "" ||
      fuel_per_kilo == "" ||
      maxBase.length === 0
    ) {
      ShowToast.warning_toast("Please complete the form");
    } else {
      var updateData = {
        _id: editId,
        car_type: type,
        bucketprice: parseFloat(bucket_price),
        kilorange: maxBase.sort(compare),
        fuel_per_kilo: parseFloat(fuel_per_kilo),
      };

      setIsBusy(true);

      //post type
      BaseService.put_api_v2(global_variables.update_price_v2, updateData)
        .then((res) => {
          ShowToast.success_toast(res?.data?.message);
          setIsBusy(false);
          setShow(false);
          setBucketPrice("");
          set_fuel_per_kilo("");
          setMaxBase([]);
          getData(limit, skip);
        })
        .catch((err) => {
          ShowToast.error_toast(err?.error);
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h5>Pricing</h5>
        <div className="flex gap-3">
          <Button
            variant="outlined"
            color="blue"
            size="sm"
            className="flex gap-2 items-center"
            onClick={() => handleEditAccross()}
          >
            <PencilSquareIcon className="h-5 w-5" />
            Edit All Prices
          </Button>
          <Button
            variant="gradient"
            color="blue"
            size="sm"
            className="flex gap-2 items-center"
            onClick={showAddModel}
          >
            <PlusCircleIcon className="h-5 w-5" />
            Add Price
          </Button>
        </div>
      </div>

      <div>
        <DataTable
          data={data}
          columns={columns}
          paginationTotalRows={total}
          progressPending={loading}
          progressComponent={<LoadingDataComponent />}
          noDataComponent={<p>You do not have any car brands</p>}
          pagination={total >= 11 ? true : false}
          paginationServer
          onChangeRowsPerPage={handleRowChange}
          onChangePage={pageChange}
        />
      </div>

      {/* Add  */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setEditing(false);
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{editing ? "Edit" : "Add"} Pricing</Modal.Title>
        </Modal.Header>

        {editing ? (
          <>
            <Modal.Body>
              <Container className="mb-3" fluid>
                {maxBase.length >= 1 ? (
                  <>
                    <Table>
                      <thead>
                        <tr>
                          <th>Maximum Distance (KM)</th>
                          <th>Base Price (GHS)</th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {maxBase.map((d: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td>{d.maxkilo}</td>
                              <td>{d.baseprice}</td>
                              <td>
                                <XMarkIcon
                                  className="h-5 w-5 cursor-pointer text-red-500"
                                  onClick={removeBase.bind(this, index)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </Container>
              <InputGroup size="sm" className="mb-3">
                <Form.Control
                  type="number"
                  id="max"
                  placeholder="Maximum distance"
                />
                <Form.Control
                  type="number"
                  id="base"
                  placeholder="Base Price"
                />
                <Button variant="gradient" color="blue" onClick={addMaxBase}>
                  +
                </Button>
              </InputGroup>

              <FloatingLabel
                controlId="truck_type"
                label="Select Type"
                className="mb-3"
              >
                <Form.Select
                  id="selectType"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="" disabled></option>
                  {(typeLoaded && <option value="">Loading...</option>) ||
                    types.map((types) => {
                      return (
                        <option value={types.cartype} key={types._id}>
                          {types.cartype}
                        </option>
                      );
                    })}
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel
                controlId="fpk"
                label="Fuel per kilometer"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="10"
                  value={fuel_per_kilo}
                  onChange={(e) => set_fuel_per_kilo(e.target.value)}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="base"
                label="Bucket Price"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="10"
                  value={bucket_price}
                  onChange={(e) => setBucketPrice(e.target.value)}
                />
              </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="gradient"
                color="blue"
                onClick={() => editPricing()}
                disabled={isBusy}
              >
                {isBusy ? <Spinner /> : "Update"}
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              <Container className="mb-3" fluid>
                {maxBase.length >= 1 ? (
                  <>
                    <Table>
                      <thead>
                        <tr>
                          <th>Maximum Distance (KM)</th>
                          <th>Base Price (GHS)</th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {maxBase.map((d: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td>{d.maxkilo}</td>
                              <td>{d.baseprice}</td>
                              <td>
                                <XMarkIcon
                                  className="h-5 w-5 cursor-pointer text-red-500"
                                  onClick={removeBase.bind(this, index)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : null}
              </Container>
              <InputGroup size="sm" className="mb-3">
                <Form.Control
                  type="number"
                  id="max"
                  placeholder="Maximum distance"
                />
                <Form.Control
                  type="number"
                  id="base"
                  placeholder="Base Price"
                />
                <Button variant="gradient" color="blue" onClick={addMaxBase}>
                  +
                </Button>
              </InputGroup>

              <FloatingLabel
                controlId="truck_type"
                label="Select Type"
                className="mb-3"
              >
                <Form.Select
                  id="selectType"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="" disabled></option>
                  {(typeLoaded && <option value="">Loading...</option>) ||
                    types.map((types) => {
                      return (
                        <option value={types.cartype} key={types._id}>
                          {types.cartype}
                        </option>
                      );
                    })}
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel
                controlId="fpk"
                label="Fuel per kilometer"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="10"
                  value={fuel_per_kilo}
                  onChange={(e) => set_fuel_per_kilo(e.target.value)}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="base"
                label="Bucket Price"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="10"
                  value={bucket_price}
                  onChange={(e) => setBucketPrice(e.target.value)}
                />
              </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="gradient"
                color="blue"
                onClick={() => postPricing()}
                disabled={isBusy}
              >
                {isBusy ? <Spinner /> : "Post"}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>

      {/* clone modal */}
      {showCLoneModal ? (
        <ClonePriceModal
          showModal={showCLoneModal}
          handleModalShow={handleShowCloneModale}
          dataToCLone={dataToClone}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}

      {/* edit by percent modal */}
      {showEditPercentModal ? (
        <EditPriceByPercentage
          showModal={showEditPercentModal}
          handleModalShow={handleEditPercentModal}
          dataToCLone={dataToClone}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}

      {/* edit accross modal */}
      {editAccross ? (
        <EditPriceForAll
          showModal={editAccross}
          handleModalShow={handleEditAccross}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Pricing;
