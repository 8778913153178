import { PowerIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Typography,
} from "@material-tailwind/react";
import ShowAlert from "../alerts/all_alerts";
import { StorageBox } from "../../helpers/storage";
import ShowToast from "../alerts/all_toasts";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Userprofile = () => {
  const navigate = useNavigate();
  const [userImg, setUserImg] = useState("");
  const [userData, setUserData] = useState<any>(null);

  //get user Data
  useEffect(() => {
    const data = StorageBox.retrieveUserData();
    setUserImg(data?.image);
    setUserData(data);
  }, []);

  //hand sign out
  const handleSignOut = () => {
    ShowAlert.question_alert(
      "Confirm",
      "Do you want to proceed?",
      "Proceed"
    ).then((action) => {
      if (action.isConfirmed) {
        StorageBox.clearStorage();
        ShowToast.success_toast("Sign out successful");
        navigate("/");
      }
    });
  };

  return (
    <>
      <Menu placement="bottom-end">
        <MenuHandler>
          <div className="flex gap-3 items-center cursor-pointer bg-[#ff660009] px-4 rounded-[10px]">
            <Avatar
              variant="circular"
              alt="profile"
              className="shadow-green-900/20 ring-1 ring-green-500/30"
              src={userImg || 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'}
            />
            <div className="hidden lg:block mt-3">
              <Typography className="font-semibold mb-0">
                {userData?.full_name}
              </Typography>
              <Typography>{userData?.phone}</Typography>
            </div>
          </div>
        </MenuHandler>
        <MenuList>
          <MenuItem
            className="flex items-center gap-2"
            onClick={() => navigate("/profile")}
          >
            <UserCircleIcon className="h-5 w-5" />
            <Typography variant="small" className="font-normal mt-3">
              My Profile
            </Typography>
          </MenuItem>
          <MenuItem
            className="flex items-center gap-2 text-red-500"
            onClick={() => handleSignOut()}
          >
            <PowerIcon className="h-5 w-5" />
            <Typography variant="small" className="font-normal mt-3">
              Sign Out
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default Userprofile;
