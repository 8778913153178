import { SetStateAction, useEffect, useState } from "react";
import loadingGif from "../../../images/yarp-spinner.gif";
import icon from "../../../images/userIcon.png";
import moment from "moment";
import { Link } from "react-router-dom";
import { GenerateId } from "../../../functions/functions";
import Swal from "sweetalert2";
import {
  Button,
  Modal,
  Image,
  Container,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { EyeIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { Empty } from "antd";

export const SuspendedUserTable = (props: any) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [users, setUser] = useState<any>([]);
  const [m, setM] = useState<boolean>(false);
  const [md, setMd] = useState<any>([]);
  const [suspendCode, setSuspendCode] = useState<string>("");
  const [randomNum, setRandomNum] = useState<string>("");
  const [showSuspend, setShowSuspend] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    document.title = "Suspended users - Yarp Company";
  }, []);

  const getUsers = async (lim: any, sk: any) => {
    setLoaded(false);

    BaseService.get_api(
      `${global_variables.yarpusers}?filter={"condition":"type_suspend","type":"user"}&sorting={"online":-1}&limit=${lim}&skip=${sk}`
    )
      .then(function (response) {
        setLoaded(true);
        setUser(response.data.payload);
        setTotal(response.data.total);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const modalTrigger = (row: any) => {
    // console.log(row)
    setM(true);
    setMd(row);
  };

  const modalTriggerUn = (row: any) => {
    setMd(row);
    setRandomNum(GenerateId(5));
    setShowSuspend(true);
  };

  const unsuspendUser = async (e: any) => {
    setShowSuspend(false);
    e.preventDefault();

    Swal.fire({
      title: "Authenticating...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      // timer: 3000,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (randomNum !== suspendCode) {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "Please enter the comfirmaton code to suspend user from yarp",
      });
    } else {
      const userId = showSuspend ? md["userid"] : "none";

      var data = {
        userid: userId,
      };

      BaseService.put_api(global_variables.unsuspend_users, data)
        .then((response) => {
          // console.log(response.data);
          setRandomNum("");
          getUsers(limit, skip);

          Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            text: response.data.message,
          });
        })
        .catch(function (error) {
          Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            text: "There was an error unsuspending this user, please try again later",
          });
        });
    }
  };

  const column: any = [
    {
      name: "",
      selector: (row: { image: string | undefined }) => (
        <Image
          src={row.image ? row.image : icon}
          alt=".."
          fluid
          style={{ objectFit: "cover", borderRadius: "100%" }}
        />
      ),
      width: "15%",
    },
    {
      name: "Name",
      selector: (row: { full_name: any }) => row.full_name,
      width: "35%",
    },
    {
      name: "Date",
      selector: (row: { suspension_end: moment.MomentInput }) =>
        moment(row.suspension_end).format("DD-MM-YYYY"),
      width: "30%",
    },
    {
      name: "Action",
      selector: (row: any) => (
        <div className="flex gap-3">
          <Link
            to="#"
            style={{ textDecoration: "none", color: "#000", fontSize: "22px" }}
            onClick={modalTrigger.bind(this, row)}
          >
            <EyeIcon className="h-5 w-5 cursor-pointer" />
          </Link>

          <Link
            to="#"
            style={{ textDecoration: "none", color: "green", fontSize: "22px" }}
            onClick={modalTriggerUn.bind(this, row)}
          >
            <UserPlusIcon className="h-5 w-5 cursor-pointer" />
          </Link>
        </div>
      ),
      width: "20%",
    },
  ];

  const handleRowChange = (e: SetStateAction<number>) => {
    // console.log(e)
    getUsers(e, 0);
    setLimit(e);
    setSkip(0);
  };

  const handlePageChange = (a: number) => {
    // console.log(a)
    if (a === 1) {
      getUsers(limit, a);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getUsers(limit, newSkip);
      setSkip(newSkip);
    }
  };

  return (
    <>
      <DataTable
        columns={column}
        data={users}
        paginationTotalRows={total}
        progressPending={!loaded}
        progressComponent={<Image src={loadingGif} fluid />}
        highlightOnHover
        pointerOnHover
        onRowClicked={(row) => {
          modalTrigger(row);
        }}
        noDataComponent={
          <div className="mt-[50px]">
            <Empty description="No data" />
          </div>
        }
        pagination
        paginationServer
        onChangeRowsPerPage={handleRowChange}
        onChangePage={handlePageChange}
      />

      {/* ViewUser Detail */}

      <Modal
        show={m}
        onHide={() => {
          setM(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>User information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {m ? (
            <Container>
              <Table>
                <tbody>
                  <tr>
                    <th style={{ width: "30%" }}></th>
                    <td></td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Name</th>
                    <td>
                      <Row>
                        <Col md={3}>
                          <Image
                            fluid
                            src={md.image}
                            style={{ borderRadius: "100px" }}
                          />
                        </Col>
                        <Col md={9}>{md.full_name}</Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Contact</th>
                    <td>{md.phone}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>Start</th>
                    <td>
                      {moment(
                        md.suspend ? md.suspend.suspension_start : null
                      ).format("Do MMMM YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>End</th>
                    <td>
                      {moment(
                        md.suspend ? md.suspend.suspension_end : null
                      ).format("Do MMMM YYYY")}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showSuspend}
        onHide={() => {
          setShowSuspend(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Unsuspend user</Modal.Title>
        </Modal.Header>

        <div className="modal-body">
          <div className="container">
            <div className="text-center mb-2">
              <h5 className="fw-light m-0 p-0"> Comfirmation Code </h5>
              <p className="fw-bold m-0 p-0">
                {showSuspend ? randomNum : "loadingGif...."}
              </p>
            </div>
            <p>
              Are you sure you want to unsuspend this user? Please enter the
              code above
            </p>
            <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control"
                id="comfirm"
                placeholder="code"
                style={{ textTransform: "uppercase" }}
                value={suspendCode}
                onChange={(e) => {
                  setSuspendCode(e.target.value.toUpperCase());
                }}
              />
              <label htmlFor="comfirm">Enter code</label>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setShowSuspend(false);
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-success"
            onClick={unsuspendUser}
          >
            Unsuspend
          </button>
        </div>
      </Modal>
    </>
  );
};

export const SuspendedDriversTable = (props: any) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [users, setUser] = useState<any>([]);
  const [m, setM] = useState<boolean>(false);
  const [md, setMd] = useState<any>([]);
  const [suspendCode, setSuspendCode] = useState<string>("");
  const [randomNum, setRandomNum] = useState<string>("");

  const [showSuspend, setShowSuspend] = useState<boolean>(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoaded(false);

    BaseService.get_api(
      `${global_variables.yarpusers}?filter={"condition":"type_suspend","type":"driver"}&sorting={"online":-1}`
    )
      .then(function (response) {
        setUser(response.data.payload);

        // console.log(response.data.payload);

        setLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const modalTrigger = (i: any) => {
    var go = users[i]["userid"];

    var thisUser = users.filter(function (theUser: any) {
      return theUser.userid === go;
    });
    // console.log(thisUser)
    setM(true);
    setMd(thisUser);
    setRandomNum(GenerateId(5));
  };

  const modalTriggerUn = (i: any) => {
    var go = users[i]["userid"];

    var thisUser = users.filter(function (theUser: any) {
      return theUser.userid === go;
    });
    // console.log(thisUser)
    setM(true);
    setMd(thisUser);
    setRandomNum(GenerateId(5));
    setShowSuspend(true);
  };

  const unsuspendUser = async (e: { preventDefault: () => void }) => {
    setShowSuspend(false);
    e.preventDefault();
    Swal.fire({
      title: "Authenticating...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      // timer: 3000,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (randomNum !== suspendCode) {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "Please enter the comfirmaton code to suspend driver from yarp",
      });
    } else {
      const userId = m ? md[0]["userid"] : "none";

      var data = {
        userid: userId,
      };

      BaseService.put_api(global_variables.unsuspend_users, data)
        .then((response) => {
          // console.log(response.data);

          Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            text: response.data.message,
          }).then(() => {
            getUsers();
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <>
      {loaded ? (
        <>
          {users.length === 0 ? (
            <p className="text-center mt-5 fw-bold">
              There is currently no suspend user on yarp
            </p>
          ) : (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Full name</th>
                    <th scope="col">Suspension End</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((d: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td>
                          {d.image ? (
                            <Image
                              src={d.image}
                              alt=".."
                              fluid
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                                borderRadius: "100%",
                              }}
                            />
                          ) : (
                            <Image
                              src={icon}
                              alt=".."
                              fluid
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                                borderRadius: "100%",
                              }}
                            />
                          )}
                        </td>
                        <td>{d.full_name}</td>
                        <td>
                          {moment(d.suspend["suspension_end"]).format(
                            "Do MMM YYYY"
                          )}
                        </td>
                        <td>
                          <Link
                            to="#"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              fontSize: "22px",
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#viewUser"
                            onClick={modalTrigger.bind(this, index)}
                          >
                            {" "}
                            <i className="fa fa-eye"></i>{" "}
                          </Link>

                          <Link
                            to="#"
                            style={{
                              textDecoration: "none",
                              color: "green",
                              fontSize: "22px",
                            }}
                            onClick={modalTriggerUn.bind(this, index)}
                          >
                            {" "}
                            <i className="fa fa-user-plus"></i>{" "}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </>
      ) : (
        <>
          <Image src={loadingGif} fluid />{" "}
        </>
      )}
      {/* ViewUser Detail */}
      <div
        className="modal fade"
        id="viewUser"
        tabIndex={-1}
        aria-labelledby="viewUserLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              {m ? (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="text-center">User detail</h3>
                    </div>
                    <hr />
                    <div className="col-md-6">
                      {md[0]["image"] ? (
                        <Image
                          src={md[0]["image"]}
                          alt=".."
                          fluid
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <Image
                          src={icon}
                          alt=".."
                          fluid
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: "100%",
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <p className="fw-bold">
                        First name{" "}
                        <span className="fw-light">{md[0]["first_name"]}</span>{" "}
                      </p>
                      <p className="fw-bold">
                        Middle name{" "}
                        <span className="fw-light">{md[0]["first_name"]}</span>{" "}
                      </p>
                      <p className="fw-bold">
                        Last tname{" "}
                        <span className="fw-light">{md[0]["last_name"]}</span>{" "}
                      </p>
                    </div>
                    <div className="col-md-12 mt-3">
                      <hr />
                      <h3 className="text-center">Suspension Detail</h3>
                      <p className="fw-bold">
                        Suspension Date{" "}
                        <span className="fw-light">
                          {md[0]["suspend"]["suspension_start"]}
                        </span>{" "}
                      </p>
                      <p className="fw-bold">
                        Suspension End{" "}
                        <span className="fw-light">
                          {md[0]["suspend"]["suspension_end"]}
                        </span>{" "}
                      </p>
                      <p className="fw-bold">
                        Suspension Message{" "}
                        <span className="fw-light">
                          {md[0]["suspend"]["suspension_end"]}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <Image
                  src={loadingGif}
                  alt=".."
                  className="img-fluid text-center"
                />
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Unsuspend user */}
      <Modal
        show={showSuspend}
        onHide={() => {
          setShowSuspend(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Unsuspend</Modal.Title>
        </Modal.Header>
        <div className="modal-body">
          <div className="container">
            <div className="text-center mb-2">
              <h5 className="fw-light m-0 p-0"> Comfirmation Code </h5>
              <p className="fw-bold m-0 p-0">
                {m ? randomNum : "loadingGif...."}
              </p>
            </div>
            <p>
              Are you sure you want to unsuspend this user? Please enter the
              code above
            </p>
            <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control"
                id="comfirm"
                placeholder="code"
                style={{ textTransform: "uppercase" }}
                value={suspendCode}
                onChange={(e) => {
                  setSuspendCode(e.target.value.toUpperCase());
                }}
              />
              <label htmlFor="comfirm">Enter code</label>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setShowSuspend(false);
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-success"
            onClick={unsuspendUser}
          >
            Unsuspend
          </button>
        </div>
      </Modal>
    </>
  );
};

export const SuspendedFleetTable = (props: any) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [users, setUser] = useState<any>([]);
  const [m, setM] = useState<boolean>(false);
  const [md, setMd] = useState<any>([]);
  const [suspendCode, setSuspendCode] = useState<string>("");
  const [randomNum, setRandomNum] = useState<string>("");

  const [showSuspend, setShowSuspend] = useState<boolean>(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoaded(false);

    BaseService.get_api(
      `${global_variables.yarpusers}?filter={"condition":"type_suspend","type":"fleet"}&sorting={"online":-1}`
    )
      .then(function (response) {
        setUser(response.data.payload);

        // console.log(response.data.payload)

        setLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const modalTrigger = (i: any) => {
    var go = users[i]["userid"];

    var thisUser = users.filter(function (theUser: { userid: any }) {
      return theUser.userid === go;
    });
    // console.log(thisUser)
    setM(true);
    setMd(thisUser);
  };

  const modalTriggerUn = (i: any) => {
    var go = users[i]["userid"];

    var thisUser = users.filter(function (theUser: { userid: any }) {
      return theUser.userid === go;
    });
    // console.log(thisUser)
    setM(true);
    setMd(thisUser);
    setRandomNum(GenerateId(5));
    setShowSuspend(true);
  };

  const unsuspendUser = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setShowSuspend(false);

    Swal.fire({
      title: "Authenticating...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      // timer: 3000,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (randomNum !== suspendCode) {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "Please enter the comfirmaton code to suspend user from yarp",
      });
    } else {
      const userId = m ? md[0]["userid"] : "none";

      var data = {
        userid: userId,
      };

      BaseService.put_api(global_variables.unsuspend_users, data)
        .then((response) => {
          // console.log(response.data);

          Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            text: response.data.message,
          }).then(() => {
            getUsers();
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <>
      {loaded ? (
        <>
          {users.length === 0 ? (
            <p className="text-center mt-5 fw-bold">
              There is currently no suspend fleet on yarp
            </p>
          ) : (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Full name</th>
                    <th scope="col">Suspension End</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((d: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td>
                          {d.image ? (
                            <Image
                              src={d.image}
                              alt=".."
                              fluid
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                                borderRadius: "100%",
                              }}
                            />
                          ) : (
                            <Image
                              src={icon}
                              alt=".."
                              fluid
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                                borderRadius: "100%",
                              }}
                            />
                          )}
                        </td>
                        <td>{d.full_name}</td>
                        <td>
                          {moment(d.suspend["suspension_end"]).format(
                            "Do MMM YYYY"
                          )}
                        </td>
                        <td>
                          <Link
                            to="#"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              fontSize: "22px",
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#viewUser"
                            onClick={modalTrigger.bind(this, index)}
                          >
                            <EyeIcon className="h-5 w-5 cursor-pointer" />
                          </Link>

                          <Link
                            to="#"
                            style={{
                              textDecoration: "none",
                              color: "green",
                              fontSize: "22px",
                            }}
                            onClick={modalTriggerUn.bind(this, index)}
                          >
                            <UserPlusIcon className="h-5 w-5 cursor-pointer" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </>
      ) : (
        <>
          <Image src={loadingGif} fluid />{" "}
        </>
      )}
      {/* ViewUser Detail */}
      <div
        className="modal fade"
        id="viewUser"
        tabIndex={-1}
        aria-labelledby="viewUserLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              {m ? (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="text-center">User detail</h3>
                    </div>
                    <hr />
                    <div className="col-md-6">
                      {md[0]["image"] ? (
                        <Image
                          src={md[0]["image"]}
                          alt=".."
                          fluid
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <Image
                          src={icon}
                          alt=".."
                          fluid
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: "100%",
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <p className="fw-bold">
                        First name{" "}
                        <span className="fw-light">{md[0]["first_name"]}</span>{" "}
                      </p>
                      <p className="fw-bold">
                        Middle name{" "}
                        <span className="fw-light">{md[0]["first_name"]}</span>{" "}
                      </p>
                      <p className="fw-bold">
                        Last tname{" "}
                        <span className="fw-light">{md[0]["last_name"]}</span>{" "}
                      </p>
                    </div>
                    <div className="col-md-12 mt-3">
                      <hr />
                      <h3 className="text-center">Suspension Detail</h3>
                      <p className="fw-bold">
                        Suspension Date{" "}
                        <span className="fw-light">
                          {md[0]["suspend"]["suspension_start"]}
                        </span>{" "}
                      </p>
                      <p className="fw-bold">
                        Suspension End{" "}
                        <span className="fw-light">
                          {md[0]["suspend"]["suspension_end"]}
                        </span>{" "}
                      </p>
                      <p className="fw-bold">
                        Suspension Message{" "}
                        <span className="fw-light">
                          {md[0]["suspend"]["suspension_end"]}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <Image
                  src={loadingGif}
                  alt=".."
                  className="img-fluid text-center"
                />
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Unsuspend user */}
      <Modal
        show={showSuspend}
        onHide={() => {
          setShowSuspend(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Unsuspend</Modal.Title>
        </Modal.Header>
        <div className="modal-body">
          <div className="container">
            <div className="text-center mb-2">
              <h5 className="fw-light m-0 p-0"> Comfirmation Code </h5>
              <p className="fw-bold m-0 p-0">
                {m ? randomNum : "loadingGif...."}
              </p>
            </div>
            <p>
              Are you sure you want to unsuspend this user? Please enter the
              code above
            </p>
            <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control"
                id="comfirm"
                placeholder="code"
                style={{ textTransform: "uppercase" }}
                value={suspendCode}
                onChange={(e) => {
                  setSuspendCode(e.target.value.toUpperCase());
                }}
              />
              <label htmlFor="comfirm">Enter code</label>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => {
              setShowSuspend(false);
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-success"
            onClick={unsuspendUser}
          >
            Unsuspend
          </button>
        </div>
      </Modal>
    </>
  );
};

export const SuspendedAdminsTable = (props: any) => {
  const [loaded, setLoaded] = useState(false);
  const [users, setUser] = useState([]);
  const [m, setM] = useState(false);
  const [md, setMd] = useState([]);
  const [suspendCode, setSuspendCode] = useState("");
  const [randomNum, setRandomNum] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoaded(false);

    BaseService.get_api(
      `${global_variables.yarpusers}?filter={"condition":"admin_suspend","type":"fleet"}&sorting={"online":-1}`
    )
      .then(function (response) {
        setUser(response.data.payload);

        // console.log(response.data.payload);

        setLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const modalTrigger = (i: any) => {
    var go = users[i]["userid"];

    var thisUser = users.filter(function (theUser: any) {
      return theUser.userid === go;
    });
    // console.log(thisUser)
    setM(true);
    setMd(thisUser);
    setRandomNum(GenerateId(5));
  };

  const modalTriggerUn = (i: any) => {
    var go = users[i]["userid"];

    var thisUser = users.filter(function (theUser: any) {
      return theUser.userid === go;
    });
    // console.log(thisUser)
    setM(true);
    setMd(thisUser);
    setRandomNum(GenerateId(5));
    setShow(true);
  };

  const unsuspendUser = async (e: any) => {
    e.preventDefault();

    Swal.fire({
      title: "Authenticating...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      // timer: 3000,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (randomNum !== suspendCode) {
      Swal.fire({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "Please enter the comfirmaton code to suspend user from yarp",
      });
    } else {
      const userId = m ? md[0]["userid"] : "none";

      var data = {
        userid: userId,
      };

      BaseService.put_api(global_variables.unsuspend_users, data)
        .then((response) => {
          // console.log(response.data);

          Swal.fire({
            toast: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            text: response.data.message,
          }).then(() => {
            getUsers();
            setShow(false);
            setSuspendCode("");
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <>
      {loaded ? (
        <>
          {users.length === 0 ? (
            <div className="mt-[50px]">
              <Empty description="No data" />
            </div>
          ) : (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Full name</th>
                    <th scope="col">Suspension End</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((d: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td>
                          {d.image ? (
                            <Image
                              src={d.image}
                              alt=".."
                              fluid
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                                borderRadius: "100%",
                              }}
                            />
                          ) : (
                            <Image
                              src={icon}
                              alt=".."
                              fluid
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                                borderRadius: "100%",
                              }}
                            />
                          )}
                        </td>
                        <td>{d.full_name}</td>
                        <td>
                          {moment(d.suspend["suspension_end"]).format(
                            "Do MMM YYYY"
                          )}
                        </td>
                        <td>
                          <div className="flex gap-3">
                            <Link
                              to="#"
                              style={{
                                textDecoration: "none",
                                color: "#000",
                                fontSize: "22px",
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#viewUser"
                              onClick={modalTrigger.bind(this, index)}
                            >
                              <EyeIcon className="h-5 w-5 cursor-pointer" />
                            </Link>

                            <Link
                              to="#"
                              style={{
                                textDecoration: "none",
                                color: "green",
                                fontSize: "22px",
                              }}
                              onClick={modalTriggerUn.bind(this, index)}
                            >
                              <UserPlusIcon className="h-5 w-5 cursor-pointer" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </>
      ) : (
        <Container className="text-center mt-5 flex justify-center items-center">
          <Image width={200} src={loadingGif} fluid />
        </Container>
      )}
      {/* ViewUser Detail */}
      <div
        className="modal fade"
        id="viewUser"
        tabIndex={-1}
        aria-labelledby="viewUserLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              {m ? (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="text-center">User detail</h3>
                    </div>
                    <hr />
                    <div className="col-md-6">
                      {md[0]["image"] ? (
                        <Image
                          src={md[0]["image"]}
                          alt=".."
                          fluid
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <Image
                          src={icon}
                          alt=".."
                          fluid
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: "100%",
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <p className="fw-bold">
                        First name{" "}
                        <span className="fw-light">{md[0]["first_name"]}</span>{" "}
                      </p>
                      <p className="fw-bold">
                        Middle name{" "}
                        <span className="fw-light">{md[0]["first_name"]}</span>{" "}
                      </p>
                      <p className="fw-bold">
                        Last tname{" "}
                        <span className="fw-light">{md[0]["last_name"]}</span>{" "}
                      </p>
                    </div>
                    <div className="col-md-12 mt-3">
                      <hr />
                      <h3 className="text-center">Suspension Detail</h3>
                      <p className="fw-bold">
                        Suspension Date{" "}
                        <span className="fw-light">
                          {md[0]["suspend"]["suspension_start"]}
                        </span>{" "}
                      </p>
                      <p className="fw-bold">
                        Suspension End{" "}
                        <span className="fw-light">
                          {md[0]["suspend"]["suspension_end"]}
                        </span>{" "}
                      </p>
                      <p className="fw-bold">
                        Suspension Message{" "}
                        <span className="fw-light">
                          {md[0]["suspend"]["suspension_end"]}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <Image
                  src={loadingGif}
                  alt=".."
                  className="img-fluid text-center"
                />
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Unsuspend admin */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <form className="modal-content" onSubmit={unsuspendUser}>
          <Modal.Header closeButton>
            <Modal.Title>Unsuspend Admin</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
            <div className="container">
              <div className="text-center mb-2">
                <h5 className="fw-light m-0 p-0"> Comfirmation Code </h5>
                <p className="fw-bold m-0 p-0">
                  {m ? randomNum : "loadingGif...."}
                </p>
              </div>
              <p>
                Are you sure you want to unsuspend this user? Please enter the
                code above
              </p>
              <div className="form-floating mb-2">
                <input
                  type="text"
                  className="form-control"
                  id="comfirm"
                  placeholder="code"
                  style={{ textTransform: "uppercase" }}
                  value={suspendCode}
                  onChange={(e) => {
                    setSuspendCode(e.target.value.toUpperCase());
                  }}
                />
                <label htmlFor="comfirm">Enter code</label>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="success"
              onClick={() => {
                setShow(false);
              }}
            >
              Unsuspend
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
