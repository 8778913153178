import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button, Textarea } from "@material-tailwind/react";
import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";

interface declineProps {
  isOpen: boolean;
  handleClose: () => void;
  handleReFetch: () => void;
  truckData: any;
}

const DeclineTruckDialog = ({
  isOpen,
  handleClose,
  truckData,
  handleReFetch,
}: declineProps) => {
  const [isBusy, setIsBusy] = useState(false);
  const [theReason, setTheReason] = useState("");

  //handleDecline
  const handleDecline = async () => {
    let data = {
      truck_number: truckData?.truckRegisteration,
      approved: false,
      remarks: theReason,
    };
    setIsBusy(true);
    try {
      const response = await BaseService.put_api_v2(
        global_variables.approve_or_decline_truck_v2,
        data
      );
      console.log(response);
      ShowToast.success_toast(response?.data?.message);
      handleClose();
      handleReFetch();
    } catch (error: any) {
      console.log(error);
      ShowToast.error_toast(error?.response?.data?.error);
      handleClose();
    }
  };

  return (
    <>
      <Modal show={isOpen} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="flex justify-between items-center text-black">
            <h5>Decline {truckData?.model}</h5>
            <XMarkIcon
              className="h-5 w-5 cursor-pointer"
              onClick={handleClose}
            />
          </div>

          <hr />

          <div>
            <Textarea
              value={theReason}
              onChange={(e) => setTheReason(e.target.value)}
              placeholder="Enter reason here..."
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="green"
            onClick={() => handleDecline()}
            disabled={isBusy || !theReason}
          >
            {isBusy ? <Spinner /> : <span>Confirm</span>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeclineTruckDialog;
