import { useEffect } from "react";
import TransactionTable from "./transactions_table";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";

const Transactions = () => {
  useEffect(() => {
    document.title = "Transactions - Yarp Company";
  }, []);

  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar transActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">
                Transactions
              </p>

              <div className="mt-3 bg-white shadow-md rounded-[20px] p-5">
                <TransactionTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
