import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import NotificationTable from "./NotificationTable";

const Notifications = ({ audioEnabled }: { audioEnabled: any }) => {
  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        <CustomSideBar
          NotificationsActive="active"
          audioEnabled={audioEnabled}
        />
        <div className="w-full">
          <CustomNavbar />
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">
                Notifications
              </p>

              <div className="mt-[20px] bg-white shadow-sm p-[20px] rounded-[20px]">
                <NotificationTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
