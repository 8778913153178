import moment from "moment";

const useCurrentWeek = (TimePeriod: string) => {
  // const currentDate = moment("2024-03-03T00:00:00.000Z");
  const currentDate = moment();

  //week
  const weekStart = currentDate.clone().startOf("isoWeek");
  const secondDay = weekStart.clone().add(1, "days");
  const thirdDay = weekStart.clone().add(2, "days");
  const forthDay = weekStart.clone().add(3, "days");
  const fifthDay = weekStart.clone().add(4, "days");
  const sixthDay = weekStart.clone().add(5, "days");
  const seventhDay = weekStart.clone().add(6, "days");
  const extraDay = weekStart.clone().add("1", "weeks");

  const formattedDayOne = weekStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedDayTwo = secondDay.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedDayThree = thirdDay.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedDayFour = forthDay.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedDayFive = fifthDay.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedDaysix = sixthDay.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedDayseven = seventhDay.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedextraDay = extraDay.format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  //start of the month
  const monthStart = currentDate.clone().startOf("month");
  const secondWeekStart = monthStart.clone().add(1, "weeks");
  const thirdWeekStart = monthStart.clone().add(2, "weeks");
  const fourthWeekStart = monthStart.clone().add(3, "weeks");
  const addExtraWeek = monthStart.clone().add("1", "month");

  const formattedMonthOne = monthStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedMonthTwo = secondWeekStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedMonthThree = thirdWeekStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedMonthFour = fourthWeekStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedWeekaddExtraWeek = addExtraWeek.format(
    "YYYY-MM-DDTHH:mm:ss.SSSZ"
  );

  //year
  const YearStart = currentDate.clone().startOf("years");
  const secondYearStart = YearStart.clone().add(1, "months");
  const thirdYearStart = YearStart.clone().add(2, "months");
  const fourthYearStart = YearStart.clone().add(3, "months");
  const fithhYearStart = YearStart.clone().add(4, "months");
  const sixhYearStart = YearStart.clone().add(5, "months");
  const seventhYearStart = YearStart.clone().add(6, "months");
  const eightYeaarStart = YearStart.clone().add(7, "months");
  const nineYearStart = YearStart.clone().add(8, "months");
  const tenYearStart = YearStart.clone().add(9, "months");
  const elevenYearStart = YearStart.clone().add(10, "months");
  const twelveYearStart = YearStart.clone().add(11, "months");

  const OverYear = YearStart.clone().add(1, "years");
  const formattedYearOver = OverYear.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedYearOne = YearStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedYearTwo = secondYearStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedYearThree = thirdYearStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedYearFour = fourthYearStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedYearfix = fithhYearStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedYearsix = sixhYearStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedYearseven = seventhYearStart.format(
    "YYYY-MM-DDTHH:mm:ss.SSSZ"
  );
  const formattedYeareight = eightYeaarStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedYaernine = nineYearStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedYearTen = tenYearStart.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const formattedYearEleven = elevenYearStart.format(
    "YYYY-MM-DDTHH:mm:ss.SSSZ"
  );
  const formattedYearTwelve = twelveYearStart.format(
    "YYYY-MM-DDTHH:mm:ss.SSSZ"
  );

  //year data
  const monthOne = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYearOne}"}}, {"timestamp":{"$lt":"${formattedYearTwo}"}}]}`;
  const monthTwo = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYearTwo}"}}, {"timestamp":{"$lt":"${formattedYearThree}"}}]}`;
  const monthThree = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYearThree}"}}, {"timestamp":{"$lt":"${formattedYearFour}"}}]}`;
  const monthFour = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYearFour}"}}, {"timestamp":{"$lt":"${formattedYearfix}"}}]}`;
  const monthFive = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYearfix}"}}, {"timestamp":{"$lt":"${formattedYearsix}"}}]}`;
  const monthSix = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYearsix}"}}, {"timestamp":{"$lt":"${formattedYearseven}"}}]}`;
  const monthSeven = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYearseven}"}}, {"timestamp":{"$lt":"${formattedYeareight}"}}]}`;
  const monthEight = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYeareight}"}}, {"timestamp":{"$lt":"${formattedYaernine}"}}]}`;
  const monthnine = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYaernine}"}}, {"timestamp":{"$lt":"${formattedYearTen}"}}]}`;
  const monthTen = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYearTen}"}}, {"timestamp":{"$lt":"${formattedYearEleven}"}}]}`;
  const monthEleven = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYearEleven}"}}, {"timestamp":{"$lt":"${formattedYearTwelve}"}}]}`;
  const monthTwelev = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedYearTwelve}"}}, {"timestamp":{"$lt":"${formattedYearOver}"}}]}`;

  const yearData = [
    monthOne,
    monthTwo,
    monthThree,
    monthFour,
    monthFive,
    monthSix,
    monthSeven,
    monthEight,
    monthnine,
    monthTen,
    monthEleven,
    monthTwelev,
  ];
  //week Data
  const dayOne = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedDayOne}"}},{"timestamp":{"$lt":"${formattedDayTwo}"}} ]}`;
  const dayTwo = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedDayTwo}"}},{"timestamp":{"$lt":"${formattedDayThree}"}}]}`;
  const dayThree = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedDayThree}"}},{"timestamp":{"$lt":"${formattedDayFour}"}}]}`;
  const dayFour = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedDayFour}"}},{"timestamp":{"$lt":"${formattedDayFive}"}}]}`;
  const dayFive = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedDayFive}"}},{"timestamp":{"$lt":"${formattedDaysix}"}}]}`;
  const daySix = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedDaysix}"}},{"timestamp":{"$lt":"${formattedDayseven}"}}]}`;
  const daySeven = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedDayseven}"}},{"timestamp":{"$lt":"${formattedextraDay}"}}]}`;
  const weekData = [
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
  ];

  //month Data
  const wekOne = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedMonthOne}"}}, {"timestamp":{"$lt":"${formattedMonthTwo}"}}]}`;
  const wekTwo = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedMonthTwo}"}}, {"timestamp":{"$lt":"${formattedMonthThree}"}}]}`;
  const wekThree = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedMonthThree}"}}, {"timestamp":{"$lt":"${formattedMonthFour}"}}]}`;
  const wekFour = `&?filter={"$and":[{"timestamp":{"$gte":"${formattedMonthFour}"}}, {"timestamp":{"$lt":"${formattedWeekaddExtraWeek}"}}]}`;

  const monthData = [wekOne, wekTwo, wekThree, wekFour];
  let query: string[] = [];
  if (TimePeriod === "Week") {
    query = weekData;
  }
  if (TimePeriod === "Month") {
    query = monthData;
  }
  if (TimePeriod === "Year") {
    query = yearData;
  }

  return [query];
};

export default useCurrentWeek;
