import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import NearbyDriversTable from "./NearbyDriversTable";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";

const NearbyDrivers = () => {
  const { param1, param2 } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        <CustomSideBar usersActive="active" />
        <div className="w-full">
          <CustomNavbar />
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">
                Near by drivers
              </p>
              <button
                className="flex gap-2 items-center h-[30px] px-3 bg-yarpBlue text-white rounded-[10px]"
                onClick={() => navigate(`/notifications/${param1}/${param2}`)}
              >
                <ArrowLeftIcon className="w-5 h-5" />
                Back
              </button>
              <div className="mt-[20px] bg-white shadow-sm p-[20px] rounded-[20px]">
                <NearbyDriversTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NearbyDrivers;
