import { useState } from 'react'
import { DraftedDriverPayload } from '../payload.model'
import { Modal, Input, notification, Spin } from 'antd'
import BaseService from '../../../helpers/baseService'
import { global_variables } from '../../../helpers/globalVariables'
const { TextArea } = Input

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    driverInfo: DraftedDriverPayload
}

const SendDriverSms = ({ isOpened, handleClose, driverInfo }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [theMessage, setTheMessage] = useState("")

    //handleSendSms
    const handleSendSms = async () => {
        if (!theMessage) {
            notification.warning({ message: "Please enter a message" })
        }
        else {
            let data = JSON.stringify({
                message: theMessage,
                numbers: [driverInfo?.user_data?.phone],
            })
            setIsBusy(true)
            try {
                const response = await BaseService.post_api(global_variables.sendSms, data)
                console.log(response?.data)
                notification.success({ message: response?.data?.message })
                setIsBusy(false)
                handleClose()
            } catch (error: any) {
                console.log(error)
                notification.error({ message: error?.response?.data?.message })
                setIsBusy(false)
            }
        }
    }

    return (
        <>
            <Modal title="Send sms to driver" open={isOpened} footer={null} closeIcon={false}>
                <Spin spinning={isBusy}>
                    <hr className='my-3' />
                    <div>
                        <Input value={driverInfo?.user_data?.phone} placeholder='Phone Number' disabled />

                        <TextArea className='mt-3' value={theMessage} onChange={(e) => setTheMessage(e.target.value)} placeholder='Enter your message' />

                        <div className='flex gap-3 justify-end items-center mt-4'>
                            <button className='px-4 py-2 border-[1px] border-gray-400 rounded-[5px]' onClick={handleClose}>Cancel</button>
                            <button className='px-4 py-2 bg-yarpOrange text-white rounded-[5px]' onClick={() => handleSendSms()}>Send Message</button>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default SendDriverSms
