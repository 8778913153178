import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import {
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Button } from "@material-tailwind/react";

const ProductCategory = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [show, setShow] = useState<any>(0);

  //on load
  useEffect(() => {
    getData(limit, skip);
  }, []);

  //get all categories
  const getData = (lim: number, ski: number) => {
    setLoading(true);

    BaseService.get_api(
      `${global_variables.get_products_cats}?sort={"created_at": -1}&limit=${lim}&skip=${ski}`
    )
      .then((res) => {
        setLoading(false);
        setData(res?.data?.payload);
        setTotal(res?.data?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns: any = [
    {
      name: "NAME",
      selector: (row: { tittle: any }) => row.tittle,
    },
    {
      name: "CREATED ON",
      selector: (row: { updated_at: moment.MomentInput }) =>
        moment(row.updated_at).format("Do MMMM YYYY"),
    },
  ];

  const handleRowChange = (a: any) => {
    getData(a, 0);
    setLimit(a);
    setSkip(0);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getData(limit, newSkip);
      setSkip(newSkip);
    }
  };

  const [cats, setCategory] = useState("");

  const addBrand = () => {
    ShowToast.loading_toast();

    var upload_data = {
      tittle: cats,
    };

    BaseService.post_api(global_variables.create_products_cats, upload_data)
      .then((res) => {
        setShow(false);
        ShowToast.success_toast(res?.data?.message);
        setCategory("");
        getData(limit, skip);
      })
      .catch((err) => {
        console.log(err);
        ShowToast.error_toast("Error creating product category");
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h5>Product category</h5>
        <Button
          variant="gradient"
          color="blue"
          onClick={() => {
            setShow(true);
          }}
        >
          Add products
        </Button>
      </div>

      <DataTable
        data={data}
        columns={columns}
        paginationTotalRows={total}
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<p>You do not have any Product caterory posted</p>}
        pagination={total >= 11 ? true : false}
        paginationServer
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
      />

      {/* Add  */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add product category</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FloatingLabel
            controlId="pname"
            label="product name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Mango"
              value={cats}
              onChange={(e) => setCategory(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="gradient" color="blue" onClick={() => addBrand()}>
            Post
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductCategory;
