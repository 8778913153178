import { Image } from "react-bootstrap";
import yarp_spinner from "./../../images/yarp-spinner.gif";

export const LoadingDataComponent = () => {
  return (
    <div className="flex justify-center items-center w-[120px]">
      <Image fluid src={yarp_spinner} />
      {/* <p>{message}</p> */}
    </div>
  );
};
