import { useState } from "react";
import { Container, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { formatCedis } from "../../../utils/formatCedis";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Empty } from "antd";

const PaymentLimit = () => {
  const [loading, setLoading] = useState(true);
  const [payment_limit, setPaymentLimit] = useState([]);

  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState("");
  const [freeMode, setFreeMode] = useState("");
  const [pack, setPack] = useState("com.yarp.yarp");

  const getPaymentLimit = () => {
    setLoading(true);

    BaseService.get_for_payment(`${global_variables.get_payment_limits}`)
      .then((res) => {
        setPaymentLimit(res?.data?.payload);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPaymentLimit();
    document.title = "Payment Limits - Yarp Main";
  }, []);

  const columns: any = [
    {
      name: "Currency",
      selector: (row: { currency: any }) => row.currency,
    },
    {
      name: "Amount",
      selector: (row: { amount: any }) => formatCedis(row.amount),
    },
    {
      name: "Free mode",
      selector: (row: { free_mode: any }) => row.free_mode,
    },
    {
      name: "Package",
      selector: (row: { package: any }) => row.package,
    },
    {
      name: "Last updated",
      selector: (row: { updated_at: moment.MomentInput }) =>
        moment(row.updated_at).format("Do MMM YYYY"),
    },
  ];

  const addPayment = () => {
    ShowToast.loading_toast();

    const paymentData = {
      amount: parseFloat(amount),
      free_mode: parseFloat(freeMode),
      package: pack,
      currency: "GHC",
    };

    BaseService.post_for_payment(
      global_variables.create_payment_limit,
      paymentData
    )
      .then((res) => {
        ShowToast.success_toast(res?.data?.message);
        getPaymentLimit();
        setAmount("");
        setFreeMode("");
        setShow(false);
      })
      .catch((err) => {
        ShowToast.error_toast(err?.error);
      });
  };

  return (
    <>
      <div className="text-end mb-3">
        <button
          className="px-3 py-2 bg-yarpOrange text-white rounded-[30px] shadow-md"
          onClick={() => {
            setShow(true);
          }}
        >
          Add payment limit
        </button>
      </div>
      <DataTable
        data={payment_limit}
        columns={columns}
        progressPending={loading}
        progressComponent={
          <div className="flex justify-center items-center h-[400px]">
            <LoadingDataComponent />
          </div>
        }
        noDataComponent={
          <div className="mt-[50px]">
            <Empty description="No data" />
          </div>
        }
      />

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add payment limit</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <FloatingLabel
              className="mb-3"
              controlId="floatingAmount"
              label="Amount"
            >
              <Form.Control
                type="number"
                placeholder="5.00"
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel
              className="mb-3"
              controlId="floatingFreeMode"
              label="Free Mode"
            >
              <Form.Control
                type="number"
                placeholder="5.00"
                value={freeMode}
                onChange={(e) => {
                  setFreeMode(e.target.value);
                }}
              />
            </FloatingLabel>

            <FloatingLabel
              className="mb-3"
              controlId="Package"
              label="Select Package"
              onChange={(e: any) => {
                setPack(e.target.value);
              }}
            >
              <Form.Select aria-label="Select the package">
                <option value="com.yarp.yarp">com.yarp.yarp</option>
              </Form.Select>
            </FloatingLabel>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="px-3 py-2 bg-yarpOrange text-white rounded-[30px] shadow-md"
            onClick={() => addPayment()}
          >
            Publish
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentLimit;
