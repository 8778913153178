import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Image, Modal, Row, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { formatCedis } from "../../../utils/formatCedis";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import NoDataComponent from "../../../components/general/noDataComponent";
import { Tooltip } from "@material-tailwind/react";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";

export const TripHistoryTable = ({
  user_id,
  mytype,
}: {
  user_id: any;
  mytype: any;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [trips, setTrips] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [reFetch, setReFetch] = useState<boolean>(false);

  const getUserTripHistory = async () => {
    try {
      const results = await BaseService.get_api_v2(
        `/yarp/trip-history?filter={"userid":"${user_id}"}`
      );
      const data = await results.data;
      setTotal(data?.total);
      setTrips(data?.payload);
      setLoading(false);
      console.log("Data is ", data);
    } catch (error) {
      console.log("Error is ", error);
    }
  };

  useEffect(() => {
    // getData(limit, skip);
    getUserTripHistory();
  }, [reFetch]);

  const columns: any = [
    {
      name: "Amount",
      selector: (row: { amount: any }) => formatCedis(row.amount),
    },
    {
      name: "Delivery Location",
      cell: (row: { delivery_place_name: any }) => row.delivery_place_name,
    },
    {
      name: "Pickup Location",
      cell: (row: { pickup_place_name: any }) => row.pickup_place_name,
    },
  ];

  const handleRowChange = (a: any) => {
    // getData(a, skip);
    //getUserTripHistory();

    setLimit(a);
  };

  const pageChange = (a: any) => {
    const newSkip = a === 1 ? 0 : (a - 1) * limit;
    //getUserTripHistory();
    // getUserTripHistory();

    setSkip(newSkip);
  };

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<any>([]);
  const showModal = (row: any) => {
    setShow(true);
    setModalData(row);
    console.log(row);
  };

  return (
    <>
      <div className="mt-[20px] flex justify-between items-center">
        <Tooltip content="Reload" placement="top">
          <button
            className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
            onClick={() => setReFetch(!reFetch)}
          >
            <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
          </button>
        </Tooltip>
        <div className="flex gap-[10px]">
          <Tooltip content="Print" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <PrinterIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <Tooltip content="Export" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
        </div>
      </div>
      <DataTable
        className="mt-[10px]"
        data={trips}
        columns={columns}
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<NoDataComponent text="No trip records found" />}
        pointerOnHover
        paginationTotalRows={total}
        pagination={total >= 11 ? true : false}
        paginationServer={total >= 11 ? true : false}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        onRowClicked={showModal}
      />

      {/* show detail  */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Trip detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show ? (
            <Table>
              <tbody>
                <tr>
                  <th colSpan={2}>
                    <h4
                      style={{
                        color: "#656565",
                      }}
                    >
                      Driver information
                    </h4>
                  </th>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>Driver name</th>
                  <td>{modalData?.driver_data?.full_name}</td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>Contact</th>
                  <td>{modalData.driver_data?.phone}</td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>created at</th>
                  <td>
                    {moment(modalData.created_at).format(
                      "Do MMMM YYYY HH:MM a"
                    )}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>Process number</th>
                  <td>{modalData.processNumber}</td>
                </tr>
                <tr>
                  <th colSpan={2}>
                    <h4
                      style={{
                        color: "#656565",
                        marginTop: "30px",
                      }}
                    >
                      Trip information
                    </h4>
                  </th>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>Total distance</th>
                  <td>{modalData.distance?.toLocaleString("en-US")}</td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>Truck type</th>
                  <td>{modalData.car_type}</td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>From</th>
                  <td>{modalData.delivery_place_name}</td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>To</th>
                  <td>{modalData.pickup_place_name}</td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>Total cost</th>
                  <td>{formatCedis(modalData.amount)}</td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>Notes</th>
                  <td>{modalData.notes}</td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>Items</th>
                  <td>
                    {modalData.items?.map((d: any, index: any) => {
                      return (
                        <p key={index}>
                          {d.name} - x{d.quantity} - {d.weight}
                          {d.unit}
                        </p>
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>Status history</th>
                  <td>
                    <p>
                      Accept :{" "}
                      {moment(modalData?.status_history?.Accept).format(
                        "Do MMMM YYYY HH:MM a"
                      )}
                    </p>
                    <p>
                      Arrived at pickup point :{" "}
                      {moment(
                        modalData?.status_history?.Arrived_At_PickUp
                      ).format("Do MMMM YYYY HH:MM a")}
                    </p>
                    <p>
                      Start Loading :{" "}
                      {moment(
                        modalData?.status_history?.Arrived_At_PickUp
                      ).format("Do MMMM YYYY HH:MM a")}
                    </p>
                    <p>
                      Trip started :{" "}
                      {moment(modalData?.status_history?.Trip_Started).format(
                        "Do MMMM YYYY HH:MM a"
                      )}
                    </p>
                    <p>
                      Start offloading :{" "}
                      {moment(
                        modalData?.status_history?.Start_Offloading
                      ).format("Do MMMM YYYY HH:MM a")}
                    </p>
                    <p>
                      Payment complete :{" "}
                      {moment(
                        modalData?.status_history?.Payment_Complete
                      ).format("Do MMMM YYYY HH:MM a")}
                    </p>
                  </td>
                </tr>

                <tr>
                  <th style={{ width: "20%" }}>Images</th>
                  <td>
                    <Row>
                      {modalData?.images.map((d: any, index: any) => {
                        return (
                          <Col md={3} key={index}>
                            <Image fluid src={d} />
                          </Col>
                        );
                      })}
                    </Row>
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
