import React from "react";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

const NewDatePickerRange = ({ onDateData }: { onDateData: any }) => {
  const handleDateRangeChange = (dates: any) => {
    if (dates && dates.length === 2) {
      const startOfDay = new Date(dates[0]);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(dates[1]);
      endOfDay.setHours(23, 59, 59, 999);

      const startDate = new Date(startOfDay).toISOString();
      const endDate = new Date(endOfDay).toISOString();
      onDateData({ start: startDate, end: endDate });
    } else {
      onDateData({ start: null, end: null });
    }
  };

  return (
    <>
      <Space direction="vertical" size={12}>
        <RangePicker
          style={{ height: "40px" }}
          onChange={handleDateRangeChange}
        />
      </Space>
    </>
  );
};

export default NewDatePickerRange;
