import { useEffect, useState } from "react";
import { Modal } from "antd";
import ShowToast from "../../../components/alerts/all_toasts";
import BaseService from "../../../helpers/baseService";

const { confirm } = Modal;

interface ModalProps {
  openUnAssignedmodal: boolean;
  handleReftch: any;
  OnRoadDataOne: {
    _id: string;
    userid: string;
    driverId: string;
  };
  setUnAssignedmodal: (open: boolean) => void;
}

const UnassignDriverModal = ({
  setUnAssignedmodal,
  openUnAssignedmodal,
  OnRoadDataOne,
  handleReftch,
}: ModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { _id, userid, driverId } = OnRoadDataOne;

  const handleCancel = () => {
    setUnAssignedmodal(false);
  };

  const handleOk = async () => {
    confirm({
      title: "Are you sure you want to unassign the trip from this driver?",
      content: "This action cannot be undone.",
      onOk: async () => {
        setLoading(true);

        const payload = {
          trip_id: _id,
          status: "Not Available",
          userid: userid,
          driver: driverId,
        };

        try {
          await BaseService.put_api_v2(`/yarp/trip/event`, payload);
          setTimeout(() => setUnAssignedmodal(false), 800);
          ShowToast.success_toast(
            "Trip has been successfully unassigned from the driver"
          );
          handleReftch();
        } catch (error: any) {
          ShowToast.info_toast(error.message || "Something went wrong");
          setUnAssignedmodal(true);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        setUnAssignedmodal(false);
      },
      okButtonProps: {
        style: {
          backgroundColor: "#52b3ef",
          color: "#FFFFFF",
          boxShadow: "none",
        },
      },
      cancelButtonProps: {
        style: { boxShadow: "none" },
      },
    });
  };

  return (
    <>
      <Modal
        width={"35%"}
        open={openUnAssignedmodal}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        okText="Unassign Trip"
        okButtonProps={{
          style: {
            backgroundColor: "#52b3ef",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "10vh" } }}
      >
        <div className=" w-full h-full">
          <section className="bg-white  flex justify-center items-center mt-10 p-6  text-center">
            <h1 className="text-lg font-semibold mb-4 text-[#252363]">
              Are you sure you want to unassign this trip from the driver?
            </h1>
            <div className="flex p-2 gap-4"></div>
          </section>
        </div>
      </Modal>
    </>
  );
};

export default UnassignDriverModal;
