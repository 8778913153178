import { Image, Modal } from "react-bootstrap";
import { TripDataModel } from "./tripdata.model";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { BASE_URL_V1, MAPS_KEY } from "../../helpers/constants";
import { StorageBox } from "../../helpers/storage";
import Swal from "sweetalert2";
import loadingGif from "../../images/yarp-spinner.gif";
import { iconPath, tracker } from "../../pages/tracking/iconPath";
import socketConnection from "../../sockets/connection";
import { useState, useEffect } from "react";

const { io } = require("socket.io-client");

interface modalProps {
  isOpened: boolean;
  handleCloseModal: () => void;
  tripData: TripDataModel;
}

const containerStyle = {
  width: "100%",
  height: "80vh",
};

const center = {
  lat: 5.6297604,
  lng: -0.303076,
};

const TripHistoryMap = ({
  isOpened,
  handleCloseModal,
  tripData,
}: modalProps) => {
  const [location, setLocation] = useState<any>(null);
  const [dataSuccess, setDataSuccess] = useState(false);
  const [logLocation, setLogLocation] = useState([]);
  const [getInitialRotation, setInitailRotation] = useState<number>(0);

  const socket = io(`${BASE_URL_V1}/yarplisteners`);

  const initChannel = "TokenPocket";
  const handShakeParams = {
    token: StorageBox.getAccessToken(),
    initial_channel: initChannel,
  };

  //onload
  useEffect(() => {
    startSocket();
    // eslint-disable-next-line
  }, []);

  //Trip logs
  const getTripLogs = (res: any) => {
    console.log("res1", res);
    socket.emit(`${res.payload.main_channel}/core/get-triplog`, {
      trackingId: tripData?._id,
    });

    socket.on(
      `${res.payload.main_channel}/core/get-triplog`,
      (response: any) => {
        console.log("tripLogs:", response);

        // setTLogSuccess(response.success);
        if (response.success) {
          // console.log(response.payload.channel)
          setLogLocation(response.payload);
          const new_channel = response.trip_channel;

          socket.on(new_channel, (channelResponse: any) => {
            console.log("channelResponse:", channelResponse);
            var tempArr: any = logLocation;
            tempArr.push(channelResponse);
            setLogLocation(tempArr);
          });
        }
      }
    );
  };

  //start sockets
  const startSocket = () => {
    socket.on("connect", () => {
      // console.log('Connected');

      socket.emit("/yarp/handshake", handShakeParams);

      socket.on(initChannel, (res: any) => {
        // console.log(res)

        //call get trip logs
        getTripLogs(res);

        socket.emit(`${res.payload.main_channel}/core/get-location`, {
          userid: tripData?.driverId,
        });

        socket.on(
          `${res.payload.main_channel}/core/get-location`,
          (response: any) => {
            // console.log("driverLocation:", response);

            setDataSuccess(response.success);
            if (response.success) {
              // console.log(response.payload.channel)
              setLocation(response.payload.location);
              const new_channel = response.payload.channel;

              socket.on(new_channel, (channelResponse: any) => {
                // console.log("channelRes:", channelResponse);
                setLocation(channelResponse);
              });
            }
          }
        );
      });
    });

    socket.on("disconnect", () => {
      // console.log("Disconnected");
      Swal.fire({
        icon: "info",
        text: "Connection lost! Please reload page",
        position: "top-right",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${MAPS_KEY}`,
  });

  return (
    <>
      <Modal
        show={isOpened}
        size="xl"
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div>
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                // center={props.mapOrigin}
                center={{
                  lat: location?.latitude ?? center.lat,
                  lng: location?.longitude ?? center.lng,
                }}
                zoom={10}
                // onLoad={onLoad}
                // onUnmount={onUnmount}
              >
                {dataSuccess && (
                  <>
                    <Marker
                      position={{
                        lat: location?.latitude,
                        lng: location?.longitude,
                      }}
                      label=""
                      icon={{
                        path: iconPath,
                        fillColor: "#ff6700",
                        fillOpacity: 0.9,
                        scale: 2,
                        strokeColor: "black",
                        strokeWeight: 1,
                        rotation: getInitialRotation - 90,
                      }}
                    />

                    {/* trip logs */}
                    {logLocation.map((loc: any, index: number) => (
                      <Marker
                        position={{ lat: loc?.latitude, lng: loc?.longitude }}
                        label=""
                        key={index}
                        icon={{
                          path: tracker,
                          fillColor: "#ff6700",
                          fillOpacity: 0.9,
                          scale: 0.05,
                          strokeColor: "#ff6700",
                          rotation: loc.rotation,
                        }}
                      />
                    ))}
                  </>
                )}
              </GoogleMap>
            ) : (
              <div className="text-center flex justify-center items-center h-full">
                <Image
                  fluid
                  src={loadingGif}
                  alt="..."
                  style={{ width: "150px" }}
                />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TripHistoryMap;
