import { useState, useEffect } from "react";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import DataTable from "react-data-table-component";
import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/outline";
import CreateProfessionModal from "./createProfession";
import EditProfessionModal from "./editProfession";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Empty } from "antd";

const AllProfessions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [professionData, setProfessionData] = useState([]);
  const [reFetch, setReFetch] = useState(false)
  const [selectedForEdit, setSelectedForEdit] = useState<any>(null)

  //open create modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const handleOpenCreateModal = () => setOpenCreateModal(!openCreateModal);

  //open edit modal
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = () => setOpenEditModal(!openEditModal);
  //data for edit
  const triggerEdit = (data: any) => {
    setSelectedForEdit(data)
    handleOpenEditModal()
  }


  //onload
  useEffect(() => {
    const getProfessions = () => {
      setIsLoading(true);
      BaseService.get_api(`${global_variables.get_profession}`)
        .then((response) => {
          // console.log(response);
          setProfessionData(response?.data?.payload);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getProfessions();
  }, [reFetch]);

  //table colums
  const columns = [
    {
      name: "Title",
      selector: (row: any) => row?.profession?.profession,
    },
    {
      name: "Description",
      cell: (row: any) => row?.profession?.description,
      width: "60%",
    },
    {
      name: "Action",
      selector: (row: any) => (
        <div className="flex gap-4">
          <PlusIcon className="w-5 h-5 cursor-pointer" title="Assign Role" />
          <PencilSquareIcon className="w-5 h-5 cursor-pointer" title="Edit" onClick={() => triggerEdit(row)} />
        </div>
      ),
    },
  ];

  //handle refetch
  const handleReFetch = () => {
    setReFetch(!reFetch);
  }

  return (
    <>
      <div className="flex justify-end">
        <button className="px-3 py-2 bg-yarpOrange text-white rounded-[30px] shadow-md" onClick={() => handleOpenCreateModal()}>
          + Create Profession
        </button>
      </div>

      <div>
        <DataTable
          columns={columns}
          data={professionData}
          progressPending={isLoading}
          progressComponent={<div className="flex justify-center items-center h-[400px]">
            <LoadingDataComponent />
          </div>}
          noDataComponent={
            <div className="mt-[50px]">
              <Empty description="No data" />
            </div>
          }
        />
      </div>

      {/* open create modal */}
      {
        openCreateModal ? <CreateProfessionModal openCreate={openCreateModal} handleOpenCreate={handleOpenCreateModal} handleReFetch={handleReFetch} /> : <></>
      }

      {/* open edit modal */}
      {
        openEditModal ? <EditProfessionModal openEdit={openEditModal} handleOpenEdit={handleOpenEditModal} selectedData={selectedForEdit} handleReFetch={handleReFetch} /> : <></>
      }
    </>
  );
};

export default AllProfessions;
