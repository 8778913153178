import { useEffect, useState } from 'react'
import { DatePicker, Input, Spin, notification } from 'antd'
import BaseService from '../../../helpers/baseService'
import { global_variables } from '../../../helpers/globalVariables'
import { calculateDateInterval } from '../../../utils/functions'
import TruckBrandSelector from '../../../components/shared/truckBrandSelector'
import TruckModelSelector from '../../../components/shared/truckModelSelector'
import TruckTypeSelector from '../../../components/shared/truckTypeSelector'
import TruckGoodsSelector from '../../../components/shared/truckGoodsSelector'
import TruckWeightSelector from '../../../components/shared/truckWeightSelector'
import { getAllTruckGoods } from '../../../functions/getAllTruckGoods'

interface theProps {
    driverInfo: any
    handleReFetch: () => void
    handleClose: () => void
}

const CompleteDriverProfile = ({ driverInfo, handleReFetch, handleClose }: theProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [isBusy2, setIsBusy2] = useState(false)
    const [nationalID, setNationalID] = useState("")
    const [licenceNum, setLicenceNum] = useState("")
    const [licenceIssued, setLicenceIssued] = useState<any>("")
    const [licenceExpiry, setLicenceExpiry] = useState<any>("")
    const [activeRenew, setActiveRenew] = useState<any>("")
    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")

    //truck
    const [truckReg, setTruckReg] = useState("")
    const [insNumber, setInsNumber] = useState("")
    const [insIssued, setInsIssued] = useState<any>("")
    const [insExpiry, setInsExpiry] = useState<any>("")
    const [roadNumber, setRoadNumber] = useState("")
    const [roadIssued, setRoadIssued] = useState<any>("")
    const [roadExpiry, setRoadExpiry] = useState<any>("")
    const [selectedBrand, setSelectedBrand] = useState("")
    const [selectedModel, setSelectedModel] = useState("")
    const [selectedTruckType, setSelectedTruckType] = useState("")
    const [truckGoods, setTruckGoods] = useState<any>([])
    const [truckWeight, setTruckWeight] = useState<any>([])
    const [goodsData, setGoodsData] = useState([])
    const [selectAll, setSelectAll] = useState(false)

    //onload
    useEffect(() => {
        getAllTruckGoods().then((response) => {
            const mappedRes = response.map((item: any) => (
                { label: item?.tittle, value: item?.tittle }
            ))
            setGoodsData(mappedRes)
        })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    //handleCreateDriver
    const handleCreateDriver = async () => {
        if (!fName || !lName || !licenceNum || !licenceIssued || !licenceExpiry || !activeRenew) {
            notification.warning({ message: "Please fill out all fields marked *" })
        }
        else {
            setIsBusy2(true)

            const data = {
                "_id": driverInfo?._id,
                "userid": driverInfo?.userid,
                "licence": licenceNum,
                "dateIssued": new Date(licenceIssued).toISOString(),
                "dateExpired": new Date(licenceExpiry).toISOString(),
                "licence_image": driverInfo?.licence_image || driverInfo?.document_images?.licence_image,
                "licence_back_image": driverInfo?.licence_back_image || driverInfo?.document_images?.licence_back_image,
                "national_id": nationalID,
                "national_id_front_image": driverInfo?.national_id_front_image || driverInfo?.document_images?.national_id_front_image,
                "national_id_back_image": driverInfo?.national_id_back_image || driverInfo?.document_images?.national_id_back_image,
                "license_renewals": calculateDateInterval(licenceIssued),
                "license_active_renewal": new Date(activeRenew).toISOString(),
                "user_details": {
                    "first_name": fName,
                    "last_name": lName,
                    "full_name": `${fName} ${lName}`,
                    "image": driverInfo?.profile_image
                }
            }

            if (!nationalID) {
                delete (data as any)['national_id']
                delete (data as any)['national_id_front_image']
                delete (data as any)['national_id_back_image']
            }

            if (driverInfo?.driver_data === null || driverInfo?.driver_data === undefined) {
                delete (data as any)['_id']
            }

            console.log("create_driver:", data)
            if (driverInfo?.driver_data) {
                console.log("using put driver----")
                try {
                    const response = await BaseService.put_api_v2(global_variables.driver_routes_v2, JSON.stringify(data))
                    console.log(response)
                    notification.success({ message: response?.data?.message })
                    setIsBusy2(false)
                } catch (error: any) {
                    console.log(error)
                    setIsBusy2(false)
                    notification.error({ message: error?.response?.data?.error })
                }
            }
            else {
                console.log("using post driver----")
                try {
                    const response = await BaseService.post_api_v2(global_variables.driver_routes_v2, JSON.stringify(data))
                    console.log(response)
                    notification.success({ message: response?.data?.message })
                    setIsBusy2(false)
                } catch (error: any) {
                    console.log(error)
                    setIsBusy2(false)
                    notification.error({ message: error?.response?.data?.error })
                }
            }
        }
    }

    //handleCreateTruck
    const handleCreateTruck = async () => {
        if (!truckReg || !insNumber || !insIssued || !insExpiry || !roadNumber || !roadIssued || !roadExpiry || !selectedBrand || !selectedModel || !selectedTruckType || truckGoods.length === 0 || truckWeight.length === 0) {
            notification.warning({ message: "Please fill out all fields marked *" })
        }
        else {
            setIsBusy(true)
            const truck_info = {
                "truckfront": driverInfo?.truck_data_partial?.truckfront || driverInfo?.truck_data?.truckfront,
                "truckside": driverInfo?.truck_data_partial?.truckside || driverInfo?.truck_data?.truckside,
                "insurance": driverInfo?.truck_data_partial?.insurance || driverInfo?.truck_data?.insurance,
                "insurance_text": insNumber,
                "insurance_expire": new Date(insExpiry).toISOString(),
                "roadworthy_inssued": new Date(roadIssued).toISOString(),
                "insurance_issued": new Date(insIssued).toISOString(),
                "roadworthy": driverInfo?.truck_data_partial?.roadworthy || driverInfo?.truck_data?.roadworthy,
                "roadworthy_text": roadNumber,
                "roadworthy_expire": new Date(roadExpiry).toISOString(),
                "model": `${selectedBrand} ${selectedModel}`,
                "weight_load": truckWeight,
                "type": "user",
                "category_type": truckGoods,
                "truckRegisteration": truckReg,
                "car_type": selectedTruckType,
                "userid": driverInfo?.truck_data_partial?.userid || driverInfo?.user_data?._id,
                "truckback": driverInfo?.truck_data_partial?.truckback || driverInfo?.truck_data?.truckback
            }

            console.log("create_truck:", truck_info)

            if (driverInfo?.truck_data) {
                console.log("using put------")
                try {
                    const response = await BaseService.put_api_v2(`${global_variables.admin_create_truck_v2}/${driverInfo?.truck_data?._id}`, JSON.stringify(truck_info))
                    console.log(response)
                    notification.success({ message: response?.data?.message })
                    handleCreateDriver()
                    setIsBusy(false)
                } catch (error: any) {
                    console.log(error)
                    handleCreateDriver()
                    setIsBusy(false)
                    notification.error({ message: error?.response?.data?.error })
                }
            }
            else {
                console.log("using post------")
                try {
                    const response = await BaseService.post_api_v2(global_variables.admin_create_truck_v2, JSON.stringify(truck_info))
                    console.log(response)
                    notification.success({ message: response?.data?.message })

                    handleCreateDriver()
                } catch (error: any) {
                    console.log(error)
                    handleCreateDriver()
                    setIsBusy(false)
                    notification.error({ message: error?.response?.data?.error })
                }
            }
        }
    }

    //handleSelectAll
    const handleSelectAll = (e?: any) => {
        if (e.target.checked) {
            console.log("select all")
            setSelectAll(true)
            setTruckGoods(goodsData.map((item: any) => item?.value))
        }
        else {
            console.log('default')
            setSelectAll(false)
            setTruckGoods([])
        }
    }

    //handleGoodsChange
    const handleGoodsChange = (data: any) => {
        setTruckGoods(data.map((item: any) => item?.value))
    }

    return (
        <>
            <Spin size="large" spinning={isBusy}>
                <div className='mb-4 mt-5'>
                    <label htmlFor='nId'>National ID Number:</label>
                    <Input id="nId" className='h-[40px]' placeholder='GH-123456789-00' value={nationalID} onChange={(e) => setNationalID(e.target.value)} disabled={isBusy2} />
                </div>

                <div className='mb-4'>
                    <label htmlFor='licence'>First Name: <span className='text-red-500'>*</span></label>
                    <Input id="licence" className='h-[40px]' placeholder='John' value={fName} onChange={(e) => setFName(e.target.value)} disabled={isBusy2} />
                </div>

                <div className='mb-4'>
                    <label htmlFor='licence'>Last Name: <span className='text-red-500'>*</span></label>
                    <Input id="licence" className='h-[40px]' placeholder='John' value={lName} onChange={(e) => setLName(e.target.value)} disabled={isBusy2} />
                </div>

                <div className='mb-4'>
                    <label htmlFor='licence'>License Number: <span className='text-red-500'>*</span></label>
                    <Input id="licence" className='h-[40px]' placeholder='919LICEMGARTTWUAZQQ' value={licenceNum} onChange={(e) => setLicenceNum(e.target.value)} disabled={isBusy2} />
                </div>

                <div className='mb-4'>
                    <label htmlFor='issued'>Date Issued (License): <span className='text-red-500'>*</span></label>
                    <div className='w-full'>
                        <DatePicker id="issued" className='h-[40px] w-full' value={licenceIssued} onChange={(e) => setLicenceIssued(e)} disabled={isBusy2} />
                    </div>
                </div>

                <div className='mb-4'>
                    <label htmlFor='exp'>Expiry Date (License): <span className='text-red-500'>*</span></label>
                    <div className='w-full'>
                        <DatePicker id="exp" className='h-[40px] w-full' value={licenceExpiry} onChange={(e) => setLicenceExpiry(e)} disabled={isBusy2} />
                    </div>
                </div>

                <div className='mb-4'>
                    <label htmlFor='renew'>Active Renewal (License): <span className='text-red-500'>*</span></label>
                    <div className='w-full'>
                        <DatePicker id="renew" className='h-[40px] w-full' value={activeRenew} onChange={(e) => setActiveRenew(e)} disabled={isBusy2} />
                    </div>
                </div>
                {/* 
                <div className="w-full">
                    <button className='w-full h-[40px] rounded-[5px] flex justify-center items-center bg-yarpOrange text-white disabled:cursor-not-allowed disabled:bg-gray-300' onClick={() => handleCreateDriver()} disabled={isBusy2}>
                        {
                            isBusy2 ? <Spin /> : "Create Driver Account"
                        }
                    </button>
                </div> */}

                <hr className='my-5' />

                <div className='mb-4'>
                    <label htmlFor='licence'>Truck Registration Number: <span className='text-red-500'>*</span></label>
                    <Input id="licence" className='h-[40px]' placeholder='GX-1234-23' value={truckReg} onChange={(e) => setTruckReg(e.target.value)} disabled={isBusy} />
                </div>

                <div className='mb-4'>
                    <label htmlFor='ins'>Insurance Number: <span className='text-red-500'>*</span></label>
                    <Input id="ins" className='h-[40px]' placeholder='919LICEMGARTTWUAZQQ' value={insNumber} onChange={(e) => setInsNumber(e.target.value)} disabled={isBusy} />
                </div>

                <div className='mb-4'>
                    <label htmlFor='insish'>Insurance Issue Date: <span className='text-red-500'>*</span></label>
                    <div className='w-full'>
                        <DatePicker id="insish" className='h-[40px] w-full' value={insIssued} onChange={(e) => setInsIssued(e)} disabled={isBusy} />
                    </div>
                </div>

                <div className='mb-4'>
                    <label htmlFor='insexp'>Insurance Expiry Date: <span className='text-red-500'>*</span></label>
                    <div className='w-full'>
                        <DatePicker id="insexp" className='h-[40px] w-full' value={insExpiry} onChange={(e) => setInsExpiry(e)} disabled={isBusy} />
                    </div>
                </div>

                <div className='mb-4'>
                    <label htmlFor='road'>Roadworthy Number: <span className='text-red-500'>*</span></label>
                    <Input id="road" className='h-[40px]' placeholder='919LICEMGARTTWUAZQQ' value={roadNumber} onChange={(e) => setRoadNumber(e.target.value)} disabled={isBusy} />
                </div>

                <div className='mb-4'>
                    <label htmlFor='roadish'>Roadworthy Issue Date: <span className='text-red-500'>*</span></label>
                    <div className='w-full'>
                        <DatePicker id="roadish" className='h-[40px] w-full' value={roadIssued} onChange={(e) => setRoadIssued(e)} disabled={isBusy} />
                    </div>
                </div>

                <div className='mb-5'>
                    <label htmlFor='roadexp'>Roadworthy Expiry Date: <span className='text-red-500'>*</span></label>
                    <div className='w-full'>
                        <DatePicker id="roadexp" className='h-[40px] w-full' value={roadExpiry} onChange={(e) => setRoadExpiry(e)} disabled={isBusy} />
                    </div>
                </div>

                <div className="mt-[20px] lg:grid lg:grid-cols-3">
                    <p className="text-[14px] font-[600]">Make / Brand <span className='text-red-500'>*</span></p>
                    <div className="lg:col-span-2 w-full">
                        <TruckBrandSelector onChange={(data) => setSelectedBrand(data?.value)} />
                    </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                    <p className="text-[14px] font-[600]">Model <span className='text-red-500'>*</span></p>
                    <div className="lg:col-span-2 w-full">
                        <TruckModelSelector selectedBrand={selectedBrand} onChange={(data) => setSelectedModel(data?.value)} />
                    </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                    <p className="text-[14px] font-[600]">Truck Type <span className='text-red-500'>*</span></p>
                    <div className="lg:col-span-2 w-full">
                        <TruckTypeSelector selectedModel={selectedModel} selectedBrand={selectedBrand} onChange={(data) => setSelectedTruckType(data?.value)} />
                    </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                    <p className="text-[14px] font-[600]">Preferred Goods <span className='text-red-500'>*</span></p>
                    <div className="lg:col-span-2 w-full">
                        {
                            selectAll ? <div className='flex w-full flex-wrap gap-2'>
                                {
                                    goodsData.map((dd: any, i) => (
                                        <div key={i} className="px-2 py-1 w-fit rounded-[5px] bg-gray-500 text-white text-xs">{dd?.value}</div>
                                    ))
                                }
                            </div>
                                :
                                <TruckGoodsSelector onChange={(data) => handleGoodsChange(data)} />
                        }
                        <label className='mt-1 flex gap-2'>
                            <input type='checkbox' onChange={(e) => handleSelectAll(e)} />
                            Select All Goods
                        </label>
                    </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                    <p className="text-[14px] font-[600]">Preferred Weight <span className='text-red-500'>*</span></p>
                    <div className="lg:col-span-2 w-full">
                        <TruckWeightSelector onChange={(data) => setTruckWeight(data.map((item: any) => item?.value))} />
                    </div>
                </div>

                <div className="w-full mt-4">
                    <button className='w-full h-[40px] rounded-[5px] flex justify-center items-center bg-yarpOrange text-white disabled:cursor-not-allowed disabled:bg-gray-300' onClick={() => handleCreateTruck()} disabled={isBusy}>
                        {
                            isBusy ? <Spin /> : "Create Driver's Account"
                        }
                    </button>
                </div>
            </Spin>
        </>
    )
}

export default CompleteDriverProfile
