import React, { useState } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import { Typography } from "@material-tailwind/react";
import CustomNavbar from "../../components/layouts/navbar";
import AdminTab from "./admin/AdminTab";
import AllProfessions from "./profession/AllProfessions";
import Roles from "./adminRoles/roles";
import PaymentLimit from "./paymentLimits/payment_limit";
import Commissions from "./commissions/commissions";
import PriceChecker from "./checkPrice/price_checker";

const Administration = () => {
  const [activeTab, setActiveTab] = useState(0);
  const adminSideMenu = [
    "Administration",
    "Professions",
    "Administrative roles",
    "Payment Limit",
    "Commission",
    "Price Checker",
  ];

  const adminMenuComponents = [
    <AdminTab />,
    <AllProfessions />,
    <Roles />,
    <PaymentLimit />,
    <Commissions />,
    <PriceChecker />,
  ];

  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar adminActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">
                Administration
              </p>

              <div className="mt-3 lg:grid lg:grid-cols-4 gap-3">
                <div className="mt-3 bg-white shadow-md rounded-[20px] py-5 col-span-1 w-full h-fit">
                  {adminSideMenu.map((menu, i) => (
                    <div
                      key={i}
                      className={`${
                        activeTab === i
                          ? "rounded-r-[30px] bg-[#FFFCF9] text-yarpOrange font-semibold"
                          : "hover:bg-[#FFFCF9] hover:rounded-r-[30px]"
                      } mr-[40px] h-fit py-1 cursor-pointer`}
                      onClick={() => setActiveTab(i)}
                    >
                      <Typography className="ml-[30px] font-medium mt-[10px]">
                        {menu}
                      </Typography>
                    </div>
                  ))}
                </div>

                <div className="mt-3 bg-white shadow-md rounded-[20px] col-span-3 p-5">
                  {adminMenuComponents[activeTab]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Administration;
