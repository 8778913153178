import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spin } from "antd";

import BaseService from "../../helpers/baseService";
import moment from "moment";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";
import RenderUserDetails from "./RenderUserDetails";

const NotificationDetails = () => {
  const navigate = useNavigate();
  const { param1, param2 } = useParams();
  const [loading, setLoading] = useState(false);
  const [resolving, setResolving] = useState(false);
  const [notificationData, setNotificationData] = useState<any>(null);
  const [cartypeData, setCartypeData] = useState<string | null>(null);
  const [pickupPlace, setPickupPlace] = useState<string | undefined>();


  function removeSlashes(placeName: string | undefined): string | undefined {
    if (placeName) {
      return placeName?.replace(/\//g, '');
    } else {
      return placeName;
    }
  }


  const fetchNotificationDetail = useCallback(async () => {
    if (!param1 || !param2) return;
    setLoading(true);
    try {
      const { data } = await BaseService.get_api_v2(
        `/yarp/notifications/realtime?sort={"updatedAt":-1}&filter={"tripId":"${param2}"}`
      );
      const detail = data.payload.find(
        (notification: any) => notification.userid === param1
      );

      console.log("detailPicked", detail);
      if (detail) {
        setCartypeData(detail?.tripData?.car_type || "vehicle");
        setPickupPlace(detail?.tripData?.pickup_place_name);
        setNotificationData(detail);
      }
    } catch (error: any) {
      console.error("Error fetching notification details:", error.message);
    } finally {
      setLoading(false);
    }
  }, [param1, param2]);

  useEffect(() => {
    fetchNotificationDetail();
  }, [fetchNotificationDetail]);

  const handleStatusChange = async () => {
    setResolving(true);
    try {
      await BaseService.put_api_v2("/yarp/notifications/realtime/resolved", {
        notificationId: param1,
      });
      fetchNotificationDetail();
    } catch (error: any) {
      console.error("Error resolving notification:", error.message);
    } finally {
      setResolving(false);
    }
  };

  const navigateToNotifications = () => navigate("/notifications");

  //console.log(`/notifications/${param1}/${param2}/${cartypeData}/${pickupPlace}`)

  return (
    <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
      <CustomSideBar usersActive="active" />
      <div className="w-full">
        <CustomNavbar />
        <div className="h-full overflow-y-auto p-8 mb-20">
          <p className="text-[26px] font-[600] text-yarpGray">
            Notifications Details
          </p>
          <button
            className="flex gap-2 items-center h-[30px] px-3 bg-yarpBlue text-white rounded-[10px]"
            onClick={navigateToNotifications}
          >
            <ArrowLeftIcon className="w-5 h-5" />
            Back
          </button>
          <Spin
            spinning={loading}
            indicator={
              <div className="flex justify-center items-center">
                <LoadingDataComponent />
              </div>
            }
          >
            <div className="mt-[20px] bg-white shadow-sm p-[20px] rounded-[20px]">
              <div className="grid grid-cols-4 mb-4">
                <section className="[&>p]:my-2">
                  <p>Purpose</p>
                  <p>Date</p>
                  <p>Time</p>
                </section>
                <section className="[&>p]:my-1">
                  <p>{notificationData?.purpose}</p>
                  <p>
                    {moment(notificationData?.createdAt).format("Do MMM YYYY")}
                  </p>
                  <p>{moment(notificationData?.createdAt).format("hh:mm A")}</p>
                </section>
                <section>
                  <p className="w-1/2 text-center -ml-6">Status</p>
                  <button
                    className="custom-select h-[30px] px-3 rounded-[20px]"
                    style={{
                      background: notificationData?.resolvedFacilitatorData
                        ? "rgba(119, 209, 164, 0.1)"
                        : "rgba(255, 99, 141, 0.1)",
                      color: notificationData?.resolvedFacilitatorData
                        ? "#77D1A4"
                        : "#FF638D",
                    }}
                  >
                    {notificationData?.resolvedFacilitatorData
                      ? "Resolved"
                      : "Unresolved"}
                  </button>
                </section>
                {!notificationData?.resolvedFacilitatorData && (
                  <section>
                    <p>Action</p>
                    <button
                      className="items-center h-[40px] px-3 bg-yarpBlue text-white rounded-[10px]"
                      onClick={handleStatusChange}
                      disabled={resolving}
                    >
                      {resolving ? "Resolving..." : "Click to Resolve Issue"}
                    </button>
                  </section>
                )}
              </div>
              <div className="text-[26px] font-[600] text-yarpGray">
                Trip info
              </div>
              <div className="w-full h-[2px] bg-gray-200"></div>
              <div className="grid grid-cols-4 my-1">
                <section className="[&>p]:my-3">
                  <p>From</p>
                  <p>To</p>
                  <p>Truck</p>
                  <p>Process Number</p>
                  <p>Weight Load</p>
                </section>
                <section className="[&>p]:my-3 w-full">
                  <p>{pickupPlace}</p>
                  <p>{notificationData?.tripData?.delivery_place_name}</p>
                  <p>{cartypeData}</p>
                  <p>{notificationData?.tripData?.processNumber}</p>
                  <p>{notificationData?.tripData?.weight_load}</p>
                </section>
                <section className="flex items-center">
                  <button
                    className="items-center h-[40px] px-3 bg-yarpBlue text-white rounded-[10px]"
                    onClick={() =>
                      navigate(`/notifications/${param1}/${param2}/${cartypeData}/${removeSlashes(pickupPlace)}`)
                    }
                  >
                    Nearby drivers
                  </button>
                </section>
              </div>
              <div className="w-full h-[2px] bg-gray-200"></div>
              <section className="h-[500px]">
                {notificationData?.userData && (
                  <RenderUserDetails
                    title="User"
                    userData={notificationData?.userData}
                    carType={cartypeData}
                  />
                )}
                {notificationData?.readFacilitatorData && (
                  <RenderUserDetails
                    title="Reader"
                    userData={notificationData?.readFacilitatorData}
                  />
                )}
                {notificationData?.resolvedFacilitatorData && (
                  <RenderUserDetails
                    title="Resolver"
                    userData={notificationData?.resolvedFacilitatorData}
                  />
                )}
              </section>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default NotificationDetails;
