import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import BaseService from "../../helpers/baseService";
import { Empty, Skeleton } from "antd";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";

const MostRecent = () => {
  const navigate = useNavigate();
  const [reFetch, setReFetch] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [OnRoadData, setOnRoadData] = useState<Array<any>>([]);

  const OnRoads = useCallback(async () => {
    setLoading(true);
    try {
      let tripsOnRoad = `/yarp/trip/onraod-admin?sort={"updated_at":-1}&limit=${2}`;
      const response = await BaseService.get_api_v2(tripsOnRoad);
      // console.log("tripsOnRoad ", response?.data?.payload);
      setOnRoadData(response?.data?.payload);
    } catch (error: any) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    OnRoads();
  }, [reFetch, OnRoads]);

  const handleOnRoadClick = useCallback(
    (data: any) => {
      navigate(`/tracking/${data?._id}`);
    },
    [navigate]
  );

  const getStatusStyle = useCallback((status: string) => {
    switch (status) {
      case `Pending`:
        return { backgroundColor: "#fcf9f0", color: "#ECC15D" };
      case `Accept`:
        return { backgroundColor: "#f3faf6", color: "#77D1A4" };
      case `Arrived At PickUp`:
        return { backgroundColor: "#eff7fc", color: "#52B3EF" };
      case `Start Queuing`:
        return { backgroundColor: "#f7f2fa", color: "#B87CD2" };
      case "End Queuing":
        return { backgroundColor: "#fdf0e6", color: "#FF6700" };
      case "Start Loading":
        return { backgroundColor: "#f2f0fe", color: "#7D68FE" };
      case `Cancel`:
        return { backgroundColor: "#fcefef", color: "#FF638D" };
      case `End Loading`:
        return { backgroundColor: "#E6EEFB", color: "#0A67F3" };
      case `Trip Started`:
        return { backgroundColor: "#F3F9E9", color: "#8BCE1C" };
      case `Trip Ended`:
        return { backgroundColor: "#EEDBDA", color: "#FF0000" };
      case `Payment Complete`:
        return { backgroundColor: "#F3F9E9", color: "#8BCE1C" };
      case `Queuing At Destination`:
        return { backgroundColor: "#f7f2fa", color: "#B87CD2" };
      case `End Queuing At Destination`:
        return { backgroundColor: "#fdf0e6", color: "#FF6700" };
      case `Start Offloading`:
        return { backgroundColor: "#f2f0fe", color: "#7D68FE" };
      case `End Offloading`:
        return { backgroundColor: "#E6EEFB", color: "#0A67F3" };
      case "Not Available":
        return { backgroundColor: "#fcefef", color: "#FF638D" };
      default:
        return { backgroundColor: "#696969", color: "#A9A9A9" };
    }
  }, []);

  //End Offloading
  const displayProperNames = useCallback((status: string) => {
    switch (status) {
      case `Pending`:
        return "Pending";
      case `Accept`:
        return "Accepted";
      case `Arrived At PickUp`:
        return "Arrived";
      case `Start Queuing`:
        return "Started Queuing";
      case "End Queuing":
        return "Ended Queuing";
      case "Start Loading":
        return "Started Loading";
      case `Cancel`:
        return "Canceled";
      case `End Loading`:
        return "Ended Loading";
      case `Trip Started`:
        return "Trip Started";
      case `Trip Ended`:
        return "Trip Ended";
      case `End Queuing At Destination`:
        return "Ended Queuing";
      case `Queuing At Destination`:
        return "Started Queuing";
      case `Payment Complete`:
        return "Payment Complete";
      case `Start Offloading`:
        return "Offloading";
      case `End Offloading`:
        return "Offload Ended";
        case "Not Available":
          return "Not Available";
      default:
        return "";
    }
  }, []);
  //End Offloading
  return (
    <>
      <div className="flex justify-between items-center mb-[10px]">
        <div className="flex gap-2 items-center">
          <Tooltip content="Reload" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
              onClick={() => setReFetch(!reFetch)}
            >
              <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>

          <div>
            <p className="font-[600] text-[20px] text-yarpGray">On Road</p>
          </div>
        </div>

        <Link
          className="text-[14px] text-yarpOrange font-[400] no-underline hover:underline"
          to="/tracking"
        >
          View All
        </Link>
      </div>

      <div>
        {loading ? (
          ["", "", ""].map((_, i) => (
            <div key={i} className="">
              <Skeleton active paragraph={{ rows: 2 }} />
            </div>
          ))
        ) : OnRoadData.length === 0 ? (
          <div className="flex justify-center items-center h-full mt-[60px]">
            <Empty description="No trucks on road" />
          </div>
        ) : (
          OnRoadData.map((trip: any, i: number) => (
            <div
              key={i}
              className="flex gap-[20px] mb-[12px] p-2 rounded-[10px] cursor-pointer hover:bg-[#eff7fc]"
              onClick={() => handleOnRoadClick(trip)}
            >
              <img
                src={
                  trip?.driver_data?.image ||
                  "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                }
                alt="img"
                className="object-cover h-[36px] w-[36px] rounded-full"
              />
              <div>
                <p className="text-[14px] my-1 font-[400] text-yarpGray mb-[2px] line-clamp-2">
                  <span className="font-[600]">From : </span>
                  {trip?.pickup_place_name}
                </p>
                <p className="text-[14px] my-1 font-[400] text-yarpGray mb-[2px] line-clamp-2">
                  <span className="font-[600]">To : </span>
                  {trip?.delivery_place_name}
                </p>
                <p className="text-[12px] text-[#9F9F9F] font-[400]">
                  {moment(trip?.updated_at).format("Do MMM YYYY - hh:mm A")}
                </p>

                {trip?.current_event_data && (
                  <div
                    style={{
                      ...getStatusStyle(trip?.current_event_data?.status),
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "fit-content",
                      borderRadius: "30px",
                      height: "30px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      paddingTop: "3px",
                      paddingBottom: "3px",
                    }}
                  >
                    {displayProperNames(trip?.current_event_data?.status)}
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default MostRecent;
