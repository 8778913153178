import moment from "moment";
import { useEffect, useState, useMemo, useCallback } from "react";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import NoDataComponent from "../../../components/general/noDataComponent";
import { Tooltip } from "@material-tailwind/react";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";

const DailySessions = ({ user_id, mytype }: { user_id: any; mytype: any }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [reFetch, setReFetch] = useState<boolean>(false);
  const [driverDaily, setDriverDaily] = useState<any>([]);

  const driverDailyLogs = useCallback(
    async (lim: number, ski: number) => {
      setLoading(true);
      try {
        const results = await BaseService.get_api_v2(
          `/yarp/online/daily-tracking?filter={"userid":"${user_id}"}&sort={"timestamp":-1}&limit=${lim}&skip=${ski}`
        );
        const { payload, total } = await results.data;
        setDriverDaily(payload);
        setTotal(total);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching driver daily logs:", error);
      } finally {
        setLoading(false);
      }
    },
    [user_id]
  );

  useEffect(() => {
    driverDailyLogs(limit, skip);
  }, [reFetch, limit, skip, user_id, driverDailyLogs]);

  const handleRowChange = (newLimit: number) => {
    setLimit(newLimit);
  };

  const pageChange = (page: number) => {
    const newSkip = page === 1 ? 0 : (page - 1) * limit;
    setSkip(newSkip);
  };

  const columns: any = useMemo(
    () => [
      {
        name: "Date",
        selector: (row: { date: any }) =>
          moment(row.date).format("Do MMMM YYYY"),
      },
      {
        name: "First Seen",
        cell: (row: { todayOnlineStart: any }) =>
          moment(row.todayOnlineStart).format("hh:mm A"),
      },
      {
        name: "Last seen",
        cell: (row: { todayOnlineEnd: any }) =>
          moment(row.todayOnlineEnd).format("hh:mm A"),
      },
      {
        name: "Time Spent",
        cell: (row: { totalSeconds: any }) => {
          const duration = moment.duration(row.totalSeconds, "seconds");
          const hours = Math.floor(duration.asHours());
          const minutes = duration.minutes();
          const seconds = duration.seconds();
          return `${hours} hours ${minutes} minutes ${seconds} seconds`;
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-[20px] flex justify-between items-center">
        <Tooltip content="Reload" placement="top">
          <button
            className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
            onClick={() => setReFetch((prev) => !prev)}
          >
            <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
          </button>
        </Tooltip>
        <div className="flex gap-[10px]">
          <Tooltip content="Print" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <PrinterIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <Tooltip content="Export" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
        </div>
      </div>
      <DataTable
        className="mt-[10px]"
        data={driverDaily}
        columns={columns}
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<NoDataComponent text="No records found" />}
        paginationTotalRows={total}
        pagination={total >= 11}
        paginationServer={total >= 11}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
      />
    </>
  );
};

export default DailySessions;
