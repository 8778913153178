import { useState } from "react";
import ActiveAdmins from "./ActiveAdminTable";
import { SuspendedAdminsTable } from "./SuspendedAdmins";
import CreateAdminModal from "./createAdminModal";

const AdminTab = () => {
  const [activeTab, setActiveTab] = useState(0);
  const adminMenus = ["Active", "Suspended"];

  const adminMenuComponents = [<ActiveAdmins />, <SuspendedAdminsTable />];

  //open create modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const handleOpenCreateModal = () => setOpenCreateModal(!openCreateModal);

  return (
    <>
      <div className="flex justify-end">
        <button
          className="px-3 py-2 bg-yarpOrange text-white rounded-[30px] shadow-md"
          onClick={() => handleOpenCreateModal()}
        >
          + Create Admin
        </button>
      </div>
      <div className="flex gap-3">
        {adminMenus.map((menu, i) => (
          <div
            key={i}
            className={`${activeTab === i ? "border-b-[3px] border-b-yarpOrange" : ""
              } w-fit cursor-pointer px-3`}
            onClick={() => setActiveTab(i)}
          >
            {menu}
          </div>
        ))}
      </div>

      <div>{adminMenuComponents[activeTab]}</div>

      {openCreateModal ? (
        <CreateAdminModal
          openCreate={openCreateModal}
          handleOpenCreate={handleOpenCreateModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminTab;
