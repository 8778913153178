import { useState, useEffect } from "react";
import BaseService from "../../helpers/baseService";
import { Typography } from "@material-tailwind/react";
import moment from "moment";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";

const GeneralInfo = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  //onload
  useEffect(() => {
    getUserInfo();
  }, []);

  //getUserInfo
  const getUserInfo = async () => {
    setIsFetching(true);
    try {
      const response = await BaseService.get_api(`/yarp/yarpuser/details`);
      //   console.log(response?.data?.payload);
      setUserData(response?.data?.payload);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <Typography variant="h5">General Information</Typography>
        <hr />

        {isFetching ? (
          <div className="flex justify-center items-center h-full">
            <LoadingDataComponent />
          </div>
        ) : (
          <>
            <div className="mt-3 flex gap-5 items-center">
              <div>
                <img
                  src={userData?.image || 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'}
                  alt="uImg"
                  className="w-[200px] h-[200px] rounded-full object-cover shadow-lg"
                />
              </div>
              <div>
                <Typography>
                  <span className="font-semibold">First Name:</span>{" "}
                  {userData?.first_name}
                </Typography>

                <Typography>
                  <span className="font-semibold">Last Name:</span>{" "}
                  {userData?.last_name}
                </Typography>

                <Typography>
                  <span className="font-semibold">Email:</span>{" "}
                  {userData?.email}
                </Typography>

                <Typography>
                  <span className="font-semibold">Phone:</span>{" "}
                  {userData?.phone}
                </Typography>

                <Typography>
                  <span className="font-semibold">Account Type:</span>{" "}
                  {userData?.user_type}
                </Typography>

                <Typography>
                  <span className="font-semibold">Login Time:</span>{" "}
                  {moment(userData?.login_at).format("Do MMM YYYY - hh:mm A")}
                </Typography>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GeneralInfo;
