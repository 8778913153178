import { SetStateAction, useEffect, useState } from "react";
import { Container, Image, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { suspend_user } from "../app_users/suspend_user";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { GenerateId } from "../../../functions/functions";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import moment from "moment";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ExclamationTriangleIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { PhotoView } from "react-photo-view";
import { CSVLink } from "react-csv";
import { Spinner, Tooltip } from "@material-tailwind/react";
import NoDataComponent from "../../../components/general/noDataComponent";
import { StorageBox } from "../../../helpers/storage";
import { notification, Badge, Select, Input } from "antd";
import debounce from "lodash/debounce";
import PhoneInput from "react-phone-number-input";
import {
  DateSort,
  caseInsensitiveSort,
} from "../../../components/general/TableSortFunctions";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
};

const DriverTable = ({ activeTab }: { activeTab: any }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [driver, setDriver] = useState<any>([]);
  const [csvDrivers, setCsvDrivers] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [selectedDrivers, setSelectedDrivers] = useState<any>([]);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [reFetch, setReFetch] = useState<boolean>(false);
  const [driverName, setDriverName] = useState<any>("");
  const [onlineStatus, setOnlineStatus] = useState<any>(`{}`);
  const [driverPhone, setDriverPhone] = useState("");
  const [show, setShow] = useState<boolean>(false);
  const [susData, setSuspendData] = useState<any>([]);
  const [randomNum, setRandomNum] = useState<string>("");
  const [confrimationCode, setConfirmationCode] = useState<string>("");
  const [msg, setMsg] = useState<string>("");
  const [susEnd, setEndDate] = useState<string>("");
  const [trucksName, setTruckName] = useState<any>("");

  const getAllDrivers = async (lim: number, sk: number) => {
    setLoading(true);
    try {
      const response = await BaseService.get_api_v2(
        `${
          global_variables.get_all_drivers_v2
        }?sort={"created_at":-1}&limit=${lim}&skip=${sk}&filter={"$and":[{"approved": true}, {"driver_data.full_name": {"$regex":${JSON.stringify(
          driverName
        )},"$options":"i"}}, {"driver_data.phone": {"$regex":${JSON.stringify(
          driverPhone
        )},"$options":"i"}}, {"vehicle_data.car_type": {"$regex":${JSON.stringify(
          trucksName
        )},"$options":"i"}}, ${onlineStatus}]}`
      );
      console.log("---allDrivers", response?.data);
      setTotal(response?.data?.total);
      setDriver(response?.data?.payload);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDrivers(limit, skip);
  }, [reFetch, limit, skip, driverName, onlineStatus, driverPhone, trucksName]);

  //handleNameInput
  const handleNameInput = debounce((driver_name: string) => {
    console.log(driver_name);
    setDriverName(driver_name);
  }, 500);

  //handlePhoneInput
  const handlePhoneInput = debounce((phone_num: string) => {
    if (phone_num) {
      const splitNum = phone_num.split("+");
      console.log(splitNum);
      setDriverPhone(splitNum[1]);
    } else {
      setDriverPhone("");
    }
  }, 500);

  const handlTruckInput = debounce((truckName: string) => {
    setTruckName(truckName);
  }, 500);

  //handleOnlineFilter
  const handleOnlineFilter = (status: any) => {
    console.log(status);
    if (status === "online") {
      setOnlineStatus(`{"connectedData" : {"$ne" : null}}`);
    } else if (status === "offline") {
      setOnlineStatus(`{"connectedData" : {"$eq" : null}}`);
    } else {
      setOnlineStatus(`{}`);
    }
  };

  useEffect(() => {
    const CSVDrivers = async () => {
      let filter = `${
        global_variables.get_all_drivers_v2
      }?filter={"$and": [{"approved": true}, {"declined": {"$eq": null}}]}&limit=${10000}`;
      filter += `&sort={"created_at":-1}`;
      const res = await BaseService.get_api_v2(`${filter}`);
      // console.log(res?.data?.payload);
      setCsvDrivers(res?.data?.payload);
    };
    CSVDrivers();
  }, []);

  const handleRowChange = (a: any) => {
    setLimit(a);
  };

  const pageChange = (a: any) => {
    const newSkip = a === 1 ? 0 : (a - 1) * limit;
    setSkip(newSkip);
  };

  //table columns
  const columns: any = [
    {
      name: "Image",
      cell: (row: { driver_data: { image: any }; connectedData: any }) => (
        <PhotoView
          src={
            row?.driver_data?.image ||
            "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
          }
        >
          <Badge
            color={"#fd7e14"}
            dot={row.connectedData}
            style={{ marginRight: "15px", marginTop: "5px", padding: "5px" }}
          >
            {
              <Image
                src={
                  row?.driver_data?.image ||
                  "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                }
                fluid
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  borderRadius: "100%",
                  marginRight: "10px",
                }}
              />
            }
          </Badge>
        </PhotoView>
      ),
      width: "10%",
    },
    {
      name: "Full Name",
      cell: (row: { driver_data: { full_name: any } }) =>
        row?.driver_data?.full_name || "N/A",
      sortable: true,
      sortFunction: caseInsensitiveSort,
    },
    {
      name: "Phone",
      selector: (row: { driver_data: { phone: any } }) =>
        row?.driver_data?.phone,
      sortable: true,
    },
    {
      name: "Last Seen",
      cell: (row: { driver_data: { login_at: moment.MomentInput } }) =>
        moment(row?.driver_data?.login_at).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: DateSort,
    },
    {
      name: "Created On",
      cell: (row: { created_at: moment.MomentInput }) =>
        moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: DateSort,
    },
    {
      name: "Action",
      selector: (row: any) => (
        <ExclamationTriangleIcon
          className="w-5 h-5 text-red-500 cursor-pointer"
          onClick={showSuspend.bind(this, row)}
        />
      ),
      width: "10%",
    },
  ];

  //export data
  const formatDataForExport = (data: any[]) => {
    return data.map((row) => ({
      "FULL NAME": row?.driver_data?.full_name,
      "TRUCK TYPE": row?.vehicle_data?.car_type,
      "PHONE NUMBER": `.${row?.driver_data?.phone}`,
      "LAST SEEN": moment(row?.driver_data?.login_at).format(
        "Do MMM YYYY - hh:mm A"
      ),
      "DATE CREATED": moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
    }));
  };

  const showSuspend = (row: SetStateAction<never[]>) => {
    setShow(true);
    setSuspendData(row);
    setRandomNum(GenerateId(5));
  };

  //suspend user
  const suspendUser = () => {
    suspend_user(
      randomNum,
      confrimationCode,
      susEnd,
      susData?.userid,
      susData?.phone,
      msg
    );
    getAllDrivers(limit, skip);
    setShow(false);
    setConfirmationCode("");
  };

  const reloadTable = () => {
    getAllDrivers(10, 0);
    setLimit(10);
    setSkip(0);
  };

  //queueForSms
  const queueForSms = () => {
    setIsBusy(true);
    setTimeout(() => {
      StorageBox.saveDriverSms(selectedDrivers);
      setIsBusy(false);
      notification.success({
        message: "Selected drivers have been queued for sms",
      });
      setSelectedDrivers([]);
      setReFetch(!reFetch);
    }, 2000);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <div className="flex  gap-2 items-center">
          <Tooltip content="Reload" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
              onClick={() => reloadTable()}
            >
              <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          {selectedDrivers.length !== 0 && (
            <button
              className="h-[40px] text-[12px]  p-2 text-white bg-yarpBlue rounded-[5px] flex justify-center items-center disabled:bg-gray-400 disabled:cursor-not-allowed"
              onClick={() => queueForSms()}
              disabled={isBusy}
            >
              {isBusy ? <Spinner /> : "Queue SMS"}
            </button>
          )}
          <div>
            <Select
              className="h-[40px] w-[200px]"
              options={[
                { value: "online", label: "Online Drivers" },
                { value: "offline", label: "Offline Drivers" },
              ]}
              placeholder="Online / Offline"
              onChange={(selected) => handleOnlineFilter(selected)}
              allowClear
            />
          </div>
          {/* <NewDatePickerRange onDateData={onDateDataHandler} /> */}
          <div className="w-[200px]">
            <Input
              className="h-[40px] w-full"
              onChange={(e) => handleNameInput(e.target.value)}
              placeholder="Driver's Name"
              allowClear
            />
          </div>

          <div className="w-[250px]">
            <PhoneInput
              className={`rounded-[5px] w-full border-[1px] border-gray-300 h-[40px] px-2 bg-white`}
              defaultCountry="GH"
              inputStyle={{ width: "100%" }}
              placeholder="Driver's phone"
              numberInputProps={{
                className: "focus:outline-none",
              }}
              // value={phone}
              onChange={(phone: any) => handlePhoneInput(phone)}
              disabled={isBusy}
            />
          </div>
          <div className="w-[150px]">
            <Input
              className="h-[40px] w-full"
              onChange={(e) => handlTruckInput(e.target.value)}
              placeholder="truck type"
              allowClear
            />
          </div>
        </div>

        <div className="flex gap-3 items-center">
          <Tooltip content="Print" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <PrinterIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <CSVLink
            data={formatDataForExport(csvDrivers)}
            filename="Approved Drivers"
          >
            <Tooltip content="Export" placement="top">
              <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>

      <DataTable
        data={driver}
        columns={columns}
        progressPending={loading}
        paginationTotalRows={total}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={<NoDataComponent text="No drivers found" />}
        pagination
        paginationServer
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
        pointerOnHover
        onRowClicked={(row: any) => {
          navigate(
            `/driverdetail/${row?.userid}?uid=${row?.driver_data?.full_name}&tab=${activeTab}`
          );
        }}
        selectableRows
        onSelectedRowsChange={(data) => setSelectedDrivers(data?.selectedRows)}
        customStyles={customStyles}
      />

      {/* Suspend Driver Table  */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Suspend {susData.full_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show ? (
            <Container className="text-center">
              <h5 className="fw-light m-0 p-0"> Comfirmation Code </h5>
              <p className="fw-bolder">{randomNum}</p>
              <p style={{ fontSize: "13px" }}>
                Are you sure you want to suspend this user? Please enter the
                code above
              </p>
              <input
                value={confrimationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                type="text"
                className="form-control my-3"
                placeholder="Comfirmation code"
              />
              <textarea
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                className="form-control my-3"
                style={{ height: "100px" }}
                placeholder="Tell the user the reason for suspension"
              ></textarea>

              <small>Suspension ends on:</small>

              <input
                value={susEnd}
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                className="form-control"
              />
            </Container>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-danger" onClick={suspendUser}>
            Suspend
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DriverTable;
