import {
  AdvancedMarker,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";

import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const Markers = forwardRef(
  (
    {
      driverStreamLatitude,
      driverStreamLongitude,
      getInitialLatitude,
      getInitialLongitude,
      polyconPoints,
      iconPath,
      rotationIsInside,
      pickupToDelivery,
    }: {
      driverStreamLatitude: any;
      rotationIsInside: any;
      driverStreamLongitude: any;
      polyconPoints: any;
      getInitialLatitude: any;
      getInitialLongitude: any;
      iconPath: any;
      pickupToDelivery: any;
    },
    ref
  ) => {
    const [directionsService, setDirectionsService] =
      useState<google.maps.DirectionsService | null>(null);
    const [directionsRenderer, setDirectionsRenderer] =
      useState<google.maps.DirectionsRenderer | null>(null);
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const flightPathRef = useRef<any>(null);

    const drawFlightPath = () => {
      if (flightPathRef.current) {
        flightPathRef.current.setMap(null);
        flightPathRef.current = null;
      } else {
        const flightPath = new google.maps.Polyline({
          path: pickupToDelivery,
          geodesic: true,
          strokeColor: "#FF6700",
          strokeOpacity: 1.0,
          strokeWeight: 4,
        });

        console.log("pickupToDelivery : ", pickupToDelivery);
        flightPath.setMap(map);
        flightPathRef.current = flightPath;
      }
    };

    useImperativeHandle(ref, () => ({
      drawFlightPath,
    }));

    useEffect(() => {
      const flightPath = new google.maps.Polyline({
        path: polyconPoints,
        geodesic: true,
        strokeColor: "#52B3EF",
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });

      flightPath.setMap(map);

      return () => flightPath.setMap(null);
    }, [map, polyconPoints]);

    const getRotatedSvgIcon = (rotation: number) => {
      if (window.google) {
        return {
          url:
            "data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%232966ff' transform='rotate(" +
            rotation +
            ")'><rect x='4' y='4' width='16' height='16'/></svg>",
          scaledSize: new window.google.maps.Size(50, 50),
          anchor: new window.google.maps.Point(25, 25),
        };
      }
      return null;
    };

    // console.log("rotationIsInside", rotationIsInside);
    return (
      <AdvancedMarker
        position={{
          lat:
            driverStreamLatitude !== 0
              ? driverStreamLatitude
              : getInitialLatitude,
          lng:
            driverStreamLongitude !== 0
              ? driverStreamLongitude
              : getInitialLongitude,
        }}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={iconPath}
            fill="#ff6700"
            fillOpacity="0.9"
            stroke="black"
            strokeWidth="1"
          />
        </svg>
      </AdvancedMarker>
    );
  }
);

export default Markers;
