import { useState, useEffect } from "react";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { Badge, Col, Image, Modal, Row } from "react-bootstrap";
import moment from "moment";
import DataTable from "react-data-table-component";
import NoDataComponent from "../../../components/general/noDataComponent";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { PhotoView } from "react-photo-view";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@material-tailwind/react";
import ShowToast from "../../../components/alerts/all_toasts";

const DeclinedTrucks = () => {
  const [loading, setLoading] = useState(true);
  const [trucks, setTrucks] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [isBusy, setIsBusy] = useState(false);

  //get denied trucks
  const getTrucks = async (lim: any, sk: any) => {
    try {
      setLoading(true);
      const response = await BaseService.get_api_v2(
        `${global_variables.get_all_trucks_v2}?filter={"$and": [{"approved": false}, {"declined": {"$ne": null}}]}&limit=${lim}&skip=${sk}`
      );
      // console.log("unapproved:", response?.data);
      setLoading(false);
      setTotalRows(response?.data?.total);
      setTrucks(response?.data?.payload);
    } catch (error) {
      console.log(error);
    }
  };

  //onload
  useEffect(() => {
    getTrucks(limit, skip);
  }, [limit, skip]);

  //table clomuns
  const columns = [
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">VEHICLE REG.</p>
      ),
      selector: (row: any) => (
        <div className="flex gap-3 items-center">
          <Image
            src={row.truckfront}
            fluid
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              borderRadius: "100%",
              marginRight: "10px",
            }}
          />
          {row?.truckRegisteration}
        </div>
      ),
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">MODEL</p>,
      selector: (row: any) => row?.model,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">VEHICLE TYPE</p>
      ),
      selector: (row: any) => row?.car_type,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">OWNERSHIP</p>,
      selector: (row: any) => row?.type,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">DATE CREATED</p>
      ),
      cell: (row: any) =>
        moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">STATUS</p>,
      selector: (row: any) =>
        row?.declined ? (
          <div className="px-4 py-1 bg-red-500 text-red-500 bg-opacity-[20%] rounded-[40px]">
            Declined
          </div>
        ) : (
          <div className="px-4 py-1 bg-yarpGreen text-yarpGreen bg-opacity-[20%] rounded-[40px]">
            Accepted
          </div>
        ),
    },
  ];

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<any>([]);
  const displayModal = (row: any) => {
    setModalData(row);
    setShow(true);
  };

  const handleRowChange = (a: any) => {
    setLimit(a);
    getTrucks(a, skip);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getTrucks(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getTrucks(limit, newSkip);
      setSkip(newSkip);
    }
  };

  //approve a truck
  const approveTruck = async () => {
    ShowToast.loading_toast("Please wait...");

    var truck_data = {
      truck_number: modalData._id,
      approved: true,
    };

    setIsBusy(true);

    try {
      const response = await BaseService.put_api(
        `${global_variables.verifyTruck}`,
        truck_data
      );
      // console.log(response);
      ShowToast.success_toast("Truck has been approved");
      setShow(false);
      getTrucks(limit, skip);
      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
      console.log(error);
      ShowToast.error_toast(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="p-2 rounded-[20px] bg-white shadow-lg">
        <DataTable
          columns={columns}
          data={trucks}
          pagination
          paginationServer
          pointerOnHover
          highlightOnHover
          paginationTotalRows={totalRows}
          noDataComponent={
            <NoDataComponent text="There are no declined trucks" />
          }
          progressPending={loading}
          progressComponent={<LoadingDataComponent />}
          onRowClicked={(row: any) => {
            displayModal(row);
          }}
          onChangeRowsPerPage={handleRowChange}
          onChangePage={pageChange}
        />
      </div>

      {/* truck details */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setModalData([]);
        }}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="p-[30px] text-yarpGray">
            <p className="text-[20px] font-[600] text-yarpGray">Genral Info</p>

            <hr />

            {show ? (
              <>
                <div className="lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Truck Images</p>
                  <div className="flex justify-between gap-[20px] lg:col-span-2">
                    <div className="w-full">
                      <PhotoView
                        src={
                          modalData?.truckfront ||
                          "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                        }
                      >
                        <img
                          className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                          src={
                            modalData?.truckfront ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                          alt="front"
                        />
                      </PhotoView>
                      <p className="text-[14px] font-[300] mt-[10px]">
                        Front view
                      </p>
                    </div>
                    <div className="w-full">
                      <PhotoView
                        src={
                          modalData?.truckside ||
                          "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                        }
                      >
                        <img
                          className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                          src={
                            modalData?.truckside ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                          alt="side"
                        />
                      </PhotoView>
                      <p className="text-[14px] font-[300] mt-[10px]">
                        Side view
                      </p>
                    </div>
                    <div className="w-full">
                      <PhotoView
                        src={
                          modalData?.truckback ||
                          "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                        }
                      >
                        <img
                          className="w-full h-[200px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                          src={
                            modalData?.truckback ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                          alt="back"
                        />
                      </PhotoView>
                      <p className="text-[14px] font-[300] mt-[10px]">
                        Back view
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-[30px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Registration</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {modalData?.truckRegisteration}
                    </p>
                  </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Ownership</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {modalData?.type.toUpperCase()}
                    </p>
                  </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Brand</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">{modalData?.brand}</p>
                  </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Model</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">{modalData?.model}</p>
                  </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Truck Type</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {modalData.car_type}
                    </p>
                  </div>
                </div>

                <div className="mt-[10px] lg:grid lg:grid-cols-3">
                  <p className="text-[14px] font-[600]">Preferred Goods</p>
                  <div className="lg:col-span-2">
                    <p className="text-[14px] font-[300]">
                      {modalData.category_type.map((d: any, index: number) => {
                        return (
                          <Badge className="ms-1" bg="secondary" key={index}>
                            {d}
                          </Badge>
                        );
                      })}
                    </p>
                  </div>
                </div>

                <div className="mt-[30px]">
                  <p className="text-[20px] font-[600] text-yarpGray">
                    Documents
                  </p>

                  <hr />

                  <div className="lg:grid lg:grid-cols-3">
                    <p className="text-[14px] font-[600]">Insurance</p>
                    <div className="lg:col-span-2">
                      <Row>
                        <Col>
                          <PhotoView
                            src={
                              modalData?.insurance ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="w-[400px] h-[250px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                              src={modalData?.insurance}
                              alt="Insurance"
                            />
                          </PhotoView>
                        </Col>
                        <Col>
                          <div className="fw-bolder mt-2">Insurance number</div>
                          <div>{modalData?.insurance_text}</div>
                          <div className="fw-bolder mt-3">Expiry Date</div>
                          <div>
                            {moment(modalData?.insurance_expire).format(
                              "Do MMMM YYYY"
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="lg:grid lg:grid-cols-3 mt-[20px]">
                    <p className="text-[14px] font-[600]">Roadworthy</p>
                    <div className="lg:col-span-2">
                      <Row>
                        <Col>
                          <PhotoView
                            src={
                              modalData?.roadworthy ||
                              "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                            }
                          >
                            <img
                              className="w-[400px] h-[250px] object-cover border border-gray-500 rounded-[10px] cursor-pointer"
                              src={modalData?.roadworthy}
                              alt="Roadworthy"
                            />
                          </PhotoView>
                        </Col>
                        <Col>
                          <div className="fw-bolder mt-2">
                            Roadworthy number
                          </div>
                          <div>{modalData?.roadworthy_text}</div>
                          <div className="fw-bolder mt-3">Expiry Date</div>
                          <div>
                            {moment(modalData?.roadworthy_expire).format(
                              "Do MMMM YYYY"
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-4">
          <button className="text-yarpGray" onClick={() => setShow(false)}>
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded-[40px] bg-yarpOrange text-white flex justify-center items-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={approveTruck}
            disabled={isBusy}
          >
            {isBusy ? <Spinner /> : <span> Approve Truck</span>}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeclinedTrucks;
