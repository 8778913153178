import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import {
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Button } from "@material-tailwind/react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

const TruckBrand = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [skip, setSkip] = useState<any>(0);
  const [limit, setLimit] = useState<any>(10);
  const [show, setShow] = useState<any>(0);

  //on load
  useEffect(() => {
    getData(limit, skip);
  }, []);

  //get data
  const getData = (lim: any, ski: number) => {
    setLoading(true);

    BaseService.get_api_v2(
      `${global_variables.get_truck_brands_v2}?limit=${lim}&skip=${ski}`
    )
      .then((res) => {
        setLoading(false);
        // console.log(res?.data);
        setData(res?.data?.payload);
        setTotal(res?.data?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //table colmns
  const columns: any = [
    {
      name: "Name",
      selector: (row: { brand: any }) => row.brand,
    },
    {
      name: "Created on",
      selector: (row: { updated_at: moment.MomentInput }) =>
        moment(row.updated_at).format("Do MMMM YYYY"),
    },
  ];

  const handleRowChange = (a: any) => {
    getData(a, 0);
    setLimit(a);
    setSkip(0);
  };

  const pageChange = (a: number) => {
    if (a === 1) {
      getData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getData(limit, newSkip);
      setSkip(newSkip);
    }
  };

  const [brand, setBrand] = useState("");

  //add new brand
  const addBrand = () => {
    ShowToast.loading_toast();

    var upload_data = {
      brand: brand,
    };

    BaseService.post_api(global_variables.create_car_brand, upload_data)
      .then((res) => {
        setShow(false);
        ShowToast.success_toast(res?.data?.message);
        setBrand("");
        getData(limit, skip);
      })
      .catch((err) => {
        console.log(err);
        ShowToast.error_toast("An error occured, please retry.");
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h5>Truck Brands</h5>
        <Button
          className="flex gap-2 items-center"
          variant="gradient"
          size="sm"
          color="blue"
          onClick={() => {
            setShow(true);
          }}
        >
          <PlusCircleIcon className="h-5 w-5" />
          Add brand
        </Button>
      </div>

      <div>
        <DataTable
          data={data}
          columns={columns}
          paginationTotalRows={total}
          progressPending={loading}
          progressComponent={<LoadingDataComponent />}
          noDataComponent={<p>You do not have any car brands</p>}
          pagination={total >= 11 ? true : false}
          paginationServer
          onChangeRowsPerPage={handleRowChange}
          onChangePage={pageChange}
        />
      </div>

      {/* Add  */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Brand</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FloatingLabel controlId="bname" label="Brand name" className="mb-3">
            <Form.Control
              type="text"
              placeholder="Toyota"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="gradient" color="blue" onClick={() => addBrand()}>
            Post
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TruckBrand;
