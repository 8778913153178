import moment from "moment";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TripHistoryTable } from "./trans_history";
import ShowToast from "../../../components/alerts/all_toasts";
import CustomSideBar from "../../../components/layouts/sidebar";
import CustomNavbar from "../../../components/layouts/navbar";
import { Typography } from "@material-tailwind/react";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { ArrowLeftIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { PhotoView } from "react-photo-view";
import DriverVehicleInfoTab from "./driverVehicleInfoTab";
// import UnderConstruction from "../../../components/general/underConstruction";
import EditDriverDetails from "./editDriverDetails";
import DailySessions from "./DailySessions";
import OfflineSession from "./OfflineSession";
import { TransactionHistoryTable } from "../app_users/TransactionHistoryTable";
const DriverDetail = () => {
  const driverId = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [driverTruckData, setDriverTruckData] = useState<any>(null);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [reFetch, setReFetch] = useState<boolean>(false);
  const handleOpenEdit = () => {
    setOpenEditModal(true);
  };
  const handleCloseEdit = () => {
    setOpenEditModal(false);
  };

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const user_name = query.get("uid");
  const activeSec = query.get("tab");

  const tabComponents = [
    <DriverVehicleInfoTab driverVehicle={driverTruckData} />,
    <TripHistoryTable user_id={user.userid} mytype="driverId" />,
    <TransactionHistoryTable user_id={user.userid} mytype="driverId" />,
    <DailySessions user_id={user.userid} mytype="driverId" />,
    <OfflineSession user_id={user.userid} mytype="driverId" />,
  ];

  //get driver details
  const getDriverDetail = () => {
    setLoading(true);
    if (user_name === "" || !user_name || user_name === "undefined") {
      ShowToast.error_toast("Driver not found");
      // navigate("/users?tab=1");
    } else {
      return new Promise((resolve, reject) => {
        BaseService.get_api(
          `${global_variables.yarpusers}?filter={"condition":"type_name","parameter":"${user_name}","type":"driver"}&limit=1`
        )
          .then((res) => {
            //
            if (res?.data?.payload.length === 0) {
              ShowToast.error_toast("User not found");
              // navigate("/users");
            } else {
              console.log(res?.data?.payload[0]);
              setUser(res?.data?.payload[0]);
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  //getdriverTruck
  const getdriverTruck = async () => {
    setIsFetching(true);
    try {
      const response = await BaseService.get_api_v2(
        `${
          global_variables.get_all_trucks_v2
        }?filter={"driver": ${JSON.stringify(driverId?.id)}}`
      );
      console.log(response?.data);
      if (response?.data?.total === 0) {
        setDriverTruckData(null);
      } else {
        setDriverTruckData(response?.data?.payload?.[0]);
      }
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdriverTruck();
    getDriverDetail();
  }, [reFetch]);

  //handleReFetch
  const handleReFetch = () => {
    setReFetch(!reFetch);
  };
  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar usersActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-[20px] mb-20">
              {/* edit button */}
              <div className="flex justify-between items-center">
                <div className="flex gap-3 items-center">
                  <button
                    className="flex gap-2 items-center h-[30px] px-3 bg-yarpBlue text-white rounded-[10px]"
                    onClick={() => navigate(`/users?tab=${activeSec}`)}
                  >
                    <ArrowLeftIcon className="w-5 h-5" />
                    Back
                  </button>
                  <p className="text-[26px] font-[600] text-yarpGray mt-[15px]">
                    Driver Details
                  </p>
                </div>
                <button
                  className="flex gap-2 items-center h-[40px] bg-yarpOrange text-white rounded-[30px] px-4 text-[14px]"
                  onClick={() => handleOpenEdit()}
                >
                  <PencilSquareIcon className="h-5 w-5" />
                  Edit Driver Details
                </button>
              </div>

              <Container fluid>
                {/* driver details */}
                <div>
                  {loading ? (
                    <div className="p-5 bg-white shadow-md rounded-[20px] mt-[10px] flex justify-center">
                      <LoadingDataComponent />
                    </div>
                  ) : (
                    <div className="p-[20px] bg-white shadow-md rounded-[20px] mt-[10px]">
                      <div className="grid grid-cols-3 gap-x-[30px] w-full">
                        <div className="col-span-1 w-full">
                          <div>
                            <div className="flex gap-[15px] items-center">
                              <PhotoView
                                src={
                                  user?.image ||
                                  "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                }
                              >
                                <img
                                  className="h-[150px] w-[150px] rounded-full object-cover border-[1px] border-gray-500 cursor-pointer"
                                  src={
                                    user?.image ||
                                    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                  }
                                  alt="driver"
                                />
                              </PhotoView>
                              <div>
                                <p className="font-[700] text-[20px] leading-[30px] text-[#656565] mb-0">
                                  {user?.full_name}
                                </p>
                                <small className="text-gray-500 text-xs">
                                  <span className="font-semibold">
                                    Created On:
                                  </span>{" "}
                                  {moment(user?.created_at).format(
                                    "Do MMM YYYY - hh:mm A"
                                  )}
                                </small>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 p-2 border-[2px] border-yarpBlue rounded-[10px] w-fit">
                            <div className="flex gap-[15px] items-center">
                              <PhotoView
                                src={
                                  driverTruckData?.truckfront ||
                                  "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                }
                              >
                                <img
                                  className="h-[50px] w-[50px] rounded-full object-cover border-[1px] border-gray-500 cursor-pointer"
                                  src={
                                    driverTruckData?.truckfront ||
                                    "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                                  }
                                  alt="driver"
                                />
                              </PhotoView>
                              <div>
                                <p className="font-[700] text-[14px] leading-[21px] text-[#656565] mb-0">
                                  {driverTruckData?.model || "No Truck Model"}
                                </p>
                                <p className="font-[300] text-[14px] leading-[21px] text-[#656565] mb-0">
                                  {driverTruckData?.truckRegisteration ||
                                    "No Truck Registration"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col justify-center w-full col-span-2 border-l-[1px] border-l-gray-500 pl-[30px]">
                          <div className="lg:grid lg:grid-cols-3 gap-y-[20px]">
                            <div className="w-full">
                              <p className="font-[700] text-[14px] leading-[21px] text-yarpGray mb-0">
                                Email
                              </p>
                              <p className="font-[300] text-[14px] leading-[21px] text-yarpGray">
                                {user?.email || "N/A"}
                              </p>
                            </div>
                            <div className="w-full">
                              <p className="font-[700] text-[14px] leading-[21px] text-yarpGray mb-0">
                                Contact
                              </p>
                              <p className="font-[300] text-[14px] leading-[21px] text-yarpGray">
                                {user?.phone || "N/A"}
                              </p>
                            </div>
                            <div className="w-full">
                              <p className="font-[700] text-[14px] leading-[21px] text-yarpGray mb-0">
                                Last Seen
                              </p>
                              <p className="font-[300] text-[14px] leading-[21px] text-yarpGray">
                                {moment(user?.login_at).format(
                                  "Do MMM YYYY - hh:mm A"
                                )}
                              </p>
                            </div>
                            <div>
                              <p className="font-[700] text-[14px] leading-[21px] text-yarpGray mb-0">
                                Licence No
                              </p>
                              <p className="font-[300] text-[14px] leading-[21px] text-yarpGray">
                                {driverTruckData?.driver_data?.licence || "N/A"}
                              </p>
                            </div>
                            <div>
                              <p className="font-[700] text-[14px] leading-[21px] text-yarpGray mb-0">
                                Date Issued
                              </p>
                              <p className="font-[300] text-[14px] leading-[21px] text-yarpGray">
                                {driverTruckData?.driver_data
                                  ? moment(
                                      driverTruckData?.driver_data?.dateIssued
                                    ).format("Do MMMM YYYY")
                                  : "N/A"}
                              </p>
                            </div>
                            <div>
                              <p className="font-[700] text-[14px] leading-[21px] text-yarpGray mb-0">
                                Expiry Date
                              </p>
                              <p className="font-[300] text-[14px] leading-[21px] text-yarpGray">
                                {driverTruckData?.driver_data
                                  ? moment(
                                      driverTruckData?.driver_data?.dateExpired
                                    ).format("Do MMMM YYYY")
                                  : " N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="p-10 bg-white shadow-lg rounded-[20px] mt-4">
                  <div className="flex gap-3">
                    {[
                      "Account",
                      "Trip History",
                      "Transactions",
                      " Daily Sessions",
                      " Online/Offline Session",
                    ].map((dd, i) => (
                      <div
                        key={i}
                        className={`${
                          activeTab === i
                            ? "border-b-[2px] border-b-yarpOrange"
                            : ""
                        } cursor-pointer`}
                        onClick={() => setActiveTab(i)}
                      >
                        <Typography className="mb-0">{dd}</Typography>
                      </div>
                    ))}
                  </div>

                  <div>{tabComponents[activeTab]}</div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>

      {/* open edit modal */}
      {openEditModal ? (
        <EditDriverDetails
          isOpened={openEditModal}
          handleClose={handleCloseEdit}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DriverDetail;
