export const global_variables = {
  //auth routes
  signin_route: "/yarp/auth/signin",
  frgt_pass_url: "/yarp/auth/frgetpass",
  validate_code: "/yarp/auth/validate",
  reset_pass: "/yarp/auth/reset",

  // get routes
  yarpusers: `/yarp/yarpmain/users`,
  yarpunverified_drivers: `/yarp/yarpmain/unverified_drivers`,
  getunverifiedTrucks: `/yarp/yarpmain/unverified_trucks`,
  getunverifiedFleet: `/yarp/yarpmain/unverified_company`,
  getTransaction: `/yarp/yarpmain/transaction`,
  uid: `/yarp/yarpuser/details`,
  getTrips: `/yarp/yarpmain/triphistory`,
  getTracking: `/yarp/yarpmain/tracking`,
  get_roles: "/yarp/yarpmain/adiministration/roles",
  get_profession: "/yarp/yarpmain/adiministration/getprofession",
  yarp_payments: "/yarp/payment/pushhistory-admin",
  get_all_helps: "/yarp/yarpmain/getallhelp",
  get_car_brands: "/yarp/yarpservice/getbrands",

  get_car_type: "/yarp/yarpmain/getall_cartype",
  get_car_models: "/yarp/yarpservice/getcarmodels",
  get_customer_service: "/yarp/customer/all",
  get_updates: "/yarp/update/update-all",
  get_generals: "/yarp/yarpmain/adiministration/general",
  get_products_cats: "/yarp/yarpservice/getcategories",
  get_pricing: "/yarp/yarpmain/getall_pricing",
  get_regions: "/yarp/yarpmain/adiministration/regions",
  get_cancel_reasons: "/yarp/yarpuser/setups/cancelresponse",
  get_payment_limits: "/yarp/payment/payment-limit",
  get_all_commissions: "/yarp/price/commission/all",
  check_pricing: "/yarp/yarpuser/price",

  //put routes
  verifyDriver: `/yarp/yarpmain/verify_driver`,
  verifyTruck: `/yarp/yarpmain/verify_truck`,
  verifyFleet: `/yarp/yarpmain/verify_company`,
  suspend_users: `/yarp/yarpmain/adiministration/suspenduser`,
  unsuspend_users: `/yarp/yarpmain/adiministration/unsuspenduser`,
  assign_profession: "/yarp/yarpmain/adiministration/assignprofession",
  yarp_help: "/yarp/yarpservice/help",
  update_price_v2: "/yarp/price",
  update_price_by_percentage: "/yarp/price/upgrade-by-percentage",
  update_price_accross: "/yarp/price/upgrade-all-by-percentage",

  //post routes
  sendSms: `/yarp/yarpmain/smsservice`,
  create_profession: "/yarp/yarpmain/adiministration/createprofession",
  create_user: "/yarp/user",
  create_car_model: "/yarp/yarpmain/postmodel",
  create_customer_service: "/yarp/customer/post",
  create_updates: "/yarp/update/post",
  create_car_brand: "/yarp/yarpmain/postbrand",
  create_car_type: "/yarp/yarpmain/postcartypes",
  create_general: "/yarp/yarpmain/adiministration/postgeneral",
  create_products_cats: "/yarp/yarpmain/postcategory",
  create_pricing: "/yarp/yarpmain/postpricing",
  create_region: "/yarp/yarpmain/adiministration/region",
  create_payment_limit: "/yarp/payment/payment-limit`",
  create_commissions: "/yarp/price/commission",
  create_price_v2: "/yarp/price",
  clone_price_v2: "/yarp/price/clone-price",

  //version 2 routes
  get_all_drivers_v2: "/yarp/driver/admin",
  approve_or_decline_driver_v2: "/yarp/driver/verify",
  approve_or_decline_truck_v2: "/yarp/truck-verify",
  get_truck_brands_v2: "/yarp/truck/brands",
  get_truck_types_v2: "/yarp/truck/cartypes",
  get_all_users_v2: "/yarp/user/all",
  get_all_trucks_v2: "/yarp/truck",
  get_all_schedules: "/yarp/yarpmain/schedules",
  edit_driver_details_v2: "/yarp/driver/admin",
  update_truck_details_v2: "/yarp/truck/admin/",
  drafted_drivers_v2: "/yarp/driver/partial",
  driver_routes_v2: "/yarp/driver/admin",
  company_roles_route_v2: "/yarp/companyrole",
  assign_company_role_v2: "/yarp/companyrole/assign",
  admin_create_truck_v2: "/yarp/truck/admin",
  delete_drafted_driver: "/yarp/driver/partial-admin",
};
