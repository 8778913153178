import { useState, useEffect } from 'react'
import Select from 'react-select';
import BaseService from '../../helpers/baseService';
import { global_variables } from '../../helpers/globalVariables';

interface selectorProps {
    onChange: (data: any) => void
}

const TruckTypeSelector = ({ onChange }: selectorProps) => {

    const [isFetching, setIsFetching] = useState(false)
    const [truckTypes, setTruckTypes] = useState([])

    //onload
    useEffect(() => {
        const getAllTypes = async () => {
            setIsFetching(true)
            try {
                const response = await BaseService.get_api(`${global_variables.get_car_type}?limit=1000000`)
                const arranged = response?.data?.payload.map((data: any) => (
                    { label: data?.cartype, value: data?.cartype }
                ))
                setTruckTypes(arranged)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getAllTypes()
    }, [])

    return (
        <>
            <Select
                className="w-full"
                placeholder="Search truck type"
                isDisabled={isFetching}
                // isLoading={isFetching}
                isClearable={true}
                isSearchable={true}
                onChange={onChange}
                name="color"
                options={truckTypes}
            />
        </>
    )
}

export default TruckTypeSelector
