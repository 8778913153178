import React, { useEffect, useRef, useState } from "react";
import loadingGif from "../../images/yarp-spinner.gif";
import { Image } from "react-bootstrap";
import { MAPS_KEY } from "../../helpers/constants";
import socketConnection from "../../sockets/connection";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import Markers from "./Marker";
import DraggableBox from "./MapComponent/DraggableBox";
import { iconPath } from "./iconPath";

const MapsLayOutnew = (props: any) => {
  const driverId = props.driverId;
  const trackingId = props.trackingId;
  const pickupLatLng = props.PickupLocationLatLong;
  const deliveryLatLng = props.DeliveryLocationLatLng;
  const TripDetails = props.detail;

  const [getInitialLatitude, setInitialLatitude] = useState<number>(0);
  const [getInitialLongitude, setInitialLongitude] = useState<number>(0);
  const [getInitialSuccess, setInitialSuccess] = useState<any>();
  const [driverStreamLatitude, setDriverStreamLatitude] = useState<number>(0);
  const [driverStreamLongitude, setDriverStreamLongitude] = useState<number>(0);
  const [driverStreamRotation, setDriverStreamRotation] = useState<number>(0);
  const [getTripFootPrint, setTripFootPrint] = useState<any[]>([]);
  const [polyconPoints, setpolyconPoints] = useState<any[]>([]);
  const [polylinePath, setPolylinePath] = useState<any[]>([]);
  const [isDistanceOnmapClicked, setisDistanceOnMapClicked] =
    useState<boolean>(false);
  const ButtomRef = useRef<any>(null);

  const handleMapDistance = () => {
    if (ButtomRef.current) {
      ButtomRef.current.drawFlightPath();
      setisDistanceOnMapClicked((prev) => !prev);
    }
  };

  useEffect(() => {
    if (pickupLatLng && deliveryLatLng) {
      setPolylinePath([pickupLatLng, deliveryLatLng]);
    }
  }, [pickupLatLng, deliveryLatLng]);

  useEffect(() => {
    socketConnection.addListener(
      "/core/get-location",
      { userid: driverId },
      false,
      "channel"
    );

    socketConnection.addListener(`${driverId}/location`, null, true, null);
    const connectionSubscription = socketConnection.listener$.subscribe(
      (response) => {
        if (response["listener"] === "/core/get-location") {
          setInitialLatitude(response?.data?.payload?.location?.latitude);
          setInitialLongitude(response?.data?.payload?.location?.longitude);
          setInitialSuccess(response?.data?.success);
        }

        if (response["listener"] === `${driverId}/location`) {
          setDriverStreamLatitude(response?.data?.latitude);
          setDriverStreamLongitude(response?.data?.longitude);
          setDriverStreamRotation(response?.data?.rotation);
        }
      }
    );

    return () => {
      socketConnection.removeListener("/core/get-location");
      socketConnection.removeListener(`${driverId}/location`);
      connectionSubscription.unsubscribe();
    };
  }, []);

  //trip logs

  useEffect(() => {
    socketConnection.addListener(
      "/core/get-triplog",
      { trackingId: trackingId },
      false,
      "channel"
    );

    socketConnection.addListener(
      `${trackingId}/tripLocation`,
      null,
      true,
      null
    );

    const connectionSubscription = socketConnection.listener$.subscribe(
      (response) => {
        if (response["listener"] === "/core/get-triplog") {
          setTripFootPrint((prevFootPrint) => [
            ...prevFootPrint,
            ...response.data.payload.map(
              (item: { latitude: any; longitude: any; rotation: any }) => ({
                latitude: item.latitude,
                longitude: item.longitude,
                rotation: item.rotation,
                randomValue: Math.random(),
              })
            ),
          ]);

          console.log("response", response);

          setpolyconPoints((prev) => [
            ...prev,
            ...response?.data?.payload.map(
              (item: { latitude: any; longitude: any; rotation: any }) => ({
                lat: item.latitude,
                lng: item.longitude,
              })
            ),
          ]);
        }

        if (response["listener"] === `${trackingId}/tripLocation`) {
          setTripFootPrint((prev) => [
            ...prev,
            {
              latitude: response?.data?.latitude,
              longitude: response?.data?.longitude,
              rotation: response?.data?.rotation,
              key: Math.random(),
            },
          ]);

          setpolyconPoints((prev) => [
            ...prev,
            {
              lat: response?.data?.latitude,
              lng: response?.data?.longitude,
            },
          ]);
        }
      }
    );

    return () => {
      socketConnection.removeListener("/core/get-triplog");
      socketConnection.removeListener(`${trackingId}/tripLocation`);
      connectionSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    console.log("polyconPoints : ", polyconPoints);
  }, [polyconPoints]);
  const getPickupIcon = () => {
    if (window.google) {
      return {
        url: "data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%232966ff'><rect x='4' y='4' width='16' height='16'/></svg>",
        scaledSize: new window.google.maps.Size(50, 50),
      };
    }
    return null;
  };

  const getDeliveryIcon = () => {
    if (window.google) {
      return {
        url: "data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff6700'><rect x='4' y='4' width='16' height='16'/></svg>",
        scaledSize: new window.google.maps.Size(50, 50),
      };
    }
    return null;
  };

  return getInitialSuccess && getInitialLatitude ? (
    <div style={{ height: "80vh", width: "100%" }}>
      <APIProvider apiKey={MAPS_KEY}>
        <Map
          mapId={"539b1f8f16816929"}
          defaultCenter={{ lat: getInitialLatitude, lng: getInitialLongitude }}
          defaultZoom={18}
        >
          <Markers
            polyconPoints={polyconPoints}
            driverStreamLatitude={driverStreamLatitude}
            driverStreamLongitude={driverStreamLongitude}
            getInitialLatitude={getInitialLatitude}
            getInitialLongitude={getInitialLongitude}
            rotationIsInside={getTripFootPrint}
            iconPath={iconPath}
            pickupToDelivery={polylinePath}
            ref={ButtomRef}
          />

          {isDistanceOnmapClicked && (
            <>
              <Marker
                position={pickupLatLng}
                icon={getPickupIcon()}
                label={{
                  text: "Start",
                  color: "#fff",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              />

              <Marker
                position={deliveryLatLng}
                icon={getDeliveryIcon()}
                label={{
                  text: "End",
                  color: "#fff",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              />
            </>
          )}

          <DraggableBox
            handleMapDistance={handleMapDistance}
            TripDetails={TripDetails}
          />
        </Map>
      </APIProvider>
    </div>
  ) : (
    <div className=" flex items-center justify-center w-full">
      <section className=" w-1/5 h-1/5  relative">
        <Image fluid src={loadingGif} />
      </section>
    </div>
  );
};

export default React.memo(MapsLayOutnew);
