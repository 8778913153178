import { useEffect, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { Tooltip } from "@material-tailwind/react";
// import EarningText from "./EarningInputs/EarningText";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import BaseService from "../../helpers/baseService";
import useColumnsHook from "./usecolumnsHook";
import { ReductionFunction } from "./ReducerFunction";
import EarningTab from "./EarningInputs/EarningTabs";
import { LoadingDataComponent } from "../../components/general/loadingDataComponent";
import NewDatePickerRange from "../../components/shared/newDatePicker/NewDatePicker";
import InputSingle from "../../components/shared/InputSingle/InputSingle";
import NoDataComponent from "../../components/general/noDataComponent";

export interface IpaymentMethod {
  paymentType: string;
  contact: string;
}
interface IUdriverInformation {
  driverName: string;
  driverImage: string;
}
export interface IuData {
  id: number;
  Date_time: string;
  Driver: IUdriverInformation;
  Remit: string;
  Balance: string;
  Method: IpaymentMethod;
  DataAndTime: Date;
}

const NewEarningTable = () => {
  const [reFetch, setReFetch] = useState<boolean>(false);
  const [TabData, setTabData] = useState<number | string>(0);
  const [creditPayloadLength, setcreditPayloadLength] = useState(0);
  const [remitPayloadLength, setremitPayloadLength] = useState(0);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setloading] = useState<boolean>(true);

  const [myColumns] = useColumnsHook();
  const [updateTransactionStatus, setTransactionStatus] =
    useState<string>("CREDIT");
  useEffect(() => {
    Totaltransfer(skip, limit);
  }, [reFetch, skip, limit, TabData]);

  const setInitialState = {
    loadingCredit: true,
    loadingRemit: true,
    error: null,
    creditState: [],
    RemitState: [],
    newState: [],
    nameSearch: [],
    SearchMethod_: "default",
  };

  const [megaState, dispatchFunction] = useReducer(
    ReductionFunction,
    setInitialState
  );
  const Totaltransfer = async (
    skipArg: any,
    limitArg: any,
    dateStart?: any,
    dateEnd?: any,
    nameFilter?: string
  ) => {
    setloading(true);

    try {
      let creditFilter = `{"transactionStatus":"credit"}`;
      let remitFilter = `{"transactionStatus":"remit"}`;

      if (dateStart && dateEnd) {
        creditFilter = `{"$and":[{"timestamp":{"$gte":"${dateStart}"}},{"timestamp":{"$lt":"${dateEnd}"}}],"transactionStatus":"credit"}`;
        remitFilter = `{"$and":[{"timestamp":{"$gte":"${dateStart}"}},{"timestamp":{"$lt":"${dateEnd}"}}],"transactionStatus":"remit"}`;
      }

      if (nameFilter) {
        const nameRegex = {
          "driver_data.full_name": { $regex: nameFilter, $options: "i" },
        };
        creditFilter = `{"$and":[${creditFilter},${JSON.stringify(
          nameRegex
        )}]}`;
        remitFilter = `{"$and":[${remitFilter},${JSON.stringify(nameRegex)}]}`;
      }

      const [creditedResults, remittedResults] = await Promise.all([
        BaseService.get_api_v2(
          `/yarp/payment/transfers?filter=${encodeURIComponent(
            creditFilter
          )}&sort={"createdAt":-1}&limit=${limitArg}&skip=${skipArg}`
        ),
        BaseService.get_api_v2(
          `/yarp/payment/transfers?filter=${encodeURIComponent(
            remitFilter
          )}&sort={"createdAt":-1}&limit=${limitArg}&skip=${skipArg}`
        ),
      ]);

      const { payload: creditPayload } = await creditedResults.data;
      const { payload: remitPayload } = await remittedResults.data;

      // console.log("Credir payload", creditPayload);
      // console.log("Remit payload", remitPayload);

      let cLenght = creditPayload.length;
      let rLength = remitPayload.length;
      setcreditPayloadLength(cLenght);
      setremitPayloadLength(rLength);

      dispatchFunction({
        type: TabData,
        payload: { creditPayload, remitPayload },
      });
    } catch (error: any) {
      console.error("Error occured", error.message);
    } finally {
      setloading(false);
    }
  };
  const Texthandler = (textinput: string) => {
    // dispatchFunction({ type: "TEXT", payload: textinput });
    // setSearchMethod_Method_("TEXT");
    Totaltransfer(skip, limit, null, null, textinput);
    if (!textinput) {
      Totaltransfer(skip, limit);
    }
  };

  const onTabDataHandler = (arg: number | string) => {
    setTabData(arg);
  };

  useEffect(() => {
    setTransactionStatus(TabData === 0 ? "CREDIT" : "REMIT");
  }, [TabData]);

  const handleRowChange = (currentRowsPerPage: any) => {
    setLimit(currentRowsPerPage);
    Totaltransfer(skip, currentRowsPerPage);
  };

  const pageChange = (page: any) => {
    const newSkip = page === 1 ? 0 : (page - 1) * limit;
    Totaltransfer(newSkip, limit);
    setSkip(newSkip);
  };

  const onDateDataHandler = (dateObj: any) => {
    const { start, end } = dateObj;
    Totaltransfer(skip, limit, start, end);
    if (!start && !end) {
      Totaltransfer(skip, limit);
    }
  };
  myColumns[2].name = updateTransactionStatus;

  return (
    <>
      <div className="bg-white shadow-md rounded-[20px] p-5 ">
        <div className="mb-[20px] flex justify-between   items-center ">
          <div className=" flex items-center justify-between w-[30%] ">
            <Tooltip content="Reload" placement="top">
              <button
                className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
                onClick={() => setReFetch((prev) => !prev)}
              >
                <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
            <EarningTab onTabData={onTabDataHandler} />
          </div>
          <div className="flex gap-[20px] ">
            <div className="flex justify-between gap-6">
              <NewDatePickerRange onDateData={onDateDataHandler} />
              <div>
                <InputSingle onText={Texthandler} />
              </div>
              <Tooltip content="Print" placement="top">
                <button
                  className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
                  disabled
                >
                  <PrinterIcon className="w-5 h-5 text-yarpBlue" />
                </button>
              </Tooltip>
              <Tooltip content="Export" placement="top">
                <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                  <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="w-full min-h-[350px] ">
          {TabData === 1 ? (
            <DataTable
              columns={myColumns}
              data={megaState.RemitState}
              // progressPending={megaState.loadingCredit}
              progressPending={loading}
              progressComponent={<LoadingDataComponent />}
              noDataComponent={<NoDataComponent text="No records found" />}
              pagination={remitPayloadLength >= 11}
              paginationServer={remitPayloadLength >= 11}
              paginationTotalRows={remitPayloadLength}
              onChangeRowsPerPage={handleRowChange}
              onChangePage={pageChange}
            />
          ) : (
            <></>
          )}
          {TabData === 0 ? (
            <DataTable
              columns={myColumns}
              data={megaState.creditState}
              // progressPending={megaState.loadingCredit}
              progressPending={loading}
              progressComponent={<LoadingDataComponent />}
              pagination={remitPayloadLength >= 11}
              paginationServer={remitPayloadLength >= 11}
              paginationTotalRows={creditPayloadLength}
              onChangeRowsPerPage={handleRowChange}
              onChangePage={pageChange}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default NewEarningTable;
