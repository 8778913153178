import { useState } from "react";
import { Container, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { formatCedis } from "../../../utils/formatCedis";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Empty } from "antd";

const Commissions = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [payment_limit, setCommissions] = useState<any>([]);

  const [show, setShow] = useState<boolean>(false);

  const [type, setType] = useState<string>("price");
  const [comm, setComm] = useState<string>("");
  const [user, setUser] = useState<string>("driver");
  const [method, setMethod] = useState<string>("gain");

  const getCommissions = () => {
    setLoading(true);

    BaseService.get_api_v2(`${global_variables.get_all_commissions}`)
      .then((res) => {
        setCommissions(res?.data?.payload);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCommissions();
    document.title = "Commisions - Yarp Main";
  }, []);

  const columns: any = [
    {
      name: "Type",
      selector: (row: { commission_type: any }) => row.commission_type,
    },
    {
      name: "Commission",
      selector: (row: { commission: any }) => formatCedis(row.commission),
    },
    {
      name: "Method",
      selector: (row: { method: any }) => row.method,
    },
    {
      name: "user",
      selector: (row: { user: any }) => row.user,
    },
    {
      name: "Last updated",
      selector: (row: { updated_at: moment.MomentInput }) =>
        moment(row.updated_at).format("Do MMM YYYY"),
    },
  ];

  const addCommission = () => {
    ShowToast.loading_toast();

    var commissionData = {
      commission_type: type,
      commission: parseFloat(comm),
      user: user,
      method: method,
    };

    BaseService.post_api_v2(global_variables.create_commissions, commissionData)
      .then((res) => {
        ShowToast.success_toast(res?.data?.message);
        setShow(false);
        setComm("");
        getCommissions();
      })
      .catch((err) => {
        ShowToast.e(err?.error);
      });
  };

  return (
    <>
      <div className="text-end mb-3">
        <button
          className="px-3 py-2 bg-yarpOrange text-white rounded-[30px] shadow-md"
          onClick={() => {
            setShow(true);
          }}
        >
          Create New
        </button>
      </div>

      <DataTable
        data={payment_limit}
        columns={columns}
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={
          <div className="mt-[50px]">
            <Empty description="No data" />
          </div>
        }
      />

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add payment limit</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <FloatingLabel
              className="mb-3"
              controlId="Type"
              label="Select Type"
              onChange={(e: any) => {
                setType(e.target.value);
              }}
            >
              <Form.Select aria-label="Select the package">
                <option value="price">Price</option>
                <option value="percent">Percent</option>
              </Form.Select>
            </FloatingLabel>

            <FloatingLabel
              className="mb-3"
              controlId="User"
              label="Select User"
              onChange={(e: any) => {
                setUser(e.target.value);
              }}
            >
              <Form.Select aria-label="Select the package">
                <option value="driver">Driver</option>
                <option value="fleet">Fleet</option>
              </Form.Select>
            </FloatingLabel>

            <FloatingLabel
              className="mb-3"
              controlId="method"
              label="Select Method"
              onChange={(e: any) => {
                setMethod(e.target.value);
              }}
            >
              <Form.Select aria-label="Select the package">
                <option value="gain">Gain</option>
                <option value="deduct">Deduct</option>
              </Form.Select>
            </FloatingLabel>

            <FloatingLabel className="mb-3" controlId="comm" label="Commission">
              <Form.Control
                type="number"
                placeholder="5.00"
                value={comm}
                onChange={(e) => {
                  setComm(e.target.value);
                }}
              />
            </FloatingLabel>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="px-3 py-2 bg-yarpOrange text-white rounded-[30px] shadow-md"
            onClick={() => addCommission()}
          >
            Publish
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Commissions;
