"use client";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
export default function TimeSelect({
  onTimeInterval = "Week",
}: {
  onTimeInterval: any;
}) {
  const [PaymentMethod, setPaymentMethod] = React.useState<string>("Week");
  const [open, setOpen] = React.useState(false);
  const [PaymemtMethod, setPaymemtMethod] = React.useState<Array<any>>([]);

  const handleChange = (event: SelectChangeEvent<typeof PaymentMethod>) => {
    setPaymentMethod(event.target.value);
  };

  React.useEffect(() => {
    onTimeInterval(PaymentMethod);
  }, [PaymentMethod]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // React.useEffect(() => {
  //   const filtered = payloadData.filter(
  //     (method: { Method: any }) =>
  //       method.Method.paymentType === "Mobile Money" ||
  //       method.Method.paymentType === "Yarp Wallet"
  //   );

  //   const filteredOnly = filtered.map((obj: any) => obj.Method.paymentType);

  //   const uniqueSet: Set<string> = new Set(filteredOnly);
  //   const uniqueArray: string[] = Array.from(uniqueSet);

  //   const paymentFilter = uniqueArray.map((data: any) => {
  //     return {
  //       id: (Math.random() * 100).toString(),
  //       payment: data,
  //     };
  //   });
  //   setPaymemtMethod(paymentFilter);
  // }, [payloadData]);

  return (
    <div>
      <FormControl sx={{ width: 200 }}>
        <InputLabel
          id="demo-controlled-open-select-label"
          sx={{
            marginTop: -1,
          }}
        >
          Time Interval
        </InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={PaymentMethod}
          label="Age"
          onChange={handleChange}
          sx={{ height: 40, borderColor: "#FF6700" }}
          defaultValue="Week"
        >
          <MenuItem value={"Week"}>This Week</MenuItem>
          <MenuItem value={"Month"}>This Month</MenuItem>
          <MenuItem value={"Year"}>This Year</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
