import { useEffect, useState } from 'react';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import BaseService from '../../helpers/baseService';
import { global_variables } from '../../helpers/globalVariables';

interface brandProps {
    onChange?: (data: any) => void
    value?: any
}

const TruckWeightSelector = ({ onChange, value }: brandProps) => {

    const allGoodsType = [
        { label: "HEAVY", value: "heavy" },
        { label: "MODERATE", value: "moderate" },
        { label: "LIGHT", value: "light" }
    ]



    return (
        <>
            <Select
                className="basic-single"
                placeholder="Search weight type"
                isClearable={true}
                isSearchable={true}
                options={allGoodsType}
                noOptionsMessage={() => "Example: HEAVY"}
                onChange={onChange}
                isMulti={true}
                value={value}
            />
        </>
    )
}

export default TruckWeightSelector
