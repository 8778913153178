import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import BaseService from "../../helpers/baseService";
import { Splitter } from "antd";
import MapPage from "./SplitterDocs/MapPage";
import MapInformation from "./SplitterDocs/MapInformation";
import socketConnection from "../../sockets/connection";

const TripHistoryMap = () => {
  const [deliveryLatitude, setDeliveryLatitude] = useState<any>();
  const [pickupLatitude, setpickupLatitude] = useState<any>();
  const [tripHistoryData, setTripHistoryData] = useState<any>();
  const [getInitialLatlng, setInitialLatlng] = useState<number>(0);
  const [getInitialSuccess, setInitialSuccess] = useState<any>();
  const [driverStreamLongitude, setDriverStreamLongitude] = useState<number>(0);
  const [driverStreamRotation, setDriverStreamRotation] = useState<number>(0);
  const [getTripFootPrint, setTripFootPrint] = useState<any[]>([]);
  const [polyconPoints, setpolyconPoints] = useState<any[]>([]);
  const [polylinePath, setPolylinePath] = useState<any[]>([]);

  const { tripID, driverId } = useParams();

  const fetchSingleMapHistory = async () => {
    try {
      if (!tripID) {
        throw new Error("Trip ID is required");
      }
      const filter = { _id: { $eq: tripID } };
      const filterString = JSON.stringify(filter);

      const url = `/yarp/trip-history?filter=${filterString}`;
      const results = await BaseService.get_api_v2(url);

      if (!results) {
        throw new Error("No response from the server");
      }

      const response = results.data;
      const { payload } = response;
      // console.log("Payload:", payload[0]);
      setTripHistoryData(payload[0]);
      setDeliveryLatitude({
        lat: payload[0].delivery_latitude,
        lng: payload[0].delivery_longitude,
      });

      setpickupLatitude({
        lat: payload[0].pickup_latitude,
        lng: payload[0].pickup_longitude,
      });
    } catch (error: any) {
      console.error("Error fetching trip history map:", error.message || error);
    }
  };

  useEffect(() => {
    fetchSingleMapHistory();
  }, [tripID]);

  useEffect(() => {
    if (pickupLatitude && deliveryLatitude) {
      setPolylinePath([pickupLatitude, deliveryLatitude]);
    }
  }, [pickupLatitude, deliveryLatitude]);

  useEffect(() => {
    socketConnection.addListener(
      "/core/get-location",
      { userid: driverId },
      false,
      "channel"
    );

    socketConnection.addListener(`${driverId}/location`, null, true, null);
    const connectionSubscription = socketConnection.listener$.subscribe(
      (response) => {
        if (response["listener"] === "/core/get-location") {
          console.log("Response: ", response);

          setInitialLatlng(response?.data?.payload?.location);
          setInitialSuccess(response?.data?.success);
        }

        if (response["listener"] === `${driverId}/location`) {
          setDriverStreamLongitude(response?.data?.longitude);
          setDriverStreamRotation(response?.data?.rotation);
        }
      }
    );

    return () => {
      socketConnection.removeListener("/core/get-location");
      socketConnection.removeListener(`${driverId}/location`);
      connectionSubscription.unsubscribe();
    };
  }, []);

  //trip logs

  useEffect(() => {
    socketConnection.addListener(
      "/core/get-triplog",
      { trackingId: tripID },
      false,
      "channel"
    );

    socketConnection.addListener(`${tripID}/tripLocation`, null, true, null);

    const connectionSubscription = socketConnection.listener$.subscribe(
      (response) => {
        if (response["listener"] === "/core/get-triplog") {
          setTripFootPrint(
            response?.data?.payload.map(
              (item: {
                latitude: any;
                longitude: any;
                rotation: any;
                speed: any;
                timestamp: any;
              }) => ({
                latitude: item?.latitude,
                longitude: item?.longitude,
                rotation: item?.rotation,
                speed: item?.speed,
                timestamp: item?.timestamp,
                randomValue: Math.random(),
              })
            )
          );
        }

        if (response["listener"] === `${tripID}/tripLocation`) {
          setTripFootPrint((prev) => [
            ...prev,
            {
              latitude: response?.data?.latitude,
              longitude: response?.data?.longitude,
              rotation: response?.data?.rotation,
              key: Math.random(),
            },
          ]);

          console.log("Response for trip Location: ", response);

          setpolyconPoints((prev) => [
            ...prev,
            {
              lat: response?.data?.latitude,
              lng: response?.data?.longitude,
            },
          ]);
        }
      }
    );

    return () => {
      socketConnection.removeListener("/core/get-triplog");
      socketConnection.removeListener(`${tripID}/tripLocation`);
      connectionSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    console.log("getTripFootPrint : ", getTripFootPrint);
  }, [getTripFootPrint]);

  return (
    <Splitter
      style={{
        height: "100vh",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Splitter.Panel
        collapsible
        min="10%"
        defaultSize="60%"
        style={{
          backgroundColor: "rgb(255, 245, 230)",
          borderRight: "2px solid #FF6700",
        }}
      >
        <MapPage
          deliveryLatitude={deliveryLatitude}
          pickupLatitude={pickupLatitude}
          getInitialLatlng={getInitialLatlng}
          getInitialSuccess={getInitialSuccess}
          getTripFootPrint={getTripFootPrint}
        />
      </Splitter.Panel>

      <Splitter.Panel
        collapsible
        style={{
          overflow: "scroll",
          padding: "10px",
          backgroundColor: "#FFF5E6",
          borderLeft: "2px solid #FF6700",
        }}
      >
        <MapInformation tripHistoryData={tripHistoryData} />
      </Splitter.Panel>
    </Splitter>
  );
};

export default TripHistoryMap;
