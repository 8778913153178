import moment from "moment";
import ShowToast from "../../../components/alerts/all_toasts";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import { sendSmsFunction } from "../../../functions/sendSms";

export const suspend_user = (
  code: string,
  gencode: string,
  suspension_end: moment.MomentInput,
  user_id: any,
  phone: any,
  msg: string,
  susData?: {
    userid: any;
    susData: any;
  }
) => {
  if (code !== gencode) {
    ShowToast.warning_toast("Please type the code to approve suspension");
  } else if (msg === "") {
    ShowToast.warning_toast("Please tell the user the reason for suspension");
  } else {
    ShowToast.loading_toast();
    const suspension_start = moment().format("YYYY-MM-D");
    const suspension_end_use = moment(suspension_end).format("YYYY-MM-D");
    // console.log(phone)

    var suspend_data = {
      suspend: false,
      suspension_start: suspension_start,
      suspension_end: suspension_end_use,
      userid: user_id,
    };

    BaseService.put_api(global_variables.suspend_users, suspend_data)
      .then((res) => {
        ShowToast.success_toast(res?.data?.message);
        sendSmsFunction({
          message: msg,
          numbers: [phone],
        });
      })
      .catch((err) => {
        ShowToast.error_toast(err?.response?.data?.error);
      });
  }
};

export const unsuspend_user = (
  code: any,
  gencode: any,
  user_id: any,
  phone: any,
  msg: string
) => {
  if (code !== gencode) {
    ShowToast.warning_toast("Please type the code to approve suspension");
  } else {
    ShowToast.loading_toast();
    const message =
      msg === ""
        ? "Your admin account has been activated, please log into your yarp account."
        : msg;
    var suspend_data = {
      userid: user_id,
    };

    BaseService.put_api(global_variables.unsuspend_users, suspend_data)
      .then((res) => {
        ShowToast.success_toast(res?.data?.message);

        sendSmsFunction({
          message: message,
          numbers: [phone],
        });
      })
      .catch((err) => {
        ShowToast.error(err?.response?.data?.error);
      });
  }
};
