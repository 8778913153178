import { useEffect, useState } from "react";
import { FloatingLabel, Form, Image, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ChatBubbleLeftRightIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Empty } from "antd";

const HelpRequest = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [helps, setHelps] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [reFetch, setReFetch] = useState<boolean>(false);

  //getHelps
  const getHelps = (lim: number, sk: number) => {
    setLoading(true);

    BaseService.get_api(
      `${global_variables.get_all_helps}?limit=${lim}&skip=${sk}&sort={"timestamp":-1}`
    )
      .then((res) => {
        console.log(res?.data);
        setLoading(false);
        setHelps(res?.data?.payload);
        setTotal(res?.data?.total);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // table columns
  const columns: any = [
    {
      name: "Name",
      selector: (row: any) => (
        <div className="flex gap-2 items-center">
          <Image
            src={row.user_data?.image}
            fluid
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              borderRadius: "100%",
              marginRight: "10px",
            }}
          />
          {row.name}
        </div>
      ),
    },
    { name: "Request", cell: (row: any) => row.message, width: "35%" },
    {
      name: "Date & time",
      cell: (row: any) => moment(row.timestamp).format("DD/mm/yyyy"),
      width: "15%",
    },
    {
      name: "Status",
      // width: '10%',
      cell: (row: any) =>
        row.replies ? (
          <div className="px-3 py-1 rounded-[30px] bg-green-500 text-green-800 bg-opacity-[20%] w-fit">
            Replied
          </div>
        ) : (
          <div className="px-3 py-1 rounded-[30px] bg-yellow-500 text-yellow-800 bg-opacity-[20%] w-fit">
            Pending
          </div>
        ),
    },
    {
      name: "Action",
      cell: (row: any) =>
        row.replies ? null : (
          <ChatBubbleLeftRightIcon
            className="h-5 w-5 cursor-pointer"
            onClick={showReply.bind(this, row)}
          />
        ),
    },
  ];

  useEffect(() => {
    getHelps(limit, skip);
    document.title = "Help Requests - Yarp Main";
  }, [reFetch]);

  const handleRowChange = (a: any) => {
    getHelps(a, 0);
    setLimit(a);
    setSkip(0);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getHelps(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getHelps(limit, newSkip);
      setSkip(newSkip);
    }
  };

  const [showChat, setShowChat] = useState(false);
  const [reply, setReply] = useState("");
  const [title, setTitle] = useState("");
  const [helpDetail, setDetail] = useState<any>();
  const showReply = (row: any) => {
    console.log(row);
    setShowChat(true);
    setDetail(row);
  };

  //replyMessage
  const replyMessage = (event: any) => {
    event.preventDefault();
    setShowChat(false);

    ShowToast.loading_toast();

    var reply_data = {
      help_id: helpDetail._id,
      response_title: title,
      response_message: reply,
    };

    BaseService.put_api(global_variables.yarp_help, reply_data)
      .then((res) => {
        console.log(res?.data);
        ShowToast.success_toast(res?.data?.messsage);
        setReply("");
        setTitle("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //handleRefetch
  const handleRefetch = () => [setReFetch(!reFetch)];

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <button
            className="h-10 w-10 border-[1px] border-yarpBlue rounded-[4px] flex justify-center items-center"
            onClick={() => handleRefetch()}
          >
            <ArrowPathIcon className="h-5 w-5 text-yarpBlue" />
          </button>
        </div>
        <div className="flex gap-3">
          <div>
            <Form className="d-flex flex-row-reverse">
              <input
                type="text"
                placeholder="Search.."
                className="form-control search_table h-10"
                style={{ width: "100%" }}
              />
            </Form>
          </div>
          <div className="flex gap-3">
            <button className="h-10 w-10 border-[1px] border-yarpBlue rounded-[4px] flex justify-center items-center">
              <PrinterIcon className="h-5 w-5 text-yarpBlue" />
            </button>
            <button
              type="submit"
              className="h-10 w-10 border-[1px] border-yarpBlue rounded-[4px] flex justify-center items-center"
            >
              <ArrowDownTrayIcon className="h-5 w-5 text-yarpBlue" />
            </button>
          </div>
        </div>
      </div>

      <div className="">
        <DataTable
          highlightOnHover
          data={helps}
          columns={columns}
          progressPending={loading}
          progressComponent={<LoadingDataComponent />}
          noDataComponent={<Empty description="No data" />}
          pagination={total >= 11 ? true : false}
          paginationServer
          paginationTotalRows={total}
          onChangeRowsPerPage={handleRowChange}
          onChangePage={pageChange}
        />
      </div>

      <Modal
        show={showChat}
        onHide={() => {
          setShowChat(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reply {helpDetail?.name}'s request</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {showChat ? (
            <Form onSubmit={replyMessage}>
              <FloatingLabel
                controlId="floatingInput"
                label="Title"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingTextarea2" label="Comments">
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                  value={reply}
                  onChange={(e) => setReply(e.target.value)}
                />
              </FloatingLabel>
              <button
                className="btn btn-sm text-light mt-3"
                style={{ background: "#52b3ef" }}
                type="submit"
              >
                send
              </button>
            </Form>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HelpRequest;
