import { useCallback } from "react";

const useStatusStyle = () => {
  const getStatusStyle = useCallback((status: string) => {
    switch (status) {
      case "Pending":
        return { backgroundColor: "#fcf9f0", color: "#ECC15D" };
      case "Accept":
        return { backgroundColor: "#f3faf6", color: "#77D1A4" };
      case "Arrived At PickUp":
        return { backgroundColor: "#eff7fc", color: "#52B3EF" };
      case "Start Queuing":
        return { backgroundColor: "#f7f2fa", color: "#B87CD2" };
      case "End Queuing":
        return { backgroundColor: "#fdf0e6", color: "#FF6700" };
      case "Start Loading":
        return { backgroundColor: "#f2f0fe", color: "#7D68FE" };
      case "Cancel":
        return { backgroundColor: "#fcefef", color: "#FF638D" };
      case "End Loading":
        return { backgroundColor: "#E6EEFB", color: "#0A67F3" };
      case "Trip Started":
        return { backgroundColor: "#F3F9E9", color: "#8BCE1C" };
      case "Trip Ended":
        return { backgroundColor: "#FBE7E6", color: "#FF0000" };
      case "Payment Complete":
        return { backgroundColor: "#F3F9E9", color: "#8BCE1C" };
      case "End Queuing At Destination":
        return { backgroundColor: "#FCE4EC", color: "#C2185B" };
      case "Start Offloading":
        return { backgroundColor: "#f2f0fe", color: "#7D68FE" };
      case "End Offloading":
        return { backgroundColor: "#E6EEFB", color: "#0A67F3" };
      case "Queuing At Destination":
        return { backgroundColor: "#f3faf6", color: "#ECC15D" };
      case "complete Trip":
        return { backgroundColor: "#f2f0fe", color: "#52B3EF" };
      case "Not Available":
        return { backgroundColor: "#fcefef", color: "#FF638D" };
      default:
        return { backgroundColor: "#696969", color: "#A9A9A9" };
    }
  }, []);

  return getStatusStyle;
};

export default useStatusStyle;
