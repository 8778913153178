import React from "react";
import { Select, Space } from "antd";

const AntSelect = ({
  onSelectData,
  placeholder,
  options,
}: {
  onSelectData: any;
  placeholder: string;
  options: any;
}) => {
  const handleChange = (value: string) => {
    onSelectData(value);
  };

  return (
    <Space wrap>
      <Select
        placeholder={placeholder}
        style={{ width: 200, height: "40px" }}
        onChange={handleChange}
        allowClear
        options={options}
      />
    </Space>
  );
};

export default AntSelect;
