import { useState } from "react";
import { Modal } from "antd";
import ShowToast from "../../../components/alerts/all_toasts";
import BaseService from "../../../helpers/baseService";

const { confirm } = Modal;

interface ModalProps {
  open: boolean;
  driverID: any;
  tripId: any;
  userid: any;
  setOpenAssignDriver: (open: boolean) => void;
}

const AssignDriverModal = ({
  open,
  driverID,
  tripId,
  userid,
  setOpenAssignDriver,
}: ModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleCancel = () => {
    setOpenAssignDriver(false);
  };

  const handleOk = async () => {
    confirm({
      title: "Are you sure you want to assign the trip to this driver?",
      content: "This action cannot be undone.",
      onOk: async () => {
        setLoading(true);

        const payload = {
          trip_id: tripId,
          status: "Accept",
          userid: userid,
          driver: driverID,
        };

        console.log("payload ", payload);
        try {
          const response = await BaseService.put_api_v2(
            `/yarp/trip/event`,
            payload
          );
          setTimeout(() => setOpenAssignDriver(false), 800);
          ShowToast.success_toast(
            "Trip has been succesfully Assigned to the Driver"
          );
        } catch (error: any) {
          ShowToast.info_toast(error.message || "Something went wrong");
          setOpenAssignDriver(true);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        setOpenAssignDriver(false);
      },
      okButtonProps: {
        style: {
          backgroundColor: "#52b3ef",
          color: "#FFFFFF",
          boxShadow: "none",
        },
      },
      cancelButtonProps: {
        style: { boxShadow: "none" },
      },
    });
  };

  return (
    <>
      <Modal
        width={"35%"}
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        okText="Assign Trip"
        okButtonProps={{
          style: {
            backgroundColor: "#52b3ef",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "10vh" } }}
      >
        <div className=" w-full h-full">
          <section className="bg-white  flex justify-center items-center mt-10 p-6  text-center">
            <h1 className="text-lg font-semibold mb-4 text-[#252363]">
              Youre going to Assign this Trip to this Driver !!
            </h1>
            <div className="flex p-2 gap-4"></div>
          </section>
        </div>
      </Modal>
    </>
  );
};

export default AssignDriverModal;
