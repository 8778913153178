import { useState } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import { Typography } from "@material-tailwind/react";
import HelpRequest from "./tabs/help_request";
import CustomerService from "./tabs/customerservice";
import Updates from "./tabs/updates";
import DriverFAQs from "./tabs/DriverFAQs";
import UserFAQs from "./tabs/UserFAQs";

const Support = () => {
  const [activeTab, setActiveTab] = useState(0);

  const allMenus = [
    // "User FAQs",
    // "Driver FAQs",
    "Help Requests",
    "Customer Service",
    "Updates",
  ];

  const allMenusComponents = [
    // <UserFAQs />,
    // <DriverFAQs />,
    <HelpRequest />,
    <CustomerService />,
    <Updates />,
  ];

  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar supportActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">Support</p>

              <div className="mt-3 lg:grid lg:grid-cols-4 gap-3">
                <div className="mt-3 bg-white shadow-md rounded-[20px] py-5 col-span-1 w-full h-fit">
                  {allMenus.map((menu, i) => (
                    <div
                      key={i}
                      className={`${
                        activeTab === i
                          ? "rounded-r-[30px] bg-yarpBlue text-white "
                          : "hover:bg-yarpBlue hover:bg-opacity-[10%] hover:rounded-r-[30px]"
                      } mr-[40px] h-fit py-1 cursor-pointer`}
                      onClick={() => setActiveTab(i)}
                    >
                      <Typography className="ml-[30px] font-medium mt-[10px]">
                        {menu}
                      </Typography>
                    </div>
                  ))}
                </div>

                <div className="mt-3 bg-white shadow-md rounded-[20px] col-span-3 p-5">
                  {allMenusComponents[activeTab]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
