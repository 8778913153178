import { useEffect, useState } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import DraftedTable from "./draftedTable";

const DraftedDrivers = () => {

  useEffect(() => {
    document.title = "Drafted Drivers - Yarp Main";
  }, []);


  return (
    <>
      <div className="h-screen overflow-hidden flex bg-[#FFFCF9]">
        {/* sidebar */}
        <CustomSideBar draftedActive="active" />
        {/* content */}
        <div className="w-full">
          {/* navbar */}
          <CustomNavbar />

          {/* main */}
          <div className="h-full overflow-y-auto">
            <div className="p-8 mb-20">
              <p className="text-[26px] font-[600] text-yarpGray">Drafted Drivers</p>

              <div>
                <DraftedTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DraftedDrivers;
