import { useEffect, useState } from "react";
import { Container, FloatingLabel, Form, Modal } from "react-bootstrap";
import { Button, Option, Select, Spinner } from "@material-tailwind/react";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import ShowAlert from "../../../components/alerts/all_alerts";

interface modalprops {
  showModal: boolean;
  handleModalShow: () => void;
  dataToCLone: any;
  handleReFetch: () => void;
}

const ClonePriceModal = ({
  showModal,
  handleModalShow,
  dataToCLone,
  handleReFetch,
}: modalprops) => {
  const [isBusy, setIsBusy] = useState(false);
  const [allCarTypes, setAllCarTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [theCloneId, setTheCloneId] = useState("");
  const [thePercentage, setThePercentage] = useState("");
  const [selectedAction, setSelectedAction] = useState("true");

  useEffect(() => {
    //getCarTypes
    const getCarTypes = () => {
      BaseService.get_api(global_variables.get_car_type)
        .then((res) => {
          setIsBusy(false);
          setAllCarTypes(res?.data?.payload);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getCarTypes();
    console.log(dataToCLone);
    setTheCloneId(dataToCLone?._id);
    setSelectedType(dataToCLone?.car_type);
  }, [dataToCLone]);

  //doClone
  const doClone = async () => {
    setIsBusy(true);
    try {
      const dataClone = JSON.stringify({
        cloneId: theCloneId,
        car_type: selectedType,
        percentage: parseInt(thePercentage),
        add: JSON.parse(selectedAction),
      });

      const response = await BaseService.post_api_v2(
        global_variables.clone_price_v2,
        dataClone
      );
      console.log(response?.data);
      ShowToast.success_toast(response?.data?.message);
      handleModalShow();
      handleReFetch();
    } catch (error: any) {
      console.log(error);
      setIsBusy(false);
      handleModalShow();
      ShowAlert.error_alert("Error", error?.response?.data?.error, "Retry");
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleModalShow}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Clone Pricing For ${dataToCLone?.car_type}`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container className="mb-3" fluid>
            <div>
              <FloatingLabel
                controlId="truck_type"
                label="Select Type"
                className="mb-3"
              >
                <Form.Select
                  id="selectType"
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                >
                  <option value="" disabled></option>
                  {(isBusy && <option value="">Loading...</option>) ||
                    allCarTypes.map((types: any) => {
                      return (
                        <option value={types.cartype} key={types._id}>
                          {types.cartype}
                        </option>
                      );
                    })}
                </Form.Select>
              </FloatingLabel>

              <div>
                <FloatingLabel
                  controlId="fpk"
                  label="Enter percentage value eg: 10"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    value={thePercentage}
                    onChange={(e) => setThePercentage(e.target.value)}
                  />
                </FloatingLabel>
              </div>

              <div>
                <Select
                  label="Add or Subtract"
                  value={selectedAction}
                  onChange={(val: any) => setSelectedAction(val)}
                >
                  <Option value="true">Add</Option>
                  <Option value="false">Subtract</Option>
                </Select>
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="gradient"
            color="blue"
            onClick={() => doClone()}
            disabled={
              isBusy || !selectedType || !thePercentage || !selectedAction
            }
          >
            {isBusy ? <Spinner /> : "Clone"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClonePriceModal;
