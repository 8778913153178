import { useState, useEffect } from 'react'
import { Empty, message, notification } from 'antd';
import DataTable from 'react-data-table-component';
import { LoadingDataComponent } from '../../components/general/loadingDataComponent';
import moment from 'moment';
import { Image } from 'react-bootstrap';
import BaseService from '../../helpers/baseService';
import { global_variables } from '../../helpers/globalVariables';
import { DraftedDriverPayload } from './payload.model';
import { PhotoView } from 'react-photo-view';
import { ArrowPathIcon, EllipsisVerticalIcon, EnvelopeIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import AccountDetails from './modals/accountDetails';
import SendDriverSms from './modals/sendDriverSms';
import PhoneInput from "react-phone-number-input";
import { debounce } from 'lodash';
import ShowAlert from '../../components/alerts/all_alerts';
import ShowToast from '../../components/alerts/all_toasts';
import { error } from 'console';
const notAvailable = "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"


const DraftedTable = () => {

    const [isFetching, setIsFetching] = useState(false)
    const [draftedList, setDraftedList] = useState([])
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [skip, setSkip] = useState(0)
    const [selectedDriver, setSelectedDriver] = useState<any>(null)
    const [reFetch, setReFetch] = useState(false)
    const [thePhoneNumber, setThePhoneNumber] = useState("");
    const [theFilter, setTheFilter] = useState<any>("{}")

    //details modal trigger
    const [openDetails, setOpenDetails] = useState(false)
    const handleOpenDetails = () => {
        setOpenDetails(true)
    }
    const handleCloseDetails = () => {
        setOpenDetails(false)
    }

    //sms modal trigger
    const [openSms, setOpenSms] = useState(false)
    const handleOpenSms = () => {
        setOpenSms(true)
    }
    const handleCloseSms = () => {
        setOpenSms(false)
    }

    //getAllDrafted
    const getAllDrafted = async (lim: number, sk: number) => {
        setIsFetching(true)
        try {
            const response = await BaseService.get_api_v2(`${global_variables.drafted_drivers_v2}?limit=${lim}&skip=${sk}&filter=${theFilter}`)
            // console.log(response?.data)
            setDraftedList(response?.data?.payload)
            setTotal(response?.data?.total)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
        }
    }

    //onload
    useEffect(() => {
        getAllDrafted(limit, skip)

        // eslint-disable-next-line
    }, [reFetch, thePhoneNumber])

    //dropdown menu items
    const items: MenuProps['items'] = [
        {
            label: <button className='w-full flex gap-2 items-center' onClick={() => handleOpenDetails()}>
                <EyeIcon className='h-4 w-4' />
                View Account
            </button>,
            key: '0',
        },
        {
            label: <button className='w-full flex gap-2 items-center' onClick={() => handleOpenSms()}>
                <EnvelopeIcon className='h-4 w-4' />
                Send Message
            </button>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <button className='w-full flex gap-2 items-center' onClick={() => handleDeleteDrafted()}>
                <TrashIcon className='h-4 w-4 text-red-500' />
                Delete Account
            </button>,
            key: '2',
        },
    ];

    //table columns
    const columns: any = [
        {
            name: <p className="font-[600] text-[14px] text-yarpGray">IMAGE</p>,
            selector: (row: any) => (
                <PhotoView
                    src={
                        row?.image || row?.profile_image || "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                    }
                >
                    <Image
                        src={
                            row?.image || row?.profile_image || "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                        }
                        fluid
                        style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "cover",
                            borderRadius: "100%",
                            marginRight: "10px",
                        }}
                    />
                </PhotoView>
            ), width: "10%"
        },
        {
            name: <p className="font-[600] text-[14px] text-yarpGray">PHONE</p>,
            cell: (row: DraftedDriverPayload) => row?.user_data?.phone,
        },
        {
            name: <p className="font-[600] text-[14px] text-yarpGray">LICENCE</p>,
            selector: (row: any) => <div className='flex gap-3'>
                <div className='text-center'>
                    <PhotoView
                        src={
                            row?.licence_image || notAvailable
                        }
                    >
                        <img className='h-10 w-10 rounded-[5px] object-cover' src={row?.licence_image || notAvailable} alt="front" />
                    </PhotoView>
                    <small>Front</small>
                </div>
                <div className='text-center'>
                    <PhotoView
                        src={
                            row?.licence_back_image || notAvailable
                        }
                    >
                        <img className='h-10 w-10 rounded-[5px] object-cover' src={row?.licence_back_image || notAvailable} alt="back" />
                    </PhotoView>
                    <small>Back</small>
                </div>
            </div>
        },
        {
            name: <p className="font-[600] text-[14px] text-yarpGray">TRUCK</p>,
            selector: (row: any) => <div className='flex gap-3'>
                <div className='text-center'>
                    <PhotoView
                        src={
                            row?.truck_data_partial?.truckfront || row?.truck_data?.truckfront || notAvailable
                        }
                    >
                        <img className='h-10 w-10 rounded-[5px] object-cover' src={row?.truck_data_partial?.truckfront || row?.truck_data?.truckfront || notAvailable} alt="front" />
                    </PhotoView>
                    <small>Front</small>
                </div>
                <div className='text-center'>
                    <PhotoView
                        src={
                            row?.truck_data_partial?.truckback || row?.truck_data?.truckback || notAvailable
                        }
                    >
                        <img className='h-10 w-10 rounded-[5px] object-cover' src={row?.truck_data_partial?.truckback || row?.truck_data?.truckback || notAvailable} alt="back" />
                    </PhotoView>
                    <small>Back</small>
                </div>
                <div className='text-center'>
                    <PhotoView
                        src={
                            row?.truck_data_partial?.truckside || row?.truck_data?.truckside || notAvailable
                        }
                    >
                        <img className='h-10 w-10 rounded-[5px] object-cover' src={row?.truck_data_partial?.truckside || row?.truck_data?.truckside || notAvailable} alt="back" />
                    </PhotoView>
                    <small>Side</small>
                </div>
            </div>,
            width: "20%"
        },
        {
            name: <p className="font-[600] text-[14px] text-yarpGray">INSURANCE</p>,
            selector: (row: any) =>
                <div className='text-center'>
                    <PhotoView
                        src={
                            row?.truck_data_partial?.insurance || row?.truck_data?.insurance || notAvailable
                        }
                    >
                        <img className='h-10 w-10 rounded-[5px] object-cover' src={row?.truck_data_partial?.insurance || row?.truck_data?.insurance || notAvailable} alt="front" />
                    </PhotoView>
                    <small>Front</small>
                </div>
        },
        {
            name: <p className="font-[600] text-[14px] text-yarpGray">ROADWORTHY</p>,
            selector: (row: any) =>
                <div className='text-center'>
                    <PhotoView
                        src={
                            row?.truck_data_partial?.roadworthy || row?.truck_data?.roadworthy || notAvailable
                        }
                    >
                        <img className='h-10 w-10 rounded-[5px] object-cover' src={row?.truck_data_partial?.roadworthy || row?.truck_data?.roadworthy || notAvailable} alt="front" />
                    </PhotoView>
                    <small>Front</small>
                </div>
        },
        {
            name: (
                <p className="font-[600] text-[14px] text-yarpGray">DATE CREATED</p>
            ),
            cell: (row: any) =>
                moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
        },
        {
            name: <p className="font-[600] text-[14px] text-yarpGray"></p>,
            cell: (row: DraftedDriverPayload) => <Dropdown menu={{ items }} trigger={['click']}>
                <button onClick={() => setSelectedDriver(row)}>
                    <EllipsisVerticalIcon className='h-5 w-5' />
                </button>
            </Dropdown>,
            width: "5%"
        },
    ];

    //hnadlePhoneChange
    const handlePhoneChange = debounce((newValue: string) => {
        setThePhoneNumber(newValue);
        console.log(newValue)
        if (newValue) {
            setTheFilter(`{"user_data.phone": {"$regex":${JSON.stringify(newValue)}}}`)
        } else {
            setTheFilter("{}")
        }
    }, 500);


    // ============ pagination start ============== //
    const handleRowChange = (a: any) => {
        setLimit(a);
        getAllDrafted(a, skip);
    };

    const pageChange = (a: any) => {
        if (a === 1) {
            getAllDrafted(limit, 0);
            setSkip(0);
        } else {
            const newSkip = a * limit - limit;
            getAllDrafted(limit, newSkip);
            setSkip(newSkip);
        }
    };
    // ============ pagination end ============== //


    //handleReftch
    const handleReftch = () => {
        setReFetch(!reFetch)
    }

    //handleDeleteDrafted
    const handleDeleteDrafted = () => {
        ShowAlert.question_alert("Delete Drafted Driver", "Are you sure you want to perform this action? This action cannot be reversed.", "Proceed")
            .then((action) => {
                if (action.isConfirmed) {
                    //proceed to delete
                    const del_data = {
                        "userid": selectedDriver?.userid
                    }

                    ShowToast.loading_toast("Please wait...")

                    BaseService.delete_api_v2(global_variables.delete_drafted_driver, del_data)
                        .then((response) => {
                            console.log("------delDraftedDriverRes:", response?.data)
                            ShowToast.success_toast(response?.data?.message || "Account was deleted successfully")
                            handleReftch()
                        }).catch((error: any) => {
                            ShowToast.error_toast(error?.response?.data?.error || "An error occured, please try again")
                        })
                }
            })
    }

    return (
        <>
            <div className="p-4 rounded-[20px] bg-white shadow-lg">
                <div className="flex justify-between items-center mb-5">
                    <button
                        className="h-10 w-10 border-[1px] border-yarpBlue rounded-[4px] flex justify-center items-center"
                        onClick={() => handleReftch()}
                    >
                        <ArrowPathIcon className="h-5 w-5 text-yarpBlue" />
                    </button>

                    {/* <div className="w-[250px]">
                        <small>Filter by phone</small>
                        <PhoneInput
                            id="phone"
                            className="rounded-[5px] w-full border-[1px] border-gray-500 px-2 h-[40px]"
                            defaultCountry="GH"
                            inputStyle={{ width: "100%" }}
                            value={thePhoneNumber}
                            onChange={(phone: any) => handlePhoneChange(phone)}
                            //   disabled={isBusy}
                            numberInputProps={{
                                className: "focus:outline-none",
                            }}
                        />
                    </div> */}
                </div>

                <div className="mt-3">
                    <DataTable
                        columns={columns}
                        data={draftedList}
                        pagination
                        paginationServer
                        pointerOnHover
                        highlightOnHover
                        paginationTotalRows={total}
                        noDataComponent={
                            <Empty description="There are no drafted drivers" />
                        }
                        progressPending={isFetching}
                        progressComponent={<LoadingDataComponent />}
                        onChangeRowsPerPage={handleRowChange}
                        onChangePage={pageChange}
                    />
                </div>
            </div>


            {/* open details modal */}
            {
                openDetails ? <AccountDetails isOpened={openDetails} handleClose={handleCloseDetails} driverInfo={selectedDriver} handleReFetch={() => setReFetch(!reFetch)} /> : <></>
            }

            {/* open sms modal */}
            {
                openSms ? <SendDriverSms isOpened={openSms} handleClose={handleCloseSms} driverInfo={selectedDriver} /> : <></>
            }

        </>
    )
}

export default DraftedTable
