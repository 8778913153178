import moment from "moment";
import { useEffect, useState } from "react";
import { Image, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import { Spinner, Tooltip } from "@material-tailwind/react";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { PhotoView } from "react-photo-view";
import DeclineDialog from "../dialogs/declineDialog";
import { CSVLink } from "react-csv";
import { Empty } from "antd";
import NewDatePickerRange from "../../../components/shared/newDatePicker/NewDatePicker";
import {
  DriverNameSort,
  UdatedAtSort,
} from "../../../components/general/TableSortFunctions";

const UnapprovedDriversTable = () => {
  const [loading, setLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [reFetch, setReFetch] = useState(false);

  //get unapproved drivers
  const getDrivers = async (
    lim: any,
    sk: any,
    dateStart: any = null,
    dateEnd: any = null
  ) => {
    try {
      let url = `filter={"$and": [{"approved": false}, {"declined": {"$eq": null}}]}`;
      if (dateStart && dateEnd) {
        url = `filter={"$and": [{"approved": false},{"created_at":{"$gte":"${dateStart}"}},{"created_at":{"$lt":"${dateEnd}"}},{"declined": {"$eq": null}}]}`;
      }

      url += `&limit=${lim}&skip=${sk}&sort={"created_at":-1}`;
      const response = await BaseService.get_api_v2(
        `${global_variables.get_all_drivers_v2}?${url}`
      );
      console.log("this is the new response", response.data.payload);
      setLoading(false);
      setTotalRows(response?.data?.total);
      setDrivers(response?.data?.payload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDrivers(limit, skip);
  }, [limit, skip, reFetch]);

  //table columns
  const columns: any = [
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">NAME</p>,
      selector: (row: any) => (
        <div className="flex gap-3 items-center">
          <Image
            src={
              row?.driver_data?.image ||
              "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI="
            }
            fluid
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              borderRadius: "100%",
              marginRight: "10px",
            }}
          />
          {row?.driver_data?.full_name}
        </div>
      ),
      width: "25%",
      sortable: true,
      sortFunction: DriverNameSort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">LICENCE NO.</p>,
      selector: (row: any) => row?.licence,
      width: "25%",
      sortable: true,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">PHONE</p>,
      selector: (row: any) => row?.driver_data?.phone,
      sortable: true,
      sortFunction: DriverNameSort,
    },
    {
      name: (
        <p className="font-[600] text-[14px] text-yarpGray">DATE CREATED</p>
      ),
      cell: (row: any) =>
        moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
      sortable: true,
      sortFunction: UdatedAtSort,
    },
    {
      name: <p className="font-[600] text-[14px] text-yarpGray">STATUS</p>,
      selector: (row: any) =>
        row?.approved ? (
          <div className="px-4 py-1 bg-yarpGreen text-yarpGreen bg-opacity-[20%] rounded-[40px]">
            Approved
          </div>
        ) : (
          <div className="px-4 py-1 bg-red-500 text-red-500 bg-opacity-[20%] rounded-[40px]">
            Pending
          </div>
        ),
    },
  ];

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<any>([]);
  const [isBusy, setIsBusy] = useState(false);
  const displayModal = (row: any) => {
    // console.log(row);
    setModalData(row);
    setShow(true);
  };

  //approve driver
  const approveDriver = async () => {
    setIsBusy(true);

    var driver_data = {
      driverId: modalData._id,
      approved: true,
      remarks: modalData?.driver_data
        ? `Congratulations ${modalData?.driver_data?.full_name}!`
        : "Congratulations!",
    };
    // console.log(driver_data);

    try {
      const response = await BaseService.put_api_v2(
        `${global_variables.approve_or_decline_driver_v2}`,
        driver_data
      );
      // console.log(response);
      ShowToast.success_toast("Driver has been approved");
      setShow(false);
      setIsBusy(false);
      getDrivers(limit, skip);
    } catch (error: any) {
      console.log(error);
      ShowToast.error_toast(error?.response?.data?.message);
    }
  };

  const handleRowChange = (a: any) => {
    setLimit(a);
    getDrivers(a, skip);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getDrivers(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getDrivers(limit, newSkip);
      setSkip(newSkip);
    }
  };

  //decline modal stuff
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const handleOpenedDeclineModal = () => {
    setOpenDeclineModal(true);
    setShow(false);
  };
  const handleCloseDeclineModal = () => {
    setOpenDeclineModal(false);
    setShow(true);
  };

  //export data
  const formatDataForExport = (data: any) => {
    return data.map((row: any) => ({
      NAME: row?.driver_data?.full_name,
      "LICENCE NO.": row?.licence,
      PHONE: `.${row?.driver_data?.phone}`,
      "DATE CREATED": moment(row?.updated_at).format("Do MMM YYYY - hh:mm A"),
      STATUS: row?.approved ? "Approved" : "Pending",
    }));
  };

  const onDateDataHandler = (dateObj: any) => {
    const { start, end } = dateObj;
    getDrivers(limit, skip, start, end);
    if (!start && !end) {
      getDrivers(limit, skip);
    }
  };

  return (
    <>
      <div className="mt-[20px]  flex justify-between items-center">
        <Tooltip content="Reload" placement="top">
          <button
            className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
            onClick={() => setReFetch(!reFetch)}
          >
            <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
          </button>
        </Tooltip>
        <div className="flex gap-[10px]">
          <NewDatePickerRange onDateData={onDateDataHandler} />

          <Tooltip content="Print" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <PrinterIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <CSVLink
            data={formatDataForExport(drivers)}
            filename="Unapproved Drivers"
          >
            <Tooltip content="Export" placement="top">
              <button className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center">
                <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
              </button>
            </Tooltip>
          </CSVLink>
        </div>
      </div>
      <DataTable
        className="mt-[10px]"
        columns={columns}
        data={drivers}
        pagination={totalRows >= 11 ? true : false}
        paginationServer
        pointerOnHover
        highlightOnHover
        paginationTotalRows={totalRows}
        noDataComponent={
          <Empty description="There are no drivers pending approval" />
        }
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        onRowClicked={(row: any) => {
          displayModal(row);
        }}
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
      />

      {/* driver details Modal */}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setModalData([]);
        }}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="p-[30px]">
            <p className="text-[20px] font-[600] text-yarpGray">
              Personal Details
            </p>

            <hr />

            {show ? (
              <>
                {/* driver info */}
                <div className="flex gap-[53px] items-center">
                  <PhotoView
                    src={
                      modalData?.driver_data?.image ||
                      "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                    }
                  >
                    <img
                      className="h-[153px] w-[153px] rounded-full object-cover cursor-pointer"
                      src={
                        modalData?.driver_data?.image ||
                        "https://storage.googleapis.com/yarp-af4a0.appspot.com/user-image/arcQzYPsi3NJ9m2"
                      }
                      alt="driver"
                    />
                  </PhotoView>
                  <div className="w-[450px]">
                    <div className="lg:grid lg:grid-cols-2 mb-0">
                      <p className="text-[14px] font-[600] text-yarpGray">
                        First Name
                      </p>
                      <p className="text-[14px] font-[300] text-yarpGray">
                        {modalData?.driver_data?.first_name}
                      </p>
                    </div>
                    <div className="lg:grid lg:grid-cols-2">
                      <p className="text-[14px] font-[600] text-yarpGray">
                        Middle Name
                      </p>
                      <p className="text-[14px] font-[300] text-yarpGray">
                        {modalData?.driver_data?.middle_name || "N/A"}
                      </p>
                    </div>
                    <div className="lg:grid lg:grid-cols-2">
                      <p className="text-[14px] font-[600] text-yarpGray">
                        Last Name
                      </p>
                      <p className="text-[14px] font-[300] text-yarpGray">
                        {modalData?.driver_data?.last_name}
                      </p>
                    </div>
                    <div className="lg:grid lg:grid-cols-2">
                      <p className="text-[14px] font-[600] text-yarpGray">
                        Phone No.
                      </p>
                      <p className="text-[14px] font-[300] text-yarpGray">
                        {modalData?.driver_data?.phone}
                      </p>
                    </div>
                    <div className="lg:grid lg:grid-cols-2">
                      <p className="text-[14px] font-[600] text-yarpGray">
                        Joined
                      </p>
                      <p className="text-[14px] font-[300] text-yarpGray">
                        {moment(modalData?.updated_at).format(
                          "Do MMMM YYYY hh:mm: a"
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                {/* national id */}
                <div className="mt-5">
                  <p className="text-[20px] font-[600] text-yarpGray">
                    National ID
                  </p>

                  <hr />

                  <div className="w-[450px]">
                    <div className="lg:grid lg:grid-cols-2">
                      <p className="font-[600] text-[14px] text-yarpGray">
                        Ghana Card NO.
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {modalData?.national_id || "N/A"}
                      </p>
                    </div>
                  </div>

                  <div className="lg:grid lg:grid-cols-2 lg:gap-x-5 w-full">
                    <div className="w-full">
                      <div className="w-full rounded-[20px] mt-2">
                        <PhotoView
                          src={
                            modalData?.national_id_image ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                        >
                          <img
                            src={
                              modalData?.national_id_image ||
                              "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                            }
                            alt="nFront"
                            className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                          />
                        </PhotoView>
                      </div>
                      <small className="text-gray-500 mt-[13px]">
                        Front View
                      </small>
                    </div>

                    <div className="w-full">
                      <div className="w-full rounded-[20px] mt-2">
                        <PhotoView
                          src={
                            modalData?.national_id_image_back ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                        >
                          <img
                            src={
                              modalData?.national_id_image_back ||
                              "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                            }
                            alt="nBack"
                            className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                          />
                        </PhotoView>
                      </div>
                      <small className="text-gray-500 mt-[13px]">
                        Back View
                      </small>
                    </div>
                  </div>
                </div>

                {/* licese */}
                <div className="mt-5">
                  <p className="text-[20px] font-[600] text-yarpGray">
                    Licence Info
                  </p>

                  <hr />

                  <div className="w-full lg:grid lg:grid-cols-3 gap-[40px]">
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Licence No.
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {modalData?.licence}
                      </p>
                    </div>
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Date Issued
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {moment(modalData?.dateIssued).format("Do MMMM YYYY")}
                      </p>
                    </div>
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Expiry Date
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {moment(modalData?.dateExpired).format("Do MMMM YYYY")}
                      </p>
                    </div>
                  </div>

                  <div className="w-full lg:grid lg:grid-cols-3 gap-[40px]">
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Active Renewal
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {moment(modalData?.license_active_renewal).format(
                          "Do MMMM YYYY"
                        )}
                      </p>
                    </div>
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Next Renewal
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {moment(modalData?.license_renewals?.[0]).format(
                          "Do MMMM YYYY"
                        )}
                      </p>
                    </div>
                    <div>
                      <p className="font-[600] text-[14px] text-yarpGray mb-1">
                        Last Renewal
                      </p>
                      <p className="font-[400] text-[14px] text-yarpGray">
                        {moment(modalData?.license_renewals?.[1]).format(
                          "Do MMMM YYYY"
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="lg:grid lg:grid-cols-2 lg:gap-x-5">
                    <div>
                      <div className="w-full border border-gray-500 rounded-[20px] mt-2">
                        <PhotoView
                          src={
                            modalData?.licence_image ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                        >
                          <img
                            src={
                              modalData?.licence_image ||
                              "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                            }
                            alt="lfront"
                            className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                          />
                        </PhotoView>
                      </div>
                      <small className="text-gray-500 mt-[13px]">
                        Front View
                      </small>
                    </div>
                    <div>
                      <div className="w-full border border-gray-500 rounded-[20px] mt-2">
                        <PhotoView
                          src={
                            modalData?.licence_back_image ||
                            "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                          }
                        >
                          <img
                            src={
                              modalData?.licence_back_image ||
                              "https://www.tundralodge.com/integration/tc-theme/public/img/placeholder_4_3.png"
                            }
                            alt="lBack"
                            className="w-full h-[300px] object-cover rounded-[20px] cursor-pointer"
                          />
                        </PhotoView>
                      </div>
                      <small className="text-gray-500 mt-[13px]">
                        Back View
                      </small>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-4">
          <button className="text-yarpGray" onClick={() => setShow(false)}>
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded-[40px] border-[1px] border-red-500 text-red-500 hover:bg-red-500 hover:text-white flex justify-center items-center disabled:cursor-not-allowed"
            onClick={() => handleOpenedDeclineModal()}
            disabled={isBusy}
          >
            {isBusy ? <Spinner color="red" /> : <span>Decline</span>}
          </button>
          <button
            className="px-4 py-2 rounded-[40px] bg-yarpOrange text-white flex justify-center items-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={approveDriver}
            disabled={isBusy}
          >
            {isBusy ? <Spinner /> : <span> Approve Driver</span>}
          </button>
        </Modal.Footer>
      </Modal>

      {/* declined modal */}
      {openDeclineModal ? (
        <DeclineDialog
          isOpen={openDeclineModal}
          handleClose={handleCloseDeclineModal}
          userData={modalData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default UnapprovedDriversTable;
