import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import BaseService from "../../helpers/baseService";
import { global_variables } from "../../helpers/globalVariables";

const TotalFleetOwners = () => {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getTotal();
  }, []);

  const getTotal = async () => {
    setLoading(true);

    try {
      const response: any = await BaseService.get_api(
        `${global_variables.yarpusers}?filter={"condition":"type","type":"fleet"}&limit=1`
      );
      // console.log(response);
      setTotal(response?.data?.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="w-full bg-purple-300 p-4 min-h-[120px] max-h-[120px] rounded-[20px] text-white">
        <Typography variant="h4">{loading ? "--" : total}</Typography>
        <Typography className="mt-1">Fleet Owners</Typography>
      </div>
    </>
  );
};

export default TotalFleetOwners;
