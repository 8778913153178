import { useEffect, useState } from 'react';
import Select from 'react-select';
import BaseService from '../../helpers/baseService';
import { global_variables } from '../../helpers/globalVariables';

interface brandProps {
    onChange?: (data: any) => void
    selectedBrand: string
    value?: any
}

const TruckModelSelector = ({ onChange, selectedBrand, value }: brandProps) => {

    const [isFetching, setIsFetching] = useState(false)
    const [allModels, setAllModels] = useState([])

    //handleModelSearch
    useEffect(() => {
        const handleModelSearch = () => {

            if (selectedBrand !== "") {
                setIsFetching(true);

                BaseService.get_api_v2(`${global_variables.get_truck_types_v2}?filter={"brand":${JSON.stringify(selectedBrand)}}&limit=1000000`)
                    //, "model":{"$regex":"TRADE","$options":"i"}
                    .then((response) => {
                        // console.log(response?.data?.payload);
                        const rawData = response?.data?.payload
                        const preparedData = rawData.map((item: any) => (
                            { label: item?.model, value: item?.model }
                        ))
                        setAllModels(preparedData)
                        setIsFetching(false);
                    }).catch((error: any) => {
                        console.log(error);
                        setIsFetching(false);
                    })
            }
            else {
                setAllModels([])
            }

        };

        handleModelSearch()
    }, [selectedBrand])


    return (
        <>
            <Select
                className="basic-single"
                placeholder="Search truck model"
                isLoading={isFetching}
                isClearable={true}
                isSearchable={true}
                options={allModels}
                noOptionsMessage={() => "Example: Ranger"}
                onChange={onChange}
                isDisabled={isFetching || selectedBrand === ""}
                value={value}
            />
        </>
    )
}

export default TruckModelSelector
