import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import BaseService from "../../../helpers/baseService";
import { global_variables } from "../../../helpers/globalVariables";
import ShowToast from "../../../components/alerts/all_toasts";
import { formatCedis } from "../../../utils/formatCedis";
import { LoadingDataComponent } from "../../../components/general/loadingDataComponent";
import NoDataComponent from "../../../components/general/noDataComponent";
import { Tooltip } from "@material-tailwind/react";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";

export const TransactionHistoryTable = ({
  user_id,
  mytype,
}: {
  user_id: any;
  mytype: any;
}) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [reFetch, setReFetch] = useState(false);

  const getData = (lim: number, sk: number) => {
    setLoading(true);
    BaseService.get_api(
      `${global_variables.getTransaction}?filter={"condition":"${mytype}","parameter":"${user_id}"}&limit=${lim}&skip=${sk}&sorting={"timestamp":-1}`
    )
      .then((res) => {
        setTotal(res?.data?.total);
        setTransactions(res?.data?.payload);
        setLoading(false);
      })
      .catch((err) => {
        ShowToast.error_toast(err?.response?.data?.error);
      });
  };

  useEffect(() => {
    getData(limit, skip);
  }, [reFetch]);

  const columns: any = [
    {
      name: "Amount",
      selector: (row: { amount: any }) => formatCedis(row?.amount),
    },
    {
      name: "Transaction Type",
      selector: (row: { action: any }) => row?.action,
    },
    {
      name: "Payment method",
      selector: (row: { method: any }) => row?.method,
    },
    {
      name: "Phone",
      selector: (row: { driver_data: { phone: any } }) =>
        row?.driver_data?.phone,
    },
  ];

  const handleRowChange = (a: any) => {
    getData(a, skip);
    setLimit(a);
  };

  const pageChange = (a: any) => {
    if (a === 1) {
      getData(limit, 0);
      setSkip(0);
    } else {
      const newSkip = a * limit - limit;
      getData(limit, newSkip);
      setSkip(newSkip);
    }
  };

  return (
    <>
      <div className="mt-[20px] flex justify-between items-center">
        <Tooltip content="Reload" placement="top">
          <button
            className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center"
            onClick={() => setReFetch(!reFetch)}
          >
            <ArrowPathIcon className="w-5 h-5 text-yarpBlue" />
          </button>
        </Tooltip>
        <div className="flex gap-[10px]">
          <Tooltip content="Print" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <PrinterIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
          <Tooltip content="Export" placement="top">
            <button
              className="w-10 h-10 border-[1px] border-yarpBlue rounded-[5px] flex justify-center items-center disabled:cursor-not-allowed"
              disabled
            >
              <ArrowDownTrayIcon className="w-5 h-5 text-yarpBlue" />
            </button>
          </Tooltip>
        </div>
      </div>
      <DataTable
        className="mt-[10px]"
        data={transactions}
        columns={columns}
        progressPending={loading}
        progressComponent={<LoadingDataComponent />}
        noDataComponent={
          <NoDataComponent text="No transaction records found" />
        }
        pointerOnHover
        paginationTotalRows={total}
        pagination
        paginationServer
        onChangeRowsPerPage={handleRowChange}
        onChangePage={pageChange}
      />
    </>
  );
};
